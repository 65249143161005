<template>
  <div class="app-links-list">
    <div class="mb" v-if="withSearch">
      <ui-input v-model="search" placeholder="输入关键词筛选……" clearable></ui-input>
    </div>
    <div v-for="item in filteredData" :key="item.key" class="app-links-list__item">
      <ui-link
        :type="value === item.id ? 'primary' : 'default'"
        @click="() => $emit('input', item.key || item.id)"
      >
        {{ item.title }}
      </ui-link>
    </div>
  </div>
</template>

<script>
import { _, containsText } from '@yishitec/web';

export default {
  name: 'AppLinksList',
  props: {
    value: { type: [Number, String], default: '' },
    data: { type: Array, default: () => [] },
    withSearch: { type: Boolean, default: false },
  },
  emits: ['input'],
  data() {
    return {
      search: '',
    };
  },
  computed: {
    filteredData() {
      if (!this.search) return [...this.data];
      return _.filter(this.data, (item) => containsText(item.title, this.search));
    },
  },
};
</script>

<style scoped lang="less">
.app-links-list {
  &__item {
    padding: 4px 0;
  }
}
</style>
