<template lang="pug">
component(v-bind="componentAttrs")
  el-button(
    :type="type"
    @click="handleClick"
  )
    slot
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    type: { type: String, default: 'default' },
    to: { type: String, default: null },
  },
  emits: ['click'],
  data() {
    return {};
  },
  computed: {
    componentAttrs() {
      if (this.to) {
        return {
          is: 'router-link',
          to: this.to,
        };
      }
      return {
        is: 'span',
      };
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {},
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped></style>
