<template lang="pug">
div.title-row
  span.title {{ title }}
  span.hint(v-if="hint") {{ hint }}
</template>

<script>
export default {
  name: 'DashboardSectionTitle',
  props: {
    title: String,
    hint: String,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.title-row {
  background: url('/images/screen-v3/dashboard-title.png') no-repeat top right;
  background-size: 133px 11px;
  padding-top: 4px;
}

.title {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #fff;
}

.hint {
  display: inline-block;
  margin-left: 0.75em;
  font-size: 12px;
  color: #9ba6ad;
}
</style>
