<template lang="pug">
div.ring-row
  .tag {{ percentage }}
  .ring
    .ring-active(:style="getStyles()")
    .hint(v-if="config.hint") {{ config.hint }}
    .label(v-if="config.label") {{ config.label.replace('#', `${n || 0}/${total || 0}`) }}
</template>

<script>
import { _, numeral } from '@yishitec/web';

export default {
  name: 'RingChart',
  props: {
    config: Object,
    list: Array,
  },
  data() {
    return {};
  },
  computed: {
    n() {
      return _.sumBy(this.list, (i) => i[this.config.numberBy]);
    },
    total() {
      return _.sumBy(this.list, (i) => i[this.config.totalBy]);
    },
    ratio() {
      const { n, total } = this;
      return n / (total || 1);
    },
    percentage() {
      return numeral(this.ratio).format('0,0.0%');
    },
  },
  methods: {
    getStyles() {
      return {
        background: `conic-gradient(from 0deg, ${this.config.color} ${
          this.ratio * 100
        }%, transparent ${this.ratio * 100}%)`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.ring-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 0 1px;
}

.tag {
  margin-right: 10px;
  background: #010101;
  border-radius: 1000px;
  box-sizing: border-box;
  padding: 6px;
  font-size: 12px;
  color: #4d5e69;
}

.ring {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  box-sizing: border-box;
  border: solid 10px #4d5e69;
  border-radius: 1000px;
  font-size: 12px;
  color: #fff;
  position: relative;
}

.ring-active {
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  mask: radial-gradient(transparent 39px, black 39px);
  border-radius: 1000px;
}

.hint {
  color: #9ba6ad;
  position: relative;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
}

.label {
  position: relative;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
}
</style>
