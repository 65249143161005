<template>
<div>
  <ui-stage>
    <ui-alert type='success' :closable='false'>
      <ui-flex row style='padding-bottom: 15px'>
        <div>
          <h3>日期</h3>
          <ui-datetime-picker v-model='date' type='date' />
        </div>
        <div class='ml'>
          <h3>项目</h3>
          <ui-admin-model-picker v-model='projectId' :config='projectPickerConfig' />
        </div>
        <div class='ml' v-if='canSaveConfig'>
          <h3>管理字段</h3>
          <router-link :to='configUrl'>
            <ui-button icon='el-icon-setting'>编辑字段</ui-button>
          </router-link>
        </div>
      </ui-flex>
    </ui-alert>
    <ui-admin-form
      v-if='entryFields && saveData'
      class='mt'
      :readonly='!canSave'
      :fields='entryFields'
      :cols='cols'
      :model='data'
      @submit='handleSubmit'
    />
    <SupervisionSignOffNav
      :projectId='projectId'
    />
  </ui-stage>
</div>
</template>

<script>
import { _, moment } from '@yishitec/web';
import UiAdminModelPicker from '@yishitec/web/components/UiAdminModelPicker.vue';
import permissions from '../../../mixins/permissions';
import SupervisionSignOffNav from '../../../components/SupervisionSignOffNav.vue';

const projectPickerConfig = {
  title: '项目',
  name: 'projectId',
  model: {
    name: 'Project',
    titleFields: ['title'],
    type: 'string',
    appData: {
      apiName: 'projects',
    },
  },
  type: 'model',
};

export default {
  name: 'List',
  components: {
    UiAdminModelPicker,
    SupervisionSignOffNav,
  },
  mixins: [permissions],
  data() {
    return {
      date: moment().format('YYYY-MM-DD'),
      projectId: '',
      data: null,
      entryFields: [],
      projectPickerConfig,
      saveData: {},
      cols: 1,
      companyName: '',
    };
  },
  computed: {
    canSave() {
      return this.amI(this.projectId, '监理单位', '管理人员', true);
    },
    canSaveConfig() {
      return this.amI(null, null, '平台管理员');
    },
    configUrl() {
      return `/admin/supervision-logs/config/${encodeURIComponent(this.companyName)}`;
    },
  },
  watch: {
    currentProjects() {
      this.projectId = _.get(this, 'currentProjects.0');
    },
    projectId() {
      this.loadData();
    },
    date() {
      this.loadData();
    },
  },
  mounted() {
    this.projectId = _.get(this, 'currentProjects.0');
  },
  methods: {
    async loadData() {
      if (!this.projectId) {
        this.projectId = _.get(this, 'currentProjects.0');
      }
      if (!this.projectId) {
        this.$message({
          type: 'error',
          message: '请选择一个项目',
        });
        return;
      }
      const res = await this.withLoading(
        this.api(
          `/api/supervision-logs/edit/${encodeURIComponent(this.projectId)}/${encodeURIComponent(
            moment(this.date).format('YYYY-MM-DD'),
          )}`,
        ),
      );
      this.saveData = _.get(res, 'item', {});
      let data = _.get(res, 'item.logs', '{}');
      const attributes = _.get(res, 'attributes', []);
      const cols = _.get(res, 'cols', 1);
      const companyName = _.get(res, 'companyName', '');
      if (!data) {
        return;
      }
      try {
        data = JSON.parse(data);
      } catch (e) {
        data = {};
        console.log(e);
      }
      this.data = data;
      this.entryFields = attributes;
      this.cols = cols;
      this.companyName = companyName;
    },
    async handleSubmit() {
      const res = await this.withLoading(
        this.api('/api/supervision-logs/save', {
          ...this.saveData,
          logs: JSON.stringify(this.data),
        }),
      );
      if (res) {
        this.$message({
          type: 'success',
          message: '已保存',
        });
        this.loadData();
      }
    },
  },
};
</script>

<style lang='less' scoped></style>
