<template lang="pug">
el-tag(
  v-if="value"
  size="mini"
  :type="type"
).ml {{ value }}
</template>

<script>
import { _ } from '@yishitec/web';

export default {
  name: 'ApprovalStateTag',
  props: {
    value: { type: String, default: null },
  },
  computed: {
    type() {
      return (
        _.get(
          {
            待审核: 'primary',
            已审核: 'success',
            受控: 'success',
            整改: 'warning',
          },
          this.value,
        ) || 'info'
      );
    },
  },
};
</script>
