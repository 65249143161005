<template lang="pug">
span {{ formatDateTime(value || Date.now()) }}
</template>

<script>
import { formatDateTime } from '@yishitec/web';

export default {
  name: 'FormDateTime',
  props: {
    value: { type: [String, Number], default: null },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {},
    formatDateTime,
  },
};
</script>

<style lang="scss" scoped></style>
