<template>
  <div>
    <ui-stage>
      <ui-alert type="success" :closable="false">
        <ui-flex row style="padding-bottom: 15px">
          <div class="ml">
            <h3>公司</h3>
            <ui-admin-model-picker
              v-model="vCompanyName"
              :config="selectCompanyConfig"
              url="/api/supervision-logs/companies"
            />
          </div>
          <div class="ml">
            <h3>管理监理日志</h3>
            <router-link :to="listUrl">
              <ui-button icon="el-icon-list">管理监理日志</ui-button>
            </router-link>
          </div>
        </ui-flex>
      </ui-alert>

      <ui-admin-form
        v-for="(item, index) in data"
        :key="index"
        class="mt"
        :fields="entryFields"
        :cols="3"
        :model="item"
        :with-save="false"
      >
        <template v-slot:field__actions>
          <ui-button-group>
            <ui-button icon="el-icon-plus" size="small" @click="addRow(index)" />
            <ui-button icon="el-icon-minus" size="small" @click="removeRow(index)" />
            <ui-button icon="el-icon-arrow-up" size="small" @click="moveRowUp(index)" />
            <ui-button icon="el-icon-arrow-down" size="small" @click="moveRowDown(index)" />
          </ui-button-group>
        </template>
      </ui-admin-form>

      <ui-divider />
      <ui-admin-form
        style="margin-top: 30px"
        :fields="attributes"
        :cols="2"
        :model="saveData"
        :with-save="false"
      />
      <ui-divider />

      <ui-flex row center>
        <ui-button type="primary" icon="el-icon-success" @click="handleSubmit">提交</ui-button>
      </ui-flex>
    </ui-stage>
  </div>
</template>

<script>
import { _ } from '@yishitec/web';
import UiAdminModelPicker from '@yishitec/web/components/UiAdminModelPicker.vue';
import permissions from '../../../mixins/permissions';

const fieldTypes = {
  input: '单行文本',
  text: '多行文本',
  file: '文件上传',
  datetime: '日期',
};

const selectCompanyConfig = {
  title: '公司',
  name: 'companyName',
  model: {
    titleFields: ['title'],
    type: 'string',
    appData: {
      apiName: 'users',
    },
  },
  type: 'model',
};

export default {
  name: 'SupervisionLogsConfig',
  components: {
    UiAdminModelPicker,
  },
  mixins: [permissions],
  props: {
    companyName: { type: String, default: '' },
  },
  data() {
    return {
      vCompanyName: this.companyName,
      data: null,
      entryFields: [
        { name: 'title', title: '字段名称' },
        {
          name: 'type',
          title: '字段类型',
          type: 'select',
          options: _.values(fieldTypes),
        },
        { name: 'actions', title: '操作' },
      ],
      selectCompanyConfig,
      saveData: {},
      cols: 1,
      attributes: [],
    };
  },
  computed: {
    listUrl() {
      return '/admin/supervision-logs/';
    },
  },
  watch: {
    vCompanyName() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      if (!this.vCompanyName) return;
      const res = await this.withLoading(
        this.api('/api/supervision-logs/company-config', {
          companyName: this.vCompanyName,
        }),
      );
      this.saveData = _.get(res, 'item', {});
      this.attributes = _.get(res, 'attributes', []);
      let data = _.get(res, 'item');
      if (!data) {
        return;
      }
      try {
        data = JSON.parse(data.config);
      } catch (e) {
        data = {};
        // eslint-disable-next-line no-console
        console.log(e);
      }
      this.data = _.map(data, (item) => {
        const type = fieldTypes[item.type] || fieldTypes.input;
        return { ...item, type };
      });
      if (!this.data.length) {
        this.data = [
          {
            name: '监理日志',
            title: '监理日志',
            type: fieldTypes.text,
          },
        ];
      }
    },
    async handleSubmit() {
      const res = await this.withLoading(
        this.api('/api/supervision-logs/save-company-config', {
          ...this.saveData,
          config: JSON.stringify(
            _.map(this.data, (item) => {
              const type = _.findKey(fieldTypes, (findType) => item.type === findType);
              return {
                ...item,
                type,
              };
            }),
          ),
        }),
      );
      if (res) {
        this.$message({
          type: 'success',
          message: '已保存',
        });
        this.fetchData();
      }
    },
    addRow(rowIndex) {
      this.data.splice(rowIndex + 1, 0, {
        name: `t${Date.now()}`,
        title: '监理日志',
        type: fieldTypes.text,
      });
    },
    removeRow(rowIndex) {
      this.data.splice(rowIndex, 1);
    },
    moveRowUp(rowIndex) {
      this.data.splice(rowIndex - 1, 0, this.data[rowIndex]);
      this.data.splice(rowIndex + 1, 1);
    },
    moveRowDown(rowIndex) {
      this.data.splice(rowIndex + 2, 0, this.data[rowIndex]);
      this.data.splice(rowIndex, 1);
    },
  },
};
</script>

<style lang="less" scoped></style>
