<template>
  <ui-flex
    row
    :style="`height: calc(100vh - ${screenOffset}); ${
      minHeight ? `min-height: ${minHeight};` : ''
    }`"
  >
    <ui-flex style="background: #eee; position: relative" :class="{ mr: !readonly }">
      <div style="width: 100%; height: 100%" id="container"></div>
      <div
        id="map-search"
        style="position: absolute; top: 15px; right: 30px; width: 220px"
        v-if="withSearch"
      >
        <div class="input-group">
          <ui-input id="map-search-input" placeholder="按地址搜索……" v-model="searchKeyword" />
        </div>
      </div>
    </ui-flex>
    <ui-flex style="flex: 0 0 400px" v-loading="!mapReady" v-if="!readonly">
      <ui-alert type="warning" v-if="isDrawing && type === 'polygon'" :closable="false" class="mb"
        >在地图上单击开始绘制，双击结束绘制。
      </ui-alert>
      <div class="mb">
        <ui-button type="primary" @click="draw" v-if="canAdd">
          添加绘制{{ type | markerName }}
        </ui-button>
        <el-link
          v-if="type === 'point' && !displayPointInput && canAdd"
          type="primary"
          class="ml"
          @click="showPointInput"
        >
          或输入精确坐标添加
        </el-link>
        <ui-button
          type="primary"
          @click="clear(0)"
          v-if="!isDrawing && type === 'point' && overlays && overlays.length"
        >
          清除坐标
        </ui-button>
        <ui-button type="primary" @click="drawEnd" v-if="isDrawing">完成绘制</ui-button>
        <div v-if="type === 'point' && displayPointInput && !isDrawing && canAdd" class="mt mb">
          <el-input v-model="pointInput">
            <ui-button slot="append" @click="addPoint">添加</ui-button>
          </el-input>
        </div>
      </div>
      <template v-if="type === 'polygon' && !isDrawing && overlays && overlays.length">
        <ui-flex
          row
          j-start
          a-center
          class="mb"
          v-for="(overlayGroup, overlayGroupIndex) in overlays"
          :key="overlayGroupIndex"
        >
          <div class="mr">{{ overlayGroupIndex | formatIndex }}</div>
          <ui-button
            type="primary"
            @click="editClose"
            v-if="isEditing && editingOverlayIndex === overlayGroupIndex"
          >
            完成编辑
          </ui-button>
          <ui-button
            type="primary"
            @click="() => edit(overlayGroupIndex)"
            v-else
            :disabled="isEditing && editingOverlayIndex !== overlayGroupIndex"
          >
            编辑{{ type | markerName }}
          </ui-button>
          <ui-button
            type="primary"
            @click="() => clear(overlayGroupIndex)"
            v-if="!isDrawing && overlays && overlays.length"
            :disabled="isEditing"
          >
            清除{{ type | markerName }}
          </ui-button>
        </ui-flex>
      </template>

      <ui-divider />
      <div class="m-t-15">
        <ui-button type="primary" @click="handleSubmit" v-if="true">保存</ui-button>
      </div>
    </ui-flex>
  </ui-flex>
</template>

<script>
/* global AMap */
import { _, numeral } from '@yishitec/web';

const KEY_TIANDITU = '76a69818bf68eb03ed03128d186186f7';

export default {
  name: 'AppMapMouseEditor',
  props: {
    value: { type: String },
    readonly: { type: Boolean, default: false },
    screenOffset: { type: String, default: '170px' },
    minHeight: { type: String, default: '' },
    type: {
      type: String,
      default: 'polygon',
      validator: (type) => ['polygon', 'point'].includes(type),
    },
    mapStyle: { type: String, default: null },
    withSearch: { type: Boolean, default: true },
  },
  emits: ['input', 'map-ready'],
  data() {
    return {
      mapReady: false,
      isDrawing: false,
      overlays: [],
      isEditing: false,
      searchKeyword: '',
      editingOverlayIndex: null,
      displayPointInput: false,
      pointInput: '',
      scriptAdded: false,
    };
  },
  computed: {
    canAdd() {
      if (this.isDrawing) {
        return false;
      }
      if (this.type === 'point' && this.overlays && this.overlays.length > 0) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    window.P = null;
    this.P = null;
    window.T = null;
    await this.initMap();
  },
  beforeDestroy() {
    const { P } = window;
    if (P) {
      if (P.map) {
        P.map.clearOverLays();
        P.map = null;
      }
    }
  },
  methods: {
    parseValue() {
      try {
        if (!this.value) return [];

        let value = [];
        if (this.type === 'polygon') {
          value = JSON.parse(this.value);
          if (!_.isArray(value[0])) throw new Error('无法读取存储的围栏数据');
        } else if (this.type === 'point') {
          value = [];
          if (this.value) {
            value.push(new window.T.LngLat(...this.value.split(',')));
          }
        }
        return value;
      } catch (e) {
        return [];
      }
    },
    async getTiandituInstance() {
      if (window.T) {
        return window.T;
      }

      if (!this.scriptAdded) {
        const script = document.createElement('script');
        script.setAttribute('src', `https://api.tianditu.gov.cn/api?v=4.0&tk=${KEY_TIANDITU}`);
        document.head.appendChild(script);
        this.scriptAdded = true;
      }

      await new Promise((resolve) => setTimeout(resolve, 500));
      const res = await this.getTiandituInstance();
      return res;
    },
    async initMap() {
      const P = {
        overlays: [...this.overlays],
        polygonOptions: {
          strokeColor: '#22BC00',
          strokeOpacity: 0.5,
          strokeWeight: 2,
          strokeStyle: 'dashed',
          fillColor: '#CDFFAA',
          fillOpacity: 0.5,
        },
        polylineOptions: {
          strokeColor: '#F43535',
          strokeOpacity: 1,
          strokeWeight: 4,
          strokeStyle: 'solid',
        },
      };
      window.P = P;
      const G = {
        mapCenterShanghai: [121.473658, 31.230378],
        mapZoomDefault: 12,
      };

      const T = await this.getTiandituInstance();

      const ext = {};

      P.map = new T.Map('container');
      P.map.centerAndZoom(new T.LngLat(...G.mapCenterShanghai), G.mapZoomDefault);
      if (this.mapStyle) {
        P.map.setStyle('indigo');
      }

      P.map.addControl(this.buildMapControl());

      this.addOverlays();
      this.overlays = this.buildOverlaysData();

      this.P = P;

      this.mapReady = true;

      this.$emit('map-ready', {
        map: P.map,
        T,
        mapUtils: {
          toLngLat: this.toLngLat,
          addPolygonOverlay: this.addPolygonOverlay,
          addPolylineOverlay: this.addPolylineOverlay,
          addPointOverlay: this.addPointOverlay,
          addLabelOverlay: this.addLabelOverlay,
          buildInfoWindow: this.buildInfoWindow,
          setFitView: this.setFitView,
        },
      });
    },
    buildInfoWindow(options) {
      const { html, width, height } = options || {};
      const { T } = window;
      const infoWindow = new T.InfoWindow({
        autoPan: true,
        closeButton: true,
        closeOnClick: true,
      });
      if (width) {
        infoWindow.setMinWidth(width);
        infoWindow.setMaxWidth(width);
      }
      if (height) {
        infoWindow.setMaxHeight(height);
      }
      infoWindow.setContent(html);
      return infoWindow;
    },
    toLngLat(value) {
      const { T } = window;
      if (_.isString(value)) {
        return new T.LngLat(...value.split(','));
      }
      if (_.isArray(value) && value.length === 2) {
        return new T.LngLat(...value);
      }
      if (_.isObject(value) && value.lng && value.lat) {
        return new T.LngLat(value.lng, value.lat);
      }
      return null;
    },
    addOverlays() {
      const viewPoints = [];
      const overlays = this.parseValue();
      if (this.type === 'polygon') {
        _.forEach(overlays, (overlayGroup, overlayGroupIndex) => {
          const points = _.map(overlayGroup, (point) => new window.T.LngLat(point.lng, point.lat));
          this.addPolygonOverlay(points);
          viewPoints.push(...points);
        });
      } else if (this.type === 'point') {
        if (overlays[0]) {
          const point = overlays[0];
          this.addPointOverlay(point);
          viewPoints.push(point);
        }
      }
      const viewport = window.P.map.getViewport(viewPoints);
      const { center, zoom } = viewport || {};
      if (center && zoom) {
        window.P.map.centerAndZoom(center, zoom);
      }
    },
    addLabelOverlay(point, text, iOptions) {
      const {
        x = 0,
        y = 0,
        fontSize = 10,
        color = '#000',
        bgColor = 'transparent',
        ...labelOptions
      } = iOptions || {};
      const { T, P } = window;
      const label = new T.Label({
        text,
        offset: new T.Point(x, y),
        position: point,
      });
      label.setFontSize(fontSize);
      label.setFontColor(color);
      label.setBackgroundColor(bgColor);
      label.setBorderLine(0);
      P.map.addOverLay(label);
      return label;
    },
    addPointOverlay(point, iconPng, iOptions) {
      const { iconSize = {}, infoWindow, onClick } = iOptions || {};
      let icon;
      if (iconPng) {
        icon = new window.T.Icon({
          iconUrl: iconPng,
          ...(iconSize && iconSize.width && iconSize.height
            ? {
                iconAnchor: new window.T.Point(iconSize.width / 2, iconSize.height),
              }
            : {}),
        });
      } else {
        icon = new window.T.Icon.Default();
      }
      const options = {
        icon,
        draggable: false,
      };
      const marker = new window.T.Marker(point, options);
      window.P.map.addOverLay(marker);

      if (infoWindow) {
        marker.addEventListener('click', () => {
          marker.openInfoWindow(this.buildInfoWindow(infoWindow));
        });
      }

      marker.addEventListener('click', () => {
        if (_.isFunction(onClick)) {
          onClick();
        }
      });

      return marker;
    },
    addPolygonOverlay(points, iOptions) {
      const { infoWindow, highlightByOpacity, onClick, ...polygonOptions } = iOptions || {};

      const {
        strokeColor: color,
        strokeWeight: weight,
        strokeOpacity: opacity,
        fillColor,
        fillOpacity,
      } = window.P.polygonOptions;
      const options = _.extend(
        {
          color,
          weight,
          opacity,
          fillColor,
          fillOpacity,
          lineStyle: 'solid',
        },
        polygonOptions,
      );
      const polygon = new window.T.Polygon(points, options);
      window.P.map.addOverLay(polygon);

      if (highlightByOpacity) {
        polygon.addEventListener('mouseover', () => {
          polygon.setOpacity(_.min([1, options.opacity * highlightByOpacity]));
          polygon.setFillOpacity(_.min([1, options.fillOpacity * highlightByOpacity]));
        });
        polygon.addEventListener('mouseout', () => {
          polygon.setOpacity(options.opacity);
          polygon.setFillOpacity(options.fillOpacity);
        });
      }

      if (infoWindow) {
        polygon.addEventListener('click', () => {
          // tianditu info window instance
          const tInfoWindow = this.buildInfoWindow(infoWindow);
          window.P.map.openInfoWindow(tInfoWindow, points[0]);
        });
      }

      polygon.addEventListener('click', () => {
        if (_.isFunction(onClick)) {
          onClick();
        }
      });

      return polygon;
    },
    addPolylineOverlay(points, iOptions) {
      const { ...polylineOptions } = iOptions || {};
      const {
        strokeColor: color,
        strokeWeight: weight,
        strokeOpacity: opacity,
        lineStyle,
      } = window.P.polylineOptions;
      const options = _.extend(
        {
          color,
          weight,
          opacity,
          lineStyle,
        },
        polylineOptions,
      );
      const polyline = new window.T.Polyline(points, options);
      window.P.map.addOverLay(polyline);
      return polyline;
    },
    buildMapControl() {
      const { T } = window;
      const control = new T.Control.Zoom({
        position: window.T_ANCHOR_BOTTOM_RIGHT,
      });
      return control;
    },
    draw() {
      this.clearTools();
      if (this.type === 'polygon') {
        this.drawPolygon();
      } else if (this.type === 'point') {
        this.drawPoint();
      }
    },
    drawPoint() {
      const { P, T } = window;
      if (P.markerTool) {
        P.markerTool.close();
      }
      const tool = new T.MarkTool(P.map, {
        icon: new T.Icon.Default(),
        follow: true,
      });
      tool.addEventListener('mouseup', (event) => {
        if (!P.isDrawing) return;
        tool.close();
        this.drawEnd();
      });
      tool.open();
      P.markerTool = tool;
      P.isDrawing = true;
      this.isDrawing = P.isDrawing;
      return tool;
    },
    drawPolygon() {
      const { P, T } = window;
      if (P.polygonTool) {
        P.polygonTool.endDraw();
        P.polygonTool.close();
      }
      const {
        strokeColor: color,
        strokeWeight: weight,
        strokeOpacity: opacity,
        fillColor,
        fillOpacity,
      } = window.P.polygonOptions;
      const tool = new T.PolygonTool(P.map, {
        color,
        weight,
        opacity,
        fillColor,
        fillOpacity,
        lineStyle: 'solid',
        showLabel: false,
      });
      P.polygonTool = tool;
      tool.addEventListener('draw', (event) => {
        if (!P.isDrawing) return;
        tool.close();
        this.drawEnd();
      });
      tool.open();
      P.isDrawing = true;
      this.isDrawing = P.isDrawing;
      this.overlays = P.overlays;
    },

    clearTools() {
      _.forEach(this.getOverlays(), (overlay) => {
        if (overlay.disableEdit) {
          overlay.disableEdit();
        }
      });
    },

    drawEnd() {
      setTimeout(() => {
        const { P, T } = window;

        this.clearTools();

        this.buildOverlaysData();
        this.overlays = P.overlays;
        P.isDrawing = false;
        this.isDrawing = P.isDrawing;
        P.isEditing = false;
        this.isEditing = P.isEditing;
      }, 100);
    },
    setFitView(markers) {
      const { P } = window;
      const viewPoints = _.flatten(
        _.map(markers, (overlay) => {
          if (!overlay) return true;
          // polygon
          if (overlay.getLngLats && overlay.getType() === 5) {
            if (overlay.getLngLats()) {
              const points = overlay.getLngLats()[0];
              return _.map(points, (point) => {
                return this.parsePoint(point);
              });
            }
          }
          // polyline
          if (overlay.getLngLats && overlay.getType() === 4) {
            if (overlay.getLngLats()) {
              const points = overlay.getLngLats();
              return _.map(points, (point) => {
                return this.parsePoint(point);
              });
            }
          }
          // point
          if (overlay.getLngLat) {
            if (overlay.getLngLat()) {
              const point = overlay.getLngLat();
              return this.parsePoint(point);
            }
          }
          return [];
        }),
      );
      const viewport = window.P.map.getViewport(viewPoints);
      const { center, zoom } = viewport || {};
      if (center && zoom) {
        P.map.centerAndZoom(center, zoom);
      }
    },
    buildOverlaysData() {
      const { P } = window;
      P.overlays = _.map(this.getOverlays(), (overlay) => {
        // polygon
        if (overlay.getLngLats && overlay.getLngLats()) {
          const points = overlay.getLngLats()[0];
          return _.map(points, (point) => {
            return this.parsePoint(point);
          });
        }
        // point
        if (overlay.getLngLat && overlay.getLngLat()) {
          const point = overlay.getLngLat();
          return this.parsePoint(point);
        }
        return [];
      });
      return P.overlays;
    },
    parsePoint(point) {
      if (!point.getLng) return null;
      const lng = point.getLng();
      const lat = point.getLat();
      return {
        lng,
        lat,
      };
    },
    getOverlays() {
      const { P } = window;
      if (this.type === 'polygon') {
        return P.map.getOverlays().filter((i) => ![1, 2, 4].includes(i.getType()));
      }
      return P.map.getOverlays();
    },
    edit(overlayIndex = 0) {
      const { P } = window;
      const overlay = this.getOverlays()[overlayIndex];
      if (!overlay) return;
      P.isEditing = true;
      this.editingOverlayIndex = overlayIndex;
      overlay.enableEdit();
      this.isEditing = P.isEditing;
    },
    editClose() {
      const { P } = window;
      const overlay = this.getOverlays()[this.editingOverlayIndex];
      this.editingOverlayIndex = null;
      if (!overlay) {
        return;
      }
      overlay.disableEdit();
      this.drawEnd();
    },
    clear(overlayIndex = 0) {
      const { P, T } = window;
      const overlay = this.getOverlays()[overlayIndex];
      if (!overlay) return;
      P.map.removeOverLay(overlay);
      this.pointInput = '';
      const overlays = this.buildOverlaysData();
      this.overlays = [...overlays];
    },
    handleSubmit() {
      const { P } = window;
      this.editClose();
      let value;
      if (this.type === 'polygon') {
        value = JSON.stringify(this.buildOverlaysData());
      } else {
        value = this.stringifyPoint(this.buildOverlaysData()[0]);
      }
      this.$emit('input', value);
      this.displayMapEditorModal = false;
    },
    stringifyPoint(point) {
      if (!point) return null;
      return [point.lng, point.lat].join(',');
    },
    showPointInput() {
      this.displayPointInput = true;
    },
    addPoint() {
      const { P, T } = window;
      const point = new T.LngLat(...String(this.pointInput || '').split(','));
      const marker = this.addPointOverlay(point);
      P.overlays.push(marker);
      this.overlays = P.overlays;
    },
  },
  filters: {
    formatIndex(index) {
      return numeral(index + 1).format('000');
    },
    markerName(type) {
      return _.get(
        {
          polygon: '围栏',
          point: '点坐标',
        },
        type,
        type,
      );
    },
  },
};
</script>

<style lang="less">
.amap-sug-result {
  z-index: 3000;
}

#map-search-input {
  background: fadeout(#fff, 90);
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  color: #fff;
}
</style>
