<template>
  <div>
    <ui-page-header title="各单位用户数统计" />
    <ui-stage class="page-container">
      <h1>在建项目人员考勤、设备登录日记录表</h1>
      <table class="table">
        <thead>
          <th>项目</th>
          <th class="th-sg-admin">施工单位管理人员</th>
          <th class="th-sg-security">施工单位安全员</th>
          <th class="th-sg-worker">施工单位作业人员</th>
          <th class="th-jl">监理单位人员</th>
          <th class="th">危险源设备</th>
          <th>日期</th>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in data" :key="rowIndex">
            <td>{{ row.title }}</td>
            <td>{{ row['施工单位管理人员'] | parted }}</td>
            <td>{{ row['施工单位安全员'] | parted }}</td>
            <td>{{ row['施工单位作业人员'] | parted }}</td>
            <td>{{ row['监理单位人员'] | parted }}</td>
            <td>{{ row['危险源设备'] }}</td>
            <td v-if="rowIndex === 0" :rowspan="data.length">
              {{ today }}
            </td>
          </tr>
        </tbody>
      </table>
    </ui-stage>
  </div>
</template>

<script>
import { moment, _ } from '@yishitec/web';
import Report from './index.vue';

export default {
  name: 'UsersCountReportPreview',
  extends: Report,
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
    };
  },
  filters: {
    parted(value) {
      return _.get(String(value || '').split('/'), [0]) || '';
    },
  },
};
</script>

<style lang="less" scoped>
.page-container {
  text-align: center;
  h1 {
    font-size: 24px;
  }
}
.table {
  margin: 0 auto;
  font-size: 18px;
  border-collapse: collapse;
  border-spacing: 0;
  th,
  td {
    border: solid 1px #000;
    padding: 4px 8px;
  }
  .th-sg-admin {
    background: red;
    color: #fff;
  }
  .th-sg-security {
    background: green;
    color: #fff;
  }
  .th-sg-worker {
    background: yellow;
  }
  .th-jl {
    background: purple;
    color: #fff;
  }
}
</style>
