<template>
  <div>
    <ui-stage v-if="data">
      <ui-tabs v-model="tab" type="border-card">
        <ui-tab-pane name="main" label="编辑">
          <ui-alert
            type="warning"
            :closable="false"
            class="mb"
            v-if="data.approve_state === '待审核'"
            >待审核</ui-alert
          >
          <ui-alert
            type="warning"
            :closable="false"
            class="mb"
            v-if="data.approve_state === '待审核' && data.permissions.canApprove"
          >
            <ui-button type="warning" size="large" @click="handleApprove">审核</ui-button>
          </ui-alert>
          <ui-admin-form
            :compact="compact"
            :readonly="vReadonly"
            :fields="attributes"
            :model="data"
            :module-url="moduleUrl"
            @submit="handleSubmit"
            :with-delete="false"
            :with-save="false"
          />
          <div class="mt text-center" v-if="!vReadonly">
            <ui-button type="primary" icon="el-icon-success" @click="handleSubmit">
              提交
            </ui-button>
            <router-link
              v-if="!vReadonly && data.id"
              :to="`${moduleUrl}/delete/${data.id}`"
              class="ml"
            >
              <ui-button type="info" icon="el-icon-delete"> 删除 </ui-button>
            </router-link>
          </div>
        </ui-tab-pane>
      </ui-tabs>
    </ui-stage>
  </div>
</template>

<script>
import { adminEdit } from '@yishitec/web/mixins';

export default {
  name: 'Edit',
  mixins: [adminEdit],
  data() {
    return {
      withCustomFields: false,
      entriesAttributes: [],
      entries: [],
      newEntry: {},
    };
  },
  methods: {
    async handleFetchDataRes(res) {
      const { canEdit, item } = res;
      this.dataReadonly = !canEdit;
      this.upperLevelUrl = `${this.moduleUrl}/list/${item.project_id}`;
    },
    async handleApprove() {
      const res = await this.withLoading(
        this.api(`/api/${this.model}/approve`, {
          id: this.data.id,
        }),
      );
      if (res) {
        this.fetchData();
        this.$message({
          type: 'success',
          message: '审核完成',
        });
      }
    },
  },
};
</script>
