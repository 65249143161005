<template lang="pug">
div.left-sidebar
  SelectProject(
    v-model="selectedProjectIds"
    :projects="projects"
    style="flex: 0 0 auto; margin-bottom: 10px;"
  )
  .left-sidebar-scroll(
    style="flex: 1 0 1px;"
  )
    LeftStats(:sections="statsSections")
</template>

<script>
import SelectProject from './SelectProject.vue';
import LeftStats from './LeftStats.vue';

export default {
  name: 'LeftSidebar',
  props: {
    selectedProjectIds: Array,
    projects: Array,
    statsSections: Array,
  },
  components: {
    SelectProject,
    LeftStats,
  },
};
</script>

<style lang="less" scoped>
@import '../../../assets/styles/app-mixins.less';

.left-sidebar {
  position: absolute;
  top: 93px;
  left: 14px;
  width: 190px;
  bottom: 0;
  box-sizing: border-box;
  padding: 16px 16px 0 16px;
  overflow: auto;
  background: url('/images/screen-v3/left-sidebar.png') no-repeat top left;
  background-size: 190px 149px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.left-sidebar-scroll {
  overflow: auto;
  box-sizing: border-box;
  margin-right: -10px;
  padding-right: 10px;
  .thin-scroll();
}
</style>
