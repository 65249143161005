<template lang="pug">
  Board(
    title="监理日志"
    icon="/images/data-center-screen/boards/icon-supervision-logs.svg"
    hint="浏览全部"
    sub-window='supervisionLogs'
    @sub-window="$emit('sub-window', $event)"
  )
    CalendarChart(
      :list="history"
      field="supervisionLogsPending"
      @select-month="updateMonth"
    )
</template>

<script>
import { _ } from '@yishitec/web';
import Board from '../Board.vue';
import CalendarChart from '../Charts/CalendarChart.vue';

export default {
  name: 'BoardSupervisionLogs',
  props: {
    projects: Array,
  },
  emits: ['sub-window'],
  components: {
    Board,
    CalendarChart,
  },
  data() {
    return {
      from: null,
      to: null,
      history: [],
    };
  },
  methods: {
    async loadData() {
      if (!this.from || !this.to) {
        return;
      }
      const res = await this.api('/api/reports/data-center-history', {
        from: this.from,
        to: this.to,
        projectIds: this.projects.map((p) => p.projectId),
      })();
      this.history = _.get(res, ['history']) || [];
    },
    updateMonth(options) {
      const { from, to } = options || {};
      this.from = from;
      this.to = to;
      this.loadData();
    },
  },
};
</script>
