<template lang="pug">
div
  .map
    MapCanvas(
      v-if="projects && projects.length"
      :projects="projects"
      :tasks="tasks"
      :cams="cams"
      @show-sub-project="showModalSubProjects($event)"
      :selectedProjectIds.sync="vSelectedProjectIds"
    )
    MapCover(
      :projects="projects"
      :usersCount="usersCount"
      :data="data"
      :tasks="tasks"
      :signins="signins"
    )
  ModalSubProjects(
    v-if="subProjectsParentId"
    :subProjects="subProjects"
    :tasks="tasks"
    @close="showModalSubProjects(null)"
  )
</template>

<script>
import { _ } from '@yishitec/web';
import MapCover from './MapCover.vue';
import MapCanvas from './MapCanvas.vue';
import ModalSubProjects from './ModalSubProjects.vue';

export default {
  name: 'ScreenMap',
  props: {
    projects: Array,
    usersCount: Number,
    data: Object,
    tasks: Object,
    cams: Array,
    signins: Array,
    selectedProjectIds: Array,
  },
  emits: ['update:selectedProjectIds'],
  components: {
    MapCover,
    MapCanvas,
    ModalSubProjects,
  },
  data() {
    return {
      subProjectsParentId: null,
      subProjects: [],
    };
  },
  computed: {
    vSelectedProjectIds: {
      get() {
        return this.selectedProjectIds;
      },
      set(value) {
        this.$emit('update:selectedProjectIds', value);
      },
    },
  },
  methods: {
    showModalSubProjects(projectId) {
      this.subProjectsParentId = projectId;
      if (!projectId) {
        this.subProjects = [];
      } else {
        this.subProjects = _.filter(
          this.projects,
          (findProject) => findProject.parentId === projectId,
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.map {
  position: absolute;
  top: 94px;
  left: 220px;
  right: 270px;
  bottom: 183px;
  overflow: hidden;
  box-sizing: border-box;
  border: solid 1px #194877;
  box-shadow: 0 3px 7px 0 fadeout(#000000, 80);
}
</style>

<style lang="less">
.amap-overlay-text-container {
  background: none;
  // color: #35f48f;
  border: none;
  font-weight: bolder;
  text-shadow: 1px 1px 2px #000;
}

.info-window {
  color: #000;

  .right-section-title {
    font-size: 12px;
    font-weight: bolder;
    margin: 3px 0;

    &.is-primary {
      font-size: 14px;
    }

    &.is-secondary {
      opacity: 0.4;
      font-size: 10px;
    }
  }

  .bar-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .bar {
      flex: 0 0 102px;
      height: 14px;
      margin: 1px 0;

      .bar-active {
        height: 14px;
        box-sizing: border-box;
        border-right: solid 2px #ffc760;
        width: 100%;

        &.is-orange {
          border-color: #ffc760;
          background: rgba(255, 229, 153, 0.3);
        }

        &.is-blue {
          background: rgba(132, 178, 250, 0.3);
          border-color: #4d7bf3;
        }

        &.is-red {
          background: rgba(#ff6969, 0.3);
          border-color: #ff6969;
        }

        &.is-green {
          background: rgba(220, 248, 182, 0.3);
          border-color: #b6ef7f;
        }

        &.is-pink {
          background: rgba(255, 134, 214, 0.3);
          border-color: #ff86d6;
        }
      }
    }

    .bar-label {
      margin-right: 8px;
      width: 5em;
      flex: 0 0 auto;
    }

    .bar-number {
      margin-left: 8px;
      width: 20px;
    }

    .bar-percentage {
      margin-left: 8px;
      width: 30px;
      opacity: 0.4;
    }
  }
}
</style>
