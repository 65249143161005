<template lang='pug'>
  Board(
    title="材料管理"
    :hint="`待处理 ${pending}`"
    icon="/images/data-center-screen/boards/icon-materials.svg"
    sub-window='materials'
    @sub-window="$emit('sub-window', $event)"
  )
    BoardStats(
      title="材料记录"
      before="收录"
      :n="n"
      :after="`份`"
    )
</template>

<script>
import Board from '../Board.vue';
import BoardStats from '../BoardStats.vue';
import { getSum } from '../genCards';

export default {
  name: 'BoardCams',
  props: {
    projects: Array,
  },
  emits: ['sub-window'],
  components: { Board, BoardStats },
  computed: {
    pending() {
      return getSum(this.projects, 'materialsPending');
    },
    n() {
      return getSum(this.projects, 'materials');
    },
  },
};
</script>
