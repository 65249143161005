<template>
  <div>
    <ui-page-header title="人员考勤报表" />
    <ui-stage>
      <ui-alert type="success" class="mb" :closable="false">
        <ui-flex row a-center>
          <span class="filter-span">起止时间：</span>
          <ui-date-picker
            v-model="dates"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="dateRangePickerOptions"
          />
          <span class="filter-span">项目：</span>
          <ui-admin-model-picker
            v-bind="projectsOptions"
            v-model="projectId"
          ></ui-admin-model-picker>
          <ui-button class="filter-span" @click="loadData">查询</ui-button>
        </ui-flex>
      </ui-alert>
      <ui-admin-table
        v-if="data"
        :attributes="attributes"
        :data="data"
        :with-create="false"
        :with-actions="false"
        always-display-pagination
      >
        <template v-slot:column_project_title="{ row }">
          <router-link :to="`/admin/projects/edit/${row.project_id}`" target="_blank">
            <ui-link>
              {{ row.project_title }}
            </ui-link>
          </router-link>
        </template>
        <template v-slot:column_username="{ row }">
          <router-link :to="`/admin/users/edit/${row.id}`" target="_blank">
            <ui-link>
              {{ row.username }}
            </ui-link>
          </router-link>
        </template>
      </ui-admin-table>
    </ui-stage>
  </div>
</template>

<script>
import { moment } from '@yishitec/web';
import UiAdminModelPicker from '@yishitec/web/components/UiAdminModelPicker.vue';

export default {
  name: 'SigninsReport',
  components: {
    UiAdminModelPicker,
  },
  data() {
    const DATE_FORMAT = 'YYYY-MM-DD';

    let projectId = null;
    let startAt = moment().startOf('month').format(DATE_FORMAT);
    let endAt = moment().format(DATE_FORMAT);
    let shouldSubmit = false;

    if (this.$route.query.projectId) {
      projectId = Number(this.$route.query.projectId);
      shouldSubmit = true;
    }
    if (this.$route.query.startAt) {
      startAt = moment(this.$route.query.startAt).format(DATE_FORMAT);
      shouldSubmit = true;
    }
    if (this.$route.query.endAt) {
      endAt = moment(this.$route.query.endAt).format(DATE_FORMAT);
      shouldSubmit = true;
    }

    return {
      attributes: [],
      data: null,
      shouldSubmit,
      dates: [startAt, endAt],
      projectId,
      projectsOptions: {
        config: {
          title: '项目',
          name: 'project_id',
          model: {
            name: 'Project',
            alias: 'xProjects',
            titleFields: ['title'],
            type: 'string',
            appData: {
              apiName: 'projects',
            },
          },
          type: 'model',
        },
        disabled: false,
      },
      dateRangePickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const start = moment().format('YYYY-MM-DD');
              const end = moment().format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '昨天',
            onClick(picker) {
              const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
              const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '本月',
            onClick(picker) {
              const start = moment().startOf('month').format('YYYY-MM-DD');
              const end = moment().format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '上月',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
              const end = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  mounted() {
    if (this.shouldSubmit) {
      this.loadData();
    }
  },
  methods: {
    async loadData() {
      this.data = null;
      const { projectId, dates } = this;
      const [startDate, endDate] = dates;
      const { attributes, data } = await this.withLoading(
        this.api('/api/reports/signins', { startDate, endDate, projectId }),
      );
      this.attributes = attributes;
      this.data = data;
    },
  },
};
</script>

<style scoped lang="less">
.filter-span {
  display: inline-block;
  margin-left: 10px;
}
</style>
