<template lang="pug">
div(
  @mouseover="$emit('mouseover')"
  @mouseout="$emit('mouseout')"
).scroll
  slot
</template>

<script>
export default {
  name: 'ScrollBox',
  emits: ['mouseover', 'mouseout'],
};
</script>

<style lang="less" scoped>
@import '../assets/styles/app-mixins.less';

.scroll {
  margin-right: -10px;
  padding-right: 10px;
  box-sizing: border-box;
  flex: 1 0 1px;
  overflow: auto;
  .thin-scroll();
}
</style>
