<template>
  <div>
    <ui-stage>
      <ui-admin-table
        v-if="data"
        :attributes="attributes"
        :data="data"
        :module-url="moduleUrl"
        pagination-method="front-end"
      >
      </ui-admin-table>
    </ui-stage>
  </div>
</template>

<script>
import { adminList } from '@yishitec/web/mixins';

export default {
  name: 'List',
  mixins: [adminList],
};
</script>

<style lang="less" scoped></style>
