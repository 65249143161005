<template lang="pug">
ChartLabel(title="考勤比例")
  RingChart(
    v-for="config in configs"
    :key="config.key"
    :config="config"
    :list="list"
  )
</template>

<script>
import ChartLabel from './ChartLabel.vue';
import RingChart from './RingChart.vue';

export default {
  name: 'RingsChart',
  props: {
    configs: Array,
    list: Array,
  },
  components: {
    ChartLabel,
    RingChart,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.ring {
  flex: 1 0 1px;
}
</style>
