<template lang="pug">
div.field-uploads
  SspTable(
    :value="list"
    :columns="getColumns()"
    :withToolbar="!readonly"
    :withRefresh="false"
    @handleRow="handleRow"
  )
    template(v-slot:toolbarLeft)
      el-button(
        size="small"
        type="primary"
        @click="handleAddItem"
      ) 添加文件
    template(v-slot:file="{ row }")
      a(
        :href="row.file"
        target="_blank"
        @click.stop="noop"
      )
        el-link(type="primary") 查看
  el-dialog(
    title="文件详情"
    :visible.sync="displayEditDialog"
    v-if="!readonly && form"
  )
    SspFormItem(
      v-for="field in getColumns()"
      :key="field.name"
      v-model="form[field.name]"
      :label="field.title"
      :type="field.type || 'input'"
    )
      template(v-if="field.name === 'file'")
        SspUpload(
          listType="text"
          :value="form.file"
          @input="value => { form.file = value; }"
          @success="handleUploadSuccess"
        )
    div.form-toolbar
      el-button(
        type="primary"
        @click="handleSubmit"
      ).mt 提交
      el-link(
        type="danger"
        @click="handleRemove"
      ) 删除
</template>

<script>
/* eslint-disable indent */
import { _ } from '@yishitec/web';
import SspTable from './ssp/SspTable.vue';
import SspUpload from './ssp/SspUpload.vue';

export default {
  name: 'FieldUploads',
  props: {
    value: { type: String },
    readonly: { type: Boolean, default: false },
  },
  emits: ['input'],
  components: { SspTable, SspUpload },
  data() {
    return {
      list: [],
      displayEditDialog: false,
      currentEditIndex: 0,
      form: {},
    };
  },
  watch: {
    list(value) {
      this.$emit('input', JSON.stringify(value));
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.list = JSON.parse(this.value || '[]');
    },
    getColumns() {
      return [
        {
          name: 'type',
          title: '文件标签',
        },
        {
          name: 'filename',
          title: '文件名',
        },
        {
          name: 'file',
          title: '文件',
          type: 'custom',
        },
      ];
    },
    updateValue(value) {
      this.$emit('input', JSON.stringify(value || []));
    },
    handleAddItem() {
      this.form = {
        id: Date.now(),
        type: '',
        filename: '',
        file: '',
      };
      this.openEdit(null);
    },
    openEdit(index) {
      this.currentEditIndex = index;
      if (index !== null) {
        this.form = this.list[this.currentEditIndex];
      }
      this.displayEditDialog = true;
    },
    handleSubmit() {
      if (this.currentEditIndex !== null) {
        this.list[this.currentEditIndex] = this.form;
      } else {
        this.list.push(this.form);
      }
      this.list = [...this.list];
      this.form = null;
      this.displayEditDialog = false;
    },
    handleRow(ev) {
      this.currentEditIndex = _.findIndex(this.list, (item) => item.id === ev.id);
      if (this.currentEditIndex === -1) {
        this.currentEditIndex = null;
      }
      this.openEdit(this.currentEditIndex);
    },
    handleUploadSuccess(res) {
      const { originalname } = res;
      if (!this.form.filename) {
        this.form.filename = originalname;
      }
      if (!this.form.type) {
        this.form.type = '文件';
      }
    },
    handleRemove() {
      this.list = _.filter(this.list, (item) => {
        return item.id !== this.form.id;
      });
      this.form = null;
    },
    noop() {},
  },
};
</script>

<style lang="scss" scoped>
.form-toolbar {
  @include ui-flex-row(space-between, center);
}
</style>
