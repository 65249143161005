<template>
  <div>
    <ui-stage>
      <ui-flex row>
        <ui-flex style="flex: 0 0 110px" class="mr br pr">
          <app-links-list v-model="filter" :data="projects" with-search />
        </ui-flex>
        <ui-flex>
          <ui-admin-table
            v-if="data"
            :attributes="attributes"
            :data="data"
            :module-url="moduleUrl"
            pagination-method="front-end"
            :link-create="linkCreate"
            :with-create="canCreate"
          >
            <template v-slot:column__adminActions="scope">
              <ui-button-group>
                <router-link :to="`${moduleUrl}/edit/${scope.row.id}`">
                  <ui-button
                    size="mini"
                    type="primary"
                    icon="el-icon-view"
                    v-if="!canEdit(scope.row.id, scope.row.project_id)"
                    >查看</ui-button
                  >
                  <ui-button size="mini" type="primary" icon="el-icon-edit" v-else>编辑</ui-button>
                </router-link>
                <router-link
                  :to="`${moduleUrl}/delete/${scope.row.id}?backUrl=${encodeURIComponent(
                    $route.fullPath,
                  )}`"
                  v-if="canEdit(scope.row.id, scope.row.project_id)"
                >
                  <ui-button size="mini" type="info" icon="el-icon-delete">删除</ui-button>
                </router-link>
              </ui-button-group>
            </template>
          </ui-admin-table>
        </ui-flex>
      </ui-flex>
    </ui-stage>
  </div>
</template>

<script>
import { adminList } from '@yishitec/web/mixins';
import AppLinksList from '../../../components/AppLinksList.vue';
import withProjectsFilters from '../../../mixins/withProjectsFilters';
import permissions from '../../../mixins/permissions';

/**
 * @typedef {WithAdminList & WithProjectsFilter & WithPermissions} This
 * */

export default {
  name: 'List',
  components: { AppLinksList },
  mixins: [adminList, withProjectsFilters, permissions],
  data() {
    return {
      apiBodyData: {
        currentProjects: this.$store.state.currentProjects,
      },
    };
  },
  computed: {
    canCreate() {
      /** @type {This} */
      const that = this;
      if (!that.filter) return false;
      return (
        that.amI(that.filter, '施工单位', '安全员', true) ||
        that.amI(that.filter, '施工单位', '管理人员', true)
      );
    },
    linkCreate() {
      return `/admin/danger-zones/add?project_id=${this.filter}`;
    },
  },
  methods: {
    canEdit(rowId, projectId) {
      /** @type {This} */
      const that = this;
      return (
        that.amI(projectId, '施工单位', '安全员', true) ||
        that.amI(projectId, '施工单位', '管理人员', true)
      );
    },
  },
};
</script>

<style lang="less" scoped></style>
