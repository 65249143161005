<template lang="pug">
.map-cover
  MapCoverCard(
    color="green"
    title="项目数"
    :number="projectsCountActive"
  )
  MapCoverCard(
    color="blue"
    title="今日交底"
    :number="workersGranted"
    :total="workersCount"
  )
  MapCoverCardWithPopover(
    color="red"
    title="危险源数量"
    :number="operatedDangerZonesCount"
    :total="devicesCount"
  )
    h3 危险源数量
    div {{ 动态危险源 }} 设备
    div {{ 静态危险源 }} 静态危险源
    div {{ 区域危险源 }} 区域危险源
  MapCoverCard(
    color="blue"
    title="施工部位情况"
    :number="opsZonesGranted"
    :total="opsZonesCount"
  )
  MapCoverCardWithPopover(
    color="yellow"
    title="今日预警事件"
    :number="$get(tasks, ['today', 'count']) || 0"
  )
    h3 今日预警事件
    div 今日预警事件：{{ $get(tasks, ['today', 'count']) || 0 }}
    div 监理已处理：{{ $get(tasks, ['today', 'processed']) || 0 }}
    div 监理待处理：{{ $get(tasks, ['today', 'todo']) || 0 }}
</template>

<script>
import { _ } from '@yishitec/web';
import MapCoverCard from './MapCoverCard.vue';
import MapCoverCardWithPopover from './MapCoverCardWithPopover.vue';

export default {
  name: 'MapCover',
  props: {
    projects: Array,
    usersCount: Number,
    data: Object,
    signins: Array,
    tasks: Object,
  },
  components: {
    MapCoverCard,
    MapCoverCardWithPopover,
  },
  computed: {
    projectsCountActive() {
      return _.get(
        _.filter(this.projects, (item) => item.workState === '活跃'),
        'length',
      );
    },
    workersGranted() {
      return _.sumBy(this.projects, (project) => {
        return Number(_.get(String(project.作业人员安全交底 || '').split(' / '), [0]) || 0);
      });
    },
    workersCount() {
      const logs = [];
      const values = _.map(this.projects, (project) => {
        const ret = Number(_.get(String(project.作业人员安全交底 || '').split(' / '), [1]) || 0);
        logs.push([project.id, ret].join('\t'));
        return ret;
      });
      return _.sum(values);
    },
    operatedDangerZonesCount() {
      return _.sumBy(this.projects, (project) => {
        return Number(project.危险源作业 || 0);
      });
    },
    devicesCount() {
      return _.sumBy(this.projects, (item) => item.危险源设备 || 0);
    },
    动态危险源() {
      return [
        _.sumBy(this.projects, (project) => {
          return Number(_.get(String(project.动态危险源 || '').split(' / '), [0]) || 0);
        }),
        _.sumBy(this.projects, (project) => {
          return Number(_.get(String(project.动态危险源 || '').split(' / '), [1]) || 0);
        }),
      ].join(' / ');
    },
    静态危险源() {
      return [
        _.sumBy(this.projects, (project) => {
          return Number(_.get(String(project.静态危险源 || '').split(' / '), [0]) || 0);
        }),
        _.sumBy(this.projects, (project) => {
          return Number(_.get(String(project.静态危险源 || '').split(' / '), [1]) || 0);
        }),
      ].join(' / ');
    },
    区域危险源() {
      return [
        _.sumBy(this.projects, (project) => {
          return Number(_.get(String(project.区域危险源 || '').split(' / '), [0]) || 0);
        }),
        _.sumBy(this.projects, (project) => {
          return Number(_.get(String(project.区域危险源 || '').split(' / '), [1]) || 0);
        }),
      ].join(' / ');
    },
    opsZonesGranted() {
      return _.sumBy(this.projects, (project) => {
        return Number(_.get(String(project.今日作业施工部位 || '').split(' / '), [0]) || 0);
      });
    },
    opsZonesCount() {
      return _.sumBy(this.projects, (project) => {
        return Number(_.get(String(project.今日作业施工部位 || '').split(' / '), [1]) || 0);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.map-cover {
  position: absolute;
  width: 108px;
  left: 40px;
  bottom: 53px;
  z-index: 1000;
}
</style>
