import _ from 'lodash';
import { ref, watch } from 'vue';

export default (input) => {
  const {
    images = { value: [] },
  } = input;
  const currentImage = ref(null);

  const checkCurrentImage = () => {
    // 确保当前选中项存在
    if (currentImage.value) {
      if (_.find(images.value, find => find.id === currentImage.value.id)) {
        currentImage.value = _.find(images.value, find => find.id === currentImage.value.id);
      } else {
        currentImage.value = null;
      }
    }
    // 确保列表有内容时至少有一个选中项
    if (!currentImage.value) {
      currentImage.value = images.value[0] || null;
    }
  };

  watch(images, () => {
    checkCurrentImage();
  });

  const selectImage = (image) => {
    currentImage.value = image;
    checkCurrentImage();
  };

  return {
    currentImage,
    selectImage,
  };
};
