<template lang="pug">
div
  RightBadge(
    color="orange"
    title="监理单位管理人员"
  )
  RightBadge(
    color="blue"
    title="施工单位管理人员"
  )
  RightBadge(
    color="red"
    title="施工单位安全员"
  )
  RightBadge(
    color="green"
    title="施工单位作业人员"
  )
  RightBadge(
    color="pink"
    title="作业的危险源"
  )
</template>

<script>
import RightBadge from './RightBadge.vue';

export default {
  name: 'RightBadges',
  components: {
    RightBadge,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
