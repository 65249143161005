<template>
  <div>
    <ui-stage>
      <ui-admin-table
        v-if="data"
        :attributes="attributes"
        :data="data"
        pagination-method="front-end"
        :with-create="false"
        :with-delete="false"
      >
        <template v-slot:column__adminActions="{ row }">
          <router-link :to="`${moduleUrl}/list/${row.id}`">
            <ui-button size="mini" type="primary" icon="el-icon-view">查看</ui-button>
          </router-link>
        </template>
      </ui-admin-table>
    </ui-stage>
  </div>
</template>

<script>
import { adminList } from '@yishitec/web/mixins';
import permissions from '../../../mixins/permissions';

export default {
  name: 'KeyComponentAcceptanceProjects',
  mixins: [adminList, permissions],
  data() {
    return {
      vvApi: '/api/key-component-acceptance/projects',
    };
  },
};
</script>

<style lang="less" scoped></style>
