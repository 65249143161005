import { _ } from '@yishitec/web';
import checkLoggedIn from '@yishitec/web/core/checkLoggedIn';

export default {
  mixins: [checkLoggedIn],
  methods: {
    handleLogin(res) {
      this.$store.commit('setCurrentProjects', res.currentProjects);
      this.$store.commit(
        'setPermissions',
        _.get(res, 'xUserProjectUser.xUserProjectsBindings') || [],
      );
    },
  },
};
