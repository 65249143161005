<template>
  <div>
    <ui-stage v-if="data">
      <ui-tabs v-model="tab" type="border-card">
        <ui-tab-pane name="main" label="编辑">
          <ui-admin-form
            :compact="compact"
            :readonly="readonly"
            :fields="attributes"
            :model="data"
            :module-url="moduleUrl"
            with-delete
            @submit="handleSubmit"
          />
        </ui-tab-pane>
      </ui-tabs>
    </ui-stage>
  </div>
</template>

<script>
import { adminEdit } from '@yishitec/web/mixins';

export default {
  name: 'Edit',
  mixins: [adminEdit],
  data() {
    return {
      withCustomFields: false,
    };
  },
};
</script>
