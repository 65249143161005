import { _ } from '@yishitec/web';

export default {
  methods: {
    async withConfirm(options = {}) {
      const {
        message = '是否确认操作？',
        title = '请确认',
        type = 'warning',
        confirmButtonText = '确定',
        cancelButtonText = '取消',
        fn = null,
      } = options || {};
      try {
        await this.$confirm(message, title, {
          confirmButtonText,
          cancelButtonText,
          type,
        });
        if (_.isFunction(fn)) {
          const res = await fn();
          return res;
        }
        return null;
      } catch (e) {
        if (e.message) {
          this.$message({
            type: 'error',
            message: e.message,
          });
          return null;
        }
        return null;
      }
    },
  },
};
