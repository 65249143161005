<script setup>
import { _ } from '@yishitec/web';
import {
  defineProps, defineEmits, ref, computed,
} from 'vue';
import { useNotification, useRouter, $fetch } from '../../composables';

const props = defineProps({
  record: Object,
  attributes: Array,
  prefix: String,
  apiPrefix: String,
  actions: Array,
  buttons: { type: Number, default: 1 },
});

const emit = defineEmits(['refresh']);

const { Notification } = useNotification();
const router = useRouter();

const pending = ref(false);

const vActions = computed(() => {
  return props.actions ? props.actions : [
    {
      title: '查看',
      icon: 'icon-edit',
      type: 'outline',
      to: (record) => `${props.prefix}/edit/${record.id}`,
    },
    {
      title: '删除',
      icon: 'icon-delete',
      type: 'text',
      api: (record) => `${props.apiPrefix}/delete`,
    },
  ];
});

const vButtons = computed(() => {
  return vActions.value.slice(0, props.buttons);
});

const vExtraActions = computed(() => {
  return vActions.value.slice(props.buttons, vActions.value.length);
});

const handleAction = async action => {
  if (action.to && _.isFunction(action.to)) {
    router.push(action.to(props.record));
  } else if (action.to && _.isString(action.to)) {
    router.push(action.to.replace(':id', props.record.id));
  } else if (action.api) {
    let { api } = action;
    if (_.isFunction(api)) {
      api = action.api(props.record);
    }
    pending.value = true;
    const res = await $fetch(api, {
      method: 'post',
      body: {
        id: props.record.id,
      },
    });
    pending.value = false;
    if (res.message) {
      Notification.warning({ title: res.message });
    } else {
      Notification.success({ title: '操作成功' });
      emit('refresh');
    }
  }
};

const handleSelect = option => {
  handleAction(option);
};
</script>

<template lang='pug'>
div
  span.inline-block.mr-2
    el-button(
      v-for="(action, actionIndex) in vButtons"
      :key="actionIndex"
      :type="action.type"
      size="mini"
      @click="handleAction(action)"
    )
      i(:class="`el-${action.icon}` || 'el-icon-info'").inline-block
      span(:class="['inline-block']").ml-2 {{ action.title }}
  el-dropdown(
    v-if="vActions.length > 1 || (!buttons && vActions.length)"
    trigger="click"
    @command="handleSelect"
  ).mr-2
    span.el-dropdown-link
      i.el-icon-more
    template(#dropdown)
      el-dropdown-menu
        el-dropdown-item(
          v-for="(action, actionIndex) in vActions"
          :key="actionIndex"
          :command="action"
        )
          i(v-if="action.icon" :class="`el-${action.icon}`").mr-2
          | {{ action.title }}
</template>

<style lang='scss'>
.inline-block {
  display: inline-block;
}

.mr-2 {
  margin-right: 6px;
}
</style>
