<template>
  <div>
    <ui-stage v-if="data">
      <ui-tabs v-model="tab" type="border-card">
        <ui-tab-pane name="main" label="编辑">
          <ui-alert class="mb" type="warning" v-if="alertRequireApprove" :closable="false">
            {{ alertRequireApprove }}
          </ui-alert>
          <ui-alert class="mb" type="warning" v-if="canApprove" :closable="false">
            <ui-button type="warning" @click="handleApprove">审核</ui-button>
          </ui-alert>

          <ui-alert type="warning" v-if="alertEditRequireApprove">
            <div v-html="alertEditRequireApprove" />
          </ui-alert>

          <ui-alert type="warning" v-if="alert">
            <div v-html="alert" />
          </ui-alert>

          <ui-section-title>用户资料</ui-section-title>
          <ui-admin-form
            :compact="compact"
            :readonly="vReadonly"
            :fields="vAttributes"
            :model="data"
            :module-url="moduleUrl"
            :with-delete="false"
            :cols="2"
            :with-save="false"
          >
            <template v-slot:field__position="{ value, onInput, readonly }">
              <template v-if="readonly">{{ value }}</template>
              <ui-select
                v-else
                :value="value"
                @input="onInput"
                filterable
                allow-create
                default-first-option
              >
                <ui-option
                  v-for="item in positionsOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                ></ui-option>
              </ui-select>
            </template>
            <template slot="field__password" slot-scope="{ value, onInput, readonly }">
              <template v-if="readonly">******</template>
              <template v-else>
                <template v-if="!id">
                  <ui-input
                    :value="value"
                    type="password"
                    @input="onInput"
                    autocomplete="new-password"
                    auto-complete="new-password"
                    show-password
                  />
                </template>
                <template v-else>
                  <ui-change-password :id="id" />
                </template>
              </template>
            </template>
            <template slot="field__certs_uploads" slot-scope="{ value, onInput, readonly }">
              <ui-uploads :value="value" @input="onInput" :readonly="readonly" />
              <div class="field-claim">* 管理人员、特殊工种必填</div>
            </template>
            <template slot="field__user_id_table_uploads" slot-scope="{ value, onInput, readonly }">
              <ui-uploads :value="value" @input="onInput" :readonly="readonly">
                <div slot="hint">
                  <a
                    :href="`/files/${encodeURIComponent('附件2：实名登记表.docx')}`"
                    target="_blank"
                    >点击这里</a
                  >
                  下载表格模板
                </div>
              </ui-uploads>
            </template>
            <template v-slot:field__eBadgeSn="{ value, onInput, readonly }">
              <div v-if="readonly">{{ value }}</div>
              <template v-else>
                <div>
                  <ui-input :value="value" @input="onInput" />
                </div>
                <div v-if="data.id">
                  <ui-button type="danger" size="small" plain @click="handleRemoveEBadge">
                    解绑电子工牌
                  </ui-button>
                </div>
              </template>
            </template>
          </ui-admin-form>
          <ui-section-title>人脸识别比对照片</ui-section-title>
          <ui-admin-form
            :compact="compact"
            :readonly="vReadonly"
            :fields="facesAttributes"
            :model="faces"
            :module-url="moduleUrl"
            :with-delete="false"
            :cols="2"
            :with-save="false"
          />
          <template v-if="data.qr_type === '使用静态码'">
            <ui-section-title>静态码</ui-section-title>
            <ui-alert v-if="!data.id">请保存后查看用户的静态码</ui-alert>
            <template v-else>
              <ui-admin-form
                :compact="compact"
                :readonly="vReadonly"
                :fields="qrStaticFields"
                :model="userQrStatic"
                :module-url="moduleUrl"
                :with-delete="false"
                :with-save="false"
              >
                <template v-slot:field__qr>
                  <ui-flex row j-start a-start>
                    <ui-flex col center>
                      <vue-qrious :value="vrToken" :size="200" />
                      <div class="qr-name title">
                        {{ data.display_name }}
                      </div>
                      <div class="qr-name">
                        {{ data.company }}
                      </div>
                    </ui-flex>
                  </ui-flex>
                </template>
              </ui-admin-form>
            </template>
          </template>
          <template
            v-if="
              data.company_type === '施工单位' &&
              (data.admintype === '安全员' || data.admintype === '作业人员')
            "
          >
            <ui-section-title>施工单位内部交底</ui-section-title>
            <user-company-internal-notice
              :id="companyInternalNoticeId"
              v-model="userCompanyInternalNotice"
              :readonly="vReadonly"
            />
          </template>
          <template
            v-if="
              data.company_type === '监理单位' &&
              (data.admintype === '专项管理员' || data.admintype === '作业人员')
            "
          >
            <ui-section-title>监理单位内部交底</ui-section-title>
            <user-company-internal-notice
              :id="companyInternalNoticeId"
              v-model="userCompanyInternalNotice"
              :readonly="vReadonly"
            />
          </template>
          <div class="mt text-center" v-if="!vReadonly">
            <ui-button type="primary" icon="el-icon-success" @click="handleSubmit()">
              提交
            </ui-button>
            <router-link
              v-if="!dataReadonly && canDelete && data.id"
              :to="`${moduleUrl}/delete/${data.id}`"
              class="ml"
            >
              <ui-button type="info" icon="el-icon-delete"> 删除</ui-button>
            </router-link>
          </div>
        </ui-tab-pane>
        <ui-tab-pane name="identities" label="其他身份" v-if="data && data.id">
          <ui-admin-table
            :attributes="identitiesAttributes"
            :data="identities"
            :with-create="false"
            :with-actions="false"
          >
            <template v-slot:column_project_title="{ row }">
              <el-tag type="primary" v-if="row.isCurrent" class="mr" :data-member-id="row.member_id"
                >当前
              </el-tag>
              {{ $get(row, 'xUserProjectProject.title') }}
            </template>
          </ui-admin-table>
        </ui-tab-pane>
        <ui-tab-pane
          v-if="data.id && plugins && plugins.pdycsp"
          name="plugin_pdycsp"
          label="浦东新区建设工程人脸识别考勤系统"
        >
          <h1>浦东新区建设工程人脸识别考勤系统</h1>
          <p>
            <el-button
              v-if="pluginsData && pluginsData.pdycsp && pluginsData.pdycsp.enabled"
              type="success"
            >
              √ 已添加到考勤系统
            </el-button>
            <el-button
              v-else
              type="primary"
              @click="
                handlePluginFn('pdycsp', 'addUser', {
                  srcType: 'User',
                  srcId: data.id,
                })
              "
            >
              添加到考勤系统
            </el-button>
          </p>
        </ui-tab-pane>
      </ui-tabs>
    </ui-stage>
  </div>
</template>

<script>
import md5 from 'md5';
import { _, G } from '@yishitec/web';
import { adminEdit } from '@yishitec/web/mixins';
import VueQrious from 'vue-qrious';
import UserCompanyInternalNotice from './components/UserCompanyInternalNotice.vue';
import permissions from '../../../mixins/permissions';

export default {
  name: 'Edit',
  components: {
    VueQrious,
    UserCompanyInternalNotice,
  },
  mixins: [adminEdit, permissions],
  data() {
    const qrStaticFields = [
      {
        name: 'qr',
        title: '用户二维码',
      },
      {
        title: '绑定物联网设备',
        name: 'iot_device_id',
        model: {
          name: 'IotDevice',
          alias: 'xUserIotDevices',
          titleFields: ['title', 'device_sn'],
          type: 'string',
          appData: {
            apiName: 'iot-devices',
          },
        },
        type: 'model',
      },
    ];
    const facesAttributes = [
      {
        name: 'face_uploads',
        title: '人脸识别比对照片',
        type: 'file',
        required: true,
      },
    ];
    const identitiesAttributes = [
      {
        name: 'project_title',
        title: '项目',
      },
      {
        name: 'company',
        title: '公司名称',
      },
      {
        name: 'company_type',
        title: '公司类型',
      },
      {
        name: 'admintype',
        title: '管理员类型',
      },
      {
        name: 'position',
        title: '工种/岗位',
      },
    ];
    return {
      withCustomFields: false,
      alert: null,
      alertRequireApprove: null,
      alertEditRequireApprove: null,
      vLockedFields: ['admintype', 'company_type'],
      positionsOptionsRef: {
        监理单位: '专监（质量） 专监（试验） 专监（测量） 安全监理 内业监理'.split(' '),
        施工单位: G().options工种(),
      },
      userCompanyInternalNotice: {},
      userQrStatic: {},
      qrStaticFields,
      facesAttributes,
      faces: {
        face_uploads: '',
      },
      identities: [],
      identitiesAttributes,
      plugins: {},
      pluginsData: {},
      upperLevelUrl: `${this.moduleUrl}/?page=${this.$route.query.pageInList || 1}&pageSize=${
        this.$route.query.listPageSize || 10
      }`,
    };
  },
  computed: {
    vAttributes() {
      const ignoreFields = [];
      if (!this.data.contract_type || this.data.contract_type === '总包') {
        ignoreFields.push('subcontract_type', 'subcontract_company');
      }
      return _.filter(this.attributes, (item) => !ignoreFields.includes(item.name));
    },
    positionsOptions() {
      return this.positionsOptionsRef[_.get(this, ['data', 'company_type'])];
    },
    canApprove() {
      if (this.data.approve_state !== '待审核') return false;
      return this.amI(null, '监理单位', '管理人员', true);
    },
    canDelete() {
      if (
        this.amIInCompany(_.get(this.data, 'company', '')) &&
        _.get(this.data, 'username') === 'admin'
      ) {
        return false;
      }
      return true;
    },
    vrToken() {
      return `SSITE_USER|${this.data.id}|${md5(
        ['com.smartconstructioncontroller.users', this.data.member_id, this.data.company].join('.'),
      )}`;
    },
    companyInternalNoticeId() {
      return [
        this.data.project_id,
        this.data.company_type,
        this.data.company,
        this.data.member_id,
      ].join('|');
    },
  },
  methods: {
    handleFetchDataRes(res) {
      if (res.requireApprove) {
        this.alertEditRequireApprove = '变更需监理单位审核';
      }
      if (_.get(res, 'item.approve_state')) {
        this.alertRequireApprove = _.get(res, 'item.approve_state');
      } else {
        this.alertRequireApprove = '';
      }

      this.alert = _.get(res, ['alert']);

      if (_.get(res, 'item.project_id')) {
        this.vLockedFields.push('project_id');
      } else {
        this.vLockedFields = _.filter(this.vLockedFields, (item) => item !== 'project_id');
      }
      this.vLockedFields = [...this.vLockedFields];

      this.userQrStatic = res.userQrStatic;

      this.faces = res.faces || {};

      this.identities = res.identities || [];

      if (res.plugins) {
        this.plugins = this.handlePluginsRes(res.plugins);
      }
      this.pluginsData = this.handlePluginsData(res);
    },
    handlePluginsRes(res) {
      const ret = {};
      _.forEach(res, (item) => {
        ret[_.get(item, ['xPluginApp', 'pluginId'])] = item.config;
      });
      return ret;
    },
    handlePluginsData(res) {
      try {
        return JSON.parse(_.get(res, ['item', 'pluginsData']));
      } catch (e) {
        return {};
      }
    },
    getExtLockedFields(item) {
      return this.amIInCompany(item.company) && item.username === 'admin' ? ['username'] : [];
    },
    beforeHandleSubmit(data) {
      const fieldsCanSaveLater = ['position'];
      _.forEach(this.vAttributes, (field) => {
        if (field.required) {
          if (!data[field.name] && !fieldsCanSaveLater.includes(field.name)) {
            throw new Error(`${field.title}不能为空！`);
          }
        }
      });
      return {
        data,
        userCompanyInternalNotice: this.userCompanyInternalNotice,
        userQrStatic: this.userQrStatic,
        faces: this.faces,
        eBadgeSn: this.data.eBadgeSn,
      };
    },
    async handleApprove() {
      const res = await this.withLoading(
        this.api('/api/users/approve', {
          id: this.id,
        }),
      );
      if (res) {
        this.fetchData();
        this.$message({
          type: 'success',
          message: '审核完成',
        });
      }
    },
    async handlePluginFn(pluginName, fnName, params) {
      try {
        const res = await this.withLoading(
          this.api(`/api/users/plugin/${pluginName}/${fnName}`, {
            ...params,
            projectId: _.get(this.data, ['project_id']),
          }),
        );
        const resMessage = _.get(res, ['message']);
        if (resMessage) {
          this.$message({
            type: 'success',
            message: resMessage,
          });
        }
        this.fetchData();
      } catch (e) {
        this.$message({
          type: 'error',
          message: e.message,
        });
      }
    },
    async handleRemoveEBadge() {
      this.loading = true;
      try {
        const res = await this.withLoading(
          this.api('/api/users/remove-e-badge-sn', {
            id: this.data.id,
          }),
        );
        if (!res) {
          throw new Error('操作失败');
        }
        this.data.eBadgeSn = '';
        this.$message({
          type: 'success',
          message: '成功解绑！',
        });
      } catch (e) {
        if (e.message) {
          this.$message({
            type: 'error',
            message: e.message,
          });
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.qr-name {
  line-height: 1.3em;

  &.title {
    font-weight: bold;
  }
}

.field-claim {
  font-size: 12px;
  color: rgba(orange, 0.5);
}
</style>
