import { _, G } from '@yishitec/web';

const d3 = require('d3');
const { differenceEuclideanRGB } = require('d3-color-difference');

const { isGray } = require('./colors');

const { colorDiffTolerance } = G();

export const analyzeImage = async (img, info = {}, canvas = null) => {
  const { naturalWidth, naturalHeight } = img;
  const { originalname, url, size } = info;

  if (!canvas) throw new Error('Please input a canvas');

  // eslint-disable-next-line no-param-reassign
  canvas.width = naturalWidth;
  // eslint-disable-next-line no-param-reassign
  canvas.height = naturalHeight;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);
  const pixels = ctx.getImageData(0, 0, naturalWidth, naturalHeight);
  const colors = [];
  _.forEach(_.chunk(pixels.data, 4), (colorArr) => {
    const [r, g, b] = colorArr;
    const color = d3.rgb(r, g, b);

    if (isGray(color)) return true;

    const match =
      _.find(colors, (findColor) => {
        if (differenceEuclideanRGB(color, d3.color(findColor)) <= colorDiffTolerance) {
          return true;
        }
        return false;
      }) || null;
    if (!match) {
      colors.push(color.formatHex());
    }
    return true;
  });
  return {
    url,
    filename: originalname,
    size,
    width: naturalWidth,
    height: naturalHeight,
    colors,
  };
};

export const handleUploadRes = (res) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.src = `${res.url}`;
    img.onload = async () => {
      const { naturalWidth, naturalHeight } = img;
      const { originalname, url, size } = res;
      const imgInfo = {
        url,
        filename: originalname,
        size,
        width: naturalWidth,
        height: naturalHeight,
      };
      resolve(imgInfo);
    };
    img.onerror = (e) => {
      reject(e);
    };
  });
