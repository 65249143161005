<template>
  <div>
    <ui-page-header title="施工报表" />
    <ui-stage>
      <ui-alert type="success" class="mb" :closable="false">
        <ui-flex row a-center>
          <span class="filter-span">项目：</span>
          <ui-admin-model-picker
            v-bind="projectsOptions"
            v-model="projectId"
          ></ui-admin-model-picker>
          <span class="filter-span">起止时间：</span>
          <ui-date-picker v-model="date" />
          <ui-button class="filter-span" @click="loadData">查询</ui-button>
          <a
            :href="
              reportHtml
                ? `/api/reports/daily-report-pdf?projectId=${projectId}&date=${dateStr}`
                : 'javascript:void(0)'
            "
            :target="reportHtml ? '_blank' : '_self'"
            class="filter-span"
          >
            <el-button :disabled="!reportHtml" type="danger">下载PDF</el-button>
          </a>
        </ui-flex>
      </ui-alert>
      <div class="report-stage">
        <div v-if="reportHtml" class="report-paper" v-html="reportHtml"></div>
      </div>
    </ui-stage>
  </div>
</template>

<script>
import { moment } from '@yishitec/web';
import UiAdminModelPicker from '@yishitec/web/components/UiAdminModelPicker.vue';

const DATE_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'SigninsReport',
  components: {
    UiAdminModelPicker,
  },
  data() {
    let projectId = null;
    let date = moment().format(DATE_FORMAT);
    let shouldSubmit = false;

    if (this.$route.query.projectId) {
      projectId = Number(this.$route.query.projectId);
      shouldSubmit = true;
    }
    if (this.$route.query.date) {
      date = moment(this.$route.query.date).format(DATE_FORMAT);
      shouldSubmit = true;
    }

    return {
      shouldSubmit,
      date,
      projectId,
      reportHtml: '',
      projectsOptions: {
        config: {
          title: '项目',
          name: 'project_id',
          model: {
            name: 'Project',
            alias: 'xProjects',
            titleFields: ['title'],
            type: 'string',
            appData: {
              apiName: 'projects',
            },
          },
          type: 'model',
        },
        disabled: false,
      },
    };
  },
  computed: {
    dateStr() {
      return moment(this.date).format(DATE_FORMAT);
    },
  },
  mounted() {
    if (this.shouldSubmit) {
      this.loadData();
    }
  },
  methods: {
    async loadData() {
      this.data = null;
      const { projectId, dateStr } = this;
      const { html } = await this.withLoading(
        this.api('/api/reports/daily-report', { date: dateStr, projectId }),
      );
      this.reportHtml = html;
    },
  },
};
</script>

<style scoped lang="scss">
.filter-span {
  display: inline-block;
  margin-left: 10px;
}
.report-stage {
  background: rgba(#000, 0.1);
  box-sizing: border-box;
  padding: 60px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 300px);
}
.report-paper {
  background: #fff;
  box-sizing: border-box;
  padding: 60px;
  width: 21cm;
  box-shadow: 0 4px 10px rgba(#000, 0.05);
  font-size: 11px;
  line-height: 1.6;
  font-family: serif;
  ::v-deep {
    .text-center {
      text-align: center;
    }
  }
}
</style>
