<template lang="pug">
  div.cam-picker
    div(style='margin-bottom: 8px; flex: 0 0 auto')
      el-select(
        v-model="camId"
        placeholder="请选择摄像头"
        @change="switchCam"
        filterable
        clearable
        style='width: 100%'
      )
        el-option(
          v-for="item in cams"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        )
    iframe(
      v-if="cam"
      :src="`/cam/${camId}?hideControls=1`"
    ).frame
</template>

<script>
import _ from 'lodash';
import CamView from '../../../components/admin/CamView/CamView.vue';

export default {
  name: 'CamPicker',
  props: {
    cams: Array,
  },
  data() {
    return {
      camId: null,
      cam: null,
    };
  },
  components: {
    CamView,
  },
  methods: {
    switchCam() {
      this.cam = _.find(this.cams, (i) => i.id === this.camId);
    },
  },
};
</script>

<style lang="scss" scoped>
.cam-picker {
  box-sizing: border-box;
  padding: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  item-align: stretch;
}

.frame {
  flex: 1 0 1px;
  border: none;
}
</style>
