<template lang="pug">
ui-flex.bottom-right(
  row,
  j-start,
  a-stretch,
  v-if="list && list.length"
)
  template(v-for="(item, index) in list")
    ui-flex.bar-chart-column(:key="item.date", column, j-start, a-stretch)
      .bar-chart-column-label {{ item.date | formatDateDay }}
        .bar-chart-column-label-caption {{ item.date | formatDateDayOfWeek }}
      ui-flex.bar-chart-column-bar(col, j-end, a-stretch)
        ui-flex.bar-chart-column-bar-block(
          v-for="block in item.values",
          :key="block.name",
          col,
          j-center,
          a-stretch,
          :style="{ background: color(block.i), height: `calc(${(block.value / (max * 1.5)) * 100}% - 2px)` }"
        )
          .bar-chart-column-bar-number(
            v-if="index === list.length - 1"
          ) {{ block.value }}
</template>

<script>
import { _, moment } from '@yishitec/web';

export default {
  name: 'BottomBarChart',
  props: {
    list: Array,
    max: Number,
  },
  data() {
    return {};
  },
  methods: {
    color(i) {
      const colors = ['#ffb946', '#4d7bf3', '#45b42b'];
      return _.get(colors, i) || '#fff';
    },
  },
  filters: {
    formatDateDay(value) {
      return moment(value).format('DD');
    },
    formatDateDayOfWeek(value) {
      return moment(value).format('ddd');
    },
  },
};
</script>

<style lang="less" scoped>
.bottom-right {
  position: absolute;
  top: 0;
  left: 182px;
  right: 0;
  bottom: 0;
}

.bar-chart-column {
  flex: 1 0 1px;

  &:last-child {
    flex: 2 0 1px;

    .bar-chart-column-bar-block {
      width: 80%;
    }
  }
}

.bar-chart-column-label {
  font-size: 10px;
  flex: 0 0 auto;
  position: relative;

  &::before {
    content: ' ';
    display: inline-block;
    margin-right: 6px;
    width: 1px;
    height: 1px;
    background: #fff;
    vertical-align: middle;
  }
}

.bar-chart-column-label-caption {
  color: fadeout(#fff, 60);
  margin-left: 7px;
  position: absolute;
  top: 14px;
  left: 0;
}

.bar-chart-column-bar {
  flex: 1 0 1px;
  border-left: solid 1px fadeout(#fff, 80);
}

.bar-chart-column-bar-block {
  flex: 0 0 auto;
  margin: 1px 0;
  background: #fff;
  min-height: 0;
  height: 1px;
  width: 7px;
  margin-left: -4px;
}

.bar-chart-column-bar-number {
  padding: 0 8px;
  font-weight: bolder;
  color: #fff;
  text-shadow: 0 0 2px #000;
  line-height: 12px;
}
</style>
