<template lang="pug">
div(v-loading="loading").app-table
  div(v-if="withToolbar").app-table-toolbar
    div.flex-1
      el-button(v-if="withRefresh" type="primary" plain size="mini" @click="emitRefresh").mr10 {{ '刷新' }}
      slot(name="toolbarLeft")
    div.app-table-toolbar-right
      slot(name="toolbarRight")
  div.app-table-scroll
    el-table(
      :data="vList"
      @row-click="handleRow"
      @expand-change="handleExpandChange"
      :expand-row-keys="expanded"
      :row-key="getRowKey"
      :row-class-name="getRowClassName"
    )
      el-table-column(
        v-if="withExpand"
        type="expand"
      )
        template(v-slot="rowProps")
          slot(name="expand" v-bind="rowProps")
      el-table-column(
        v-for="column in vColumns"
        :key="column.name"
        :prop="column.name"
        :label="column.title"
        :align="column.align || 'left'"
        :width="column.width || 'auto'"
      )
        template(v-slot="scope")
          slot(
            :name="column.name"
            v-bind="scope"
          ) {{ $get(scope.row, column.name) }}
  div.pagination-container
    el-pagination(
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-sizes="[5, 10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="vFiltered.length"
      :prev-text="'上一页'"
      :next-text="'下一页'"
    )
</template>

<script>
import { _ } from '@yishitec/web';

export default {
  name: 'AppTable',
  props: {
    value: { type: Array, default: () => [] },
    // columns: [{ name, title, align = left }]
    columns: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    withExpand: { type: Boolean, default: false },
    withToolbar: { type: Boolean, default: false },
    withRefresh: { type: Boolean, default: false },
    searchKeyword: { type: String, default: '' },
    rowCursor: { type: String, default: 'pointer' },
  },
  emits: ['refresh', 'handleRow'],
  data() {
    return {
      expanded: [],
      page: 1,
      pageSize: 5,
    };
  },
  computed: {
    vColumns() {
      return _.map(this.columns, (column, index) => {
        // eslint-disable-next-line prefer-const
        let { name, title, ...rest } = column || {};
        if (!name) {
          name = `_column_${index}`;
        }
        if (!title) {
          title = _.titleCase(name);
        }
        return {
          name,
          title,
          ...rest,
        };
      });
    },
    vFiltered() {
      let ret = this.value;
      ret = _.filter(ret, (item) => this.containsText(item, this.searchKeyword));
      return ret;
    },
    vList() {
      let ret = this.vFiltered;
      const page = Number(this.page);
      const pageSize = Number(this.pageSize);
      const start = (page - 1) * pageSize;
      const end = start + pageSize;
      ret = _.slice(ret, start, end);
      return ret;
    },
  },
  watch: {
    vFiltered() {
      this.page = 1;
    },
    pageSize() {
      this.page = 1;
    },
  },
  mounted() {
    this.pageSize = Number(_.get(localStorage, 'pageSize') || 20);
  },
  methods: {
    handleRow(ev) {
      this.$emit('handleRow', ev);
    },
    $get: _.get,
    emitRefresh() {
      this.$emit('refresh');
    },
    handleExpandChange(row) {
      this.toggleRowExpand(this.getRowKey(row));
    },
    toggleRowExpand(rowKey) {
      if (this.expanded.includes(rowKey)) {
        this.expanded = _.filter(this.expanded, (item) => item !== rowKey);
        return;
      }
      this.expanded.push(rowKey);
    },
    getRowKey(row) {
      if (row.id) {
        return row.id;
      }
      return _.values(row).join('-');
    },
    handleSizeChange(ev) {
      this.pageSize = ev;
      _.set(localStorage, 'pageSize', ev);
    },
    handleCurrentChange(ev) {
      this.page = ev;
    },
    getRowClassName() {
      const ret = [];
      if (this.rowCursor === 'pointer') {
        ret.push('cursor-pointer');
      }
      return ret.join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
.app-table-toolbar {
  padding: 10px;
  background: #fff;
  border-bottom: solid 1px $light-gray;
  @include pc {
    @include ui-flex-row(space-between, center);
  }
}
.app-table-toolbar-right {
  flex: 0 0 auto;
  flex-wrap: wrap;
  margin-top: 10px;
  @include ui-flex-row(flex-start, center);
  margin-left: -10px;
  @include pc {
    margin-top: 0;
    @include ui-flex-row(flex-end, center);
  }
}
.toolbar-search {
  display: inline-block;
  max-width: 200px;
}
.pagination-container {
  margin: 10px 0;
  @include ui-flex-row;
}
.app-table-scroll {
  width: 100%;
  overflow-x: auto;
}
::v-deep small {
  font-size: 12px;
  display: inline-block;
}
</style>
