<template lang="pug">
ScrollBox(
  ref="scroll"
  @mouseover="isMouseIn = true"
  @mouseout="isMouseIn = false"
  style="min-height: 120px;"
)
  slot
  .fill(:style="{ height: `${height}px` }")
</template>

<script>
export default {
  name: 'ScrollingBox',
  data() {
    return {
      isMouseIn: false,
      height: 0,
    };
  },
  mounted() {
    this.tick();
  },
  methods: {
    tick() {
      if (!this.$refs.scroll) return;
      if (!this.isMouseIn) {
        const y = this.$refs.scroll.$el.scrollTop;
        let yNext = y + 1;
        this.height = Math.max(this.$refs.scroll.$el.offsetHeight - 45, 0);
        if (yNext >= this.$refs.scroll.$el.scrollHeight - this.$refs.scroll.$el.offsetHeight) {
          yNext = 0;
          this.isMouseIn = true;
          setTimeout(() => {
            this.isMouseIn = false;
          }, 2000);
        }
        this.$refs.scroll.$el.scrollTo({
          left: 0,
          top: yNext,
          behavior: 'smooth',
        });
      }
      setTimeout(this.tick, 100);
    },
  },
};
</script>
