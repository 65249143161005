<template lang="pug">
div.pfl-projects-filter-list
  div(
    v-if="withFilters"
  ).pfl-left
    div
      Searchable(
        v-model="filterKeyword"
      )
        div
          el-link(
            :type="filterProjectId === null ? 'primary' : 'default'"
            @click="handleFilterSearch({ projectId: null })"
          ) 全部
        div(
          v-for="project in vProjects"
          :key="project.id"
        ).filter-item
          el-link(
            :type="filterProjectId === project.id ? 'primary' : 'default'"
            @click="handleFilterSearch({ projectId: project.id })"
          ) {{ project.title }}
  div(:class="{ 'full-width': !withFilters }").pfl-right
    div
      slot(
        :projectId="filterProjectId"
      )
    div
      SspTable(
        :value="list"
        :columns="attributes"
        @handleRow="handleRow"
      )
        template(
          v-for="field in attributes"
          v-slot:[field.name]="scope"
        )
          slot(
            :name="field.name"
            v-bind="scope"
          )
</template>

<script>
import { _ } from '@yishitec/web';

export default {
  name: 'ProjectsFilterList',
  props: {
    attributesFilter: { type: Array, default: null },
    withFilters: { type: Boolean, default: true },
    url: {
      type: String,
      default: '/api/ops-zones/list',
    },
    pathPrefix: {
      type: String,
      default: '/admin/ops-zones/edit',
    },
  },
  data() {
    return {
      projects: [],
      filterKeyword: '',
      filterProjectId: null,
      list: [],
      attributes: [],
    };
  },
  computed: {
    vProjects() {
      return _.filter(this.projects, (item) => {
        return this.containsText(item.title, this.filterKeyword);
      });
    },
  },
  mounted() {
    this.loadMetaData();
    this.loadData();
    this.handleFilterSearch();
  },
  methods: {
    async loadMetaData() {
      const resProjects = await this.withLoading(this.api('/api/projects/list'));
      const { list = [] } = resProjects || {};
      this.projects = list;
    },
    async loadData() {},
    async handleFilterSearch(searchCondition) {
      this.filterProjectId = _.get(searchCondition, 'projectId') || null;
      const res = await this.withLoading(this.api(this.url, { ...searchCondition }));
      const { list, attributes } = res || {};
      this.list = list;
      this.attributes = attributes;
      if (this.attributesFilter) {
        this.attributes = _.filter(attributes, (attribute) => {
          return this.attributesFilter.includes(attribute.name);
        });
      }
    },
    handleRow(row) {
      this.$router.push(`${this.pathPrefix}/${row.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.pfl-projects-filter-list {
  @include ui-flex-row(flex-start, stretch);
}
.pfl-left {
  flex: 0 0 150px;
  border-right: solid 1px #eee;
  padding-right: 10px;
  box-sizing: border-box;
  margin-right: 10px;
}
.pfl-right {
  box-sizing: border-box;
  flex: 1 0 1px;
  max-width: calc(100% - 150px - 10px);
  &.full-width {
    max-width: 100%;
  }
}
.filter-item {
  margin: 4px 0;
}
</style>
