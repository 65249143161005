<script setup>
import { _ } from '@yishitec/web';
import { defineProps, defineEmits, computed } from 'vue';
import { useRoute, useRouter, useSubmit } from '../../composables';

const props = defineProps({
  form: Object,
  attributes: Array,
  isForm: Boolean,
  readonly: Boolean,
  action: String,
  backTo: String,
  actions: Array,
  prefix: String,
  apiPrefix: String,
  direction: { type: String, default: 'vertical' },
  defaultSpan: { type: Number, default: 12 },
  withSaveDraft: { type: Boolean, default: true },
  labelPosition: {
    type: String,
    default: 'left',
    validator(value) {
      return ['top', 'left', 'right'].includes(value);
    },
  },
});

const emit = defineEmits(['update-field', 'update:attributes', 'update:form', 'refresh']);

const vActions = computed(() => {
  return (
    props.actions || [
      {
        title: '删除',
        icon: 'icon-delete',
        type: 'text',
        api: (record) => `${props.apiPrefix}/delete`,
      },
    ]
  );
});

const route = useRoute();
const router = useRouter();

const goBack = (backTo) => {
  const vBackTo = backTo || props.backTo;
  if (vBackTo === 'RELOAD') {
    window.location.reload();
  } else if (vBackTo === 'EMIT') {
    emit('refresh');
  } else if (vBackTo) {
    router.push(vBackTo);
  }
};

const { handleSubmit, pending } = useSubmit(props.action, null, {
  afterSubmit: (id, res) => {
    goBack();
  },
});

const { handleSubmit: handleSubmitDraft, pending: pendingDraft } = useSubmit(props.action, null, {
  afterSubmit: (id, res) => {
    if (route.path === `${props.prefix}/edit/new`) {
      router.replace(`${props.prefix}/edit/${id}`);
      return;
    }
    emit('refresh');
  },
});

const handleUpdateForm = (value) => {
  emit('update:form', {
    ...(props.form || {}),
    ...(value || {}),
  });
};

const handleUpdateField = (attr, $event) => {
  emit('update-field', {
    apiPrefix: props.apiPrefix,
    attribute: attr,
    form: props.form,
    value: $event,
    updateAttributes: (value) => {
      if (value) {
        emit('update:attributes', value);
      }
    },
    updateForm: handleUpdateForm,
  });
};

const handleWidgetAction = (attr, $event) => {
  emit('update-field', {
    attribute: attr,
    form: props.form,
    widgetAction: $event.action,
    updateAttributes: (value) => {
      if (value) {
        emit('update:attributes', value);
      }
    },
    updateForm: handleUpdateForm,
  });
};
</script>

<template lang="pug">
  el-form(:model="form" v-if="isForm" :label-position="labelPosition" label-width='120px').admin-form
    AdminForm(
      :form="form"
      @update:form="handleUpdateForm"
      :attributes="attributes"
      @update:attributes="emit('update:attributes', $event)"
      :readonly="readonly"
      :default-span="defaultSpan"
      :actions="vActions"
      :prefix="prefix"
      :api-prefix="apiPrefix"
      :direction="direction"
      @update-field="emit('update-field', $event)"
      @refresh="emit('refresh')"
    )
    div(
      :style='{ marginTop: "30px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }'
      v-if='action && !readonly'
    )
      span
        el-button(size="large" type="primary" @click="handleSubmit(form)" :loading="pending" :disabled="pendingDraft" :style='{ marginRight: "6px" }') {{ withSaveDraft ? '提交并返回' : '提交' }}
        el-button(v-if="withSaveDraft" size="large" @click="handleSubmitDraft(form)" :loading="pendingDraft" :disabled="pending") 保存
      AdminActions(
        v-if="vActions.length"
        :record="form"
        :prefix="prefix"
        :api-prefix="apiPrefix"
        :buttons="0"
        :actions="vActions"
        @refresh="goBack"
      )
  div(v-else)
    el-row(:gutter="10")
      template(
        v-for="(attr, attrIndex) in attributes"
      )
        el-col(
          v-if="attr"
          :key="attr.name || `COL__${attrIndex}`"
          :lg="attr.span || (attr.children ? 24 : defaultSpan)"
        )
          div(
            v-if="attr.widget === 'card'"
            shadow='never'
          ).mb-2.p-4
            AdminCardTitle(v-if='false')
              i.el-icon-arrow-left(v-if="attr.withBack" @click="goBack(attr.backTo)").mr-2.cursor-pointer
              span {{ attr.title || attr.name }}
              i.el-icon-refresh(v-if="attr.withRefresh" @click="emit('refresh')").ml-2.cursor-pointer
            AdminForm(
              v-if="attr.form"
              :form="attr.form"
              @update:form="($event) => { attr.form = $event }"
              :attributes="attr.children"
              @update:attributes="attr.children = $event"
              :readonly="attr.readonly"
              is-form
              :action="attr.action"
              :back-to="attr.backTo"
              :default-span="attr.defaultSpan || defaultSpan"
              :actions="attr.actions || vActions"
              :prefix="attr.prefix || prefix"
              :api-prefix="attr.apiPrefix || apiPrefix"
              :direction="attr.direction || 'vertical'"
              @update-field="emit('update-field', $event)"
              @refresh="emit('refresh')"
            )
            AdminForm(
              v-else
              :form="form"
              @update:form="handleUpdateForm"
              :attributes="attr.children"
              @update:attributes="attr.children = $event"
              :readonly="readonly || attr.readonly"
              is-form
              :action="attr.action"
              :back-to="attr.backTo"
              :default-span="defaultSpan"
              :actions="vActions"
              :prefix="prefix"
              :api-prefix="apiPrefix"
              :direction="attr.direction || 'vertical'"
              @update-field="emit('update-field', $event)"
              @refresh="emit('refresh')"
            )
          el-card(
            v-else-if="attr.widget === 'group'"
            :bordered="false"
          )
            AdminCardTitle {{ attr.title || attr.name }}
            AdminForm(
              :form="form"
              @update:form="handleUpdateForm"
              :attributes="attr.children"
              @update:attributes="emit('update:attributes', $event)"
              :readonly="readonly"
              :action="attr.action"
              :back-to="attr.backTo"
              :default-span="defaultSpan"
              :actions="vActions"
              :prefix="prefix"
              :api-prefix="apiPrefix"
              :direction="attr.direction || 'vertical'"
              @update-field="emit('update-field', $event)"
              @refresh="emit('refresh')"
            )
          div(
            v-else-if="attr.widget === 'divider'"
          )
            AdminCardTitle {{ attr.title || attr.name }}
            el-divider
          el-form-item(
            v-else-if="!attr.hidden"
            :label="attr.title || attr.name"
            :required='attr.required'
          )
            AdminInput(
              :modelValue.sync="form[attr.name]"
              :form="form"
              @change="handleUpdateField(attr, $event)"
              @widgetAction="handleWidgetAction(attr, $event)"
              :attribute="attr"
              :readonly="readonly"
            )
</template>

<style lang="scss" scoped>
.mb-2 {
  margin-bottom: 6px;
}

.mr-2 {
  margin-right: 6px;
}

.ml-2 {
  margin-left: 6px;
}

.p-4 {
  padding: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.admin-form {
  :deep(.el-form-item__label) {
    line-height: 24px;
    box-sizing: border-box;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  :deep(.el-alert .el-alert__description) {
    line-height: 1.6;
  }
}
</style>
