<template>
  <div class="admin-change-password">
    <ui-page-header title="修改密码" />
    <ui-stage>
      <ui-admin-form
        :model="changePasswordForm"
        :fields="changePasswordFields"
        @submit="handleSubmitChangePassword"
      />
    </ui-stage>
  </div>
</template>

<script>
import changePassword from '@yishitec/web/core/changePassword';

export default {
  name: 'AdminChangePassword',
  mixins: [changePassword],
};
</script>

<style scoped></style>
