<template>
  <div>
    <ui-stage>
      <ui-admin-table
        v-if="data"
        :attributes="attributes"
        :data="data"
        :module-url="moduleUrl"
        pagination-method="front-end"
        :with-create="canCreate"
        :with-delete="canCreate"
      >
        <template v-slot:column_plugins="{ row }">
          <el-tag v-for="item in row.xProjectPlugins" :key="item.id" size="mini" type="primary">
            {{ item.pluginTitle }}
          </el-tag>
        </template>
        <template v-slot:column_withProgressModule="{ row }">
          <el-tag v-if="row.withProgressModule" size="mini" type="success"> 是</el-tag>
          <template v-else>-</template>
        </template>
        <template v-slot:column_enableEBadge="{ row }">
          <el-tag v-if="row.enableEBadge" size="mini" type="success"> 是</el-tag>
          <template v-else>-</template>
        </template>
        <template v-slot:column_withQrDailyGrants="{ row }">
          <el-tag v-if="row.withQrDailyGrants" size="mini" type="success"> 是</el-tag>
          <template v-else>-</template>
        </template>
        <template v-slot:column_withFaceDailyGrants="{ row }">
          <el-tag v-if="row.withFaceDailyGrants" size="mini" type="success"> 是</el-tag>
          <template v-else>-</template>
        </template>
        <template v-slot:column_withHealthQrOcr="{ row }">
          <el-tag v-if="row.withHealthQrOcr" size="mini" type="success"> 是</el-tag>
          <template v-else>-</template>
        </template>
        <template v-slot:column_enableEBadge="{ row }">
          <el-tag v-if="row.enableEBadge" size="mini" type="success"> 是</el-tag>
          <template v-else>-</template>
        </template>
        <template v-slot:column_enablePreviewIdCard="{ row }">
          <el-tag v-if="row.enablePreviewIdCard" size="mini" type="success"> 是</el-tag>
          <template v-else>-</template>
        </template>
      </ui-admin-table>
    </ui-stage>
  </div>
</template>

<script>
import { adminList } from '@yishitec/web/mixins';
import permissions from '../../../mixins/permissions';

export default {
  name: 'List',
  mixins: [adminList, permissions],
  data() {
    return {
      apiBodyData: {
        currentProjects: this.$store.state.currentProjects,
        includeXProjectPlugins: true,
      },
    };
  },
  computed: {
    canCreate() {
      return this.amI(null, '建设单位', '管理人员', true) || this.amI(null, null, '平台管理员');
    },
  },
};
</script>

<style lang="less" scoped></style>
