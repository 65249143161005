import { getCurrentInstance } from 'vue';

export default () => {
  const ctx = getCurrentInstance().proxy;
  const Notification = {};
  const byType = (type) => {
    Notification[type] = (options) => {
      const { title } = options || {};
      ctx.$message({
        type,
        message: title,
      });
    };
  };
  byType('warning');
  byType('success');
  return {
    Notification,
  };
};
