<template lang="pug">
.map-info-window-mask(v-show="value", @click="closeWindow")
  .map-info-window(@click.stop)
    .map-info-window-scroll
      slot
    .map-info-close(@click="closeWindow") × 关闭
</template>

<script>
export default {
  name: 'MapInfoWindow',
  props: {
    value: Boolean,
  },
  emits: ['input'],
  methods: {
    closeWindow() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-info-window-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(#000, 0.8);
  transition: all 0.3s;
  z-index: 200;
}
.map-info-window {
  border: solid 1px rgba(#fff, 0.2);
  background: rgba(#000, 0.6);
  color: #fff;
  position: absolute;
  top: 30px;
  left: 30px;
  right: 70px;
  bottom: 30px;
}
.map-info-window-scroll {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 40px;
  font-size: 16px;
  overflow: auto;
}
.map-info-close {
  position: absolute;
  top: 0;
  right: -61px;
  background: rgba(#000, 0.8);
  color: #fff;
  width: 60px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
</style>
