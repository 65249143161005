<template>
  <div>
    <ui-stage v-if="data">
      <ui-tabs v-model="tab" type="border-card">
        <ui-tab-pane name="main" label="编辑">
          <ui-alert type="warning" :closable="false" v-if="data.approve_state">{{
            data.approve_state
          }}</ui-alert>
          <ui-alert type="warning" :closable="false" v-if="data.approve_state && canApprove">
            <ui-button type="warning" @click="handleApprove">审核</ui-button>
          </ui-alert>
          <ui-flex row>
            <div style="flex: 0 0 auto; max-width: calc(100% - 230px)">
              <ui-admin-form
                :compact="compact"
                :readonly="readonly || !canEdit"
                :fields="vAttributes"
                :model="data"
                :module-url="moduleUrl"
                :with-delete="canEdit"
                @submit="handleSubmit"
              >
                <template slot="field__cert_uploads" slot-scope="{ value, onInput, readonly }">
                  <ui-uploads :value="value" @input="onInput" :readonly="readonly" />
                </template>
                <template v-slot:field__location_amap="{ value, onInput, readonly }">
                  <app-map-editor :value="value" @input="onInput" :readonly="readonly" />
                </template>
                <template v-slot:field__location_point="{ value, onInput, readonly }">
                  <app-map-editor
                    :value="value"
                    @input="onInput"
                    :readonly="readonly"
                    type="point"
                  />
                </template>
                <template v-slot:field__type="{ value, onInput, readonly }">
                  <template v-if="readonly">{{ value }}</template>
                  <ui-select
                    v-else
                    :value="value"
                    @input="onInput"
                    filterable
                    allow-create
                    default-first-option
                  >
                    <ui-option
                      v-for="item in typeOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></ui-option>
                  </ui-select>
                </template>
              </ui-admin-form>
            </div>
            <div style="margin-left: 10px; flex: 0 0 200px">
              <div
                v-if="data.id && data.op_type && data.op_type !== '动态危险源'"
                style="padding-top: 380px; text-align: center"
              >
                <vue-qrious :value="vrToken" :size="200" :alt="vrToken" />
                <p>危险源名称：{{ [data.title, data.sn].filter(Boolean).join(' - ') }}</p>
                <p>危险源等级：{{ data.level }}</p>
                <p v-if="data.location">位置：{{ data.location }}</p>
                <p v-if="data.type">类型：{{ data.type }}</p>
              </div>
            </div>
          </ui-flex>
        </ui-tab-pane>
      </ui-tabs>
    </ui-stage>
  </div>
</template>

<script>
import { _ } from '@yishitec/web';
import { adminEdit } from '@yishitec/web/mixins';
import md5 from 'md5';
import VueQrious from 'vue-qrious';
import permissions from '../../../mixins/permissions';
import AppMapEditor from '../../../components/AppMapEditor.vue';

const activeFields = ['location_amap', 'location_point'];
const fieldsSpec = {
  区域危险源: ['location_amap'],
  静态危险源: ['location_point'],
};

export default {
  name: 'Edit',
  mixins: [adminEdit, permissions],
  components: {
    AppMapEditor,
    VueQrious,
  },
  data() {
    const typeOptions =
      '电箱（总箱） 电箱（分箱） 挖机 起重机 履带吊 汽车吊 塔吊 龙门吊 压路机 发电机 打桩机 搅拌桩 钻孔桩'.split(
        ' ',
      );
    return {
      withCustomFields: false,
      vLockedFields: ['project_id'],
      typeOptions,
    };
  },
  computed: {
    canEdit() {
      /** @type {This} */
      const that = this;
      return (
        that.amI(_.get(that.data, 'project_id'), '施工单位', '安全员', true) ||
        that.amI(_.get(that.data, 'project_id'), '施工单位', '管理人员', true)
      );
    },
    canApprove() {
      /** @type {This} */
      const that = this;
      const projectId = _.get(this.data, 'project_id');
      return (
        that.amI(projectId, '监理单位', '专项管理员', true) ||
        that.amI(projectId, '监理单位', '管理人员', true)
      );
    },
    vrToken() {
      const tokenStr = [
        'com.smartconstructioncontroller.danger-zones',
        this.data.id,
        this.data.project_id,
      ].join('.');
      return `SSITE_DANGER_ZONE|${this.data.id}|${md5(tokenStr)}`;
    },
    vAttributes() {
      const ignoreFields = _.without(activeFields, ..._.get(fieldsSpec, this.data.op_type, []));
      const ret = _.filter(
        this.attributes,
        (attribute) =>
          !activeFields.includes(attribute.name) || !ignoreFields.includes(attribute.name),
      );
      return ret;
    },
  },
  methods: {
    async handleApprove() {
      const res = await this.withLoading(this.api(`/api/danger-zones/approve/${this.getId()}`));
      if (!res) return;
      this.$message({
        type: 'success',
        message: '审核成功',
      });
      this.fetchData();
    },
    beforeHandleSubmit() {
      const ignoreFields = _.without(activeFields, ..._.get(fieldsSpec, this.data.op_type, []));
      _.forEach(ignoreFields, (field) => {
        this.data[field] = null;
      });
      return this.data;
    },
  },
};
</script>
