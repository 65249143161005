<template lang="pug">
ui-flex.sub-project-nav-item(
  row,
  center,
  :class="{ active }",
  @click="emitClick"
)
  template(v-if="isTool") {{ title }}
  template(v-else)
    .data-title(v-if="title") {{ title }}
    el-progress.data-progress(:percentage="vPercentage", :show-text="false")
    .data-percentage {{ vPercentage | formatPercentage }}
</template>

<script>
import { numeral } from '@yishitec/web';

export default {
  name: 'SubProjectNavItem',
  props: {
    id: { type: [String, Number], default: null },
    title: { type: String, default: '' },
    progress: { type: Number, default: null },
    active: { type: Boolean, default: false },
    isTool: { type: Boolean, default: false },
  },
  emits: ['click'],
  data() {
    return {};
  },
  computed: {
    vPercentage() {
      return this.progress;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {},
    emitClick() {
      this.$emit('click');
    },
  },
  filters: {
    formatPercentage(value) {
      return numeral((value || 0) / 100).format('0,0.000%');
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-project-nav-item {
  margin-right: 10px;
  box-sizing: border-box;
  padding: 4px 8px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 12px;
  font-weight: normal;

  &:hover,
  &.active {
    background: rgba(rgb(0, 119, 255), 0.1);
  }

  .data-title {
    flex: 0 0 auto;
    margin-right: 10px;
  }

  .data-progress {
    flex: 0 0 auto;
    width: 80px;
  }

  .data-percentage {
    flex: 0 0 auto;
    font-size: 12px;
    margin-left: 6px;
  }
}
</style>
