<template lang="pug">
div.admin-login
  div.admin-login-left
    div
      h1.admin-login-title 智慧工地进度可视化管理平台
      div.admin-login-hint 请先输入并确认登录信息后进入系统
      ssp-form-item(
        label="公司名"
        icon="building"
        placeholder="点此输入..."
        v-model="login.companyName"
      ).mb30
      ssp-form-item(
        label="用户名"
        icon="user"
        placeholder="点此输入..."
        v-model="login.username"
      ).mb30
      ssp-form-item(
        label="登录密码"
        icon="lock"
        placeholder="点此输入..."
        v-model="login.password"
        type="password"
      ).mb30
      div.form-submit
        el-button(
          type="primary"
          @click="handleSubmit"
        ) 确认登录
  div.admin-login-right
    img(
      :src="require('../../assets/images/progress/progress-login-bg.jpg')"
    ).admin-login-right-img
</template>

<script>
import login from '@yishitec/web/core/login';

export default {
  name: 'AdminLogin',
  mixins: [login],
  data() {
    return {
      login: {
        username: '',
        password: '',
        companyName: '',
      },
    };
  },
  methods: {
    async handleSubmit() {
      const res = await this.withLoading(this.api('/api/login', { ...this.login }));
      if (res) {
        this.$router.push('/progress/');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.admin-login {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background: #fff;
  @include ui-flex-row(flex-start, stretch);
}
.admin-login-title {
  font-size: 30px;
  line-height: 45px;
}
.admin-login-hint {
  margin: 16px 0 50px 0;
  color: #8181a5;
  font-size: 14px;
  line-height: 21px;
}
.admin-login-left {
  flex: 1 0 1px;
  @include ui-flex-col(center, center);
  margin: 16px;
}
.admin-login-right {
  flex: 0 0 auto;
  max-width: 40vw;
  @include xs {
    display: none;
  }
  margin: 30px;
}
.admin-login-right-img {
  display: block;
  width: auto;
  height: 100%;
}
.mb30 {
  margin-bottom: 30px;
}
</style>
