<template>
  <div>
    <ui-stage>
      <ui-admin-table
        v-if="data"
        :attributes="attributes"
        :data="data"
        :module-url="moduleUrl"
        pagination-method="front-end"
        :with-create="false"
        :with-delete="false"
        is-edit-view
      >
        <template v-slot:column_title="{ row }">
          <router-link :to="`${moduleUrl}/edit/${row.id}`">{{ row.title }}</router-link>
        </template>
      </ui-admin-table>
    </ui-stage>
  </div>
</template>

<script>
import { adminList } from '@yishitec/web/mixins';

export default {
  name: 'List',
  mixins: [adminList],
  data() {
    return {
      apiBodyData: {
        currentProjects: this.$store.state.currentProjects,
      },
    };
  },
};
</script>

<style lang="less" scoped></style>
