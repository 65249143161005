<template lang="pug">
div
  .title 选择项目
  el-select(
    :value="value"
    @input="$emit('input', $event)"
    multiple
    clearable
    filterable
    size="small"
    style="width: 100%"
  )
    el-option(
      v-for="item in projects",
      :key="item.id",
      :label="item.title",
      :value="item.id"
    )
</template>

<script>
import LeftSidebarSectionTitle from './LeftSidebarSectionTitle.vue';

export default {
  name: 'SelectProject',
  props: {
    value: { type: Array, default: () => [] },
    projects: { type: Array, default: () => [] },
  },
  emits: ['input'],
  components: { LeftSidebarSectionTitle },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
