<template>
  <div class="home">
    <app-nav />
    <ui-container>
      <pre style="white-space: pre-line">
前言

上海同川信息技术有限公司及旗下各关联公司（以下统称“同川”或“我们”）在此庄严承诺保护使用同川产品和服务（以下统称“同川服务”）之用户（以下统称“用户”或“您”）的个人信息和隐私安全。您在使用同川服务时，我们可能会收集和使用您的相关个人信息，但是我们确保该等收集和使用将是在法律、法规限定的范畴内进行的。
我们希望通过《上海同川信息技术有限公司公司隐私政策》（以下简称“本政策”）向您说明我们在收集和使用您相关个人信息时对应的处理规则等相关事宜，以便更好地保障您的权益。
本政策适用于我们提供的所有同川服务。当您使用我们任何单项服务时，您同意接受本政策以及我们在该单项服务中发出的特定隐私信息类政策条款（下列称“特定条款”，如适用）的保护，在此情况下特定条款与本政策条款同时对您产生效力。如特定条款与本政策条款存在同类条款的不一致约定，则在特定条款约束范围内应以特定条款为准。如我们提供的某一单项服务不适用本政策的，该服务中会以适当方式明示排除适用本政策。
在使用各项同川服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体/粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后再开始使用。如果您不同意本政策的内容，将可能导致同川服务无法正常运行，或者无法达到我们希望能带给您的服务效果体验，您应立即停止访问/使用同川服务。您使用或继续使用同川服务，都表示您同意我们按照本政策（包括更新版本）收集、使用、储存、分享、转让和披露您的个人信息。
在阅读完本政策后，如您对本政策或与本政策相关的事宜有任何问题，您可通过本政策“如何联系我们”章节所列的反馈渠道联系我们，我们会尽快为您作出解答。

目录
一、我们如何收集和使用个人信息
二、我们如何使用COOKIES或同类技术
三、我们可能分享、转让和披露的个人信息
四、我们如何储存和保护个人信息
五、我们如何管理您的个人信息
六、第三方服务
七、通知和修订
八、如何联系我们

一、我们如何收集和使用个人信息
我们收集您的个人信息主要是为了您和其他用户能够更容易和更满意地使用同川服务。同川的目标是向所有的互联网用户提供安全、优质的上网经历。而这些信息有助于我们实现这一目标。
（一）我们将通过以下途径收集和获得您的个人信息：
1、基于您同意而提供的信息。
例如：
（1）您在注册同川服务的帐号或使用同川服务时，向我们提供的信息；
（2）您通过同川服务向其他方提供的共享信息，以及您使用同川服务时所储存的信息。
请注意，如您在同川服务中其他用户可见的公开区域内上传或发布的信息中、您对其他人上传或发布的信息作出的回应中公开您的信息，该等信息可能会被他人收集并加以使用。当您发现他人不正当地收集或使用您的信息时，可通过本政策“如何联系我们”章节所列的反馈渠道联系我们。
2、其他方分享的您的信息。
亦即其他方（在您予以授权的前提下）使用同川服务时所提供有关您的共享信息。
3、基于现有技术服务需求而获取的您的信息。
您在使用同川服务时，我们收集、汇总、记录的信息，例如日志信息、位置信息、设备信息。
（二）我们会出于以下目的，收集和使用您以下类型的个人信息：
1、帮助您完成注册及登录
为便于我们为您提供服务，您需要提供基本注册或登录信息，包括手机号码、身份证号，并创建您的帐号、用户名和密码。
您也可以在注册、登录或后续使用过程中，填写或补充您的其他额外信息（例如您的昵称、头像、性别、籍贯、职业、学历、出生日期、兴趣爱好），这将有助于我们给您提供个性化的推荐和更优的服务体验，但如果您不提供这些信息，并不会影响您使用同川服务的基本功能。
此外，在符合同川单项服务的服务协议约定条件及国家相关法律法规规定的情况下，我们也提供注册帐号的注销，您可以联系我们处理或按照单项服务的具体指引进行操作，完成对注册账号的注销后，我们将停止对您个人信息的收集和使用。
2、实现身份认证
依照相关法律法规规定及监管要求，或为满足用户身份真实核验、保障系统和服务安全及其他特定服务功能等需要，我们可能需要您提供真实身份信息（例如姓名、身份证、护照、驾驶证、户口本、面部特征、指纹及其他身份信息）以完成身份认证。如果您不提供上述信息，我们将不能向您提供相关的功能及服务。
为实现身份认证的目的，您同意并授权我们可以自行或委托第三方向有关身份认证机构（如个人征信机构、政府部门等）提供、查询、核对您的前述身份信息。
3、维护基础功能的正常运行
在您使用我们服务过程中，为识别帐号异常状态、了解产品适配性，向您提供浏览、搜索等基本服务，维护基础功能的正常运行，我们可能会自动收集、储存关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：
（1）日志信息：当您使用我们的服务时，我们可能会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的登录帐号、搜索查询内容、IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页浏览记录、Push打开记录、停留时长、刷新记录、发布记录、关注、订阅、收藏及分享。
（2）设备信息：我们可能会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如IMEI、MAC、Serial、SIM卡IMSI识别码、设备机型、操作系统及版本、客户端版本、设备分辨率、包名、设备设置、进程及软件列表、唯一设备标识符、软硬件特征信息）、设备所在位置相关信息（例如IP 地址、GPS位置以及能够提供相关信息的WLAN接入点、蓝牙和基站传感器信息）。
同时为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限，我们收集这些信息是为了向您提供我们基本服务和基础功能，如您拒绝提供上述权限将可能导致您无法使用同川服务。
请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。
4、向您提供互动与发布服务
您在使用同川服务时、上传和/或发布信息以及进行相关行为（例如对服务进行评价、分享、收藏等）时，我们将收集您上传、发布或形成的信息，并有权展示您的昵称、头像和发布内容。
5、帮助您完成下单、支付并向您交付商品或服务
（1）当您在同川服务中订购具体商品及/或服务时，我们会通过系统为您生成购买该商品及/或服务的订单。在下单过程中，您至少需提供收货人个人身份信息/公司身份信息、姓名、联系地址、邮政编码、联络人、联系电话、支付状态信息。如果您拒绝提供此类信息，我们将无法完成相关交付服务。如您通过同川服务为其他人订购商品或服务，您需要提供该实际订购人的前述信息。向我们提供该实际订购人的前述信息之前，您需确保您已经取得其授权同意；
（2）为展示您帐号的订单信息，我们会收集您在使用同川服务过程中产生的订单信息（例如您所购买的商品或服务信息、具体订单号、订单创建时间、交易金额）用于向您展示及便于您对订单进行管理；
（3）为完成订单支付、交付商品或服务、确认交易状态及为您提供售后与争议解决服务，我们会通过您基于交易所选择的交易对象、支付机构等收集与交易进度相关的您的帐号、订单、交易、支付、服务信息，或将您的交易信息共享给上述服务提供者。
6、为您提供客服或其他用户响应功能
当您联系我们的客服或使用其他用户响应功能时（例如提出个人信息保护投诉或建议、其他客户投诉和需求），我们可能需要您提供必要的个人信息以匹配并核验您的用户身份，以便保障您的帐号与系统安全。我们可能还会保存您的联系方式（您与我们联系时使用的或您向我们主动提供的其他联系方式）、您与我们的通信/通话记录和内容、与您需求相关联的其他必要信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
7、我们通过间接方式收集到的您的个人信息：
我们可能从关联方、第三方合作伙伴获取您授权共享的相关信息。例如，我们可能从第三方获取您授权共享的帐号信息（包括用户名、头像、昵称）并在您同意本政策后将您的第三方帐号与您的同川服务帐号绑定，使您可以通过第三方帐号直接登录并使用同川服务。我们将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的信息来源合法的前提下，收集并使用您的这些信息。
8、向您提供商品或服务的信息展示和推送
（1）为您展示和推送商品或服务
基于您向我们提供的信息、我们可能收集的信息及我们通过间接方式收集到的您的信息（例如您的浏览及搜索记录、设备信息、位置信息、订单信息），我们可能会基于上述一项或几项信息的结合，进行推荐算法建模、程序化广告推荐算法建模、用户行为分析及用户画像，用于提取您的浏览、搜索偏好、行为习惯、位置信息相关特征，以便向您提供更契合您需求的页面展示和推荐个性化内容。
（2）向您发出通知
我们可能在必需时（例如当我们由于系统维护而暂停某一单项服务、变更、终止提供某一单项服务时）向您发出与服务有关的通知。
如您不希望继续接收我们推送的消息，您可要求我们停止推送，例如：根据短信退订指引要求我们停止发送推广短信，或在移动端设备中进行设置，不再接收我们推送的消息；但我们依法律规定或单项服务的服务协议约定发送消息的情形除外。
9、为您提供安全保障
为提高您使用我们及合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或同川服务相关协议规则的情况，我们可能会收集、使用或整合您的帐号信息、交易信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您帐号及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
10、改进我们的服务
我们可能将通过某一项同川服务所收集的信息，用于我们的其他服务。例如，在您使用某一项同川服务时所收集的您的信息，可能在另一项同川服务中用于向您提供特定内容或向您展示与您相关的、而非普遍推送的信息；我们可能让您参与有关同川服务的调查，帮助我们改善现有服务或设计新服务；同时，我们可能将您的信息用于软件更新。
11、其他用途
您了解并同意，在收集您的个人信息后，我们可能通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别您的身份，在此情况下我们有权使用已经去标识化的信息，对用户数据库进行分析并予以商业化的利用，而无需另行获得您的同意。
请您注意，如果我们要将您的个人信息用于本政策中未载明的其他用途或额外收集未提及的其他个人信息，我们会通过页面提示、交互流程、网站公告或其他方式另行征得您的同意。一旦您同意，该等额外用途将视为本政策的一部分，该等额外信息也将适用本政策。
12、您理解并同意，部分单项服务可能需要您在您的设备中开启特定的访问权限，以实现这些权限所涉及信息的收集和使用。例如：
（1）在您开启相册和/或相机（摄像头）权限后，您能够上传、拍摄照片/图片/视频，实现发布信息和评论、视频直播或与客服沟通提供证明等功能；
（2）在您开启通讯录权限后，我们可以获取您的通讯录好友信息，以便向您提供好友状态查询、邀请您的好友使用同川服务、向您认识的人分享内容/产品等功能；
（3）在您开启麦克风和相关语音权限后，您能够使用语音功能与我们的产品、他人进行信息交互，或与客服联系等；
（4）在您开启位置权限后，我们可以获取您的位置信息，以便让您与附近的朋友进行信息交互或向您推送个性化内容等；
（5）在您开启我们可读取/写入您日历的权限后，我们可以获取您的日程时间，以便为您提供记录、提醒等功能。
当您需要关闭这些功能权限时，大多数移动设备都会支持您的这项需求，具体方法请参考或联系您移动设备的服务商或生产商。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
13、征得授权同意的例外
根据相关法律法规规定，以下情形中收集您的信息无需征得您的授权同意：
（1）与国家安全、国防安全有关的；
（2）与公共安全、公共卫生、重大公共利益有关的；
（3）与犯罪侦查、起诉、审判和判决执行等有关的；
（4）出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
（5）所收集的信息是您自行向社会公众公开的；
（6）从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；
（7）根据您的要求签订合同所必需的；
（8）用于维护同川服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
（9）为合法的新闻报道所必需的；
（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；
（11）法律法规规定的其他情形。
14、有关个人敏感信息的提示
以上由您提供或我们收集您的信息中，可能包含您的个人敏感信息，例如身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息。请您谨慎并留意个人敏感信息，您同意您的个人敏感信息我们可以按本政策所述的目的和方式来处理。
我们承诺，在收集您的上述个人敏感信息时，应取得您的明示同意，并确保该明示同意是您在完全知情的基础上自愿给出的、具体的、清晰明确的愿望表示。
若您拒绝提供上述个人敏感信息的，将无法正常提供同川服务的全部或部分。
二、我们如何使用COOKIES或同类技术
我们或我们的第三方合作伙伴可能通过COOKIES或同类技术获取和使用您的信息，并将该等信息储存为日志信息。
通过使用COOKIES，我们向用户提供简单易行并富个性化的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让其用户可以受益。比如，为使得同川虚拟社区的登录过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录同川的服务时能更加方便快捷。COOKIES能帮助我们确定您连接的页面和内容，您在同川特定服务上花费的时间和您所选择的同川服务。
COOKIES使得我们能更好、更快地为您服务，并且使您在同川服务上的经历更富个性化。然而，您应该能够控制COOKIES是否以及怎样被您的浏览器接受。请查阅您的浏览器附带的文件以获得更多这方面的信息。
我们和第三方合作伙伴可能通过COOKIES或同类技术收集和使用您的信息，并将该等信息储存。
我们使用自己的COOKIES或同类技术，可能用于以下用途：
1、记住您的身份。例如：COOKIES或同类技术有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信息；
2、分析您使用我们服务的情况。我们可利用COOKIES或同类技术来了解您使用同川服务进行什么活动、或哪些服务最受欢迎；
3、广告优化。COOKIES或同类技术有助于我们根据您的信息，向您提供与您相关的广告而非进行普遍的广告投放。
我们为上述目的使用COOKIES或同类技术的同时，可能将通过COOKIES或同类技术收集的非个人身份信息汇总提供给广告商和其他伙伴，用于分析您和其他用户如何使用同川服务并用于广告服务。
同川服务上可能会有广告商和其他合作方放置的COOKIES或同类技术。这些COOKIES和或同类技术可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。这些第三方COOKIES或同类技术收集和使用该等信息不受本政策约束，而是受到其自身的信息保护声明约束，我们不对第三方的COOKIES或同类技术承担责任。
您可以通过浏览器或用户选择机制拒绝或管理COOKIES或同类技术。但请您注意，如果您停用COOKIES或同类技术，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。同时，您仍然将收到广告，只是这些广告与您的相关性会降低。
三、我们可能分享、转让或披露的个人信息
（一）分享
除以下情形外，未经您同意，我们不会与同川之外的任何第三方分享您的个人信息：
1、向您提供我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以实现同川服务的功能，让您正常使用您需要的服务，例如：提供支付服务的支付机构、向同川提供服务的技术供应商、提供数据服务（包括网络广告监测、数据统计、数据分析）的合作伙伴、第三方合作的律师事务所及律师；
2、与关联方的必要分享。为便于我们基于统一的帐号体系向您提供一致化服务以及便于您进行统一管理、个性化推荐、保障系统和帐号安全等，您的个人信息可能会在我们和我们的关联方之间进行必要共享；
3、实现本政策第一条“我们如何收集和使用个人信息”部分所述目的；
4、履行我们在本政策或我们与您达成的其他协议中的义务和行使我们的权利；
5、向委托我们进行推广的合作伙伴等第三方共享，目的是为了使该等委托方了解推广的覆盖面和有效性。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客；
6、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他同川用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。不过，这并不包括违反本政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息；
7、应您合法需求或经您的授权同意；
8、应您的监护人合法要求而提供您的信息；
9、根据与您签署的单项服务协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
10、基于学术研究而提供；
11、基于符合法律法规的社会公共利益而提供。
我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理信息。
（二）转让
1、随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。
2、在获得您的明确同意后，我们会向其他方转让您的个人信息。
（三）披露
我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息：
1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；
2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。
（四）分享、转让、披露信息时事先征得授权同意的例外
以下情形中，分享、转让、披露您的信息无需事先征得您的授权同意：
1、与国家安全、国防安全有关的；
2、与公共安全、公共卫生、重大公共利益有关的；
3、与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；
4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
5、您自行向社会公众公开的信息；
6、从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道。
根据法律规定，共享、转让、披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
四、我们如何储存和保护个人信息
我们仅在本政策所述目的所必需期间和法律法规要求的最短时限内储存您的个人信息。如我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理，但法律法规或监管部门另有规定的除外。
我们在中华人民共和国境内运营中收集和产生的个人信息，储存在中国境内，以下情形除外：
1、法律法规有明确规定；
2、获得您的授权同意；
针对以上情形，我们会确保依据本政策及国家法律法规要求对您的个人信息提供足够的保护。
我们非常重视信息安全，成立了专门的安全团队，并采取一切合理可行的措施，保护您的个人信息：
（一）数据安全技术措施
我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。
我们的服务采取了多种加密技术，例如在某些同川服务中，我们将利用加密技术（例如SSL）来保护您的个人信息，采取加密技术对您的个人信息进行加密保存，并通过隔离技术进行隔离。
在个人信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中的安全性。
我们还会采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。
（二）我们为保护个人信息采取的其他安全措施
我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的储存和使用。
我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。我们还会举办安全和隐私保护培训课程，加强员工的安全意识以及对于保护信息重要性的认识。
（三）我们仅允许有必要知晓这些信息的同川员工、合作伙伴访问您的个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与同川的合作关系。
（四）我们会采取一切合理可行的措施，确保未收集无关的个人信息。
（五）互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。
（六）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的个人信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致个人信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
（七）安全事件处置
在通过同川服务与第三方进行沟通或购买商品及服务时，您不可避免的要向交易对方或潜在的交易对方披露自己的信息，如联络方式或者邮政地址等。请您妥善保护自己的信息，仅在必要的情形下向他人提供。
为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。
请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入同川服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。
请您务必妥善保管好您的帐号、密码及其他身份要素。您在使用同川服务时，我们会通过您的帐号、密码及其他身份要素来识别您的身份。一旦您泄露了前述信息，您可能会蒙受损失，并可能对您产生不利。如您发现帐号、密码及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。
五、我们如何管理您的个人信息
（一）访问、更新和删除
我们鼓励您更新和修改您的个人信息以使其更准确有效。您能通过同川服务访问您的个人信息，并根据对应信息的管理方式自行完成或要求我们进行修改、补充和删除。我们将采取适当的技术手段或提供提交申请的联系渠道，尽可能保证您可以访问、更新和更正自己的个人信息或使用同川服务时提供的其他信息。
在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障信息安全。对于通过COOKIES或同类技术收集的您的信息，我们还在本政策第二条“我们如何使用COOKIES或同类技术”部分说明了向您提供的选择机制。如您想查询、修改或删除您的部分信息，请联系客服进行操作。
除法律法规另有规定，当您更正、删除您的个人信息或申请注销帐号时，我们可能不会立即从备份系统中更正或删除相应的信息，但会在备份更新时更正或删除这些信息。
（二）公开与分享
我们的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关信息，例如，您在同川服务中所上传或发布的信息、您对其他人上传或发布的信息作出的回应，通过电子邮件或在同川服务中不特定用户可见的公开区域内上传或公布您的信息，以及包括与这些信息有关的位置数据和日志信息。只要您不删除您所公开或共享的信息，有关信息可能一直留存在公众领域；即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公众领域保存。如您将信息通过上述渠道公开或共享，由此造成您的信息泄露，我们不承担责任。因此，我们提醒并请您慎重考虑是否通过上述渠道公开或共享您的信息。
（三）注销
在符合同川单项服务的服务协议约定条件及国家相关法律法规规定的情况下，您的该项同川服务帐号可能被注销或删除。当帐号注销或被删除后，与该帐号相关的、该单项服务项下的全部服务资料和数据将依照单项服务的服务协议约定删除或处理，但法律法规另有规定的除外。
（四）改变您授权同意的范围
您总是可以选择是否披露信息。有些信息是使用同川服务所必需的，但大多数其他信息的提供是由您决定的。您可以通过删除信息、关闭设备功能等方式改变您授权我们继续收集信息的范围或撤回您的授权。
当您撤回授权后，我们无法继续为您提供撤回授权所对应的服务，也不再处理您相应的信息。但您撤回授权的决定，不会影响此前基于您的授权而开展的信息处理。
六、第三方服务
同川服务可能接入或链接至第三方提供的社交媒体或其他服务（包括网站或其他服务形式）。包括：
1、您可利用“分享”键将某些同川服务内容分享到第三方服务，或您将第三方服务内容分享到同川服务。这些功能可能会收集您的信息（包括您的日志信息），并可能在您的电脑装置COOKIES，从而正常运行上述功能；
2、我们通过广告或我们服务的其他方式向您提供链接，使您可以链接至第三方的服务或网站；及
3、其他接入第三方服务的情形。例如，为实现本政策中声明的目的，我们可能会接入第三方服务商提供的SDK或其他类似的应用程序，并将我们依照本政策收集的您的某些信息共享给该等第三方服务商，以便提供更好的客户服务和用户体验。目前，我们接入的第三方服务商主要包括以下几种类型：
（1）用于广告相关服务，包括广告展示、广告数据监测/统计等；
（2）用于消息推送功能，包括手机厂商Push推送、特定事件提醒、个性化内容推荐等；
（3）用于支付相关服务，包括订单支付、交易行为核验、收入结算、支付信息汇总统计等；
（4）用于在您同意的情况下获取设备位置权限、搜集设备信息和日志信息等；
（5）用于第三方授权服务，包括第三方帐号登陆、将有关内容分享至第三方产品等；
（6）用于支持产品功能模块，包括在线直播、视频播放、语音识别、AR、智能客服、弹幕播放、内容存储等；
（7）用于优化产品性能，包括提高硬件配网能力、降低服务器成本、功能热修复等；
（8）用于帐号安全、产品加固相关服务，包括网络监测、域名解析（HTTPDNS）、防劫持、反垃圾反作弊、加解密服务等。
我们仅会出于正当、必要、特定的目的共享您的信息。对我们与之共享信息的第三方服务商，我们会要求他们履行相关保密义务并采取相应的安全措施。
该等第三方社交媒体或其他服务由相关的第三方负责运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本政策）约束，您需要仔细阅读其条款。本政策仅适用于我们所收集的个人信息，并不适用于任何第三方提供的服务或第三方的信息使用规则。如您发现这些第三方社交媒体或其他服务存在风险时，建议您终止相关操作以保护您的合法权益。
七、通知和修订
为了给您提供更好的服务，我们可能会根据同川服务的更新情况及法律法规的相关要求适时修改本政策的条款，该等修改构成本政策的一部分。如该等更新造成您在本政策下权利的实质减少或重大变更，我们将在本政策生效前通过网站公告、推送通知、弹窗提示或其他方式来通知您，您如果不同意该等变更，可以选择停止使用同川服务；如您仍然继续使用同川服务的，即表示您已充分阅读、理解并同意受经修订的本政策的约束。
我们的任何修改都会将您的满意度置于首位。我们鼓励您在每次使用同川服务时都查阅我们的隐私政策。
我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。
八、如何联系我们
我们设立了个人信息保护专职部门，将按照本政策保护您的个人信息。如您有关于网络信息安全的投诉和举报，或您对本政策、同川的隐私措施、您的信息的相关事宜有任何问题、意见或建议，请通过APP意见反馈或客服来联系我们，一般情况下，我们将在收到您的问题、意见或建议，并验证您的用户身份后的十五天内予以回复。
联系方式：【tongchuan2022@163.com】
      </pre>
    </ui-container>
    <app-features class="mt" />
    <app-footer style="margin-top: 30px" />
  </div>
</template>

<script>
import AppNav from '../components/AppNav.vue';
import AppFooter from '../components/AppFooter.vue';

export default {
  name: 'Home',
  components: {
    AppFooter,
    AppNav,
  },
};
</script>
