<template>
  <div>
    <ui-page-header title="抽查检查清单" />
    <ui-stage>
      <div class="report-hint" v-html="hint"></div>
      <div>
        <ui-flex row j-start a-start>
          <span class="filter-span" style="line-height: 40px">项目：</span>
          <div>
            <div class="mb">
              <ui-admin-model-picker
                v-bind="projectsOptions"
                v-model="projectId"
              ></ui-admin-model-picker>
            </div>
            <el-checkbox-group v-model="sectionsSelected">
              <div v-for="(option, optionValue) in sections" :key="optionValue" class="mb">
                <el-checkbox :label="optionValue" border>{{ option }}</el-checkbox>
              </div>
            </el-checkbox-group>
            <ui-flex class="mt" row j-start a-center>
              <component
                :is="projectId ? 'a' : 'span'"
                :href="`/api/reports/checklists/pdf?projectId=${projectId}&sections=${sectionsSelected.join(
                  ',',
                )}`"
                target="_blank"
              >
                <el-button :disabled="!projectId" type="danger">下载PDF</el-button>
              </component>
            </ui-flex>
          </div>
        </ui-flex>
      </div>
    </ui-stage>
  </div>
</template>

<script>
import UiAdminModelPicker from '@yishitec/web/components/UiAdminModelPicker.vue';

export default {
  name: 'DailyReports3',
  components: {
    UiAdminModelPicker,
  },
  data() {
    let projectId = null;
    let shouldSubmit = false;

    if (this.$route.query.projectId) {
      projectId = Number(this.$route.query.projectId);
      shouldSubmit = true;
    }

    const hint = `请选择项目后下载PDF用于打印或批注。
        每一份检查清单包含当日（及近日）的人员、设备、施工部位、监理日志等现场关键信息。关注各项数据的真实性、完整性、时效性。
        检查人员可参考检查清单在现场巡检并记录问题。
    `;

    const sections = {
      a0: '评分表',
      a1: '表1 人员管理',
      a2: '表2 设备管理',
      a3: '表3 施工部位检查',
      a4: '表4 监理日志检查',
    };

    return {
      shouldSubmit,
      projectId,
      reportHtml: '',
      projectsOptions: {
        config: {
          title: '项目',
          name: 'project_id',
          model: {
            name: 'Project',
            alias: 'xProjects',
            titleFields: ['title'],
            type: 'string',
            appData: {
              apiName: 'projects',
            },
          },
          type: 'model',
        },
        disabled: false,
      },
      hint,
      sections,
      sectionsSelected: ['a0', 'a1', 'a2', 'a3', 'a4'],
    };
  },
};
</script>

<style scoped lang="scss">
.filter-span {
  display: inline-block;
  margin-left: 10px;
}
.report-stage {
  background: rgba(#000, 0.1);
  box-sizing: border-box;
  padding: 60px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 300px);
}
.report-paper {
  background: #fff;
  box-sizing: border-box;
  padding: 60px;
  width: 21cm;
  box-shadow: 0 4px 10px rgba(#000, 0.05);
  font-size: 11px;
  line-height: 1.6;
  font-family: serif;
  ::v-deep {
    .text-center {
      text-align: center;
    }
  }
}
.report-hint {
  padding: 45px;
  color: rgba(#000, 0.8);
  white-space: pre-line;
  line-height: 1.8;
}
</style>
