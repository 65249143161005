<template lang="pug">
div.progress-container
  router-view
</template>

<script>
export default {
  name: 'ProgressContainer',
};
</script>

<style lang="scss" scoped>
.progress-container {
  background: #dbe0ee;
  min-height: 100vh;
  ::v-deep .el-button--primary {
    background: #5e81f4;
  }
  ::v-deep .el-button--primary.is-plain {
    background: #fff;
    &:hover,
    &:active {
      color: #5e81f4;
    }
    &:focus,
    &:hover {
      color: #5e81f4;
    }
  }
  ::v-deep .cursor-pointer {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.popper-info-window {
  background: #fff8e1;
  border-color: #e5d291;
  &[x-placement^='top'] {
    .popper__arrow {
      &::after {
        border-top-color: #e5d291;
        border-width: 12px 12px 0 0;
        bottom: -6px;
      }
    }
  }
}
</style>
