<template>
  <div>
    <ui-stage>
      <ui-flex row>
        <ui-flex style="flex: 0 0 110px" class="mr br pr">
          <app-links-list v-model="filter" :data="filters" with-search />
        </ui-flex>
        <ui-flex style="flex: 1 0 1px; overflow-x: auto">
          <ui-admin-table
            v-if="data"
            :attributes="attributes"
            :data="data"
            :module-url="moduleUrl"
            pagination-method="front-end"
            :with-create="canCreate"
          >
            <template slot="toolbar">
              <div class="mb mt" v-if="canTriggerSync">
                <ui-button type="primary" @click="handleSyncGpsoo" size="small"
                  >从GPSOO、18GPS平台同步</ui-button
                >
              </div>
            </template>
          </ui-admin-table>
        </ui-flex>
      </ui-flex>
    </ui-stage>
  </div>
</template>

<script>
import { adminList } from '@yishitec/web/mixins';
import permissions from '../../../mixins/permissions';
import AppLinksList from '../../../components/AppLinksList.vue';

export default {
  name: 'List',
  mixins: [adminList, permissions],
  components: {
    AppLinksList,
  },
  data() {
    const filters = [
      { id: 'ALL', title: '全部' },
      { id: 'DANGER_ZONES', title: '绑定到危险源' },
      { id: 'MEMBERS', title: '绑定到人员' },
      { id: 'NONE', title: '未绑定的' },
    ];
    return {
      filter: '全部',
      filters,
      apiBodyData: {
        filter: '全部',
      },
    };
  },
  computed: {
    canTriggerSync() {
      return this.amI(null, null, '平台管理员');
    },
    canCreate() {
      return (
        this.amI(null, null, '平台管理员', true) ||
        this.amI(null, '建设单位', '管理人员', true) ||
        this.amI(null, '施工单位', '管理人员', true) ||
        this.amI(null, '施工单位', '安全员', true)
      );
    },
  },
  watch: {
    filter() {
      this.apiBodyData.filter = this.filter;
      this.fetchData();
    },
  },
  methods: {
    async handleSyncGpsoo() {
      await this.withLoading(this.api('/api/iot-devices/sync'));
      await this.fetchData();
    },
  },
};
</script>

<style lang="less" scoped></style>
