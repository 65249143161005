<script setup>
import { computed } from 'vue';

// eslint-disable-next-line no-undef
const props = defineProps({
  text: { type: String, default: '' },
});

const isAdvanced = computed(() => {
  return props.text.includes('提前');
});
</script>

<template lang="pug">
  .alert(
    v-if="text"
    :class="{ isAdvanced: isAdvanced }"
  ) {{ text }}
</template>

<style lang="scss" scoped>
.alert {
  background: #ffeaea;
  border-radius: 20px;
  text-align: center;

  &.isAdvanced {
    background: #ebf7ff;
  }
}
</style>
