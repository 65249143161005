module.exports = {
  icon: 'images/data-center-screen/boards/icon-ops-zones.svg',
  title: '施工部位管理',
  apiUrl: '/api/reports/data-center-screen/sub-windows/ops-zones',
  indicators: [
    {
      title: '今日作业施工部位',
      n: 'opsZonesToday',
      total: 'opsZonesTotal',
    },
    {
      title: '今日作业危险源区域',
      n: 'areaDangerZonesToday',
      total: 'areaDangerZonesTotal',
    },
    {
      title: '进场待审核',
      n: '进场待审核',
      total: 'opsZonesToday',
    },
    {
      title: '退场待审核',
      n: '退场待审核',
      total: 'opsZonesToday',
    },
  ],
  table: [
    {
      title: '项目名称',
      name: 'title',
      width: '220px',
    },
    {
      name: '今日作业施工部位',
      width: '180px',
      type: 'stats',
      cols: ['opsZonesToday', 'opsZonesTotal'],
    },
    {
      name: '今日作业危险源区域',
      width: '180px',
      type: 'stats',
      cols: ['areaDangerZonesToday', 'areaDangerZonesTotal'],
    },
    {
      name: '已完成入场登记',
      width: '120px',
      type: 'stats',
      cols: ['已完成入场登记', 'opsZonesToday'],
    },
    {
      name: '已完成退场登记',
      width: '120px',
      type: 'stats',
      cols: ['已完成退场登记', 'opsZonesToday'],
    },
    // {
    //   name: '查看详情',
    //   type: 'adminOps',
    //   subWindow: 'projectDangerZones',
    // },
    {},
  ],
};
