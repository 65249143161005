<template lang="pug">
div
</template>

<script>
import permissionsMixin from '../../mixins/permissions';

export default {
  name: 'AdminDefaultScreen',
  props: {},
  mixins: [permissionsMixin],
  data() {
    return {};
  },
  computed: {},
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      if (!this.amI(null, '施工单位', '作业人员', true)) {
        this.$router.push('/screen');
        return;
      }
      this.$router.push('/admin/select-projects');
    },
  },
};
</script>

<style lang="scss" scoped></style>
