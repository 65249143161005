<template lang="pug">
div
  ui-stage
    el-date-picker(
      v-model="date"
      type="date"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
    )
    el-checkbox(
      border
      v-model="validOnly"
    ).ml 仅查看有数据的记录
    SspTable(
      :value="vList"
      :columns="getColumns()"
    )
      template(v-slot:startAt="{ row }") {{ formatDateTime(row.startAt) }}
      template(v-slot:endAt="{ row }") {{ formatDateTime(row.endAt || '-') }}
</template>

<script>
import { _, moment, formatDateTime as formatDateTimeUtils } from '@yishitec/web';

export default {
  name: 'List',
  data() {
    return {
      validOnly: true,
      list: [],
      date: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    vList() {
      if (this.validOnly) {
        return _.filter(this.list, (item) => item.countZones);
      }
      return this.list;
    },
  },
  watch: {
    date() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatDateTime(value) {
      const mValue = moment(value);
      if (!mValue.isValid()) {
        return '-';
      }
      return formatDateTimeUtils(value);
    },
    async loadData() {
      const res = await this.withLoading(
        this.api('/api/ops-zones-logs/report', {
          projectId: this.$route.query.projectId,
          date: this.date,
        }),
      );
      this.list = res;
    },
    getColumns() {
      return [
        {
          name: 'id',
          title: 'ID',
        },
        {
          name: 'level1',
          title: '危大工程类别',
        },
        {
          name: 'level2',
          title: '危大工程特征',
        },
        {
          name: 'level3',
          title: '危大工程相关规范规定数值',
        },
        {
          name: 'level4',
          title: '危大工程特征细化量化数值',
        },
        {
          name: 'degree',
          title: '危大工程等级',
        },
        {
          name: 'countZones',
          title: '涉及重大危险源数量',
        },
        {
          name: 'startAt',
          title: '实施开始时间',
        },
        {
          name: 'endAt',
          title: '实施终止时间',
        },
        {
          name: 'todo',
          title: '未实施数',
        },
        {
          name: 'wip',
          title: '正在实施数',
        },
        {
          name: 'done',
          title: '已完成数',
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped></style>
