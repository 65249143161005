module.exports = {
  icon: 'images/data-center-screen/boards/icon-materials.svg',
  title: '材料管理',
  // apiUrl: '/api/reports/data-center-screen/sub-windows/materials',
  indicators: [
    {
      title: '待审核的材料记录',
      n: 'materialsPending',
      total: 'materials',
      after: '份',
    },
  ],
  table: [
    {
      title: '项目名称',
      name: 'title',
      width: '220px',
    },
    {
      title: '材料记录数',
      name: 'materials',
      width: '120px',
      type: 'stats',
      cols: ['materialsPending', 'materials'],
    },
    {
      name: '查看详情',
      type: 'adminOps',
      to: '/admin/materials/list/:projectId',
    },
    {},
  ],
};
