<template>
  <div>
    <ui-stage v-if="data">
      <ui-tabs v-model="tab" type="border-card">
        <ui-tab-pane name="main" label="编辑">
          <ui-alert
            type="warning"
            :closable="false"
            class="mb"
            v-if="data.approve_state === '待审核'"
            >待审核</ui-alert
          >
          <ui-alert
            type="warning"
            :closable="false"
            class="mb"
            v-if="data.approve_state === '待审核' && data.permissions.canApprove"
          >
            <ui-button type="warning" size="large" @click="handleApprove">审核</ui-button>
          </ui-alert>
          <ui-admin-form
            :compact="compact"
            :readonly="vReadonly"
            :fields="attributes"
            :model="data"
            :module-url="moduleUrl"
            @submit="handleSubmit"
            :with-delete="false"
            :with-save="false"
          />
          <div class="mt text-center" v-if="!vReadonly">
            <ui-button
              type="warning"
              @click="handleUpdateState"
              icon="el-icon-edit"
              v-if="data.id && data.state === '已入场'"
            >
              设备出场
            </ui-button>
            <ui-button type="primary" icon="el-icon-success" @click="handleSubmit">
              提交
            </ui-button>
            <router-link
              v-if="!vReadonly && data.id"
              :to="`${moduleUrl}/delete/${data.id}`"
              class="ml"
            >
              <ui-button type="info" icon="el-icon-delete"> 删除 </ui-button>
            </router-link>
          </div>
        </ui-tab-pane>
      </ui-tabs>
    </ui-stage>
  </div>
</template>

<script>
import { _ } from '@yishitec/web';
import { adminEdit } from '@yishitec/web/mixins';

export default {
  name: 'Edit',
  mixins: [adminEdit],
  data() {
    return {
      withCustomFields: false,
      entriesAttributes: [],
      entries: [],
      newEntry: {},
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'data.device_id': function () {
      this.updateDeviceUsers();
    },
  },
  methods: {
    async handleFetchDataRes(res) {
      const { canEdit, item } = res;
      this.dataReadonly = !canEdit;
      this.upperLevelUrl = `${this.moduleUrl}/list/${item.project_id}`;
    },
    async handleApprove() {
      const res = await this.withLoading(
        this.api(`/api/${this.model}/approve`, {
          id: this.data.id,
        }),
      );
      if (res) {
        this.fetchData();
        this.$message({
          type: 'success',
          message: '审核完成',
        });
      }
    },
    async updateDeviceUsers() {
      const deviceId = this.data.device_id;
      if (!deviceId) return;
      const deviceRes = await this.withLoading(
        this.api(`/api/danger-zone-event-devices/edit/${deviceId}`),
      );
      if (!this.data.operator_id && !this.data.security_id) {
        this.data.operator_id = _.get(deviceRes, ['item', 'default_operator'], null);
        this.data.security_id = _.get(deviceRes, ['item', 'default_security'], null);
      }
    },
    async handleUpdateState() {
      await this.withLoading(
        this.api(`/api/danger-zone-events/save/${this.data.id}`, {
          id: this.data.id,
          project_id: this.data.project_id,
          state: '已出场',
        }),
      );
      this.$message({
        type: 'success',
        message: '操作成功，设备已经出场',
      });
      this.fetchData(this.data.id);
    },
  },
};
</script>
