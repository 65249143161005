<template lang="pug">
div
  ui-page-header(title="数据中心")
  ui-stage.rel-stage(v-loading="loading")
    .select-project
      ui-data-select(
        multiple,
        :options="projectsOptions",
        v-model="projectIds",
        placeholder="全部项目"
      )
      ui-button.ml(icon="el-icon-refresh", @click="loadData", type="primary")
    AppReports(:data="data", :layout="layout")
</template>

<script>
import { _ } from '@yishitec/web';

export default {
  name: 'ReportsDashboard',
  data() {
    return {
      loading: false,
      data: {},
      layout: [],
      projectsOptions: [],
      projectIds: [],
      loadDataDebounced: _.debounce(this.loadData, 800),
    };
  },
  watch: {
    projectIds() {
      this.loadDataDebounced();
    },
  },
  async mounted() {
    await this.loadMeta();
    await this.loadData();
  },
  methods: {
    async loadMeta() {
      this.loading = true;
      const res = await this.withLoading(this.api('/api/projects/list'));
      this.projectsOptions = _.map(_.get(res, ['list']), (item) => ({
        value: item.id,
        title: item.title,
      }));
      this.loading = false;
    },
    async loadData() {
      this.loading = true;
      const res = await this.withLoading(
        this.api('/api/reports/dashboard', {
          projectIds: this.projectIds,
        }),
      );
      const { data, layout } = res || {};
      this.data = data;
      this.layout = layout;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.rel-stage {
  position: relative;
}
.select-project {
  position: absolute;
  top: -100px;
  right: 20px;
}
</style>
