<template lang="pug">
.info-window-title
  slot
</template>

<script>
export default {
  name: 'InfoWindowTitle',
};
</script>

<style lang="scss" scoped>
.info-window-title {
  font-size: 1.8em;
  font-weight: bolder;
  color: #fff;
  margin: 20px 0 10px 0;
}
</style>
