<template>
  <div ref="videoContainer" class="hello-ezuikit-js" style="width: 100%; aspect-ratio: 600/400">
    <div key="camPlayerErrorMessage" class="cam-player-error-message" v-if="error">{{ error }}</div>
    <div v-else id="video-container" style="width: 100%; aspect-ratio: 600/400"></div>
    <div class="label" v-if="!isLive">
      <ui-tag effect="dark" type="info">回放</ui-tag>
    </div>
    <div v-if="false">
      <button v-on:click="init">init</button>
      <button v-on:click="stop">stop</button>
      <button v-on:click="play">play</button>
      <button v-on:click="openSound">openSound</button>
      <button v-on:click="closeSound">closeSound</button>
      <button v-on:click="startSave">startSave</button>
      <button v-on:click="stopSave">stopSave</button>
      <button v-on:click="capturePicture">capturePicture</button>
      <button v-on:click="fullScreen">fullScreen</button>
      <button v-on:click="getOSDTime">getOSDTime</button>
      <button v-on:click="ezopenStartTalk">开始对讲</button>
      <button v-on:click="ezopenStopTalk">结束对讲</button>
      <button v-on:click="destroy">销毁</button>
    </div>
    <div class="corner-button">
      <ui-button plain size="mini" @click="refreshPage">刷新</ui-button>
    </div>
  </div>
</template>

<script>
import { _, moment } from '@yishitec/web';

export default {
  name: 'CamPlayerEz',
  props: {
    cam: { type: Object, default: null },
    isHd: { type: Boolean, default: false },
    isLive: { type: Boolean, default: true },
    start: { type: [Date, Number, Date], default: null },
    end: { type: [Date, Number, Date], default: null },
  },
  data() {
    return {
      accessToken: null,
      error: null,
      player: null,
    };
  },
  computed: {
    videoUrl() {
      // 直播
      const hd = this.isHd ? '.hd' : '';
      if (this.isLive) {
        return `ezopen://open.ys7.com/${this.cam.device_serial}/${this.cam.camera_id}${hd}.live`;
      }
      return `ezopen://open.ys7.com/${this.cam.device_serial}/${
        this.cam.camera_id
      }${hd}.rec?begin=${moment(this.start).format('YYYYMMDDHHmmss')}&end=${moment(this.end).format(
        'YYYYMMDDHHmmss',
      )}`;
    },
  },
  watch: {
    accessToken() {
      this.init();
    },
    isLive() {
      this.updateUrl();
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.destroy();
  },
  metaInfo: {
    script: [
      {
        src: '/EZUI8/ezuikit.js',
      },
    ],
  },
  methods: {
    async init() {
      const ctx = this;
      if (!window.EZUIKit) {
        setTimeout(() => {
          ctx.init();
        }, 500);
        return;
      }

      if (this.player) {
        await this.destroy();
      }

      if (!this.accessToken) {
        this.fetchAccessToken();
        return;
      }
      this.withLoading(
        this.api('/api/user-logs/save', {
          behavior: 'CAM__START',
          model_name: 'Cam',
          model_id: this.cam.id,
        }),
      );
      setTimeout(() => {
        this.error = '查看监控已超1小时，如需继续查看请刷新';
        this.stop();
      }, 3600 * 1000);

      this.player = new window.EZUIKit.EZUIKitPlayer({
        id: 'video-container', // 视频容器ID
        accessToken: this.accessToken,
        url: this.videoUrl,
        // simple: 极简版; pcLive: pc直播; pcRec: pc回放; mobileLive: 移动端直播; mobileRec: 移动端回放;security: 安防版; voice: 语音版;
        template: 'simple',
        width: this.$refs.videoContainer.offsetWidth,
        height: this.$refs.videoContainer.offsetHeight,
        handleSuccess: (data) => {
          console.log('播放器成功handler', data);
          this.api('/api/user-logs/save?flag=ok', {
            behavior: 'CAM__SUCCESS',
            model_name: 'Cam',
            model_id: this.cam.id,
          })();
        },
        handleError: (err) => {
          console.error('播放器错误handler', err);
          if (String(err.msg || '').includes('cas响应超时')) {
            this.init();
          } else {
            this.api('/api/user-logs/save?flag=error', {
              behavior: `CAM__ERROR:${err.msg}`,
              model_name: 'Cam',
              model_id: this.cam.id,
            })();
          }
        },
      });
    },
    async fetchAccessToken() {
      const res = await this.withLoading(this.api('/api/cams/cams-access-token'));
      if (res) {
        this.accessToken = res;
      }
    },
    updateUrl() {
      if (!this.player) {
        return;
      }
      this.stop();
      setTimeout(() => {
        this.init();
      }, 500);
    },
    play() {
      const playPromise = this.player.play();
      playPromise.then((data) => {
        console.log('promise play 获取 数据', data);
      });
    },
    stop() {
      const stopPromise = this.player.stop();
      stopPromise.then((data) => {
        console.log('promise stop 获取 数据', data);
      });
    },
    getOSDTime() {
      const getOSDTimePromise = this.player.getOSDTime();
      getOSDTimePromise.then((data) => {
        console.log('promise getOSDTime 获取 数据', data);
      });
    },
    capturePicture() {
      const capturePicturePromise = this.player.capturePicture(`${new Date().getTime()}`);
      capturePicturePromise.then((data) => {
        console.log('promise capturePicture 获取 数据', data);
      });
    },
    openSound() {
      const openSoundPromise = this.player.openSound();
      openSoundPromise.then((data) => {
        console.log('promise openSound 获取 数据', data);
      });
    },
    closeSound() {
      const openSoundPromise = this.player.closeSound();
      openSoundPromise.then((data) => {
        console.log('promise closeSound 获取 数据', data);
      });
    },
    startSave() {
      const startSavePromise = this.player.startSave(`${new Date().getTime()}`);
      startSavePromise.then((data) => {
        console.log('promise 获取 数据', data);
      });
    },
    stopSave() {
      const stopSavePromise = this.player.stopSave();
      stopSavePromise.then((data) => {
        console.log('promise 获取 数据', data);
      });
    },
    ezopenStartTalk() {
      this.player.startTalk();
    },
    ezopenStopTalk() {
      this.player.stopTalk();
    },
    fullScreen() {
      this.player.fullScreen();
    },
    async destroy() {
      const destroyPromise = this.player.destroy();
      await destroyPromise.then((data) => {
        console.log('promise 获取 数据', data);
      });
      this.api('/api/user-logs/save', {
        behavior: 'CAM__STOP',
        model_name: 'Cam',
        model_id: this.cam.id,
      })();
      this.player = null;
    },
    refreshPage() {
      window.location.reload();
    },
  },
};
</script>

<style lang="less" scoped>
.label {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 999;
}

.cam-player-error-message {
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 10em;
}

.hello-ezuikit-js {
  :deep(#video-container-headControl) {
    display: none !important;
  }
}

.corner-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
}
</style>
