<template>
  <div>
    <ui-stage v-if="data">
      <ui-tabs v-model="tab" type="border-card">
        <ui-tab-pane name="main" label="编辑">
          <ui-admin-form
            :compact="compact"
            :readonly="readonly"
            :fields="attributes"
            :model="data"
            :module-url="moduleUrl"
            with-delete
            @submit="handleSubmit"
          >
            <template slot="field__member_fill">
              <ui-link type="primary">test 管理用户</ui-link>
            </template>
            <template slot="field__member_audit">
              <ui-button type="primary" disabled>确认审核</ui-button>
            </template>
            <template slot="field__member_health_agent">
              <ui-button type="primary" disabled>确认审核</ui-button>
            </template>
          </ui-admin-form>
        </ui-tab-pane>
      </ui-tabs>
    </ui-stage>
  </div>
</template>

<script>
import { adminEdit } from '@yishitec/web/mixins';

export default {
  name: 'Edit',
  mixins: [adminEdit],
  data() {
    return {
      withCustomFields: false,
    };
  },
};
</script>
