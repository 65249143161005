<template lang="pug">
div.p-page
  div.p-container
    div.greetings Hi！{{ userInfo.display_name }}，欢迎使用。
    div.cards
      SspHomeCard(
        v-for="item in homeCards"
        :key="item.key"
        v-bind="item"
      ).cards-item
</template>

<script>
import SspHomeCard from '../../components/SspHomeCard.vue';

export default {
  name: 'ProgressHome',
  props: {},
  components: { SspHomeCard },
  data() {
    return {
      homeCards: [],
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const res = await this.withLoading(this.api('/api/progress/home'));
      if (!res) {
        return;
      }
      const { projectsCount, proceduresCount, progressTotal } = res;
      this.homeCards = [
        {
          key: 'projects',
          icon: 'projects',
          title: '项目管理',
          to: '/progress/projects',
          color: '#6D69FF',
          stats: [
            {
              key: 'count',
              title: '项目总数',
              number: projectsCount || 0,
              color: '#2F3CED',
            },
          ],
          statusBar: '通过项目列表进行查看与管理',
        },
        // {
        //   key: 'members',
        //   icon: 'members',
        //   title: '人员管理',
        //   to: '/progress/members',
        //   color: '#FF5454',
        //   stats: [
        //     {
        //       key: 'companiesCount',
        //       title: '当前单位数量',
        //       number: 212,
        //     },
        //     {
        //       key: 'membersCount',
        //       title: '当前人员数量',
        //       number: 12000,
        //     },
        //   ],
        //   statusBar: '单位人员管理 与 以人员角度查看进度',
        // },
        {
          key: 'progress',
          icon: 'progress',
          title: '作业计划',
          to: '/progress/progress',
          color: '#57FFDD',
          stats: [
            {
              key: 'count',
              title: '工序总数',
              number: proceduresCount || 0,
            },
            {
              key: 'progress',
              title: '总项目完成度',
              number: progressTotal || 0,
              type: 'ProgressBar',
            },
          ],
          statusBar: '单位人员管理 与 以人员角度查看进度',
        },
        // {
        //   key: 'tasks',
        //   icon: 'tasks',
        //   title: '任务中心',
        //   to: '/progress/tasks',
        //   color: '#D3BA02',
        //   stats: [
        //     {
        //       key: 'today',
        //       title: '今日计划工序',
        //       number: 12,
        //       color: '#D3BA02',
        //     },
        //     {
        //       key: 'toApprove',
        //       title: '待审核数量',
        //       number: 39,
        //       color: '#D3BA02',
        //     },
        //   ],
        //   statusBar: '单位人员管理 与 以人员角度查看进度',
        // },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.greetings {
  color: #0f1b41;
  font-size: 20px;
  line-height: 24px;
  font-weight: bolder;
  margin-bottom: 28px;
}
.cards {
  @include ui-flex-row(flex-start, stretch);
  flex-wrap: wrap;
  margin: 0 -10px;
}
.cards-item {
  margin: 10px;
  flex: 0 0 auto;
  width: 400px;
  max-width: 90vw;
}
</style>
