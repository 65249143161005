<template lang="pug">
.header-date-v3(:class="[theme]")
  span(v-if='weather').item {{ weather.city }}
    img(:src='`/images/seniverse-weather/${weather.weatherCode}@2x.png`').weather-icon
    | {{ weather.temperature }}℃
  span.item {{ formatDateTime(now) }}
</template>

<script>
export default {
  name: 'HeaderDate',
  props: {
    theme: String,
    weather: Object,
  },
  data() {
    return {
      now: Date.now(),
      tiNow: null,
    };
  },
  mounted() {
    this.tiNow = setInterval(this.tick, 1000);
  },
  beforeDestroy() {
    if (this.tiNow) {
      clearInterval(this.tiNow);
    }
  },
  methods: {
    tick() {
      this.now = Date.now();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-date-v3 {
  position: absolute;
  top: 31px;
  right: 270px;
  width: 258px;
  height: 60px;
  box-sizing: border-box;
  color: #fff;
  font-size: 13px;
  padding: 6px 0 0 32px;
  background: url('/images/screen-v3/chip-datetime.png') no-repeat top left;
  background-size: 178px 30px;

  &.darker {
    background-image: url('/images/screen-v3/chip-datetime-darker.png');
  }
}

.item {
  display: inline-block;
  margin-right: 1em;
}

.weather-icon {
  height: 16px;
  width: auto;
  vertical-align: baseline;
  display: inline-block;
  margin: 0 2px;
}
</style>
