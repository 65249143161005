<template>
  <div class="report-grids-row">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ReportGridsRow',
};
</script>

<style lang="scss">
.report-grids-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  & > div {
    height: auto !important;
    box-sizing: border-box;
    padding: 20px 0;
    background: #fff;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: solid 1px #b5c2d1;
    border-radius: 5px;
    font-size: 1em;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
