<script setup>
import { defineProps, ref, watch } from 'vue';
import { useFetch2 } from '../composables';
import ProgressBlueprintImages from './ProgressBlueprintImages';

const props = defineProps({
  blueprintIds: {
    type: Array,
    required: true,
  },
});

const currentBlueprintId = ref(String(props.blueprintIds[0]));

const blueprints = ref([]);

const { refresh } = useFetch2('/api/v3/progress-blueprint/titles', {
  method: 'post',
  getBody: () => {
    return {
      ids: props.blueprintIds,
    };
  },
  success: (data) => {
    const { list: iList = [] } = data.value;
    blueprints.value = iList;
  },
});

refresh();

watch(props, () => {
  refresh();
});
</script>

<template lang="pug">
div(v-if='blueprintIds && blueprintIds.length')
  el-tabs(
    v-model='currentBlueprintId'
    type='card'
  )
    el-tab-pane(
      v-for='tab in blueprints'
      :key='tab.id'
      :label='tab.title'
      :name='String(tab.id)'
      lazy
    )
  ProgressBlueprintImages(
    :key="currentBlueprintId"
    :blueprintId='Number(currentBlueprintId)'
    readonly
  )
</template>

<style scoped lang="scss"></style>
