<template>
  <ui-flex row class="admin-login">
    <ui-flex style="flex: 1 0 1px">
      <ui-flex col center class="admin-login__left" style="width: 100%; height: 100%">
        <ui-logo style="height: 50px"></ui-logo>
      </ui-flex>
    </ui-flex>
    <ui-flex style="flex: 1 0 1px">
      <ui-flex col center class="admin-login__right" style="width: 100%; height: 100%">
        <ui-container style="width: 380px; max-width: 100%">
          <ui-card v-if="tab === 'companyName'" class="text-center">
            <h1>管理员登录</h1>
            <div class="mt" />
            <ui-form @submit.native.prevent="handleSubmitCompanyName">
              <ui-input
                v-model="login.companyName"
                placeholder="请输入公司名称"
                name="companyName"
              />
            </ui-form>
            <div class="mt">
              <ui-button
                type="primary"
                @click="handleSubmitCompanyName"
                :disabled="!login.companyName"
              >
                提交
              </ui-button>
            </div>
          </ui-card>
          <ui-card v-if="tab === 'login'" class="text-center">
            <h1>管理员登录</h1>
            <div class="mt"></div>
            <ui-form class="mt" @keyup.enter.native.prevent="handleSubmit">
              <ui-alert type="success" class="mb w-full" close-text="返回编辑" @close="handleBack">
                {{ login.companyName }}
              </ui-alert>
              <div>
                <ui-input
                  v-model="login.username"
                  placeholder="请输入用户名"
                  name="username"
                ></ui-input>
              </div>
              <div class="mt">
                <ui-input
                  v-model="login.password"
                  type="password"
                  show-password
                  placeholder="请输入密码"
                  name="password"
                ></ui-input>
              </div>
              <div class="mt">
                <ui-button @click="handleSubmit" type="primary">登录</ui-button>
              </div>
            </ui-form>
          </ui-card>
        </ui-container>
      </ui-flex>
    </ui-flex>
  </ui-flex>
</template>

<script>
import login from '@yishitec/web/core/login';

export default {
  name: 'AdminLogin',
  mixins: [login],
  data() {
    return {
      tab: 'companyName',
      login: {
        username: '',
        password: '',
        companyName: '',
      },
    };
  },
  methods: {
    handleSubmitCompanyName() {
      this.tab = 'login';
    },
    handleBack() {
      this.tab = 'companyName';
    },
  },
};
</script>

<style scoped lang="less">
@import '../../assets/styles/app-mixins';

.admin-login {
  width: 100%;
  height: 100vh;
  &__left {
    background: darken(@theme, 20);
  }
}
</style>
