<template lang="pug">
  ui-stage
    ui-flex.gap-2(col j-start stretch)
      ui-flex.gap-2(row j-start a-end)
        ui-flex.gap-2(col j-start stretch)
          div 选择日期
          el-date-picker(
            v-model='date'
          )
          ui-flex(row j-start a-center)
            el-button(
              type='primary'
              icon='el-icon-arrow-left'
              circle
              plain
              @click='nextDate(-1)'
            )
            el-button(
              type='primary'
              icon='el-icon-arrow-right'
              circle
              plain
              @click='nextDate(1)'
            )
        ui-flex.gap-2(col j-start stretch)
          div 选择项目
          el-select(
            v-model='project'
            clearable
            filterable
            placeholder='请选择'
          )
            el-option(
              v-for='item in projects'
              :key='item.id'
              :label='item.title'
              :value='item.id'
            )
          ui-flex(row j-start a-center)
            el-button(
              type='primary'
              icon='el-icon-arrow-left'
              circle
              plain
              @click='nextProject(-1)'
            )
            el-button(
              type='primary'
              icon='el-icon-arrow-right'
              circle
              plain
              @click='nextProject(1)'
            )
        ui-flex.gap-2.data-cams(col j-start stretch)
          div 选择监控
          el-select(
            v-model='cam'
            clearable
            filterable
            placeholder='请选择'
          )
            el-option(
              v-for='item in cams'
              :key='item.id'
              :label='item.title'
              :value='item.id'
            )
          ui-flex(row j-start a-center)
            el-button(
              type='primary'
              icon='el-icon-arrow-left'
              circle
              plain
              @click='nextCam(-1)'
            )
            el-button(
              type='primary'
              icon='el-icon-arrow-right'
              circle
              plain
              @click='nextCam(1)'
            )
            el-button(
              type='primary'
              icon='el-icon-refresh'
              circle
              plain
              @click='loadList'
              :disabled='!date || !project || !cam'
            )
      el-divider
      ui-flex.gap-2.list(row j-start stretch wrap)
        template(v-if='!date || !project || !cam')
          el-alert(v-if='!date') 请选择日期
          el-alert(v-if='!project') 请选择项目
          el-alert(v-if='!cam') 请选择监控
        template(v-else)
          el-alert(v-if='!list || !list.length') 暂无快照
          CamSnapshot.list-item(
            v-for='item in list'
            :key='item.id'
            :item='item'
            :list='list'
          )
        el-alert 系统每日 6:00 - 21:00 期间每小时对所有监控快照截图
</template>

<script>
import { _, moment } from '@yishitec/web';
import CamSnapshot from '@/components/CamSnapshot.vue';

export default {
  name: 'CamSnapshotsProjects',
  components: {
    CamSnapshot,
  },
  data() {
    const today = moment().format('YYYY-MM-DD');
    return {
      date: today,
      projects: [],
      project: null,
      cams: [],
      cam: null,
      list: [],
    };
  },
  watch: {
    project() {
      this.loadCams();
    },
    cam() {
      this.loadList();
    },
    date() {
      this.loadList();
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.projects = await this.getProjects();
      await this.loadCams();
    },
    async getProjects() {
      const res = await this.api('/api/projects/list', {
        full: true,
      })();
      return _.map(_.get(res, ['list'], []), (item) => {
        return {
          id: item.id,
          title: item.title,
        };
      });
    },
    resetCams() {
      this.cam = null;
      this.cams = [];
    },
    async loadCams() {
      if (!this.project) {
        this.resetCams();
        return;
      }
      this.cams = await this.getCams();
      const camInCams = () => {
        return Boolean(
          _.find(this.cams, (item) => {
            return item.id === this.cam;
          }),
        );
      };
      if (!camInCams()) {
        this.cam = null;
      }
    },
    async getCams() {
      const res = await this.api('/api/cams/list-v2', {
        project_id: this.project,
      })();
      return _.map(_.get(res, ['list'], []), (item) => {
        return {
          id: item.id,
          title: item.title,
        };
      });
    },
    async loadList() {
      this.list = await this.getList();
    },
    async getList() {
      const { date, project, cam } = this;
      if (!date || !project || !cam) {
        return [];
      }
      const res = await this.api('/api/cam-snapshots/snapshots-list', {
        date,
        projectId: project,
        camId: cam,
      })();
      return _.get(res, ['list'], []);
    },
    nextCam(delta = 1) {
      if (!this.cams) return;
      const getNextIndex = () => {
        if (!this.cam) {
          return 0;
        }
        if (!this.cams.length) {
          return 0;
        }
        const currentIndex = _.findIndex(this.cams, (item) => {
          return item.id === this.cam;
        });
        let nextIndex = currentIndex + delta;
        while (nextIndex < 0) {
          nextIndex += this.cams.length;
        }
        while (nextIndex >= this.cams.length) {
          nextIndex -= this.cams.length;
        }
        return nextIndex;
      };
      const nextIndex = getNextIndex();
      if (this.cams[nextIndex]) {
        this.cam = this.cams[nextIndex].id;
      }
    },
    nextProject(delta = 1) {
      if (!this.projects) return;
      const getNextIndex = () => {
        if (!this.project) {
          return 0;
        }
        if (!this.projects.length) {
          return 0;
        }
        const currentIndex = _.findIndex(this.projects, (item) => {
          return item.id === this.project;
        });
        let nextIndex = currentIndex + delta;
        while (nextIndex < 0) {
          nextIndex += this.projects.length;
        }
        while (nextIndex >= this.projects.length) {
          nextIndex -= this.projects.length;
        }
        return nextIndex;
      };
      const nextIndex = getNextIndex();
      if (this.projects[nextIndex]) {
        this.project = this.projects[nextIndex].id;
      }
    },
    nextDate(delta = 1) {
      const dateFormat = 'YYYY-MM-DD';
      if (!this.date) {
        this.date = moment().format(dateFormat);
        return;
      }
      this.date = moment(this.date).add(delta, 'days').format(dateFormat);
    },
  },
};
</script>

<style lang="less" scoped>
.gap-2 {
  gap: 0.5em;
}

.data-cams {
  width: 400px;
}

.list-item {
  flex: 0 0 auto;
  width: calc((100% - 3 * 0.5em) / 4);
}
</style>
