<template lang="pug">
  Board(
    title="监控管理"
    :hint="`待处理 ${pending}`"
    icon="/images/data-center-screen/boards/icon-cams.svg"
    subWindow="cams"
    @sub-window="$emit('sub-window', $event)"
  )
    BoardStats(
      title="监控数量"
      before="在线"
      :n="n"
      :after="`/ ${total || 0}台`"
    )
    template(v-if='projects.length === 1')
      div(v-if='!cam' v-loading='camLoading').no-cam.cam-container {{ camLoading ? '加载中...' : '暂无可用监控' }}
      div(v-else-if='modalCams')
        SubWindow(@close='() => { modalCams = false; camLocked = true }')
          table.cams-table
            tbody
              tr
                td(valign='top')
                  CamPicker(:cams='cams')
                td(valign='top')
                  CamPicker(:cams='cams')
              tr
                td(valign='top')
                  CamPicker(:cams='cams')
                td(valign='top')
                  CamPicker(:cams='cams')
      div(v-else-if='camLocked' @click='camLocked = false')
        div.no-cam.cam-container.cursor-pointer
          i.el-icon-video-play(style='font-size: 40px; margin-top: 20px;')
          div 点击播放监控画面
        a.view-button(@click='modalCams = true') 查看更多监控
      div(v-else style='flex: 1').cam-container
        CamView(:cam='cam' hideControls)
        a.view-button(@click='modalCams = true') 查看更多监控
</template>

<script>
import Board from '../Board.vue';
import BoardStats from '../BoardStats.vue';
import CamView from '../../../../components/admin/CamView/CamView.vue';
import SubWindow from '../SubWindow.vue';
import CamPicker from '../CamPicker.vue';
import { getSum } from '../genCards';

export default {
  name: 'BoardCams',
  props: {
    projects: Array,
  },
  emits: ['sub-window'],
  components: {
    Board,
    BoardStats,
    CamView,
    SubWindow,
    CamPicker,
  },
  data() {
    return {
      camLoading: false,
      cam: null,
      cams: [],
      modalCams: false,
      camLocked: true,
    };
  },
  computed: {
    pending() {
      return getSum(this.projects, 'camsAlerts');
    },
    n() {
      return getSum(this.projects, 'camsOnline');
    },
    total() {
      return getSum(this.projects, 'cams');
    },
    projectId() {
      return this.projects.length === 1 ? this.projects[0].projectId : null;
    },
  },
  mounted() {
    this.loadCam();
  },
  watch: {
    projectId() {
      this.loadCam();
    },
  },
  methods: {
    async loadCam() {
      this.camLocked = true;
      const res = await this.api('/api/reports/data-center-screen/online-cams', {
        projectId: this.projects[0].projectId,
      })();
      this.cams = res;
      // eslint-disable-next-line prefer-destructuring
      this.cam = this.cams[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.cam-container {
  box-sizing: border-box;
  min-height: 150px;
  margin-top: 10px;
}

.no-cam {
  background: rgba(#fff, 0.05);
  border-radius: 5px;
  border: dashed 1px rgba(#fff, 0.5);
  color: #fff;
  text-align: center;
  padding: 20px;
}

.view-button {
  background: rgba(#fff, 0.05);
  border-radius: 5px;
  border: solid 1px rgba(#fff, 0.5);
  color: #fff;
  text-align: center;
  padding: 4px;
  margin-top: 10px;
  width: 100%;
  display: block;
  box-sizing: border-box;
  cursor: pointer;
}

.cams-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;

  td {
    width: 50%;
    height: 50%;
    padding: 0;
    border: none;
  }
}
</style>
