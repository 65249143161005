<template lang="pug">
  Board(
    title="项目管理"
    icon="/images/data-center-screen/boards/icon-projects.svg"
    hint='浏览全部'
    sub-window='projects'
    @sub-window="$emit('sub-window', $event)"
  )
    template(v-if='projects.length > 1')
      Legends(
        title="进度管理"
        :list="legends"
      ).mb
      ScrollingBarChart(
        :legends="legends"
        :list="projects"
        availableBy="withProgress"
      )
    template(v-else-if='projects.length === 1')
      BoardStats(
        title="项目进度"
        :n="projects[0].progressDone"
        :after="`/ ${projects[0].withProgress ? `${(projects[0].progressDone + projects[0].progressWip + projects[0].progressTodo)}工序` : '未启用'}`"
      )
</template>

<script>
import Board from '../Board.vue';
import Legends from '../Legends.vue';
import ScrollingBarChart from '../Charts/ScrollingBarChart.vue';
import BoardStats from '../BoardStats.vue';

export default {
  name: 'BoardProjects',
  props: {
    projects: Array,
  },
  emits: ['sub-window'],
  components: {
    Legends,
    Board,
    ScrollingBarChart,
    BoardStats,
  },
  data() {
    return {
      legends: [
        {
          color: '#73F39A',
          title: '已完成',
          name: 'progressDone',
        },
        {
          color: '#7396F3',
          title: '进行中',
          name: 'progressWip',
        },
        {
          color: '#4D5E69',
          title: '未开始',
          name: 'progressTodo',
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
