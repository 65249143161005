<template lang="pug">
.dashboard-screen.dashboard-screen-container.screen-page-v3(
  ref="screenContainer",
  :class="{ lite }"
)
  Header(@action="handleAction")
  HeaderDate(:weather='weather')
  HeaderNavs(@action="handleAction")
  HeaderDataCenterNav(@switch="$emit('switch', $event)")
  LeftSidebar(
    :selectedProjectIds.sync="selectedProjectIds"
    :projects="projects"
    :statsSections="statsSections"
  )
  RightSidebar(
    :projects="projects"
    :tasks="tasks"
  )
  RightPie(:projects="projects" :signins="signins")
  Bottom(:history="history")
  ScreenMap(
    v-if="displayMap"
    :projects="projects"
    :usersCount="usersCount"
    :data="data"
    :tasks="tasks"
    :cams="cams"
    :signins="signins"
    :selectedProjectIds.sync="selectedProjectIds"
  )
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable indent */
/* eslint-disable arrow-body-style */
// eslint-disable-next-line object-curly-newline
import { _, moment } from '@yishitec/web';

import Header from './Header.vue';
import HeaderDate from './HeaderDate.vue';
import LeftSidebar from './LeftSidebar.vue';
import RightSidebar from './RightSidebar.vue';
import RightPie from './RightPie.vue';
import Bottom from './Bottom.vue';
import ScreenMap from './ScreenMap.vue';
import HeaderNavs from './HeaderNavs.vue';
import HeaderDataCenterNav from './HeaderDataCenterNav.vue';

import detectZoom from './detectZoom';
import numberExplode from './numberExplode';
import enterFullScreen from './enterFullScreen';
import transformHistory from './transformHistory';

export default {
  name: 'DashboardScreen',
  components: {
    Header,
    HeaderDate,
    HeaderNavs,
    HeaderDataCenterNav,
    LeftSidebar,
    RightSidebar,
    RightPie,
    Bottom,
    ScreenMap,
  },
  props: {},
  emits: ['switch'],
  data() {
    return {
      barMax: 20,
      data: {},
      tasks: {},
      history: {},
      cams: [],
      ti: null,
      today: moment().format('YYYY-MM-DD'),
      lite: this.$route.query.lite === '1',
      zoom: 1,
      date: '',
      displayMapInfoWindow: false,
      // stats: {
      //   jl: [],
      //   sg: [],
      //   devices: [],
      //   staticDangerZones: [],
      //   opsZones: [],
      // },
      usersCount: 0,
      statsSections: [],
      filterType: 'SINGLE',
      statsFilterValue: [],
      selectedProjectIds: [],
      loadStatsSectionsDebounced: _.debounce(this.loadStatsSections, 800),
      displayMap: false,
      weather: null,
    };
  },
  computed: {
    projects() {
      return _.map(_.get(this.data, ['data']), (item) => {
        const [jlOnline, jlCount] = numberExplode(item, '监理单位人员');
        const [sgAdminOnline, sgAdminCount] = numberExplode(item, '施工单位管理人员');
        const [sgSecOnline, sgSecCount] = numberExplode(item, '施工单位安全员');
        const [sgOnline, sgCount] = numberExplode(item, '作业人员安全交底');
        _.extend(item, {
          jlOnline,
          jlCount: _.max([jlCount, jlOnline]),
          sgAdminOnline,
          sgAdminCount: _.max([sgAdminCount, sgAdminOnline]),
          sgSecOnline,
          sgSecCount: _.max([sgSecCount, sgSecOnline]),
          sgOnline,
          sgCount: _.max([sgCount, sgOnline]),
          activeDangerZones: Number(_.get(item, '危险源作业设备数') || 0),
          dangerZones: _.max([
            Number(_.get(item, '危险源设备') || 0),
            Number(_.get(item, '危险源作业设备数') || 0),
          ]),
          dangerZoneGrants: Number(_.get(item, '危险源作业') || 0),
        });
        if (jlOnline > this.barMax) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.barMax = jlOnline;
        }
        if (sgAdminOnline > this.barMax) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.barMax = sgAdminOnline;
        }
        if (sgOnline > this.barMax) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.barMax = sgOnline;
        }
        return item;
      });
    },
    fences() {
      let ret = _.map(this.projects, (item) => {
        return JSON.parse(item.fence || '[]');
      });
      ret = _.flatten(ret);
      return ret;
    },
    projectsCount() {
      return this.projects.length;
    },
    statsFilter: {
      get() {
        if (this.filterType === 'SINGLE') {
          return this.statsFilterValue[0] || null;
        }
        return this.statsFilterValue;
      },
      set(value) {
        if (this.filterType === 'SINGLE') {
          this.statsFilterValue = [value];
        } else {
          this.statsFilterValue = value;
        }
      },
    },
    signins() {
      let jlOnline = 0;
      let sgAdminOnline = 0;
      let sgOnline = 0;
      let notSignedIn = 0;
      _.forEach(this.projects, (item) => {
        jlOnline += item.jlOnline || 0;
        sgAdminOnline += item.sgAdminOnline || 0;
        sgOnline += item.sgOnline || 0;
        notSignedIn +=
          item.jlCount -
          item.jlOnline +
          (item.sgAdminCount - item.sgAdminOnline) +
          (item.sgCount - item.sgOnline);
      });
      if (!(jlOnline + sgAdminOnline + sgOnline + notSignedIn)) {
        return [{ name: '未登录人员', value: 1e6 }];
      }
      return [
        { name: '监理单位人员', value: jlOnline },
        { name: '施工单位管理', value: sgAdminOnline },
        { name: '施工作业人员', value: sgOnline },
        { name: '未登录人员', value: notSignedIn },
      ];
    },
  },
  watch: {
    zoom(value) {
      if (value !== 100) {
        this.$notify.warning({
          title: `当前页面比例为${value}%，建议按Ctrl+0 / Cmd+0恢复正常页面比例以获得最佳浏览效果`,
        });
      }
    },
    filterType(next) {
      if (next === 'SINGLE') {
        if (this.statsFilterValue.length > 1) {
          this.statsFilterValue = [this.statsFilterValue[0]];
        }
      }
    },
    // statsFilterValue() {
    //   this.loadStats();
    // },
    selectedProjectIds() {
      this.loadStatsSectionsDebounced();
    },
  },
  mounted() {
    this.loadDataStatic();
    this.loadData();
    const autoRefreshInterval = 3 * 60 * 1000;
    this.ti = setInterval(() => {
      this.loadData();
    }, autoRefreshInterval);
    const zoomHandler = () => {
      this.zoom = detectZoom();
    };
    setTimeout(zoomHandler, 3000);
    this.displayMap = true;
  },
  beforeDestroy() {
    if (this.ti) {
      clearInterval(this.ti);
    }
    if (window.showModalSubProjects) {
      window.showModalSubProjects = null;
    }
  },
  methods: {
    async loadDataStatic() {
      const camsRes = await this.withLoading(this.api('/api/cams/list-v2', { project_id: '_' }));
      this.cams = _.get(camsRes, 'list') || [];
      this.history = transformHistory(
        await this.withLoading(
          this.api('/api/reports/data-center-history', {
            from: moment().subtract(13, 'days').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
          }),
        ),
      );
    },
    async loadData() {
      this.date = moment().format('YYYY年MM月DD日');
      this.data = await this.withLoading(this.api('/api/reports/users-count', {}));
      if (this.data && this.data.data) {
        this.usersCount = this.data.usersCount;
        this.data.data = _.map(this.data.data, (project) => {
          const projectOnlineRatio =
            _.sum(
              _.map(
                ['施工单位管理人员', '施工单位安全员', '作业人员安全交底', '监理单位人员'],
                (key) => {
                  let [online, usersCount] = String(project[key] || '0 / 0').split(' / ');
                  online = Number(String(online).trim()) || 0;
                  usersCount = Number(String(usersCount).trim()) || 1;
                  return online / usersCount;
                },
              ),
            ) / 4;
          return {
            ...project,
            projectOnlineRatio,
          };
        });
      }
      this.tasks = await this.withLoading(this.api('/api/tasks/screen-summary'));
      // this.loadStats();
      this.loadStatsSections();
      this.loadWeather();
    },
    // async loadStats() {
    //   this.stats = _.extend(
    //     {
    //       jl: [],
    //       sg: [],
    //       devices: [],
    //       staticDangerZones: [],
    //       opsZones: [],
    //     },
    //     (await this.api('/api/reports/sidebar-stats', {
    //       projectIds: this.selectedProjectIds,
    //     })()) || {},
    //   );
    // },
    async loadStatsSections() {
      this.statsSections =
        (await this.api('/api/reports/sidebar-stats', {
          projectIds: this.selectedProjectIds,
        })()) || [];
    },
    async loadWeather() {
      const res = await this.api('/api/v3/weather-history/now')();
      this.weather = res;
    },
    handleAction(action) {
      if (action === 'full-screen') {
        enterFullScreen(this.$refs.screenContainer);
      }
    },
  },
};
</script>

<style lang="less">
@import '../../../assets/styles/app-mixins.less';

.dashboard-screen.screen-page-v3 {
  &.dashboard-screen-container {
    background: #050d1b;
    color: #fff;
    font-size: 12px;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-image: linear-gradient(180deg, #050d1b 0%, #050d1b 71%, #0c1e3a 100%);
  }
}
</style>
