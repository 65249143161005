<template lang="pug">
.ai-chat-messages
  .message-item(
    v-for='message in messages',
    :key='message.id',
    :class='{ me: message.sender === "user" }'
  ) {{ message.text }}
  .message-hint(v-if='newMessagesLoading') 正在思考……
  .message-item.me.input
    ui-flex(row)
      el-input.input-textarea(v-model='input', placeholder='请输入', type='textarea')
      el-button(@click='sendMessage', type='primary', icon='el-icon-arrow-right') 发送
  .message-hint 温馨提示：目前自由询问仅面向平台管理员体验、调试，请勿滥用
</template>

<script>
import { _ } from '@yishitec/web';

export default {
  name: 'AiChatMessages',
  props: {
    camSnapshotId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      input: '',
      messages: [],
      newMessagesLoading: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const res = await this.withLoading(
        this.api('/api/cam-snapshots/ai-dialogs', {
          id: this.camSnapshotId,
        }),
      );
      this.messages = res.dialogs;
    },
    async sendMessage() {
      // Reset input
      const message = this.input;
      this.input = '';
      this.newMessagesLoading = true;
      this.messages.push({
        id: this.messages.length + 1,
        text: message,
        sender: 'user',
      });

      const res = await this.withLoading(
        this.api('/api/cam-snapshots/ai-chat', {
          id: this.camSnapshotId,
          message,
        }),
      );

      this.messages.push(..._.get(res, ['newMessages'], []));
      this.newMessagesLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.ai-chat-messages {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}
.message-item {
  max-width: calc(100% - 4em);
  align-self: flex-start;
  background: rgba(blue, 0.05);
  padding: 0.5em;
  border-radius: 0.5em;

  &.me {
    align-self: flex-end;
    background: rgba(green, 0.05);
  }

  &.input {
    width: calc(100% - 4em);
  }
}

.input-textarea {
  flex: 1 0 1px;
  margin-right: 1em;
}
.message-hint {
  font-size: 0.8em;
  color: #999;
  text-align: center;
}
</style>
