<template lang="pug">
div(v-if="dangerZoneId && data")
  AppMapMouseEditor(
    readonly
    :value="data.location_amap || data.location_point"
    :key="version"
  )
</template>

<script>
export default {
  name: 'DangerZoneMap',
  props: {
    dangerZoneId: {
      type: [String, Number],
      defualt: null,
    },
  },
  data() {
    return {
      data: null,
      version: 1,
    };
  },
  computed: {},
  watch: {
    dangerZoneId() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      if (!this.dangerZoneId) {
        this.data = null;
        this.version += 1;
        return;
      }
      const res = await this.withLoading(this.api(`/api/danger-zones/map/${this.dangerZoneId}`));
      this.data = res || null;
      this.version += 1;
    },
  },
};
</script>

<style lang="scss" scoped></style>
