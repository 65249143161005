<template lang="pug">
ui-flex(row, j-center, a-center).pie-container
  .pie-chart(ref="pie")
  .legend-container
    .pie-legend
      .pie-legend-item(v-for="item in value", :key="item.name")
        span.pie-legend-badge(:style="{ background: color(item.name) }")
        span.pie-legend-title {{ item.name }}
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable function-paren-newline */
import { _ } from '@yishitec/web';

const d3 = require('d3');

export default {
  name: 'AppPie',
  props: {
    value: { type: Array, default: () => [] },
    width: { type: Number, default: 96 },
    height: { type: Number, default: 96 },
  },
  data() {
    const color = d3
      .scaleOrdinal()
      .domain(this.value.map((d) => d.name))
      .range(
        d3.quantize((t) => d3.interpolateSpectral(t * 0.95 + 0.1), _.max([2, this.value.length])),
      );
    return {
      color,
    };
  },
  computed: {},
  watch: {
    value() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.color = d3
        .scaleOrdinal()
        .domain(this.value.map((d) => d.name))
        .range(
          d3.quantize((t) => d3.interpolateSpectral(t * 0.95 + 0.1), _.max([2, this.value.length])),
        );

      const { width, height } = this;
      const pie = d3
        .pie()
        .padAngle(0.005)
        .sort(null)
        .value((d) => d.value);
      const arc = (() => {
        const radius = Math.min(width, height) / 2;
        return (
          d3
            .arc()
            // .innerRadius(radius - 14)
            .innerRadius(0)
            .outerRadius(radius - 1)
        );
      })();

      const { color } = this;

      const arcs = pie(this.value);

      const svg = d3.create('svg').attr('viewBox', [-width / 2, -height / 2, width, height]);

      svg
        .selectAll('path')
        .data(arcs)
        .join('path')
        .attr('fill', (d) => color(d.data.name))
        .attr('d', arc)
        .append('title')
        .text((d) => `${d.data.name}: ${d.data.value.toLocaleString()}`);

      svg
        .append('g')
        .attr('font-family', 'sans-serif')
        .attr('font-size', 12)
        .attr('text-anchor', 'middle')
        .selectAll('text')
        .data(arcs)
        .join('text')
        .attr('transform', (d) => `translate(${arc.centroid(d)})`)
        // .call(text =>
        //   text
        //     .append('tspan')
        //     .attr('y', '-0.4em')
        //     .attr('font-weight', 'bold')
        //     .text(d => d.data.name),
        // )
        .call((text) =>
          text
            .filter((d) => d.endAngle - d.startAngle > 0.25)
            .append('tspan')
            .attr('x', 0)
            .attr('y', '0.5em')
            .attr('fill-opacity', 0.7)
            .attr('fill', '#fff')
            .attr('font-weight', 'bold')
            .attr('class', 'pie-text-shadow')
            .text((d) => {
              if (d.data.value === 1e6) {
                return '/';
              }
              return d.data.value.toLocaleString();
            }),
        );
      _.forEach(this.$refs.pie.children, (child) => {
        this.$refs.pie.removeChild(child);
      });
      this.$refs.pie.append(svg.node());
    },
  },
};
</script>

<style lang="less" scoped>
.pie-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.legend-container {
  margin-left: 20px;
}

.pie-chart {
  width: 96px;
  height: 96px;
}

.pie-title {
  position: absolute;
  top: 2px;
  bottom: 2px;
  right: 8px;
  left: auto;
  width: 130px;
  height: 130px;
  color: #fff;
  font-size: 14px;
  font-weight: bolder;
  text-align: center;
}

.pie-legend {
  margin-top: 4px;
  font-size: 10px;
}

.pie-legend-badge {
  display: inline-block;
  vertical-align: top;
  margin-right: 4px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  top: 4px;
}

.pie-legend-title {
  display: inline-block;
  width: auto;
}

.pie-legend-value {
  display: inline-block;
  margin-left: 4px;
  color: fadeout(#fff, 50);
}
</style>

<style lang="less">
.pie-text-shadow {
  text-shadow: 1px 1px 2px #000;
}
</style>
