import { Vue, _ } from '@yishitec/web';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {},
    currentProjects: [], // id[]
    permissions: {}, // projectId: admintype
    isSuperAdmin: false,
  },
  getters: {
    currentProjectsLength(state) {
      return state.currentProjects.length;
    },
  },
  mutations: {
    login(state, res) {
      state.userInfo = res;
    },
    logout(state) {
      state.userInfo = {};
      state.currentProjects = [];
      state.permissions = {};
      state.isSuperAdmin = false;
    },
    setCurrentProjects(state, value) {
      state.currentProjects = value;
    },
    setPermissions(state, value) {
      state.permissions = value;
      state.isSuperAdmin = Boolean(_.find(value, (item) => item.admintype === '平台管理员'));
    },
  },
  actions: {},
  modules: {},
});
