<template lang="pug">
  div.sub-window-mask(@click.stop="$emit('close')" :class="{ active: isActive }")
    .sub-window(@click.stop)
      .close(@click.stop="$emit('close')")
        i.el-icon-close.icon
      slot
</template>

<script>
export default {
  name: 'SubWindow',
  emits: ['close'],
  data() {
    return {
      isActive: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isActive = true;
    }, 100);
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.sub-window-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: fadeout(#000, 20);
  opacity: 0;
  transition: 0.3s;
  z-index: 2;

  &.active {
    opacity: 1;
  }
}

.sub-window {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 80px;
  background: #20292e;
  box-sizing: border-box;
  padding: 20px 100px 20px 20px;
}

.close {
  width: 100px;
  height: 68px;
  background-image: linear-gradient(180deg, fadeout(#fff, 80) 0%, fadeout(#20292e, 100) 100%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-image: linear-gradient(180deg, fadeout(#fff, 60) 0%, fadeout(#20292e, 100) 100%);
  }

  .icon {
    font-size: 22px;
  }
}
</style>
