<template>
  <div class="app-features">
    <ui-container>
      <ui-flex row a-start between class="column--mobile">
        <ui-flex
          v-for="item in items"
          :key="item.title"
          style="flex: 1 0 1px"
          class="app-features__item"
        >
          <app-feature-item v-bind="item" />
        </ui-flex>
      </ui-flex>
    </ui-container>
  </div>
</template>

<script>
import AppFeatureItem from './AppFeatureItem.vue';

export default {
  name: 'AppFeatures.vue',
  components: { AppFeatureItem },
  data() {
    const items = [
      {
        title: '监控画面直播',
        en: 'Live',
        // eslint-disable-next-line global-require
        image: require('../assets/images/features/surveillance.jpg'),
      },
      {
        title: '回放',
        en: 'Playback',
        // eslint-disable-next-line global-require
        image: require('../assets/images/features/play-control.jpg'),
      },
      {
        title: '云台控制',
        en: 'PTZ Control',
        // eslint-disable-next-line global-require
        image: require('../assets/images/features/video-control.jpg'),
      },
      {
        title: '联系我们',
        en: 'Email: tongchuan@yishitec.com',
        // eslint-disable-next-line global-require
        image: require('../assets/images/features/contact-us.jpg'),
      },
    ];
    return {
      items,
    };
  },
};
</script>

<style scoped lang="scss">
.app-features {
  .column--mobile {
    @include xs {
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__item {
    margin-left: 24px;
    &:first-child {
      margin-left: 0;
    }
    @include xs {
      margin-top: 24px;
      margin-left: 0;
    }
  }
}
</style>
