<template lang="pug">
div
  ui-page-header(title="闸机操作")
  ui-stage
    ui-tabs.mb(type="border-card", value="main")
      ui-tab-pane(name="main", label="详情")
        h1 详情
    ui-tabs.mb(type="border-card", value="main")
      ui-tab-pane(name="main", label="操作")
        h1 操作
    ui-tabs.mb(type="border-card", value="main")
      ui-tab-pane(name="main", label="指令")
        h1 指令
    ui-tabs.mb(type="border-card", value="main")
      ui-tab-pane(name="main", label="指令结果")
        h1 结果
</template>

<script>
export default {
  name: 'IotGatewayTasks',
  data() {
    return {
      pageId: null,
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    $route() {
      this.loadData();
    },
  },
  methods: {
    async loadData() {
      const { id } = this.$route.params;
      this.pageId = Number(id);

      this.data = await this.$api('/api/iot-gateway-tasks', {
        taskSetId: this.pageId,
      })();
    },
  },
};
</script>

<style lang="scss"></style>
