<template>
  <div class="report-grid-badge">
    <div class="grid-title">{{ title }}</div>
    <div class="grid-number" :style="{ color }">{{ number }}</div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ReportGridBadge',
  props: {
    reportsData: Object,
    title: String,
    data: Array,
    color: String,
  },
  computed: {
    number() {
      return _.get(this.reportsData, this.data) || 0;
    },
  },
};
</script>

<style lang="scss">
.report-grid-badge {
  width: 100%;
  height: 100%;
  border: solid 1px #b5c2d1;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .grid-title {
    font-size: 1em;
    color: #8d99ab;
  }
  .grid-number {
    width: 4.5em;
    background: #dcecff;
    border-radius: 1000px;
    text-align: center;
    font-size: 1.2em;
    font-weight: bolder;
    line-height: 30px;
    color: #1e3258;
  }
}
</style>
