<template lang="pug">
el-dialog(
  :title="`图示详情`"
  :visible="visible"
  @visible:update="handleUpdateVisible"
  @close="handleClose"
)
  div(v-loading="loading")
    template(v-if="vData && attributes")
      SspFormItem(
        v-for="field in attributes"
        :key="field.key"
        v-model="vData[field.key]"
        v-bind="field"
      )
      div.editor-toolbar.mt
        el-button(
          type="primary"
          @click="handleSubmit"
        ) 提交
        el-link(type="danger" @click="handleRemove") 删除
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable indent */
import ProgressImageViewer from './ProgressImageViewer.vue';
import SspUpload from './ssp/SspUpload.vue';

export default {
  name: 'ProgressImageEditor',
  props: {
    visible: { type: Boolean, default: false },
    data: { type: Object, default: null },
  },
  emits: ['visible:update', 'close', 'refresh'],
  components: { ProgressImageViewer, SspUpload },
  data() {
    return {
      loading: false,
      refImageLoading: false,
      vData: null,
    };
  },
  computed: {
    attributes() {
      return [
        {
          key: 'filename',
          label: '文件名',
        },
      ];
    },
  },
  watch: {
    data() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData(res) {
      this.vData = res || this.data;
    },
    handleUpdateVisible(ev) {
      this.$emit('update:visible', ev);
    },
    handleClose() {
      this.$emit('close');
    },
    triggerRefresh() {
      this.$emit('refresh');
    },
    async handleSubmit() {
      this.loading = true;
      const res = await this.withLoading(
        this.api('/api/progress/save-image', {
          ...this.vData,
        }),
      );
      if (res) {
        this.triggerRefresh();
        this.handleClose();
      }
      this.loading = false;
    },
    async handleRemove() {
      this.loading = true;
      await this.withLoading(this.api('/api/progress/delete-image', { id: this.vData.id }));
      this.loading = false;
      this.triggerRefresh();
      this.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.checkboxes {
  @include ui-flex-row(flex-start, center);
  flex-wrap: wrap;
}
.checkbox-item {
  flex: 0 0 auto;
  margin: 10px 10px 10px 0;
  width: calc((100% - 3 * 10px) / 3);
}
.color-box {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 10px;
  border-radius: 16px;
}
.full-img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
}
.editor-toolbar {
  @include ui-flex-row(space-between, center);
}
.info-canvas {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 1px;
  height: 1px;
  display: none;
}
</style>
