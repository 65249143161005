<script>
/* eslint-disable indent */
import { _, numeral, moment } from '@yishitec/web';
import ProgressItemEditor from '../../../components/ProgressItemEditor.vue';
import ProgressStateTag from '../../../components/ProgressStateTag.vue';
import ProgressImagesManager from '../../../components/ProgressImagesManager.vue';
import BlueprintImageViews from '../../../components/BlueprintImageViews.vue';
import ProgressDatesAlert from '../../../components/ProgressDatesAlert.vue';
import ProgressStateAlert from '../../../components/ProgressStateAlert.vue';

const levels = ['项目', '工序组', '工序', '工序日志'];

export default {
  name: 'ProgressProressProjectItem',
  props: {
    projectId: { type: [String, Number], default: null },
    id: { type: [String, Number], default: null },
    isPreview: { type: Boolean, default: false },
  },
  components: {
    ProgressItemEditor,
    ProgressStateTag,
    ProgressImagesManager,
    // eslint-disable-next-line global-require
    SubProjectNavItem: require('../../../components/SubProjectNavItem.vue').default,
    BlueprintImageViews,
    ProgressDatesAlert,
    ProgressStateAlert,
  },
  data() {
    return {
      tab: 'All',
      project: null,
      data: null,
      children: [],
      loading: false,
      sortBy: 'updatedAt',
      keyword: '',
      firstLevel: _.first(levels),
      lastLevel: _.last(levels),
      level: '项目',
      childLevel: '工序组',
      editorItemId: null,
      displayCreateModal: false,
      displayEditModal: false,
      activeImageId: null,
      selectedChildItemId: null,
      displaySaveTemplateModal: false,
      displayApplyTemplateModal: false,
      isSuper: this.$route.query.super === '1',
      displayPartsImages: true,
      isFullscreen: this.$route.query.fullscreen === '1',
    };
  },
  computed: {
    vId() {
      return Number(_.get(this.data, ['id'])) || 0;
    },
    tabs() {
      return [
        {
          key: 'All',
          badge: false,
          title: '全部',
        },
        {
          key: 'Wip',
          number: 0,
          title: '进行中',
          color: '#89C45A',
        },
        {
          key: 'Todo',
          number: 0,
          title: '未开始',
          color: '#E48080',
        },
        {
          key: 'Done',
          number: 0,
          title: '已结束',
        },
      ];
    },
    vData() {
      return _.reverse(_.sortBy(this.data, [(item) => moment(item.updatedAt)]));
    },
    sortByOptions() {
      return [
        {
          value: 'updatedAt',
          title: '更新时间',
        },
      ];
    },
    attributes() {
      return [
        {
          name: 'id',
          title: 'ID',
        },
        {
          name: 'title',
          title: `${this.childLevel}名称`,
        },
        {
          name: 'state',
          title: '当前状态',
        },
        {
          name: 'aggProgress',
          title: '当前进度',
        },
        ...(this.isPreview
          ? []
          : [
              {
                name: 'highlight',
                title: '绑定图示',
              },
            ]),
        {
          name: 'startedAt',
          title: '开始时间',
        },
        {
          name: 'endedAt',
          title: '完成时间',
        },
        {
          name: 'memo',
          title: '备注',
        },
      ];
    },
    attributesLogs() {
      return [
        {
          name: 'createdAt',
          title: '日志时间',
          width: 180,
        },
        {
          name: 'memo',
          title: '备注',
        },
        {
          name: 'image',
          title: '图片',
          width: 120,
        },
      ];
    },
    shouldRequireImage() {
      return !['工序日志'].includes(this.level);
    },
    withSidebar() {
      return true;
    },
  },
  watch: {
    tab() {
      this.loadData();
    },
    editorItemId(value) {
      if (value) {
        this.selectedChildItemId = null;
      }
    },
    displayCreateModal(value) {
      if (value) {
        this.selectedChildItemId = null;
      }
    },
    displayEditModal(value) {
      if (value) {
        this.selectedChildItemId = null;
      }
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const { projectId } = this;
      let { id } = this;
      if (projectId) {
        const projectRes = await this.withLoading(
          this.api(`/api/progress/project/${this.projectId}`),
        );
        if (projectRes) {
          this.project = projectRes;
        }
      }
      if (!id || Number(id) === Number(_.get(this.project, 'xProjectProgresss.id'))) {
        id = _.get(this.project, 'idFirstChild');
      }
      if (id) {
        const res = await this.withLoading(this.api(`/api/progress/item/${id}`));
        if (!res) {
          this.data = null;
        } else {
          res.xProgressImages = _.map(res.xProgressImages || [], (item, index) => {
            return { ...item, index };
          });
          this.data = res;
          this.displayPartsImages = Boolean(_.get(this.data.xProgressBlueprints, 'length'));
          this.children = _.get(res, ['xProjectProgresss']) || [];
          this.level = res.level || levels[0];
          this.childLevel =
            _.get(
              {
                单位工程: '工序组',
                分段: '工序组',
                工序: '工序日志',
              },
              res.level,
            ) || res.level;
          if (
            this.childLevel === '工序组' &&
            Boolean(_.find(this.children, (i) => i.level === '工序'))
          ) {
            this.childLevel = '工序';
          }
        }
      }

      if (this.$route.query.withEdit === '1') {
        this.showEditModal();
      }

      this.loading = false;
    },
    handleRow(row) {
      if (row.level === this.lastLevel) {
        this.editorItemId = row.id;
        return;
      }
      this.$router.push(`/progress/progress/${this.projectId}/${row.id}`);
    },
    handleEditorClose() {
      this.editorItemId = null;
      this.loadData();
    },
    handleCreateModalClose() {
      this.displayCreateModal = false;
    },
    showCreateModal() {
      this.displayCreateModal = true;
    },
    showEditModal() {
      this.displayEditModal = true;
    },
    handleEditModalClose() {
      this.displayEditModal = false;
    },
    highlightRow(row) {
      if (this.selectedChildItemId) {
        this.selectedChildItemId = null;
        return;
      }
      this.selectedChildItemId = row.id;
    },
    noop() {},
    handleClickNavItem(id) {
      this.$router.push(`/progress/progress/${this.project.id}/${id}`);
    },
    async handleCreateSubProject() {
      await this.$message({
        message: '正在创建...',
      });
      const projectId = _.get(this.project, ['id']);
      const parentId = _.get(this.project, 'xProjectProgresss.id');
      const res = await this.withLoading(
        this.api('/api/progress/save', {
          projectId,
          parentId,
          level: '单位工程',
        }),
      );
      this.$router.push(`/progress/progress/${projectId}/${res.id}?withEdit=1`);
    },
    gotoEdit() {
      this.$router.push(`/progress/progress/${this.projectId}/${_.get(this.data, 'id') || ''}`);
    },
    gotoPreview() {
      this.$router.push(`/progress/progress/view/${this.projectId}/${this.data.id}`);
    },
    clearHighlightColors(value) {
      this.highlightColor = value;
    },
    handleSaveTemplate() {
      this.displaySaveTemplateModal = true;
    },
    closeSaveTemplateModal() {
      this.displaySaveTemplateModal = false;
    },
    handleApplyTemplate() {
      this.displayApplyTemplateModal = true;
    },
    closeApplyTemplateModal() {
      this.displayApplyTemplateModal = false;
    },
    async handleApplyTemplates() {
      const templates = await this.withLoading(
        this.api('/api/progress-templates/list-templates', {
          parentId: this.data.id,
        }),
      );
      this.withLoading(async () => {
        const getTemplateIdByName = async (searchName) => {
          const id = _.get(
            _.find(templates, (findTemplate) => {
              return findTemplate.title === searchName;
            }),
            ['id'],
          );
          if (!id) {
            throw new Error(`找不到模板：${searchName}`);
          }
          return id;
        };

        let templateName = '';
        let templateNameAffix = '';
        try {
          ({ value: templateName } = await this.$prompt('请输入模板名称', 'SUPER 应用子项模板'));
          ({ value: templateNameAffix } = await this.$prompt(
            '请输入子项模板后缀',
            'SUPER 应用子项模板',
          ));
        } catch (e) {
          this.$message({
            title: e.message,
            type: 'error',
          });
          return;
        }

        await _.asyncEach(this.children, async (childItem) => {
          const getItemChildren = async () => {
            const childItemChildren = await this.api(`/api/progress/item/${childItem.id}`)();
            return _.get(childItemChildren, ['xProjectProgresss']) || [];
          };
          const childItemChildren = await getItemChildren();
          if (!childItemChildren.length) {
            await this.api('/api/progress-templates/apply-template', {
              templateId: await getTemplateIdByName(templateName),
              parentId: childItem.id,
            })();
            this.$message({
              type: 'success',
              message: ['应用成功', childItem.title, templateName].join(' '),
            });
          }
          if (!childItemChildren.length) {
            // eslint-disable-next-line no-shadow
            const childItemChildren = await getItemChildren();
            await _.asyncEach(childItemChildren, async (childItemChild) => {
              // eslint-disable-next-line no-shadow
              const templateName = `${childItemChild.title}${templateNameAffix}`;
              await this.api('/api/progress-templates/apply-template', {
                templateId: await getTemplateIdByName(templateName),
                parentId: childItemChild.id,
              })();
              this.$message({
                type: 'success',
                message: ['应用成功', childItemChild.title, templateName].join(' '),
              });
            });
          }
        });
      });
    },
    getPartTitles(row) {
      const titles = _.flattenDeep(
        _.map(_.get(row, ['xProgressBindParts']), (bind) => {
          return _.get(bind, ['xProgressBlueprintPart', 'title']);
        }),
      ).filter(Boolean);
      if (!titles.length) return '';
      return `分段：${titles.join(', ')} (${titles.length}段)`;
    },
  },
  filters: {
    formatDateTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
    formatDate(value) {
      if (!value) return '/';
      const m = moment(value);
      if (!m.isValid()) return '/';
      return m.format('YYYY-MM-DD');
    },
    formatPercentage(value) {
      if (!Number(value)) return '-';
      return numeral(value).format('0,0%');
    },
    formatPercentageNumber(value) {
      return numeral(value).format('0,0%');
    },
  },
};
</script>

<template lang="pug">
  div
    div.p-trace(v-if='!isFullscreen')
      div.p-container
        ui-flex(row j-start a-center)
          ui-flex(zero)
            el-breadcrumb
              el-breadcrumb-item(
                to="/progress/progress"
              ) 作业计划
              el-breadcrumb-item(
                to="/progress/progress"
              ) 项目列表
              template(v-if="data && data.xBreadcrumbs && project && project.id")
                el-breadcrumb-item(
                  v-for="item in data.xBreadcrumbs"
                  :key="item.id"
                  :to="`/progress/progress/${project.id}/${item.id}`"
                ) {{ item.level }}
              el-breadcrumb-item {{ level }}详情
          ui-flex.ml
            ui-alert(v-if="project && !project.withProgressModule" type="warning")
              | 该项目未启用进度管理系统。
              router-link(
                :to="`/admin/projects/edit/${project.id}`"
              )
                ui-button(size="mini" type="warning").ml 编辑项目
    div(v-loading="loading").p-page
      div(v-if="!project" :style="{ height: '300px' }")
      div(v-else).p-container
        template(v-if='!isFullscreen')
          ui-flex(row j-start a-center)
            router-link(
              :to="`/progress/projects/edit/${project.id}`"
            ).project-page-title
              el-tooltip(
                content='点击进入项目管理，配置图示和分段桩号/井号/标号'
              )
                div.p-title {{ project.title }}
            ProgressStateTag(
              :value="$get(project, 'xProjectProgresss.state')"
            ).mr
            span(v-if="false").hint.mr10
              span 系统ID：{{ project.id }}
              el-divider(direction="vertical")
              span 工序计划时间：
                | {{ $get(project, 'xProjectProgresss.plannedStart') | formatDate }}
                | ~ {{ $get(project, 'xProjectProgresss.plannedEnd') | formatDate }}
              el-divider(direction="vertical")
              span 当前进度：{{ $get(project, 'xProjectProgresss.aggProgress') | formatPercentage }}
            ui-flex(
              row
              j-start
              a-center
              wrap
            )
              SubProjectNavItem(
                v-if="isPreview"
                title="使用编辑模式"
                isTool
                @click="gotoEdit"
              )
              SubProjectNavItem(
                v-else
                title="使用预览模式"
                isTool
                @click="gotoPreview"
              )
              SubProjectNavItem(
                v-for="item in project.subProjects"
                :key="item.id"
                :title="item.title"
                :progress="item.aggProgress * 100"
                :id="item.id"
                :active="item.id === vId"
                @click="handleClickNavItem(item.id)"
              ).data-nav-item
              SubProjectNavItem(
                v-if="!isPreview"
                title="+ 添加单位工程"
                isTool
                @click="handleCreateSubProject"
              )
          div(v-if="data && !data.parentId && false").p-card
            div.p-card-title 快速跳转
              small 智慧推荐
            el-alert 推荐表格
        div(v-if="data").p-card
          div.p-card-title(v-if='!isFullscreen') {{ `${level}${(level && data.title) ? `：${data.title}` : ''}` }}
            ProgressStateTag(
              v-if="data.level !== '项目'"
              :value="data.state"
            )
            ui-link(
              type="primary"
              @click="showEditModal"
              v-if="!isPreview"
            ).ml
              img(
                :src="require('../../../assets/images/progress/icon-edit.png')"
              ).memo-icon
          SspSidebar(
            :withSidebar="withSidebar"
          )
            template(v-slot:sidebar)
              SspSidebarLink(
                icon="edit"
                :title="`${level}备注`"
                bookmark="memo"
              )
              SspSidebarLink(
                icon="images"
                :title="`${childLevel}图示`"
                bookmark="images"
                v-if="shouldRequireImage"
              )
              SspSidebarLink(
                icon="list"
                :title="`${childLevel}列表`"
                bookmark="list"
              )
            div.section(v-if='!isFullscreen')
              el-divider {{ level }}备注
              div.meta-info-row
                div.meta-info
                  div.meta-label 进度
                  div.meta-value {{ data.aggProgress | formatPercentageNumber }}
                div.meta-info
                  div.meta-label 计划开始时间
                  div.meta-value {{ data.plannedStart | formatDate }}
                div.meta-info
                  div.meta-label 计划完成时间
                  div.meta-value {{ data.plannedEnd | formatDate }}
                div.meta-info
                  div.meta-label 实际开始时间
                  div.meta-value {{ data.startedAt | formatDate }}
                  ProgressDatesAlert(
                    :text="data.stateAlertStart"
                  )
                div.meta-info
                  div.meta-label 实际完成时间
                  div.meta-value {{ data.endedAt | formatDate }}
                  ProgressDatesAlert(
                    :text="data.stateAlertEnd"
                  )
                .meta-info
                  ProgressStateAlert(
                    :data="data"
                  )
              div(v-if="data.memo").text-block <strong>备注：</strong>{{ data.memo }}
              div(v-if="!isPreview").memo-toolbar
                ui-link(
                  type="primary"
                  @click="showEditModal"
                ).ml
                  img(
                    :src="require('../../../assets/images/progress/icon-edit.png')"
                  ).memo-icon
            div(
              v-if="shouldRequireImage"
            ).section
              template(v-if='!isFullscreen')
                el-divider(v-if="withSidebar")
                  span(style='display: inline-block; margin-right: 16px') {{ level }}图示
                  el-switch(
                    v-model='displayPartsImages'
                    active-text="显示分段图示"
                    inactive-text="显示工序图示"
                  )
              template(v-if='displayPartsImages')
                BlueprintImageViews(
                  v-if='data.xProgressBlueprints && data.xProgressBlueprints.length'
                  :blueprintIds='data.xProgressBlueprints'
                )
                div.empty(v-else) 暂无图示
              template(v-else)
                ProgressImagesManager(
                  v-model="data.xProgressImages"
                  :progressId="data.id"
                  @refresh="loadData"
                  :isPreview="isPreview"
                  :selectedChildItemId.sync="selectedChildItemId"
                  :children="children"
                )
            div(v-if="childLevel && !isFullscreen").section
              el-divider {{ childLevel }}列表
              div(v-if="!isPreview").table-toolbar
                div
                  el-button(
                    type="primary"
                    round
                    @click="showCreateModal"
                  ).mr
                    img(
                      :src="require('../../../assets/images/progress/icon-new.png')"
                    ).button-icon
                    | 创建新{{ childLevel }}
                  template(v-if="childLevel !== '工序日志'")
                    el-button(
                      v-if="children && children.length"
                      type="primary"
                      round
                      plain
                      @click="handleSaveTemplate"
                    ) 保存下列{{ childLevel }}列表为模板
                    el-button(
                      v-else
                      type="primary"
                      round
                      plain
                      @click="handleApplyTemplate"
                    ) 应用模板
                    el-button(
                      v-if="isSuper"
                      type="danger"
                      round
                      plain
                      @click="handleApplyTemplates"
                    ) 按子项应用模板
                div
                  el-input(
                    v-model="keyword"
                    placeholder="系统ID或关键词"
                  )
                    template(v-slot:append)
                      el-button(icon="el-icon-search")
              div(v-if="data.level === '工序'")
                SspTable(
                  :value="children"
                  :columns="attributesLogs"
                  @refresh="loadData"
                  :searchKeyword="keyword"
                  @handleRow="handleRow"
                )
                  template(v-slot:createdAt="{ row }") {{ row.createdAt | formatDateTime }}
                  template(v-slot:memo="{ row }")
                    div
                      span.inline-block {{ getPartTitles(row) }}
                      ProgressStateTag(:value="row.state || '未开始'")
                    div(v-if="row.memo").mt.mb.text-block {{ row.memo }}
                  template(v-slot:image="{ row }")
                    div(v-if="row.image")
                      el-image(
                        fit="cover"
                        :src="row.image"
                        :preview-src-list="[row.image]"
                        @click.stop="noop"
                      ).image-thumb
              div(v-else)
                SspTable(
                  :value="children"
                  :columns="attributes"
                  @refresh="loadData"
                  :searchKeyword="keyword"
                  @handleRow="handleRow"
                )
                  template(v-slot:state="{ row }")
                    ProgressStateTag(:value="row.state")
                  template(v-slot:aggProgress="{ row }") {{ row.aggProgress | formatPercentage }}
                  template(v-slot:startedAt="{ row }")
                    div(v-if="row.startedAt") {{ row.startedAt | formatDate }}
                    div(v-if="row.plannedStart").hint 计划：{{ row.plannedStart | formatDate }}
                  template(v-slot:endedAt="{ row }")
                    div(v-if="row.endedAt") {{ row.endedAt | formatDate }}
                    div(v-if="row.plannedEnd").hint 计划：{{ row.plannedEnd | formatDate }}
                  template(v-slot:memo="{ row }")
                    template(v-if="row.memo")
                      el-popover(
                        placement="top-start"
                        title="备注"
                        width="200"
                        trigger="hover"
                        :content="row.memo"
                        popper-class="popper-info-window"
                      )
                        template(v-slot:reference)
                          span.table-icon-button
                            img(
                              :src="require('../../../assets/images/progress/icon-view.png')"
                            )
                    span(v-else)
                  template(v-slot:highlight="{ row }")
                    span(
                      @click.stop="highlightRow(row)"
                      :class="{ dim: selectedChildItemId && selectedChildItemId !== row.id, active: selectedChildItemId && selectedChildItemId === row.id }"
                    ).table-icon-button
                      img(
                        :src="require('../../../assets/images/progress/icon-magic.png')"
                      )
    ProgressItemEditor(
      visible
      v-if="project && editorItemId"
      @close="handleEditorClose"
      :id="editorItemId"
      :projectId="project.id"
      :level="childLevel"
      :parentId="data.id"
      @refresh="loadData"
    )
    ProgressItemEditor(
      visible
      v-if="displayCreateModal"
      @close="handleCreateModalClose"
      :projectId="project.id"
      :level="childLevel"
      :parentId="data.id"
      @refresh="loadData"
    )
    ProgressItemEditor(
      visible
      v-if="displayEditModal"
      @close="handleEditModalClose"
      :projectId="project.id"
      :level="level"
      :parentId="data.parentId"
      :id="data.id"
      @refresh="loadData"
    )
    ProgressSaveTemplateModal(
      v-if="displaySaveTemplateModal"
      @close="closeSaveTemplateModal"
      :sourceId="data.id"
      :sourceItem="data"
    )
    ProgressApplyTemplateModal(
      v-if="displayApplyTemplateModal"
      @close="closeApplyTemplateModal"
      @refresh="loadData"
      :parentId="data.id"
    )
</template>

<style lang="scss" scoped>
.map-container {
  margin-bottom: 32px;
}

.text-block {
  white-space: pre-line;
  font-size: 14px;
  line-height: 1.6;
}

.memo-toolbar {
  @include ui-flex-row(flex-end, center);
}

.memo-icon {
  height: 14px;
}

.map-image {
  width: 100%;
}

.table-toolbar {
  @include ui-flex-row(space-between, center);
}

.button-icon {
  vertical-align: middle;
  display: inline-block;
  height: 14px;
  margin-right: 10px;
}

.hint {
  font-size: 12px;
  color: #aaa;
}

.meta-info-row {
  margin: 30px 0;
  @include ui-flex-row(flex-start, stretch);
  flex-wrap: wrap;
}

.meta-info {
  flex: 0 0 auto;

  & + & {
    margin-left: 15px;
    border-left: solid 1px $light-gray;
    padding-left: 15px;
  }
}

.meta-label {
  font-weight: bolder;
  font-size: 0.8em;
  color: #aaa;
}

.meta-value {
  font-size: 1.5em;
}

.image-thumb {
  width: 100px;
  height: 100px;
}

.table-icon-button {
  transition: all 0.3s;
  padding: 0 16px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 2px;
  height: 32px;
  box-sizing: border-box;
  line-height: 32px;
  white-space: nowrap;

  &:hover,
  &.active {
    background: rgba(#000, 0.1);
  }

  &.dim {
    opacity: 0.2;
  }

  img {
    display: inline-block;
    width: 16px;
    vertical-align: middle;
  }
}

.color-block {
  display: inline-block;
  border-radius: 16px;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  margin-right: 10px;
}

.data-nav-item {
  flex: 0 0 auto;
}

.project-page-title {
  flex: 0 0 auto;
}

.empty {
  background: #f2f2f2;
  border-radius: 10px;
  padding: 60px 20px;
  text-align: center;
}
</style>
