<template lang="pug">
span {{ name || '' }}
</template>

<script>
/* eslint-disable camelcase */
export default {
  name: 'MemberName',
  props: {
    memberId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      name: '',
    };
  },
  watch: {
    memberId() {
      this.loadData();
    },
  },
  computed: {},
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const res = await this.api(`/api/app-users/name/${this.memberId}`)();
      const { display_name = '' } = res || {};
      this.name = display_name;
    },
  },
};
</script>

<style lang="scss" scoped></style>
