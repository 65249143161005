<template>
  <div class="report-cards-row">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ReportCardsRow',
};
</script>

<style lang="scss">
.report-cards-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  & > div {
    flex: 1 0 1px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
