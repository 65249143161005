<template lang="pug">
div(v-if="form")
  ui-stage
    el-card.m-card
      div.m-card
        el-tag(v-if="form.id").mr ID: {{ form.id }}
        el-tag.mr 创建人:
          MemberName(
            :memberId="form.userId"
          )
        el-tag.mr 创建时间:
          FormDateTime(
            :value="form.createdAt"
          )
        el-tag.mr 项目:
          ProjectName(
            :projectId="form.projectId"
          )
      SspFormItem(
        label="名称"
        v-model="form.title"
      )
      SspFormItem(
        label="状态"
        v-model="form.state"
        type="select"
        :options="options.state"
      )
      SspFormItem(
        label="进场时间"
        v-model="form.startAt"
        type="datetime"
      )
      SspFormItem(
        label="退场时间"
        v-model="form.endAt"
        type="datetime"
      )
    el-tabs(type="border-card").m-card
      el-tab-pane(
        label="围栏"
      )
        el-alert(
          type="warning"
        ).m-card 请选择一个静态或区域危险源作为施工部位的围栏。如需编辑，请前往：
          router-link(to="/admin/danger-zones" target="_blank")
            el-link(type="warning") 安全管理
        SspFormItem(
          label="选择危险源"
          type="custom"
        ).m-card
          ui-admin-model-picker(
            v-model="form.dangerZoneId"
            :config="$get(modelPickers, 'dangerZoneId')"
          )
        div.m-card
          DangerZoneMap(
            :dangerZoneId="form.dangerZoneId"
          )
      el-tab-pane(
        label="危险源"
      )
        SelectDangerZones(
          v-model="dangerZoneIds"
          :projectId="form.projectId"
        )
      el-tab-pane(
        label="危险类型检查清单"
      )
        SelectOpsDangerType(
          v-model="opsDangerTypesIds"
        )
      el-tab-pane(
        v-if="form.id"
        label="作业记录"
      )
        ProjectsFilterList(
          :url="`/api/ops-zones-logs/list/${form.id}`"
          pathPrefix="/admin/ops-zones-logs/edit"
          :withFilters="false"
        )
          div.m-card
            AppButton(
              v-if="canEditLogs"
              type="primary"
              :to="`/admin/ops-zones-logs/add?projectId=${form.projectId}&opsZoneId=${form.id}&date=${today}&userId=${userId}&startApprovalState=待审核&startApprovedBy=&endAt=&endApprovalState=&endApprovalMemo=&endApprovedBy=`"
            ) 添加今日施工部位作业记录
          template(v-slot:projectId="{ row }") {{ $get(row, ['xProject', 'title']) }}
          template(v-slot:opsZoneId="{ row }") {{ $get(row, ['xOpsZone', 'title']) }}
          template(v-slot:userId="{ row }") {{ $get(row, ['xUser', 'display_name']) }}
          template(v-slot:startApprovedBy="{ row }") {{ $get(row, ['xOpsZonesLogStartApprovedBy', 'display_name']) }}
          template(v-slot:endApprovedBy="{ row }") {{ $get(row, ['xOpsZonesLogEndApprovedBy', 'display_name']) }}
          template(v-slot:images="{ row }")
            small(v-if="!row.images") -
            i(v-else).el-icon-picture
          template(v-slot:startAt="{ row }")
            small {{ formatDateTime(row.startAt) }}
          template(v-slot:endAt="{ row }")
            small {{ formatDateTime(row.endAt) }}
          template(v-slot:createdAt="{ row }")
            small {{ formatDateTime(row.createdAt) }}
          template(v-slot:updatedAt="{ row }")
            small {{ formatDateTime(row.updatedAt) }}
          template(v-slot:startApprovalState="{ row }")
            ApprovalStateTag(:value="row.startApprovalState")
          template(v-slot:endApprovalState="{ row }")
            ApprovalStateTag(:value="row.endApprovalState")
    div.m-card.text-center
      AppButton(
        type="primary"
        @click="handleSubmit"
      ) 提交
</template>

<script>
import { _, moment } from '@yishitec/web';
import permissionsMixin from '../../../mixins/permissions';

export default {
  name: 'Edit',
  mixins: [permissionsMixin],
  props: {},
  data() {
    const options = {
      state: ['未开始', '进行中', '已完成'],
    };
    const today = moment().format('YYYY-MM-DD');
    return {
      form: null,
      attributes: {},
      modelPickers: {},
      dangerZoneIds: [],
      opsDangerTypesIds: [],
      options,
      today,
    };
  },
  computed: {
    userId() {
      return this.$store.state.permissions[0].member_id;
    },
    canEditLogs() {
      const { projectId } = this.form;
      return (
        this.amI(projectId, '施工单位', '管理人员', true) ||
        this.amI(projectId, '施工单位', '安全员', true)
      );
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const res = await this.withLoading(
        this.api(`/api/ops-zones/edit/${this.$route.params.vId || ''}`),
      );
      const { attributes, item } = res;
      this.form = item;
      this.attributes = _.zipObject(
        _.map(attributes, (attribute) => attribute.name),
        attributes,
      );
      this.modelPickers.dangerZoneId = this.attributes.dangerZoneId;
      const { projectId } = this.$route.query;
      if (projectId) {
        this.form.projectId = projectId;
      }
      this.dangerZoneIds = _.map(
        _.get(item, ['xOpsZonesDangerZones']) || [],
        (entry) => entry.dangerZoneId,
      );
      this.opsDangerTypesIds = _.map(
        _.get(item, ['xOpsZonesDangerTypes']) || [],
        (entry) => entry.opsDangerTypeId,
      );
    },
    async handleSubmit() {
      const { form, dangerZoneIds, opsDangerTypesIds } = this;
      const res = await this.withLoading(
        this.api('/api/ops-zones/save', {
          item: form,
          dangerZoneIds,
          opsDangerTypesIds,
        }),
      );
      if (res) {
        this.$message({
          type: 'success',
          message: '保存成功',
        });
        this.$router.push('/admin/ops-zones');
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
