<template>
  <div>
    <ui-stage>
      <ui-admin-table
        v-if="data"
        :attributes="attributes"
        :data="data"
        :module-url="moduleUrl"
        pagination-method="front-end"
        :link-create="linkCreate"
      />
    </ui-stage>
  </div>
</template>

<script>
import { adminList } from '@yishitec/web/mixins';
import permissions from '../../../mixins/permissions';

export default {
  name: 'List',
  mixins: [adminList, permissions],
  props: {
    projectId: { type: String },
  },
  data() {
    return {
      apiBodyData: {
        project_id: this.projectId,
      },
      linkCreate: `${this.moduleUrl}/add?project_id=${this.projectId}`,
    };
  },
};
</script>

<style lang="less" scoped></style>
