module.exports = {
  icon: 'images/data-center-screen/boards/icon-members.svg',
  title: '人员信息管理',
  apiUrl: '/api/reports/data-center-screen/sub-windows/members',
  indicators: [
    {
      title: '施工单位管理人员',
      n: '施工单位管理人员',
      total: '施工单位管理人员待完善',
      after: '待完善',
    },
    {
      title: '监理单位',
      n: '监理单位人员',
      total: '监理单位人员待完善',
      after: '待完善',
    },
    {
      title: '施工单位作业人员',
      n: '施工单位作业人员',
      total: '施工单位作业人员待完善',
      after: '待完善',
    },
  ],
  table: [
    {
      name: '项目名称',
      width: '220px',
    },
    {
      title: '施工单位管理人员',
      name: '施工单位管理人员情况',
      width: '120px',
    },
    {
      title: '施工单位作业人员',
      name: '施工单位作业人员情况',
      width: '120px',
    },
    {
      title: '监理',
      name: '监理单位人员情况',
      width: '120px',
    },
    // {
    //   name: '查看详情',
    //   type: 'adminOps',
    //   subWindow: 'projectMembers',
    // },
    {},
  ],
};
