<template>
  <component :is="vComponentName" :to="vTo" :href="vHref" class="app-nav-item">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'AppNavItem',
  props: {
    link: { type: String, default: '#' },
    isExternal: { type: Boolean, default: false },
  },
  computed: {
    vComponentName() {
      return this.isExternal ? 'a' : 'router-link';
    },
    vTo() {
      return this.isExternal ? undefined : this.link;
    },
    vHref() {
      return this.isExternal ? this.link : undefined;
    },
  },
};
</script>

<style scoped lang="less">
@import '../assets/styles/app-mixins.less';

.app-nav-item {
  display: block;
  padding: 8px 12px;
  color: @theme;
  text-decoration: none;
  transition: all 0.3s;
  &:hover {
    background: @theme;
    color: #fff;
  }
}
</style>
