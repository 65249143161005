import { _, numeral } from '@yishitec/web';

const formatNumber = (value) => {
  return numeral(value).format('0,0');
};

const formatPercentage = (value) => {
  return numeral(value).format('0,0%');
};

export default (project, options) => {
  const { tasks } = options || {};
  let projectStateHint = '';
  const projectStateHints = [];
  if (project.workState === '非活跃') {
    projectStateHints.push('非活跃');
  }
  if (project.今日交底状态 !== '作业') {
    projectStateHints.push(project.今日交底状态);
  }
  if (projectStateHints.length) {
    projectStateHint = _.map(
      projectStateHints,
      (hint) => `
                <div class='right-section-title is-secondary'>
                  ${hint}
                </div>
              `,
    ).join('\n');
  }
  const html = `
    <div class='right-section-title'>${project.title}</div>
    ${projectStateHint}
    <div class='right-section-title is-secondary'>
    今日作业施工部位：${project['今日作业施工部位'] || 0}
    </div>
    <div class='right-section-title is-secondary'>
    今日作业危险源：${project['今日作业危险源'] || 0}
    </div>
    <div class='right-section-title is-secondary'>
    今日预警数量：${_.get(tasks, ['projects', project.id, 'todo']) || 0}次
    </div>
    <div class='right-section-title is-secondary'>
    监理已处理预警：${_.get(tasks, ['projects', project.id, 'processed']) || 0}次
    </div>
    <div class='right-section-title is-secondary'>
    今日监理巡查记录：${project['今日监理巡查记录'] || 0}
    </div>

    <div class='bar-row'>
      <div class='bar'>
        <div style='width: ${
          (project.jlOnline / (project.jlCount || 1)) * 100
        }%' class='bar-active is-orange'></div>
      </div>
      <div class='bar-number'>${formatNumber(project.jlOnline)}</div>
      <div class='bar-percentage'>${formatPercentage(
        project.jlOnline / (project.jlCount || 1),
      )}</div>
    </div>

    <div class='bar-row'>
      <div class='bar'>
        <div style='width: ${
          (project.sgAdminOnline / (project.sgAdminCount || 1)) * 100
        }%' class='bar-active is-blue'></div>
      </div>
      <div class='bar-number'>${formatNumber(project.sgAdminOnline)}</div>
      <div class='bar-percentage'>${formatPercentage(
        project.sgAdminOnline / (project.sgAdminCount || 1),
      )}</div>
    </div>

    <div class='bar-row'>
      <div class='bar'>
        <div style='width: ${
          (project.sgSecOnline / (project.sgSecCount || 1)) * 100
        }%' class='bar-active is-red'></div>
      </div>
      <div class='bar-number'>${formatNumber(project.sgSecOnline)}</div>
      <div class='bar-percentage'>${formatPercentage(
        project.sgSecOnline / (project.sgSecCount || 1),
      )}</div>
    </div>

    <div class='bar-row'>
      <div class='bar'>
        <div style='width: ${
          (project.sgOnline / (project.sgCount || 1)) * 100
        }%' class='bar-active is-green'></div>
      </div>
      <div class='bar-number'>${formatNumber(project.sgOnline)}</div>
      <div class='bar-percentage'>${formatPercentage(
        project.sgOnline / (project.sgCount || 1),
      )}</div>
    </div>

    <div class='bar-row'>
      <div class='bar'>
        <div style='width: ${
          (project.activeDangerZones / (project.dangerZones || 1)) * 100
        }%' class='bar-active is-pink'></div>
      </div>
      <div class='bar-number'>${formatNumber(project.activeDangerZones)}</div>
      <div class='bar-percentage'>${formatPercentage(
        project.activeDangerZones / (project.dangerZones || 1),
      )}</div>
    </div>

    <div class='right-section-title is-secondary'>综合达标率：${Number(
      Number(project.projectOnlineRatio || 0) * 100,
    ).toFixed(2)}%</div>
  `;
  return html;
};
