<script setup>
import _ from 'lodash';
import SspEmptyCard from '@/components/ssp/SspEmptyCard.vue';
import { defineProps, ref, onMounted } from 'vue';
import { $fetch } from '@/composables';

const props = defineProps({
  id: [String, Number],
});

const { map, saveMapInstance } = useMap();

const { updateMapOverlays } = useMapData({
  map,
});

const { loaded, loadData } = useRes({
  updateMapOverlays,
});

onMounted(() => {
  loadData();
});

function useMap() {
  const map = ref(null);

  function saveMapInstance(value) {
    map.value = value;
  }

  return {
    map,
    saveMapInstance,
  };
}

function useMapData({ map }) {
  const mapOverlays = ref({});

  function draw() {
    if (!map.value) {
      setTimeout(() => {
        draw();
      }, 100);
      return;
    }

    const { map: mapInstance, mapUtils } = map.value;
    const {
      toLngLat,
      addPolygonOverlay,
      addPolylineOverlay,
      addPointOverlay,
      addLabelOverlay,
      setFitView,
    } = mapUtils;

    const shapes = [];
    _.forEach(mapOverlays.value.polygons, (polygon) => {
      shapes.push(drawPolygon(polygon));
    });
    _.forEach(mapOverlays.value.polylines, (polyline) => {
      shapes.push(drawPolyline(polyline));
    });
    _.forEach(mapOverlays.value.seriesPoints, (series) => {
      _.forEach(series.points, (point, index) => {
        shapes.push(drawSeriesPoint(point, index + 1));
      });
    });
    setFitView(shapes.filter(Boolean));

    function drawPolygon(polygon) {
      const { points, color = '#35F48F' } = polygon;
      const shape = addPolygonOverlay(toLngLats(points), {
        color,
        opacity: 0.2,
        fillColor: color,
        fillOpacity: 0.1,
        weight: 1,
        highlightByOpacity: 5,
      });
      return shape;
    }

    function drawPolyline(polyline) {
      const { points, color = '#F43535' } = polyline;
      return addPolylineOverlay(toLngLats(points), {});
    }

    function drawSeriesPoint(point, indexNumber) {
      const marker = addPointOverlay(toLngLat(point));
      const label = addLabelOverlay(toLngLat(point), String(indexNumber || ''), {
        bgColor: '#000',
        color: '#fff',
        y: -4,
      });
      return marker;
    }

    function toLngLats(points) {
      return _.map(points, (point) => {
        return toLngLat(point);
      });
    }
  }

  function updateMapOverlays(res) {
    mapOverlays.value = res || {};
    draw();
  }

  return {
    updateMapOverlays,
  };
}

function useRes({ updateMapOverlays }) {
  const loaded = ref(false);

  async function loadData() {
    const res = await $fetch('/api/v3/prospecting-project-task-node/task-trace', {
      method: 'post',
      body: {
        taskId: props.id,
      },
    });
    updateMapOverlays(res);

    loaded.value = true;
  }

  return {
    loaded,
    loadData,
  };
}
</script>

<template lang="pug">
  AppMapMouseEditor(
    v-if="loaded"
    readonly
    screenOffset='170px'
    minHeight=''
    type='polygon'
    mapStyle=''
    withSearch
    @map-ready='saveMapInstance'
  )
  div(v-else)
    SspEmptyCard(
      title='暂无任务节点轨迹'
    )
</template>
