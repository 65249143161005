import { axios, _ } from '@yishitec/web';
import { ref } from 'vue';
import useNotification from './useNotification';
import useRouter from './useRouter';

export default (url, urlAfterSubmit, options) => {
  const { Notification } = useNotification();
  const router = useRouter();

  const { afterSubmit: iAfterSubmit = null } = options || {};

  const pending = ref(false);

  const afterSubmit =
    iAfterSubmit ||
    (() => {
      if (urlAfterSubmit) {
        router.push(urlAfterSubmit);
      }
    });

  const handleSubmit = async (value) => {
    pending.value = true;
    try {
      const axiosRes = await axios(url, {
        method: 'post',
        data: value,
      });
      const res = axiosRes.data;
      if (res.message) {
        Notification.warning({ title: res.message });
      } else {
        Notification.success({ title: '操作成功' });
        afterSubmit(_.get(res, ['data', 'id']), res.data, value);
      }
      pending.value = false;
      return res;
    } catch (e) {
      setTimeout(() => {
        pending.value = false;
        Notification.warning({ title: '操作失败，请重试' });
      }, 1000);
      return null;
    }
  };
  return {
    pending,
    handleSubmit,
  };
};
