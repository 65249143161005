<template lang="pug">
  div.progress-image-viewer
    h1 {{ point }}
    div.rel
      canvas(
        v-if="item && item.id"
        ref="canvas"
        :class="{ 'drawing': Boolean(addPathForItemId) && isDrawMode }"
        resize
        hidpi="off"
      ).viewer-canvas
      div.canvas-toolbar
        div
          span(v-if="Boolean(addPathForItemId)") {{ opsHint }}
        div
          el-checkbox(
            border
            size="mini"
            v-model="zoomDirectionNatural"
          ).canvas-toolbar-checkbox 翻转滚轮方向(自然模式)
          template(v-if="Boolean(addPathForItemId)")
            el-button(
              size="mini"
              type="info"
              plain
              @click="isDrawMode = false"
              v-if="isDrawMode"
            ).ml -> 缩放模式
            el-button(
              size="mini"
              type="info"
              plain
              @click="isDrawMode = true"
              v-else
            ).ml -> 选区模式
            el-button(
              size="mini"
              type="success"
              @click="handleAddPathForItemId"
            ).ml 保存
    img(
      ref="image"
      :src="imageUrl"
      v-if="imageUrl"
    ).viewer-image
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unreachable */
import { _, G } from '@yishitec/web';
import paper from 'paper';

const noop = () => {};

export default {
  name: 'ProgressImageViewer',
  props: {
    item: { type: Object, default: () => ({}) },
    addPathForItemId: { type: [String, Number], default: null },
    children: { type: Array, default: () => [] },
  },
  emits: ['canvasInit', 'bindPaths'],
  data() {
    return {
      imageUrl: this.item.url,
      point: null,
      viewCenter: null,
      viewZoom: null,
      opsHint: '',
      opsHintLast: '',
      selectedPath: null,
      paths: [],
      isDrawMode: true,
      layerImage: null,
      layerPaths: null,
      noopTool: null,
      canvasPan: null,
      pathTool: null,
      zoomDirectionNatural: false,
    };
  },
  watch: {
    addPathForItemId() {
      this.updateLayerPaths();
      this.placeChildrenPaths();
      this.updateTools();
    },
    isDrawMode() {
      this.updateTools();
    },
    selectedPath(next, prev) {
      this.updateSelected(prev);
    },
  },
  mounted() {
    this.resetStates();
    this.initCanvas();
  },
  methods: {
    resetStates() {
      this.viewCenter = null;
      this.viewZoom = null;
      this.selectedPath = null;
      this.layerPaths = null;
    },
    placeImage() {
      const rasterImage = new paper.Raster(this.$refs.image);
      rasterImage.position = [0, 0];
      rasterImage.locked = true;
      rasterImage.addTo(this.layerImage);

      const updateScale = () => {
        if (this.viewZoom === null || this.viewCenter === null) {
          const scaleRatio = _.min([
            paper.view.viewSize.width / rasterImage.size.width,
            paper.view.viewSize.height / rasterImage.size.height,
          ]);
          if (!scaleRatio || !_.isFinite(scaleRatio)) {
            throw new Error('scaleRatios is not ready');
          }
          paper.view.zoom = scaleRatio || 1;
          paper.view.center = new paper.Point(0, 0);
          this.viewZoom = paper.view.zoom;
          this.viewCenter = paper.view.center;
        } else {
          paper.view.zoom = this.viewZoom || 1;
          paper.view.center = this.viewCenter;
        }
      };

      const tryUpdateScale = () => {
        try {
          updateScale();
        } catch (e) {
          setTimeout(tryUpdateScale, 200);
        }
      };
      tryUpdateScale();
    },
    resizeCanvas() {
      const $canvas = this.$refs.canvas;
      if (!$canvas) return;
      $canvas.width = $canvas.offsetWidth;
      $canvas.height = $canvas.offsetHeight;
      this.placeImage();
    },
    initCanvas() {
      if (!this.item) return;
      if (!this.item.url) return;

      this.$nextTick(() => {
        const $canvas = this.$refs.canvas;
        if (!$canvas) return;
        paper.setup($canvas);
        paper.project.clear();

        window.p = paper;
        window.t = this;

        const { Layer, Path } = paper;
        this.layerImage = new Layer({ name: 'image' });
        this.updateLayerPaths();

        this.resizeCanvas();
        this.$emit('canvasInit', {
          handleResize: () => {
            this.resizeCanvas();
          },
        });

        const initMousePanTool = () => {
          let activated = false;
          let panning = false;
          let startX = null;
          let startY = null;
          let startCenter = null;

          const activate = () => {
            activated = true;
          };
          const deactivate = () => {
            activated = false;
          };
          paper.view.element.onmousewheel = (ev) => {
            if (!activated) return;
            ev.preventDefault();
            ev.stopPropagation();
            const scrollDelta = ev.deltaY;
            const ratioIndex = 0.1;
            const direction = this.zoomDirectionNatural ? 1 : -1;
            const targetZoom = 1 + (scrollDelta > 0 ? ratioIndex : -ratioIndex) * direction;
            paper.view.scale(targetZoom);
            this.viewZoom = paper.view.zoom;
          };
          paper.view.element.onmousedown = (ev) => {
            if (!activated) return;
            panning = true;
            startX = ev.x;
            startY = ev.y;
            startCenter = paper.view.center;
          };
          paper.view.element.onmouseup = (ev) => {
            panning = false;
          };
          paper.view.element.onmousemove = (ev) => {
            if (!activated) return;
            if (!panning) return;
            paper.view.center = startCenter.subtract(
              new paper.Point([ev.x - startX, ev.y - startY]).divide(paper.view.zoom),
            );
            this.viewCenter = paper.view.center;
          };
          return {
            activate,
            deactivate,
          };
        };

        this.noopTool = new paper.Tool();
        this.canvasPan = initMousePanTool();

        const initMousePathTool = () => {
          const tool = new paper.Tool();
          let path;

          let startCenter = null;
          let startPoint = null;

          let lastClick = null;
          let isDrawing = false;
          let isKeySegment = false;

          tool.onMouseDown = (event) => {
            let isDoubleClick = false;
            if (Date.now() - lastClick < 250) {
              isDoubleClick = true;
            }
            lastClick = Date.now();

            if (isDoubleClick) {
              if (!isDrawing) return;
              path.add(event.point);
              this.selectedPath = path;
              this.opsHint =
                '多边形选区：选中一项；点击任意其他位置添加选区；按退格键（Backspace）或Delete删除图形';

              path.style = {
                cursor: 'pointer',
              };

              _.set(
                _.find(this.children, (findChildItem) => findChildItem.id === path.data.itemId),
                ['bindPaths'],
                JSON.stringify({
                  paths: this.exportPaths(),
                }),
              );

              isDrawing = false;
              return;
            }

            if (isDrawing) {
              path.removeSegment(path.lastSegment.index);
              path.add(event.point);
              isKeySegment = true;
              return;
            }

            if (event.item) {
              this.selectedPath = event.item;
              return;
            }

            isDrawing = true;
            this.selectedPath = null;
            path = new paper.Path();
            this.paths.push(path);
            path.addTo(this.layerPaths);
            path.data.itemId = this.addPathForItemId;
            path.data.image = this.imageUrl;
            path.strokeColor = new paper.Color(151 / 255);
            path.fillColor = this.getFillColor(
              _.find(this.children, (findItem) => findItem.id === this.addPathForItemId),
            );
            path.closed = true;
            path.add(event.point);
            isKeySegment = true;
            startCenter = paper.view.center;
            startPoint = event.point;
            this.opsHint = '多边形选区：点击任意位置添加点；双击完成选区';
          };

          tool.onKeyUp = (ev) => {
            if (this.selectedPath && ['delete', 'backspace'].includes(ev.key)) {
              this.selectedPath.remove();
              this.paths = _.filter(
                this.paths,
                (findPath) => findPath && findPath.id !== this.selectedPath.id,
              );
              this.opsHint = '多边形选区：点击任意位置开始框选';
            }
          };

          tool.onMouseMove = (event) => {
            if (!isDrawing) return;
            if (!isKeySegment) {
              path.removeSegment(path.lastSegment.index);
            }
            path.add(event.point);
            isKeySegment = false;
          };

          const activate = () => {
            this.opsHint = '多边形选区：点击任意位置开始框选';
            tool.activate();
          };
          return {
            activate,
          };
        };

        this.pathTool = initMousePathTool();

        this.placeChildrenPaths();
        this.updateTools();
      });
    },

    placeChildrenPaths() {
      this.paths = [];
      _.forEach(this.children, (item) => {
        try {
          _.forEach(JSON.parse(item.bindPaths).paths, (pathJson) => {
            const path = new paper.Path();
            path.addTo(this.layerPaths);
            this.paths.push(path);
            path.importJSON(pathJson);
            path.fillColor = this.getFillColor(item);
            if (path.data.image !== this.imageUrl) {
              path.remove();
            }
            path.selected = false;
          });
        } catch (e) {
          noop();
        }
      });
      this.tweenColors();
    },

    async tweenColors() {
      const activeIds = _.map(
        _.filter(this.children, (item) => item.state === '进行中'),
        (item) => item.id,
      );
      const paths = _.filter(this.paths, (item) => {
        return item.data.image === this.imageUrl && activeIds.includes(item.data.itemId);
      });
      const from = {
        fillColor: new paper.Color(59 / 255, 152 / 255, 255 / 255),
        opacity: 0.59,
      };
      const to = {
        fillColor: new paper.Color(59 / 255, 152 / 255, 255 / 255),
        opacity: 0.3,
      };
      const duration = 1000;
      const easing = 'easeInOutCubic';
      const options = {
        duration,
        easing,
      };
      await Promise.all(
        _.map(paths, (path) => {
          return new Promise((resolve) => {
            path.tween(from, to, options).then(() => {
              path.tween(to, from, options).then(() => {
                resolve();
              });
            });
          });
        }),
      );
      setTimeout(() => {
        this.tweenColors();
      }, 0);
    },

    getFillColor(item) {
      if (this.addPathForItemId) {
        if (Number(item.id) === Number(this.addPathForItemId)) {
          return new paper.Color(59 / 255, 152 / 255, 255 / 255, 0.59);
        }
        return new paper.Color(133 / 255, 146 / 255, 172 / 255, 0.59);
      }

      if (item.state === '已完成') {
        return new paper.Color(121 / 255, 198 / 255, 0, 0.59);
      }
      if (item.state === '进行中') {
        return new paper.Color(59 / 255, 152 / 255, 255 / 255, 0.59);
      }
      return new paper.Color(133 / 255, 146 / 255, 172 / 255, 0.59);
    },
    updateSelected(prev) {
      if (prev) {
        prev.selected = false;
      }
      if (this.selectedPath) {
        this.selectedPath.selected = true;
      }
    },
    handleAddPathForItemId() {
      this.$emit('bindPaths', this.exportPaths(true));
    },
    exportPaths(withFilters = true) {
      let list = this.paths;
      if (withFilters) {
        list = _.filter(this.paths, (path) => {
          return path.data.itemId === this.addPathForItemId;
        });
      }
      return _.map(list, (path) => {
        return path.exportJSON({
          asString: false,
        });
      });
    },
    updateLayerPaths() {
      if (!this.layerPaths) {
        this.layerPaths = new paper.Layer({ name: 'paths' });
      }
      this.layerPaths.removeChildren();
      this.layerPaths.bringToFront();
      this.layerPaths.activate();
    },
    updateTools() {
      if (this.addPathForItemId && this.isDrawMode) {
        this.canvasPan.deactivate();
        this.pathTool.activate();
        if (this.selectedPath) {
          _.forEach(
            _.filter(this.paths, (findPath) => findPath && findPath.id === this.selectedPath.id),
            (path) => {
              path.selected = true;
            },
          );
        }
        if (this.opsHintLast) {
          this.opsHint = this.opsHintLast;
          this.opsHintLast = '';
        }
      } else {
        this.noopTool.activate();
        if (this.selectedPath) {
          this.selectedPath.selected = false;
          this.selectedPath = null;
        }
        this.canvasPan.activate();
        if (this.opsHint) {
          this.opsHintLast = this.opsHint;
          this.opsHint = '使用滚轮缩放，拖动可移动画布';
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-image-viewer {
  @include ui-flex-col;
  margin-bottom: 60px;
  border: solid 1px #22445f;
  background: #fff;
  border-radius: 10px 10px 0 0;
}

.viewer-canvas {
  width: 100%;
  height: calc(100vh - 400px);
  min-height: 400px;
  cursor: move;

  &.drawing {
    cursor: crosshair;
  }
}

.viewer-image {
  display: none;
}

.rel {
  position: relative;
  width: 100%;
}

.canvas-toolbar {
  position: absolute;
  left: -1px;
  right: -1px;
  bottom: -40px;
  height: 40px;
  background: rgba(#22445f, 1);
  color: #fff;
  font-size: 12px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px rgba(#000, 0.8);
  border-radius: 0 0 10px 10px;
  @include ui-flex-row(space-between, center);
}

.canvas-toolbar-checkbox {
  color: rgba(#fff, 0.8);
}
</style>
