<template lang="pug">
el-dialog(
  :title="`${level || ''}详情`"
  :visible="visible"
  @update:visible="handleUpdateVisible"
  @close="handleClose"
)
  div(v-loading="loading")
    template(v-if="data && attributes")
      SspFormItem(
        v-for="field in attributes"
        :key="field.key"
        v-model="data[field.valueKey || field.key]"
        v-bind="field"
      )
      div.dialog-toolbar.mt
        el-button(
          type="primary"
          @click="handleSubmit"
        ) 提交
        el-link(
          type="danger"
          @click="handleDestroyItem"
        ) 删除
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable indent */
import { _ } from '@yishitec/web';
import withConfirm from '../mixins/withConfirm';

export default {
  name: 'ProgressItemEditor',
  props: {
    visible: { type: Boolean, default: false },
    id: { type: [String, Number], default: null },
    projectId: { type: [String, Number], default: null },
    level: { type: [String], default: null },
    parentId: { type: [String, Number], default: null },
  },
  emits: ['update:visible', 'close', 'refresh'],
  mixins: [withConfirm],
  data() {
    return {
      loading: false,
      data: null,
      optionsBlueprints: [],
      optionsBlueprintParts: [],
    };
  },
  computed: {
    isFirstLevel() {
      return this.level === '项目';
    },
    isSpecLevel() {
      return this.level === '工序';
    },
    isLastLevel() {
      return this.level === '工序日志';
    },
    attributes() {
      const vIf = (condition = false, arr = []) => {
        if (condition) return arr;
        return [];
      };
      return [
        ...vIf(!this.isFirstLevel, [
          {
            key: 'level',
            label: '层级',
            type: 'select',
            options: [
              {
                value: '单位工程',
                title: '单位工程',
              },
              {
                value: '工序组',
                title: '工序组',
              },
              {
                value: '工序',
                title: '工序',
              },
            ],
          },
          {
            key: 'title',
            label: '标题',
          },
        ]),
        ...vIf(!this.isLastLevel, [
          {
            key: 'xProgressBlueprints',
            label: '关联图示配置',
            type: 'multiselect',
            options: this.optionsBlueprints,
            placeholder: '默认从上级继承',
          },
        ]),
        ...vIf(this.isLastLevel, this.optionsBlueprintParts),
        ...vIf(this.isLastLevel, [
          {
            key: 'state',
            label: '状态',
            type: 'select',
            options: ['未开始', '进行中', '已完成'],
          },
        ]),
        ...vIf(this.isLastLevel || this.isSpecLevel, [
          {
            key: 'plannedStart',
            label: '计划开始时间',
            type: 'date',
          },
          {
            key: 'plannedEnd',
            label: '计划完成时间',
            type: 'date',
          },
          {
            key: 'startedAt',
            label: '实际开始时间',
            type: 'date',
          },
          {
            key: 'endedAt',
            label: '实际结束时间',
            type: 'date',
          },
        ]),
        {
          key: 'memo',
          label: '备注',
          type: 'textarea',
        },
        ...(this.isLastLevel
          ? [
              {
                key: 'image',
                label: '照片',
                type: 'file',
              },
            ]
          : []),
      ];
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const { level, parentId, projectId } = this;
      const res = await this.withLoading(
        this.api(`/api/progress/item${this.id ? '/' : ''}${this.id || ''}`, {
          level,
          parentId,
          projectId,
        }),
      );
      this.data = this.decode(res);
      this.optionsBlueprints = _.get(res, ['options', 'blueprints']) || [];
      this.optionsBlueprintParts = _.get(res, ['options', 'parts']);
      this.loading = false;
    },
    decode(res) {
      res.progress = Number(res.progress) * 100;
      res.aggProgress = Number(res.aggProgress) * 100;
      return res;
    },
    encode(res) {
      res.progress = Number(res.progress) / 100;
      res.aggProgress = Number(res.aggProgress) / 100;
      return res;
    },
    handleUpdateVisible(ev) {
      this.$emit('update:visible', ev);
    },
    handleClose() {
      this.$emit('close');
    },
    triggerRefresh() {
      this.$emit('refresh');
    },
    async handleSubmit() {
      this.loading = true;
      const res = await this.withLoading(
        this.api('/api/progress/save', {
          ..._.omit(this.data, ['xProjectProgresss', 'xProgressImages', 'xBreadcrumbs']),
          progress: this.data.state === '已完成' ? 1 : 0,
          projectId: this.projectId,
          parentId: this.parentId,
        }),
      );
      if (res) {
        if (res.level === '单位工程') {
          this.$router.push(`/progress/progress/${this.data.projectId}/${res.id || ''}?withEdit=0`);
        }
        this.triggerRefresh();
        this.handleClose();
      }
      this.loading = false;
    },
    async handleDestroyItem() {
      this.loading = true;
      await this.withConfirm({
        message:
          '删除记录将删除其备注、图示、子项目、进度日志等，并重新评估项目进度。是否确定删除此记录？',
        fn: async () => {
          await this.withLoading(this.api('/api/progress/destroy', { id: this.data.id }));
          this.$router.push(
            `/progress/progress/${this.data.projectId}/${this.data.parentId || ''}?withEdit=0`,
          );
          this.handleClose();
        },
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-toolbar {
  @include ui-flex-row(space-between, center);
}
</style>
