<template lang="pug">
div.progress-admin-header
  div.ph-left
    div.logo
      img(
        :src="require('../assets/images/progress/progress-logo.png')"
      ).logo-img
    div.app-title {{ appTitle }}
  div.ph-center
    router-link(
      :to="`/progress/`"
    ).header-link 开始
    router-link(
      :to="`/progress/projects`"
    ).header-link 项目管理
    router-link(
      :to="`/progress/members`"
      v-if="false"
    ).header-link 人员管理
    router-link(
      :to="`/progress/progress`"
    ).header-link 作业计划
    router-link(
      :to="`/progress/tasks`"
      v-if="false"
    ).header-link 任务中心
    router-link(
      v-if="false"
      :to="`/progress/stats`"
    ).header-link 数据统计
  div.ph-right
    div.user-icon
    div {{ userInfo.display_name }}
    ui-divider(direction="vertical")
    router-link(
      :to="`/admin/`"
    )
      el-link.header-toolbar-link 进入智慧工地
    img(
      :src="require('../assets/images/progress/logout.png')"
      @click="handleLogout"
    ).logout-icon
</template>

<script>
export default {
  name: 'ProgressAdminHeader',
  props: {},
  data() {
    return {
      appTitle: '智慧工地进度可视化管理平台',
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo || {};
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {},
    handleLogout() {
      this.$store.commit('logout');
      this.$router.push('/progress/login');
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-admin-header {
  background: #f5f7fd;
  height: 50px;
  box-sizing: border-box;
  @include ui-flex-row(space-between, center);
  padding-right: 16px;
}
.ph-left,
.ph-center,
.ph-right {
  @include ui-flex-row;
}
.logo-img {
  height: 50px;
  display: block;
}
.app-title {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bolder;
  color: #183b56;
}
.header-link {
  display: block;
  @include ui-flex-row;
  height: 50px;
  box-sizing: border-box;
  padding: 0 16px;
  font-size: 13px;
  color: #4b4b4b;
  cursor: pointer;
  &:hover {
    background: #183b56;
    color: #fff;
  }
}
.user-icon {
  width: 30px;
  height: 30px;
  background: url('../assets/images/progress/user.png') no-repeat center center;
  background-size: contain;
  margin-right: 10px;
}
.header-toolbar-link {
  margin: 0 10px;
}
.logout-icon {
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
</style>
