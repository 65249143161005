<script setup>
import { _, moment, numeral } from '@yishitec/web';
import { defineProps } from 'vue';

defineProps({
  record: Object,
  attribute: Object,
});

const formatDateTime = (value, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!value) {
    return '';
  }
  return moment(value).format(format);
};

const formatMoney = value => {
  if (!value) {
    return '';
  }
  return numeral(value).format('0.00');
};

const getDict = (value, dict) => {
  return _.get(dict, value, value || '');
};

const getDictColor = (value, dict) => {
  const colors = ['primary', 'warning', 'success', 'info'];
  const colorIndex = _.indexOf(_.keys(dict), value) % colors.length;
  return colors[colorIndex];
};

const getRelativeTime = value => {
  if (!value) {
    return '';
  }
  return moment(value).fromNow();
};

const getModelTitle = (record, attribute) => {
  const ret = record[attribute.name];
  if (attribute.model) {
    const item = _.get(record, `x${attribute.model}`);
    if (item) {
      return _.values(item).join(' ');
    }
  }
  return ret;
};
</script>

<template lang='pug'>
div(v-if="attribute.widget === 'datetime'") {{ formatDateTime(record[attribute.name]) }}
div(v-else-if="attribute.widget === 'date'") {{ formatDateTime(record[attribute.name], 'YYYY-MM-DD') }}
div(v-else-if="attribute.widget === 'month'") {{ formatDateTime(record[attribute.name], 'YYYY-MM') }}
div(v-else-if="attribute.widget === 'money'") {{ formatMoney(record[attribute.name]) }}
div(v-else-if="attribute.widget === 'switchRound'")
  el-switch(type="round" :value="record[attribute.name]" disabled)
div(v-else-if="['select', 'radios'].includes(attribute.widget) && attribute.dict")
  el-tag(:type="getDictColor(record[attribute.name], attribute.dict)") {{ getDict(record[attribute.name], attribute.dict) }}
div(v-else-if="attribute.widget === 'url'")
  a(target="_blank" :href="record[attribute.name]") {{ record[attribute.name] }}
div(v-else-if='attribute.widget === "model"') {{ getModelTitle(record, attribute) }}
div(v-else-if='attribute.widget === "slot"')
  slot(
    :value="record[attribute.name]"
    :row="record"
  )
div(v-else) {{ record[attribute.name] }}
</template>
