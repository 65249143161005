<template lang="pug">
div.screen-bg
  ScreenView(
    v-if="loaded.screenView"
    :class="{ hidden: active !== 'screenView' }"
    @switch="switchScreen"
    ref="screenView"
  ).screen
  DataCenterView(
    v-if="loaded.dataCenter"
    :class="{ hidden: active !== 'dataCenter' }"
    @switch="switchScreen"
    ref="dataCenter"
  ).screen
</template>

<script>
import ScreenView from './index.vue';
import DataCenterView from '../DataCenterScreen/index.vue';

export default {
  name: 'ScreensContainer',
  props: {
    screen: { type: String, default: 'screenView' },
  },
  components: {
    ScreenView,
    DataCenterView,
  },
  data() {
    return {
      loaded: {
        screenView: this.screen === 'screenView',
        dataCenter: this.screen === 'dataCenter',
      },
      active: this.screen,
    };
  },
  watch: {
    screen() {
      this.switchScreen(this.screen);
    },
  },
  methods: {
    switchScreen(screen) {
      if (!this.loaded[screen]) {
        this.$set(this.loaded, screen, true);
      }
      this.active = screen;
      setTimeout(() => {
        if (this.$refs[screen] && this.$refs[screen].afterMount) {
          this.$refs[screen].afterMount();
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.screen-bg {
  background: #000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.screen {
  transition: opacity 1s;
  opacity: 1;
  z-index: 10;

  &.hidden {
    opacity: 0;
    z-index: -1;
  }
}
</style>
