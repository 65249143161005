<template lang="pug">
div.stats
  .title {{ title }}
  .numbers
    span(v-if="before") {{ before }}
    span.primary {{n || '0'}}
    span(v-if="after") {{ after }}
</template>

<script>
export default {
  name: 'BoardStats',
  props: {
    title: String,
    before: String,
    n: [String, Number],
    after: String,
  },
};
</script>

<style lang="less" scoped>
.stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
  border: solid 1px #596973;
  border-radius: 5px;
  background: fadeout(#fff, 95);
}

.title {
  font-size: 12px;
  color: #9ba6ad;
}

.numbers {
  font-size: 14px;
  color: #fff;
}

.primary {
  font-size: 16px;
  color: #f6be43;
  display: inline-block;
  margin: 0 0.1em;
}
</style>
