<template lang="pug">
div
  div.p-trace
    div.p-container
      el-breadcrumb
        el-breadcrumb-item(
          to="/progress/projects"
        ) 项目管理
        el-breadcrumb-item(
          to="/progress/projects"
        ) 项目列表
        el-breadcrumb-item(
          v-if='projectId'
          :to='`/progress/projects/edit/${projectId}`'
        ) 项目详情
        el-breadcrumb-item(
          v-if='blueprintId'
          :to='`/progress/projects/blueprints/edit/${blueprintId}`'
        ) 图示配置
        el-breadcrumb-item 分段详情
  div.p-page
    div.p-card
      div.admin-form-page-wrapper
        AdminFormPage(
          :id='id'
          module='progress-blueprint-part'
          prefix='/progress/projects/part'
          apiPrefix='/api/v3/progress-blueprint-part'
          ref='form'
          @load='handleFormLoaded'
        )
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ProgressProjectBlueprintPartEdit',
  props: {
    id: { type: [String, Number], default: null },
  },
  data() {
    return {
      projectId: null,
      blueprintId: null,
    };
  },
  methods: {
    handleFormLoaded(res) {
      this.blueprintId = _.get(res, ['form', 'blueprintId']);
      this.projectId = _.get(res, ['form', 'xProgressBlueprint', 'projectId']);
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-form-page-wrapper {
  margin: -12px;
}
</style>
