<template lang="pug">
div
  el-checkbox(
    border
    v-model="selectedOnly"
  ).m-card 仅已选中的项
  SspTable(
    :value="vOpsDangerTypes"
    :columns="attributes"
    @handleRow="toggleRow"
    withToolbar
    :searchKeyword="searchKeyword"
  )
    template(v-slot:toolbarRight)
      Searchable(
        v-model="searchKeyword"
      )
    template(v-slot:check="{ row }")
      el-checkbox(
        :value="value.includes(row.id)"
        @change="toggleRow(row)"
      )
</template>

<script>
import { _ } from '@yishitec/web';

export default {
  name: 'SelectOpsDangerType',
  props: {
    value: { type: Array, default: () => [] },
  },
  emits: ['input'],
  data() {
    return {
      opsDangerTypes: [],
      attributes: [],
      selectedOnly: false,
      searchKeyword: '',
    };
  },
  computed: {
    vOpsDangerTypes() {
      if (this.selectedOnly) {
        return _.filter(this.opsDangerTypes, (item) => this.value.includes(item.id));
      }
      return this.opsDangerTypes;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loadOpsDangerTypes();
    },
    async loadOpsDangerTypes() {
      const res = await this.withLoading(this.api('/api/ops-danger-types/list', {}));
      const { list = [], attributes } = res || {};
      this.opsDangerTypes = list;
      this.attributes = [
        {
          name: 'check',
          title: '选择',
          width: 80,
        },
        ..._.filter(attributes, (item) => {
          return !['createdAt', 'updatedAt'].includes(item.name);
        }),
      ];
    },
    toggleRow(row) {
      if (this.value.includes(row.id)) {
        this.$emit(
          'input',
          _.filter(this.value, (item) => item !== row.id),
        );
        return;
      }
      this.$emit('input', _.uniq([...this.value, row.id]));
    },
  },
};
</script>

<style lang="scss" scoped></style>
