import { render, staticRenderFns } from "./AppPie.vue?vue&type=template&id=21715d88&scoped=true&lang=pug"
import script from "./AppPie.vue?vue&type=script&lang=js"
export * from "./AppPie.vue?vue&type=script&lang=js"
import style0 from "./AppPie.vue?vue&type=style&index=0&id=21715d88&prod&lang=less&scoped=true"
import style1 from "./AppPie.vue?vue&type=style&index=1&id=21715d88&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21715d88",
  null
  
)

export default component.exports