<template lang="pug">
div.side-cards
  SideCard(
    v-for="item in cards"
    :key="item.key"
    v-bind="item"
    @sub-window="$emit('sub-window', $event)"
  ).item
</template>

<script>
import SideCard from './SideCard.vue';

export default {
  name: 'SideCards',
  props: {
    cards: Array,
  },
  emits: ['sub-window'],
  components: { SideCard },
};
</script>

<style lang="scss" scoped>
.item {
  margin: 15px 0;
}
</style>
