<template lang="pug">
div(v-if="value")
  TreeAggregationItem(
    v-for="(tree, treeNodeIndex) in value",
    :key="treeNodeIndex"
    :title="tree.title",
    :stats="getStatsText(tree)",
    :empty="!tree.children"
  )
    template(v-if="tree.children")
      StatsScreenTree(:value="tree.children")
  .empty(v-if="!value.length") 暂无数据
</template>

<script>
import TreeAggregationItem from './TreeAggregationItem.vue';

export default {
  name: 'StatsScreenTree',
  props: {
    value: Array,
  },
  components: {
    TreeAggregationItem,
  },
  methods: {
    getStatsText(treeNode) {
      const { unit, total, active, forceActive } = treeNode;
      if (!active && !forceActive) {
        return `${total || ''}${unit || ''}`;
      }
      if (forceActive) {
        return `${active || 0}/${total || 0}${unit || ''}`;
      }
      return `${active || ''}/${total || ''}${unit || ''}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  margin: 5px 0;
  opacity: 0.5;
}
</style>
