<template>
  <div>
    <ui-stage v-if="data">
      <ui-tabs v-model="tab" type="border-card">
        <ui-tab-pane name="main" label="编辑">
          <ui-admin-form
            :compact="compact"
            :readonly="readonly || !canProcess"
            :fields="attributes"
            :model="data"
            :module-url="moduleUrl"
            :with-delete="false"
            @submit="handleSubmit"
          />
        </ui-tab-pane>
      </ui-tabs>
    </ui-stage>
  </div>
</template>

<script>
import { _ } from '@yishitec/web';
import { adminEdit } from '@yishitec/web/mixins';
import permissions from '../../../mixins/permissions';

/** @typedef {AdminEditView} This */

export default {
  name: 'Edit',
  mixins: [adminEdit, permissions],
  data() {
    return {
      withCustomFields: false,
    };
  },
  computed: {
    canProcess() {
      /** @type {This} */
      const that = this;
      const projectId = _.get(this.data, 'project_id');
      return (
        that.amI(projectId, '监理单位', '管理人员', true) ||
        that.amI(projectId, '监理单位', '专项管理员', true)
      );
    },
  },
};
</script>
