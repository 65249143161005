<script setup>
// eslint-disable-next-line import/first
import _ from 'lodash';
import { defineProps, defineEmits, ref } from 'vue';

const props = defineProps({
  value: Array,
  options: Array,
  foldOptions: Array,
  foldHint: String,
});
const emit = defineEmits(['input']);

const handleClick = (value) => {
  const isSelected = props.value.includes(value);
  if (isSelected) {
    emit(
      'input',
      props.value.filter((v) => v !== value),
    );
  } else {
    emit('input', [...props.value, value]);
  }
};

const isInitFold = () => {
  return Boolean(_.some(props.foldOptions, (item) => props.value.includes(item.value)));
};
const displayFold = ref(isInitFold());
</script>

<script>
export default { name: 'SspCheckboxButtons' };
</script>

<template lang="pug">
div(v-if='(options && options.length) || (foldOptions && foldOptions.length)').checkbox-buttons
  el-button(
    v-for="option in options"
    :key="option.value"
    size="small"
    :type="value.includes(option.value) ? 'primary' : 'default'"
    @click="handleClick(option.value)"
  ).item {{ option.title }}
  template(v-if='foldOptions && foldOptions.length')
    el-divider(
      content-position='left'
      @click='displayFold = !displayFold'
    ).divider {{ foldHint || '展开' }}
    SspCheckboxButtons(
      v-if='displayFold'
      :value='value'
      :options='foldOptions'
      @input='emit("input", $event)'
    )
div(v-else).hint 暂无可选项
</template>

<style lang="scss" scoped>
.item {
  display: inline-block;
  margin: 4px 8px 4px 0;
}

.hint {
  font-size: 12px;
  color: #ccc;
  line-height: 40px;
}

.divider {
  opacity: 0.5;
  cursor: pointer;
}
</style>
