<template lang="pug">
div.column
  .bullet.row
    .item.active {{ n || '0' }}
    .item {{ total || '0' }}
  .title {{ title }}
</template>

<script>
export default {
  name: 'BulletNumbers',
  props: {
    n: Number,
    total: Number,
    title: String,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bullet {
  background: #2d373d;
  border-radius: 1000px;
  padding: 3px;
  box-sizing: border-box;
  color: #fff;
  font-size: 12px;
}

.title {
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
}

.item {
  color: #fff;
  font-size: 15px;
  padding: 4px 14px;

  &.active {
    background: #906d22;
    border-radius: 1000px;
    padding: 3px 18px;
  }
}
</style>
