<template>
  <div class="mt">
    <ui-flex row>
      <ui-flex class="mr options-box">
        <h3>已选{{ title }}</h3>
        <app-links-list :data="selected" with-search @input="handleDeleteItem"></app-links-list>
      </ui-flex>
      <ui-flex class="options-box" v-if="!readonly">
        <h3>← 未选{{ title }}</h3>
        <app-links-list :data="altOptions" with-search @input="handleAddItem"></app-links-list>
      </ui-flex>
    </ui-flex>
  </div>
</template>

<script>
import { _ } from '@yishitec/web';
import AppLinksList from './AppLinksList.vue';

export default {
  name: 'UiTransferList',
  props: {
    value: { type: String, default: '' }, // v-model
    options: { type: Array, default: () => [] },
    title: { type: String, default: '项' },
    readonly: { type: Boolean, default: false },
  },
  emits: ['input'],
  components: { AppLinksList },
  computed: {
    vValue: {
      get() {
        return JSON.parse(this.value || '[]');
      },
      set(value) {
        this.$emit('input', JSON.stringify(value));
      },
    },
    selected() {
      return _.filter(this.options, (i) => this.vValue.includes(i.key));
    },
    altOptions() {
      return _.filter(this.options, (i) => !this.vValue.includes(i.key));
    },
  },
  methods: {
    handleAddItem(id) {
      if (this.readonly) return;
      this.vValue = [...this.vValue, id];
    },
    handleDeleteItem(id) {
      if (this.readonly) return;
      this.vValue = _.filter(this.vValue, (item) => item !== id);
    },
  },
};
</script>

<style lang="scss" scoped>
.options-box {
  border: solid 1px #ddd;
  border-radius: 3px;
  padding: 10px;
}
</style>
