<template>
  <ui-with-error-view :error="error">
    <ui-stage v-if="data">
      <ui-tabs v-model="tab" type="border-card">
        <ui-tab-pane name="main" label="编辑">
          <ui-section-title> 项目资料</ui-section-title>
          <ui-admin-form
            :compact="compact"
            :readonly="readonly || !canCreate"
            :fields="attributes"
            :model="data"
            :module-url="moduleUrl"
            :with-delete="false"
            :with-save="false"
          >
            <template slot="field__withQrDailyGrants" slot-scope="{ value, onInput, readonly }">
              <template v-if="readonly"> {{ value ? '是' : '否' }}</template>
              <ui-data-select :options="boolSelectOptions" :value="value" @input="onInput" />
            </template>

            <template slot="field__withFaceDailyGrants" slot-scope="{ value, onInput, readonly }">
              <template v-if="readonly"> {{ value ? '是' : '否' }}</template>
              <ui-data-select :options="boolSelectOptions" :value="value" @input="onInput" />
            </template>

            <template slot="field__withHealthQrOcr" slot-scope="{ value, onInput, readonly }">
              <template v-if="readonly"> {{ value ? '是' : '否' }}</template>
              <ui-data-select :options="boolSelectOptions" :value="value" @input="onInput" />
            </template>

            <template slot="field__withProgressModule" slot-scope="{ value, onInput, readonly }">
              <template v-if="readonly"> {{ value ? '是' : '否' }}</template>
              <ui-data-select :options="boolSelectOptions" :value="value" @input="onInput" />
            </template>
            <template slot="field__withQrDailyGrants" slot-scope="{ value, onInput, readonly }">
              <template v-if="readonly"> {{ value ? '是' : '否' }}</template>
              <ui-data-select :options="boolSelectOptions" :value="value" @input="onInput" />
            </template>
            <template slot="field__withFaceDailyGrants" slot-scope="{ value, onInput, readonly }">
              <template v-if="readonly"> {{ value ? '是' : '否' }}</template>
              <ui-data-select :options="boolSelectOptions" :value="value" @input="onInput" />
            </template>
            <template slot="field__withHealthQrOcr" slot-scope="{ value, onInput, readonly }">
              <template v-if="readonly"> {{ value ? '是' : '否' }}</template>
              <ui-data-select :options="boolSelectOptions" :value="value" @input="onInput" />
            </template>
            <template slot="field__enableEBadge" slot-scope="{ value, onInput, readonly }">
              <template v-if="readonly"> {{ value ? '是' : '否' }}</template>
              <ui-data-select :options="boolSelectOptions" :value="value" @input="onInput" />
            </template>
            <template slot="field__enablePreviewIdCard" slot-scope="{ value, onInput, readonly }">
              <template v-if="readonly"> {{ value ? '是' : '否' }}</template>
              <ui-data-select :options="boolSelectOptions" :value="value" @input="onInput" />
            </template>
            <template slot="field__fence" slot-scope="{ value, onInput, readonly }">
              <app-map-editor :value="value" @input="onInput" :readonly="readonly" />
            </template>
            <template slot="field__shortCode">
              <div v-if="data && data.shortCode && guestSignupLink">
                <vue-qrious :size="200" :value="guestSignupLink" v-if="guestSignupLink" />
                <div>
                  <a :href="guestSignupLink" target="_blank">
                    <el-link type="primary">{{ guestSignupLink }}</el-link>
                  </a>
                </div>
              </div>
            </template>
          </ui-admin-form>
          <div v-for="projectCompanyType in projectCompanyTypes" :key="projectCompanyType">
            <ui-section-title>负责单位信息 - {{ projectCompanyType }}： </ui-section-title>
            <ui-admin-form
              :compact="compact"
              :readonly="readonly || !canCreate"
              :fields="attributesProjectCompanies"
              :model="projectCompanies[projectCompanyType]"
              :with-delete="false"
              :with-save="false"
            />
          </div>
          <div v-for="projectFileType in projectFileTypes" :key="projectFileType">
            <ui-section-title>资质文件上传 - {{ projectFileType }}： </ui-section-title>
            <ui-admin-form
              :compact="compact"
              :readonly="readonly || !canCreate"
              :fields="attributesProjectFiles"
              :model="projectFiles[projectFileType]"
              :with-delete="false"
              :with-save="false"
            >
              <template slot="field__file" slot-scope="{ value, onInput, readonly }">
                <ui-uploads :value="value" @input="onInput" :readonly="readonly" />
              </template>
            </ui-admin-form>
          </div>
          <div class="text-center mt">
            <ui-button @click="handleSubmit" type="primary">保存</ui-button>
          </div>
        </ui-tab-pane>
        <ui-tab-pane name="plugins" label="项目能力">
          <SspEmptyCard v-if="!xProjectPlugins || !xProjectPlugins.length" title="未关联项目能力" />
          <div style="min-height: 300px" v-loading="pluginsLoading" v-else>
            <div v-for="plugin in xProjectPlugins" :key="plugin.id">
              <ui-section-title>
                {{ plugin.pluginTitle }}
              </ui-section-title>
              <div>
                启用状态：
                <el-switch
                  :value="plugin.enabled"
                  @input="(switchValue) => handleSwitchPluginEnabled(plugin, switchValue)"
                />
              </div>
            </div>
          </div>
        </ui-tab-pane>
      </ui-tabs>
    </ui-stage>
  </ui-with-error-view>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { _ } from '@yishitec/web';
import { adminEdit } from '@yishitec/web/mixins';
import permissions from '../../../mixins/permissions';
import AppMapEditor from '../../../components/AppMapEditor.vue';

export default {
  name: 'Edit',
  components: { AppMapEditor },
  mixins: [adminEdit, permissions],
  data() {
    const projectCompanyTypes = ['施工单位', '监理单位', '设计单位', '勘察单位'];
    const projectFileTypes = [
      '施工许可证',
      '前期准备工作情况 - 大临建设',
      '前期准备工作情况 - 施工腾地',
      '管线监护绿卡',
    ];
    const boolSelectOptions = [
      {
        value: true,
        title: '是',
      },
      {
        value: null,
        title: '否',
      },
    ];
    return {
      withCustomFields: false,
      error: '',
      projectCompanyTypes,
      projectFileTypes,
      attributesProjectCompanies: [],
      attributesProjectFiles: [],
      projectCompanies: {},
      projectFiles: {},
      boolSelectOptions,
      xProjectPlugins: null,
      pluginsLoading: false,
    };
  },
  computed: {
    ...mapState(['currentProjects']),
    canCreate() {
      return this.amI(null, '建设单位', '管理人员', true) || this.amI(null, null, '平台管理员');
    },
    guestSignupLink() {
      if (!this.model) return '';
      return `https://www.smartconstructioncontroller.com/l/guest/${this.data.shortCode}`;
    },
  },
  mounted() {
    if (!this.id && !this.canCreate) {
      this.error = '没有权限';
    }
  },
  methods: {
    ...mapMutations(['setCurrentProjects']),
    vAfterSubmit(res) {
      console.log('after save', res);
      if (!this.data.id) {
        const projectId = res.id;
        this.setCurrentProjects(_.uniq([...this.currentProjects, projectId]));
      }
    },
    async handleFetchDataRes(res) {
      const prepareProjectCompanies = () => {
        const { attributes, newItem, list } = _.get(res, ['extra', 'projectCompanies']);
        this.attributesProjectCompanies = _.filter(
          attributes,
          (field) => !['id', 'createdAt', 'updatedAt'].includes(field.name),
        );
        this.newProjectCompany = newItem;
        this.projectCompanyTypes.forEach((type) => {
          const findData = _.find(list, (item) => item.type === type);
          if (findData) {
            this.projectCompanies[type] = findData;
          } else {
            this.projectCompanies[type] = {
              ..._.cloneDeep(newItem),
              type,
            };
          }
        });
        this.projectCompanies = { ...this.projectCompanies };
      };
      prepareProjectCompanies();

      const prepareProjectFiles = () => {
        const { attributes, newItem, list } = _.get(res, ['extra', 'projectFiles']);
        this.attributesProjectFiles = _.filter(
          attributes,
          (field) => !['id', 'createdAt', 'updatedAt'].includes(field.name),
        );
        this.projectFileTypes.forEach((type) => {
          const findData = _.find(list, (item) => item.type === type);
          if (findData) {
            this.projectFiles[type] = findData;
          } else {
            this.projectFiles[type] = {
              ..._.cloneDeep(newItem),
              type,
            };
          }
        });
        this.projectFiles = { ...this.projectFiles };
      };
      prepareProjectFiles();

      this.xProjectPlugins = _.get(res, ['extra', 'xProjectPlugins']) || [];
    },
    async beforeHandleSubmit(data) {
      const { projectCompanies, projectFiles } = this;
      return {
        data,
        projectCompanies,
        projectFiles,
      };
    },
    async handleSwitchPluginEnabled(plugin, value) {
      this.pluginsLoading = true;
      const projectId = this.data.id;
      const pluginId = plugin.id;
      const boolValue = (i) => {
        return Boolean(i || false);
      };
      let res = null;
      if (boolValue(plugin.enabled) !== boolValue(value)) {
        if (value) {
          res = await this.withLoading(
            this.api(`/api/projects/${projectId}/enable-plugin/${pluginId}`),
          );
        } else {
          res = await this.withLoading(
            this.api(`/api/projects/${projectId}/disable-plugin/${pluginId}`),
          );
        }
      }
      if (res) {
        _.extend(plugin, res);
      }
      this.pluginsLoading = false;
    },
  },
};
</script>
