<template>
  <div class="user-count-view">
    <ui-page-header title="各单位用户数统计" />
    <ui-stage v-loading="loading">
      <ui-admin-table
        :attributes="reportAttributes"
        :data="data"
        :with-create="false"
        :with-actions="false"
        :pagination-method="null"
        max-height="calc(100vh - 300px)"
      >
        <template v-slot:toolbar>
          <ui-flex row j-start a-center>
            <ui-date-picker v-model="today" :picker-options="pickerOptions" />
            <ui-dropdown split-button :hide-timeout="1000000" class="ml">
              大屏展示
              <el-dropdown-menu slot="dropdown">
                <router-link to="/screen" target="_blank" style="display: block">
                  <el-dropdown-item>完整版</el-dropdown-item>
                </router-link>
                <router-link to="/screen?lite=1" target="_blank" style="display: block">
                  <el-dropdown-item>精简版</el-dropdown-item>
                </router-link>
              </el-dropdown-menu>
            </ui-dropdown>
          </ui-flex>
        </template>
        <template v-slot:column_title="{ row }">
          <router-link
            :to="`/admin/reports/signin?projectId=${row.id}&startAt=${today}&endAt=${today}`"
          >
            <ui-link>
              {{ row.title }}
            </ui-link>
          </router-link>
        </template>
      </ui-admin-table>
    </ui-stage>
  </div>
</template>

<script>
import { moment } from '@yishitec/web';

export default {
  name: 'UsersCountReport',
  data() {
    const pickerOptions = {
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
      shortcuts: [
        {
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', moment().startOf('day').valueOf());
          },
        },
        {
          text: '昨天',
          onClick(picker) {
            picker.$emit('pick', moment().subtract(1, 'day').startOf('day').valueOf());
          },
        },
      ],
    };
    return {
      reportAttributes: [],
      data: [],
      today: moment().format('YYYY-MM-DD'),
      loading: false,
      pickerOptions,
    };
  },
  watch: {
    today() {
      this.loadData();
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const { attributes, data } = await this.withLoading(
        this.api('/api/reports/users-count', {
          date: moment(this.today).format('YYYY-MM-DD'),
        }),
      );
      this.reportAttributes = attributes;
      this.data = data;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-count-view {
  ::v-deep {
    .el-table--fluid-height.el-table--scrollable-y {
      @include ui-flex-col(flex-start, stretch);
      .el-table__header-wrapper {
        flex: 0 0 auto;
      }
    }
  }
}
</style>
