<template lang="pug">
.info-window-card
  .info-window-card-title(:class="{ primary }")
    span {{ title || titleEmpty || '/' }}
    .info-window-card-body.number {{ number || '&nbsp;' }}
  .info-window-card-body(v-if="!number || withSlots")
    slot
</template>

<script>
export default {
  name: 'InfoWindowCard',
  props: {
    title: String,
    titleEmpty: String,
    number: { type: [String, Number] },
    primary: Boolean,
    withSlots: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.info-window-card {
  margin: 10px 4px 10px;
  box-sizing: border-box;
  border: solid 1px rgba(#fff, 0.2);
  border-radius: 5px;
  background: linear-gradient(328deg, rgba(255, 255, 255, 0.06), #000);
  color: #fff;
  min-width: 10em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 10px;
}
.info-window-card-title {
  color: rgba(#fff, 0.9);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &.primary {
    color: #637089;
    font-weight: bolder;
    font-size: 1.6em;
  }
}
.info-window-card-body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  & > div {
    flex: 1 0 1px;
    min-width: 8em;
  }
  &.number {
    font-weight: bolder;
    font-size: 1.4em;
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
</style>
