<template lang='pug'>
  Board(
    title="人员管理"
    :hint="`交底待处理 ${alerts} 人员待审核 ${pending}`"
    icon="/images/data-center-screen/boards/icon-members.svg"
  )
    BoardMemberCheckins(
      :projects="projects"
      :history="history"
      @sub-window='$emit("sub-window", $event)'
    ).col.expand
    Divider
    BoardMemberProfiles(
      :projects="projects"
      @sub-window='$emit("sub-window", $event)'
    ).col
</template>

<script>
import Board from '../Board.vue';
import BoardMemberCheckins from './BoardMemberCheckins.vue';
import BoardMemberProfiles from './BoardMemberProfiles.vue';
import Divider from '../Divider.vue';
import { getSum } from '../genCards';

export default {
  name: 'BoardCams',
  props: {
    projects: Array,
    history: Array,
  },
  emits: ['sub-window'],
  components: {
    Board,
    BoardMemberCheckins,
    Divider,
    BoardMemberProfiles,
  },
  computed: {
    alerts() {
      return getSum(this.projects, 'membersAlerts');
    },
    pending() {
      return getSum(this.projects, 'membersPending');
    },
  },
};
</script>

<style lang='less' scoped>
.col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 0 0 auto;

  &.expand {
    flex: 1 0 1px;
  }
}
</style>
