<template lang='pug'>
  Board(
    title="危险源管理"
    :hint="`待处理 ${pending}`"
    icon="/images/data-center-screen/boards/icon-danger-zones.svg"
    sub-window='dangerZones'
    @sub-window="$emit('sub-window', $event)"
  )
    .row.mb
      BulletNumbers(
        title="今日作业危险源"
        :n="dangerZonesToday"
        :total="dangerZonesTotal"
      )
      BulletNumbers(
        title="设备"
        :n="devicesToday"
        :total="devicesTotal"
      )
      BulletNumbers(
        title="静态危险源"
        :n="staticDangerZonesToday"
        :total="staticDangerZonesTotal"
      )
    Legends(
      :list="legends"
    )
    LineChart(
      :list="history"
      :legends="legends"
      x="date"
      area
    )
</template>

<script>
import Board from '../Board.vue';
import BulletNumbers from '../Charts/BulletNumbers.vue';
import Legends from '../Legends.vue';
import LineChart from '../Charts/LineChart.vue';
import { getSum } from '../genCards';

export default {
  name: 'BoardDangerZones',
  props: {
    projects: Array,
    history: Array,
  },
  components: {
    Board,
    BulletNumbers,
    Legends,
    LineChart,
  },
  data() {
    return {
      legends: [
        {
          color: '#73F39A',
          title: '作业危险源',
          name: 'dangerZonesToday',
        },
        {
          color: '#7396F3',
          title: '作业设备',
          name: 'devicesToday',
        },
        {
          color: '#C75C39',
          title: '待处理事件',
          name: 'alertsTodo',
        },
      ],
    };
  },
  computed: {
    pending() {
      return getSum(this.projects, 'dangerZonesAlerts');
    },
    devicesToday() {
      return this.sum('devicesToday');
    },
    devicesTotal() {
      return this.sum('devicesTotal');
    },
    staticDangerZonesToday() {
      return this.sum('staticDangerZonesToday');
    },
    staticDangerZonesTotal() {
      return this.sum('staticDangerZonesTotal');
    },
    dangerZonesToday() {
      return Number(this.devicesToday || 0) + Number(this.staticDangerZonesToday || 0);
    },
    dangerZonesTotal() {
      return Number(this.devicesTotal || 0) + Number(this.staticDangerZonesTotal || 0);
    },
  },
  methods: {
    sum(name) {
      return getSum(this.projects, name);
    },
  },
};
</script>

<style lang='scss' scoped>
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}

.mb {
  margin-bottom: 15px;
}
</style>
