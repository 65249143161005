<template>
  <div class="report-grids-m3">
    <div class="grid-1" v-if="children[0]">
      <ReportComponent :item="children[0]" :reportsData="reportsData" />
    </div>
    <div class="grids-v2">
      <div class="grid-2" v-if="children[1]">
        <ReportComponent :item="children[1]" :reportsData="reportsData" />
      </div>
      <div class="grid-3" v-if="children[2]">
        <ReportComponent :item="children[2]" :reportsData="reportsData" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportGridsM3',
  props: {
    children: Array,
    reportsData: Object,
  },
};
</script>

<style lang="scss">
.report-grids-m3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  & + & {
    margin-top: 10px;
  }
  .grid-1 {
    flex: 2 0 1px;
    margin-right: 10px;
    box-sizing: border-box;
    box-shadow: 0 1px 5px rgba(#000, 0.1);
    border-radius: 5px;
  }
  .grids-v2 {
    flex: 3 0 1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
  .grid-2 {
    margin-bottom: 10px;
    flex: 1 0 1px;
  }
  .grid-3 {
    flex: 1 0 1px;
  }
}
</style>
