var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ui-page-header',{attrs:{"title":"施工报表"}}),_c('ui-stage',[_c('ui-alert',{staticClass:"mb",attrs:{"type":"success","closable":false}},[_c('ui-flex',{attrs:{"row":"","a-center":""}},[_c('span',{staticClass:"filter-span"},[_vm._v("项目：")]),_c('ui-admin-model-picker',_vm._b({model:{value:(_vm.projectId),callback:function ($$v) {_vm.projectId=$$v},expression:"projectId"}},'ui-admin-model-picker',_vm.projectsOptions,false)),_c('span',{staticClass:"filter-span"},[_vm._v("起止时间：")]),_c('ui-date-picker',{model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('ui-button',{staticClass:"filter-span",on:{"click":_vm.loadData}},[_vm._v("查询")]),_c('a',{staticClass:"filter-span",attrs:{"href":_vm.reportHtml
              ? `/api/reports/daily-report-2-pdf?projectId=${_vm.projectId}&date=${_vm.dateStr}`
              : 'javascript:void(0)',"target":_vm.reportHtml ? '_blank' : '_self'}},[_c('el-button',{attrs:{"disabled":!_vm.reportHtml,"type":"danger"}},[_vm._v("下载PDF")])],1),_c('a',{staticClass:"filter-span",attrs:{"href":_vm.reportHtml
              ? `/api/reports/daily-report-2-pdf?projectId=${_vm.projectId}&date=${_vm.dateStr}&png=1`
              : 'javascript:void(0)',"target":_vm.reportHtml ? '_blank' : '_self'}},[_c('el-button',{attrs:{"disabled":!_vm.reportHtml,"type":"primary","plain":""}},[_vm._v("下载图片")])],1),_c('a',{staticClass:"filter-span",attrs:{"href":_vm.reportHtml
              ? `/api/reports/daily-report-2-pdf?projectId=${_vm.projectId}&date=${_vm.dateStr}&v2=0`
              : 'javascript:void(0)',"target":_vm.reportHtml ? '_blank' : '_self'}},[_c('el-button',{attrs:{"disabled":!_vm.reportHtml,"type":"danger","plain":""}},[_vm._v("PDF(旧版)")])],1)],1)],1),_c('div',{staticClass:"report-stage"},[(_vm.reportHtml)?_c('div',{staticClass:"report-paper",domProps:{"innerHTML":_vm._s(_vm.reportHtml)}}):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }