import { render, staticRenderFns } from "./Report.vue?vue&type=template&id=48514484&scoped=true&lang=pug"
import script from "./Report.vue?vue&type=script&lang=js"
export * from "./Report.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48514484",
  null
  
)

export default component.exports