<template>
  <div class="report-section">
    <div class="section-title" v-if="title">
      {{ title }}
    </div>
    <div class="section-body" v-if="children && children.length">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportSection',
  props: {
    title: {
      type: String,
      default: '',
    },
    children: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.report-section {
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(#000, 0.2);
  padding: 20px;
  border-radius: 5px;
  & + & {
    margin-top: 10px;
  }
}
.section-title {
  font-size: 1.6em;
  font-weight: bolder;
  color: #1e3258;
}
.section-body {
  margin-top: 20px;
}
</style>
