<template>
  <ui-container>
    <div class="page-centered">
      <p>
        由于全球SSL R3根证书过期，Android
        8.0系统以下手机可能无法正常访问系统。请使用手机浏览器手动安装以下根证书到手机系统。
      </p>
      <div class="btn-area">
        <a href="/files/isrg-root.cer">
          <el-button type="primary">下载根证书文件</el-button>
        </a>
      </div>
      <div class="btn-area">
        <vue-qrious :value="qr" :size="200" />
      </div>
    </div>
  </ui-container>
</template>

<script>
export default {
  name: 'SslRoot',
  computed: {
    qr() {
      return window.location.href;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-centered {
  margin: 60px 16px;
  text-align: center;
}
.btn-area {
  margin: 30px 0;
}
</style>
