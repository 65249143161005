var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ui-stage',[_c('ui-flex',{attrs:{"row":""}},[_c('ui-flex',{staticClass:"mr br pr",staticStyle:{"flex":"0 0 110px"}},[_c('app-links-list',{attrs:{"data":_vm.projects,"with-search":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('ui-flex',[(_vm.data)?_c('ui-admin-table',{ref:"table",attrs:{"attributes":_vm.attributes,"data":_vm.data,"module-url":_vm.moduleUrl,"pagination-method":"front-end","with-create":false,"always-display-pagination":""},scopedSlots:_vm._u([{key:"column__adminActions",fn:function(scope){return _c('div',{},[_c('ui-button-group',[_c('router-link',{attrs:{"to":`${_vm.moduleUrl}/edit/${scope.row.id}?pageInList=${_vm.$refs.table.currentPage}&listPageSize=${_vm.$refs.table.pageSize}`}},[_c('ui-button',{attrs:{"size":"mini","type":"primary","icon":"el-icon-edit"}},[_vm._v("编辑")])],1),(_vm.canEditRow(scope.row))?_c('router-link',{attrs:{"to":`${_vm.moduleUrl}/delete/${scope.row.id}?backUrl=${encodeURIComponent(
                  _vm.$route.fullPath,
                )}`}},[_c('ui-button',{attrs:{"size":"mini","type":"info","icon":"el-icon-delete"}},[_vm._v("删除")])],1):_vm._e()],1)],1)}}],null,false,1709992242)},[_c('template',{slot:"toolbar"},[(_vm.canManageSuperAdmin)?_c('div',{staticClass:"mb"},[_c('router-link',{staticClass:"mr",attrs:{"to":`/admin/users/add?admintype=${encodeURIComponent('平台管理员')}`}},[_c('ui-button',{attrs:{"type":"primary","icon":"el-icon-edit-outline"}},[_vm._v("创建平台管理员")])],1)],1):_vm._e(),(_vm.canManageJsAdmin)?_c('div',{staticClass:"mb"},[_c('router-link',{staticClass:"mr",attrs:{"to":`/admin/users/add?project_id=${encodeURIComponent(
                  _vm.filter,
                )}&company_type=${encodeURIComponent('建设单位')}&admintype=${encodeURIComponent(
                  '管理人员',
                )}`}},[_c('ui-button',{attrs:{"type":"primary","icon":"el-icon-edit-outline"}},[_vm._v("创建建设单位管理人员 ")])],1),_c('router-link',{staticClass:"mr",attrs:{"to":`/admin/users/add-js?project_id=${encodeURIComponent(_vm.filter)}`}},[_c('ui-button',{attrs:{"type":"primary","icon":"el-icon-edit-outline"}},[_vm._v(" 添加建设单位管理人员 ")])],1)],1):_vm._e(),(_vm.canManageJsAdminSameCompany)?_vm._l((_vm.companyNamesJs),function(company,index){return _c('div',{key:`js1-${index}`,staticClass:"mb"},[_c('router-link',{staticClass:"mr",attrs:{"to":`/admin/users/add?project_id=${encodeURIComponent(
                    _vm.filter,
                  )}&company=${encodeURIComponent(company)}&company_type=${encodeURIComponent(
                    '建设单位',
                  )}&admintype=${encodeURIComponent('管理人员')}`}},[_c('ui-button',{attrs:{"type":"primary","icon":"el-icon-edit-outline"}},[_vm._v("创建建设单位管理人员："+_vm._s(company)+" ")])],1)],1)}):_vm._e(),(_vm.canManageJsAdminNoProject)?_c('div',{staticClass:"mb"},[_c('router-link',{staticClass:"mr",attrs:{"to":`/admin/users/add?company_type=${encodeURIComponent(
                  '建设单位',
                )}&admintype=${encodeURIComponent('管理人员')}`}},[_c('ui-button',{attrs:{"type":"primary","icon":"el-icon-edit-outline"}},[_vm._v("创建建设单位管理人员 ")])],1)],1):_vm._e(),(_vm.canManageJsAdminNoProjectSameCompany)?_vm._l((_vm.companyNamesJs),function(company,index){return _c('div',{key:`js2-${index}`,staticClass:"mb"},[_c('router-link',{staticClass:"mr",attrs:{"to":`/admin/users/add?company=${encodeURIComponent(
                    company,
                  )}&company_type=${encodeURIComponent(
                    '建设单位',
                  )}&admintype=${encodeURIComponent('管理人员')}`}},[_c('ui-button',{attrs:{"type":"primary","icon":"el-icon-edit-outline"}},[_vm._v("创建建设单位管理人员："+_vm._s(company)+" ")])],1)],1)}):_vm._e(),(_vm.canManageJsProjectOps)?_vm._l((_vm.companyNamesJs),function(company,index){return _c('div',{key:`js2-${index}`,staticClass:"mb"},[_c('router-link',{staticClass:"mr",attrs:{"to":`/admin/users/add?project_id=${encodeURIComponent(
                    _vm.filter,
                  )}&company=${encodeURIComponent(company)}&company_type=${encodeURIComponent(
                    '建设单位',
                  )}&admintype=${encodeURIComponent('专项管理员')}`}},[_c('ui-button',{attrs:{"type":"primary","icon":"el-icon-edit-outline"}},[_vm._v("创建建设单位专项管理员："+_vm._s(company)+" ")])],1)],1)}):_vm._e(),(_vm.canManageJlAdmin)?_c('div',{staticClass:"mb"},[_c('router-link',{staticClass:"mr",attrs:{"to":`/admin/users/add?project_id=${encodeURIComponent(
                  _vm.filter,
                )}&company_type=${encodeURIComponent('监理单位')}&admintype=${encodeURIComponent(
                  '管理人员',
                )}`}},[_c('ui-button',{attrs:{"type":"primary","icon":"el-icon-edit-outline"}},[_vm._v("创建监理单位管理人员 ")])],1)],1):_vm._e(),(_vm.canManageJlAdminSameCompany)?_vm._l((_vm.companyNamesJl),function(company,index){return _c('div',{key:`jl1-${index}`,staticClass:"mb"},[_c('router-link',{staticClass:"mr",attrs:{"to":`/admin/users/add?project_id=${encodeURIComponent(
                    _vm.filter,
                  )}&company=${company}&company_type=${encodeURIComponent(
                    '监理单位',
                  )}&admintype=${encodeURIComponent('管理人员')}`}},[_c('ui-button',{attrs:{"type":"primary","icon":"el-icon-edit-outline"}},[_vm._v("创建监理单位管理人员："+_vm._s(company)+" ")])],1)],1)}):_vm._e(),(_vm.canManageJlOperator)?_vm._l((_vm.companyNamesJl),function(company,index){return _c('div',{key:`js2-${index}`,staticClass:"mb"},[_c('router-link',{staticClass:"mr",attrs:{"to":`/admin/users/add?project_id=${encodeURIComponent(
                    _vm.filter,
                  )}&company=${encodeURIComponent(company)}&company_type=${encodeURIComponent(
                    '监理单位',
                  )}&admintype=${encodeURIComponent('作业人员')}`}},[_c('ui-button',{attrs:{"type":"primary","icon":"el-icon-edit-outline"}},[_vm._v("创建监理单位作业人员："+_vm._s(company)+" ")])],1)],1)}):_vm._e(),(_vm.canManageSgAdmin)?_c('div',{staticClass:"mb"},[_c('router-link',{staticClass:"mr",attrs:{"to":`/admin/users/add?project_id=${encodeURIComponent(
                  _vm.filter,
                )}&company_type=${encodeURIComponent('施工单位')}&admintype=${encodeURIComponent(
                  '管理人员',
                )}`}},[_c('ui-button',{attrs:{"type":"primary","icon":"el-icon-edit-outline"}},[_vm._v("创建施工单位管理人员 ")])],1)],1):_vm._e(),(_vm.canManageSgAdminSameCompany)?_vm._l((_vm.companyNamesSg),function(company,index){return _c('div',{key:`sg1-${index}`,staticClass:"mb"},[_c('router-link',{staticClass:"mr",attrs:{"to":`/admin/users/add?project_id=${encodeURIComponent(
                    _vm.filter,
                  )}&company=${encodeURIComponent(company)}&company_type=${encodeURIComponent(
                    '施工单位',
                  )}&admintype=${encodeURIComponent('管理人员')}`}},[_c('ui-button',{attrs:{"type":"primary","icon":"el-icon-edit-outline"}},[_vm._v("创建施工单位管理人员："+_vm._s(company)+" ")])],1)],1)}):_vm._e(),(_vm.canManageSgAdminSameCompany)?_vm._l((_vm.companyNamesSg),function(company,index){return _c('div',{key:`jl2-${index}`,staticClass:"mb"},[_c('router-link',{staticClass:"mr",attrs:{"to":`/admin/users/add?project_id=${encodeURIComponent(
                    _vm.filter,
                  )}&company=${company}&company_type=${encodeURIComponent(
                    '施工单位',
                  )}&admintype=${encodeURIComponent('安全员')}`}},[_c('ui-button',{attrs:{"type":"primary","icon":"el-icon-edit-outline"}},[_vm._v("创建施工单位安全员："+_vm._s(company)+" ")])],1)],1)}):_vm._e(),(_vm.canManageSgOperatorSameCompany)?_vm._l((_vm.companyNamesSg),function(company,index){return _c('div',{key:`sg2-${index}`,staticClass:"mb"},[_c('router-link',{staticClass:"mr",attrs:{"to":`/admin/users/add?project_id=${encodeURIComponent(
                    _vm.filter,
                  )}&company=${company}&company_type=${encodeURIComponent(
                    '施工单位',
                  )}&admintype=${encodeURIComponent('作业人员')}`}},[_c('ui-button',{attrs:{"type":"primary","icon":"el-icon-edit-outline"}},[_vm._v("创建施工单位作业人员："+_vm._s(company)+" ")])],1)],1)}):_vm._e()],2)],2):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }