import { _ } from '@yishitec/web';

export default (res) => {
  let valueMax = 0;
  const list = [];

  const { history = [] } = res || {};
  _.forEach(history, (i) => {
    if (!_.find(list, (find) => find.date === i.date)) {
      list.push({
        date: i.date,
        values: [
          {
            i: 0,
            name: '监理单位管理人员',
            value: 0,
          },
          {
            i: 1,
            name: '施工单位管理人员与安全员',
            value: 0,
          },
          {
            i: 2,
            name: '施工单位作业人员',
            value: 0,
          },
        ],
      });
    }
    const item = _.find(list, (find) => find.date === i.date);
    item.values[0].value += Number(i.jlIn);
    item.values[1].value += Number(i.sgIn);
    item.values[2].value += Number(i.workerIn);
    valueMax = _.max([
      valueMax,
      item.values[0].value + item.values[1].value + item.values[2].value,
    ]);
  });

  return {
    valueMax,
    list,
  };
};
