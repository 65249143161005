module.exports = {
  icon: 'images/data-center-screen/boards/icon-cams.svg',
  title: '项目管理',
  apiUrl: '/api/reports/data-center-screen/sub-windows/projects',
  indicators: [
    {
      title: '项目数',
      n: '项目数',
    },
    {
      title: '今日作业项目数',
      n: '今日作业项目数',
    },
  ],
  table: [
    {
      name: '项目名称',
      width: '220px',
    },
    {
      name: '今日作业状态',
      width: '120px',
    },
    {
      name: '施工单位',
      width: '180px',
    },
    {
      name: '监理单位',
      width: '180px',
    },
    {
      name: '施工进度',
      width: '120px',
    },
    // {
    //   name: '查看详情',
    //   type: 'adminOps',
    //   subWindow: 'projects',
    // },
    {},
  ],
};
