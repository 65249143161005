<template lang="pug">
div
  ui-stage
    ProjectsFilterList(
      :attributesFilter="attributesFilter"
    )
      template(v-slot="{ projectId }")
        AppButton(
          v-if="projectId"
          type="primary"
          :to="`/admin/ops-zones/add?projectId=${projectId}`"
        ) 添加施工部位
      template(v-slot:projectId="{ row }")
        span {{ $get(row, ['xProject', 'title']) }}
      template(v-slot:startAt="{ row }")
        small {{ formatDateTime($get(row, ['startAt'])) }}
      template(v-slot:endAt="{ row }")
        small {{ formatDateTime($get(row, ['endAt'])) }}
      template(v-slot:state="{ row }")
        ProgressStateTag(
          :value="row.state"
        )
</template>

<script>
export default {
  name: 'List',
  data() {
    const attributesFilter = ['id', 'projectId', 'title', 'state', 'startAt', 'endAt'];
    return {
      attributesFilter,
    };
  },
};
</script>

<style lang="scss" scoped></style>
