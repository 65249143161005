const getreqfullscreen = () => {
  const root = document.documentElement;
  return (
    root.requestFullscreen ||
    root.webkitRequestFullscreen ||
    root.mozRequestFullScreen ||
    root.msRequestFullscreen
  );
};

export default (el) => {
  getreqfullscreen().call(el);
};
