<template lang="pug">
ui-flex(row j-center a-center).navs
  a(
    v-for="item in navs"
    :key="item.key"
    :style="getStyles(item)"
    :href="item.to"
    @click.prevent="goto(item)"
  ).item
</template>

<script>
import { _ } from '@yishitec/web';
import HeaderNavItem from './HeaderNavItem.vue';

export default {
  name: 'HeaderDataCenterNav',
  props: {
    theme: { type: String, default: '' },
    active: { type: String, default: 'visualization-map' },
  },
  emits: ['leave', 'switch'],
  components: {
    HeaderNavItem,
  },
  data() {
    return {
      navs: [
        {
          key: 'data-center',
          title: '数据云中心',
          to: '/data-center',
          toView: 'dataCenter',
          image: `/images/screen-v3/${['nav-data-center', this.theme]
            .filter(Boolean)
            .join('-')}.png`,
          imageActive: `/images/screen-v3/${['nav-data-center-2', this.theme, 'active']
            .filter(Boolean)
            .join('-')}.png`,
          width: '197px',
          height: '127px',
          delayBeforeLeave: 1000,
        },
        {
          key: 'visualization-map',
          title: '可视化地图',
          to: '/screen',
          toView: 'screenView',
          image: `/images/screen-v3/${['nav-visualization-map-2', this.theme]
            .filter(Boolean)
            .join('-')}.png`,
          imageActive: `/images/screen-v3/${['nav-visualization-map', this.theme, 'active']
            .filter(Boolean)
            .join('-')}.png`,
          width: '234px',
          height: '127px',
        },
      ],
    };
  },
  methods: {
    getStyles(item) {
      return {
        backgroundImage: `url('${this.active === item.key ? item.imageActive : item.image}')`,
        width: item.width,
        height: item.height,
        backgroundSize: `${item.width} ${item.height}`,
      };
    },
    getActiveItem() {
      return _.find(this.navs, (i) => i.key === this.active);
    },
    goto(item) {
      if (item.to === this.getActiveItem().to) {
        return;
      }
      this.$emit('leave');
      const { delayBeforeLeave = 0 } = this.getActiveItem();
      setTimeout(() => {
        // this.$emit('switch', item.toView);
        this.$router.push(item.to);
      }, delayBeforeLeave);
    },
  },
};
</script>

<style lang="scss" scoped>
.navs {
  position: absolute;
  top: 0;
  left: 50%;
  width: 432px;
  height: 127px;
  margin-left: -216px;
}

.item {
  display: block;
  outline: 0;
  border: none;
  flex: 0 0 auto;
  background: no-repeat top left;
}
</style>
