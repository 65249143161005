module.exports = {
  icon: 'images/data-center-screen/boards/icon-members.svg',
  title: '人员考勤',
  apiUrl: '/api/reports/data-center-screen/sub-windows/checkins',
  indicators: [
    {
      title: '施工单位管理人员',
      n: '今日施工单位管理人员',
      total: '施工单位管理人员',
    },
    {
      title: '监理单位',
      n: '今日监理单位人员',
      total: '监理单位人员',
    },
    {
      title: '施工单位作业人员',
      n: '今日施工单位作业人员',
      total: '施工单位作业人员',
    },
  ],
  table: [
    {
      name: '项目名称',
      width: '220px',
    },
    {
      name: '施工单位管理人员',
      width: '120px',
      type: 'stats',
      cols: ['今日施工单位管理人员', '施工单位管理人员'],
    },
    {
      name: '施工单位作业人员',
      width: '120px',
      type: 'stats',
      cols: ['今日施工单位作业人员', '施工单位作业人员'],
    },
    {
      name: '监理',
      width: '120px',
      type: 'stats',
      cols: ['今日监理单位人员', '监理单位人员'],
    },
    {
      name: '人员综合达标率',
      width: '120px',
    },
    // {
    //   name: '查看详情',
    //   type: 'adminOps',
    //   subWindow: 'projectCheckins',
    // },
    {},
  ],
};
