<template lang="pug">
div(
  @click="handleClickCard"
  :style="{ borderColor: color }"
).ssp-home-card
  div.section.card-cover
    div(
      v-if="icon"
      :style="{ backgroundImage: `url('/images/progress/app-icons/${icon}.png')` }"
    ).icon
    div.title {{ title }}
  div.card-stats.section
    div(
      v-for="item in stats"
      :key="item.key"
    ).stats-item
      div(v-if="item.type === 'ProgressBar'")
        div.stats-numer {{ item.number | formatPercentage }}
        el-progress(
          :percentage="item.number * 100"
          :color="item.color || '#2F3CED'"
          :show-text="false"
          :style="{ width: '100%' }"
        )
      div(
        v-else
        :style="{ color: item.color }"
      ).stats-number {{ item.number }}
      div.stats-title {{ item.title }}
  div.card-status.section
    div.icon-info
    div {{ statusBar }}
</template>

<script>
import { numeral } from '@yishitec/web';

export default {
  name: 'SspHomeCard',
  props: {
    icon: { type: String, default: '' },
    title: { type: String, default: '' },
    to: { type: String, default: '' },
    statusBar: { type: String, default: '' },
    stats: { type: Array, default: () => [] },
    color: { type: String, default: '#aaa' },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {},
    handleClickCard() {
      this.$router.push(this.to);
    },
  },
  filters: {
    formatPercentage(value) {
      return numeral(value).format('0,0.00%');
    },
  },
};
</script>

<style lang="scss" scoped>
.ssp-home-card {
  box-sizing: border-box;
  border: solid 0px #fff;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
  @include ui-flex-col(space-between, stretch);
  &::before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 3px;
    border: solid 1px;
    border-color: inherit;
    opacity: 0;
    transition: all 0.3s;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
  }
}
.section {
  box-sizing: border-box;
  padding: 0 16px;
  @include ui-flex-row(flex-start, center);
  &.section-lg {
    padding: 32px;
  }
}
.section + .section {
  border-top: solid 1px #efefef;
}
.icon {
  background: no-repeat center center;
  background-size: 71px 71px;
  display: block;
  width: 71px;
  height: 71px;
  margin-right: 10px;
}
.title {
  font-size: 22px;
  line-height: 27px;
  color: #4b4b4b;
  position: relative;
  padding-right: 24px;
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -7px;
    width: 10px;
    height: 15px;
    background: url('../assets/images/progress/card-arrow.png') no-repeat right center;
    background-size: 10px 15px;
  }
}
.card-stats {
  @include ui-flex-row(center, stretch);
}
.stats-item {
  @include ui-flex-col(center, stretch);
  text-align: center;
  flex: 1 0 1px;
  padding: 16px;
  & + & {
    border-left: solid 1px #efefef;
  }
}
.stats-title {
  color: #b7b7b7;
  font-size: 12px;
  line-height: 16px;
  margin-top: 12px;
}
.stats-number {
  font-size: 14px;
  font-weight: bolder;
}
.card-cover {
  padding: 32px;
}
.card-status {
  background: #fbfbfb;
  padding: 8px 16px;
  font-size: 13px;
  line-height: 18px;
  color: #4b4b4b;
}
.icon-info {
  width: 20px;
  height: 20px;
  background: url('../assets/images/progress/icon-info.png') no-repeat center center;
  background-size: 20px 20px;
  margin-right: 10px;
}
</style>
