<template lang="pug">
  div.col(style="width: 100%; height: 100%")
    .row
      DashboardSectionTitle(title="要点速览看板" hint="KEY POINTS DASHBOARD")
    .row.expand
      .col(style="width: 340px;")
        BoardProjects(v-bind="screenData" @sub-window="$emit('sub-window', $event)" :class="{ expand: projects.length !== 1 }").row
        BoardCams(v-bind="screenData" @sub-window="$emit('sub-window', $event)" :class='{ expand: projects.length === 1 }').row
        BoardMaterials(v-bind="screenData" @sub-window='$emit("sub-window", $event)').row
      BoardMembers(v-bind="screenData" @sub-window='$emit("sub-window", $event)').col.expand
    .row
      BoardSupervisionLogs.col(v-bind="screenData" style="width: 224px; min-height: 336px;" @sub-window='$emit("sub-window", $event)')
      BoardDangerZones(v-bind="screenData" @sub-window='$emit("sub-window", $event)').col.expand
      BoardOpsZones(v-bind="screenData" @sub-window='$emit("sub-window", $event)').col.expand
</template>

<script>
import Board from './Board.vue';
import DashboardSectionTitle from './DashboardSectionTitle.vue';
import BoardProjects from './Boards/BoardProjects.vue';
import BoardCams from './Boards/BoardCams.vue';
import BoardMaterials from './Boards/BoardMaterials.vue';
import BoardMembers from './Boards/BoardMembers.vue';
import BoardSupervisionLogs from './Boards/BoardSupervisionLogs.vue';
import BoardDangerZones from './Boards/BoardDangerZones.vue';
import BoardOpsZones from './Boards/BoardOpsZones.vue';

export default {
  name: 'Dashboard',
  props: {
    projects: Array,
    history: Array,
  },
  emits: ['sub-window'],
  components: {
    Board,
    DashboardSectionTitle,
    BoardProjects,
    BoardCams,
    BoardMaterials,
    BoardMembers,
    BoardSupervisionLogs,
    BoardDangerZones,
    BoardOpsZones,
  },
  computed: {
    screenData() {
      return {
        projects: this.projects,
        history: this.history,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex: 0 0 auto;

  & + .row {
    margin-top: 20px;
  }
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 0 0 auto;

  & + .col {
    margin-left: 20px;
  }
}

.expand {
  flex: 1 0 1px;
}
</style>
