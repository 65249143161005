<template>
  <div>
    <ui-button
      type="primary"
      @click="displayMapEditorModal = true"
      :icon="value ? 'el-icon-success' : 'el-icon-warning-outline'"
    >
      {{ value ? (readonly ? '查看' : '编辑') + markerName : '创建' + markerName }}
    </ui-button>
    <el-dialog
      :title="`${readonly ? '查看' : '编辑'}地理围栏`"
      :visible.sync="displayMapEditorModal"
      v-if="displayMapEditorModal"
      fullscreen
    >
      <app-map-mouse-editor
        :value="value"
        @input="
          (value) => {
            $emit('input', value);
            displayMapEditorModal = false;
          }
        "
        :type="type"
        :readonly="readonly"
      />
    </el-dialog>
  </div>
</template>

<script>
import { _ } from '@yishitec/web';
import AppMapMouseEditor from './maps/AppMapMouseEditor.vue';

export default {
  name: 'AppMapEditor',
  components: { AppMapMouseEditor },
  props: {
    value: { type: String },
    readonly: { type: Boolean, default: false },
    type: { type: String, default: 'polygon' },
  },
  emits: ['input'],
  data() {
    return {
      displayMapEditorModal: false,
    };
  },
  computed: {
    markerName() {
      return _.get(
        {
          polygon: '地理围栏',
          point: '地理坐标',
        },
        this.type,
        '地理围栏',
      );
    },
  },
};
</script>
