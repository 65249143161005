<template lang="pug">
div(@click="handleClick").ssp-sidebar-link
  div(
    :style="style"
  ).icon
  div.title {{ title }}
</template>

<script>
export default {
  name: 'SspSidebarLink',
  props: {
    icon: { type: String, default: 'list' },
    title: { type: String, default: '详情' },
    bookmark: { type: String, default: 'main' },
  },
  emits: ['click'],
  computed: {
    style() {
      return {
        backgroundImage: `url('/images/progress/sidebar-icons/${this.icon}.png')`,
      };
    },
  },
  methods: {
    handleClick() {
      const { bookmark } = this;
      this.$emit('clcik', { bookmark });
    },
  },
};
</script>

<style lang="scss" scoped>
.ssp-sidebar-link {
  height: 32px;
  @include ui-flex-row(flex-start, center);
  cursor: pointer;
  border-radius: 3px;
  padding: 0 8px;
}
.icon {
  width: 20px;
  height: 20px;
  background-size: contain;
  margin-right: 13px;
}
.title {
  font-size: 16px;
  line-height: 20px;
}
.ssp-sidebar-link {
  &:hover,
  &.active {
    background-color: rgba(#525ce5, 0.05);
  }
}
</style>
