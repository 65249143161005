<template lang="pug">
.section(
  :class="isActive ? 'active-blink' : ''"
)
  .title {{ project.title }}
    el-tag.ml(
      size="mini",
      type="info",
      effect="dark",
      v-if="project.workState === '非活跃'"
    ) 非活跃
    el-tag.ml(
      size="mini",
      type="info",
      effect="dark",
      v-if="project.今日交底状态 !== '作业'"
    ) {{ project.今日交底状态 }}
  .hints
    .hint 危险源数量：{{ project['危险源设备'] || 0 }}
    .hint 今日作业授权：{{ project['危险源作业'] || 0 }}次
    .hint 今日预警：{{ $get(tasks, ['projects', project.id, 'count']) || 0 }}
    .hint 监理已处理预警：{{ $get(tasks, ['projects', project.id, 'processed']) || 0 }}
  RightBar(
    title="监理单位"
    color="orange"
    :n="project.jlOnline"
    :total="project.jlCount"
  )
  RightBar(
    title="施工单位"
    color="blue"
    :n="project.sgAdminOnline"
    :total="project.sgAdminCount"
  )
  RightBar(
    title="安全员"
    color="red"
    :n="project.sgSecOnline"
    :total="project.sgSecCount"
  )
  RightBar(
    title="作业人员"
    color="green"
    :n="project.sgOnline"
    :total="project.sgCount"
  )
  RightBar(
    title="危险源"
    color="pink"
    :n="project.activeDangerZones"
    :total="project.dangerZones"
  )
</template>

<script>
import RightSectionTitle from './RightSectionTitle.vue';
import RightBar from './RightBar.vue';

export default {
  name: 'RightProjectInfo',
  props: {
    isActive: Boolean,
    project: Object,
    tasks: Object,
  },
  components: {
    RightSectionTitle,
    RightBar,
  },
};
</script>

<style lang="less" scoped>
.section {
  margin: 30px 0;
  color: #fff;
  font-size: 12px;
}

.active-blink {
  @keyframes animationFrames {
    0% {
      background: fadeout(#000, 0);
    }
    100% {
      background: fadeout(#000, 100);
    }
  }

  // animation: animationFrames ease 1s;
  // animation-iteration-count: 1;
  // animation-fill-mode: forwards; /*when the spec is finished*/
}

.title {
  margin-bottom: 8px;
  font-weight: bold;
}

.hints {
  margin: 8px 0 12px 0;
}

.hint {
  color: #737373;
  line-height: 18px;
}
</style>
