<template lang="pug">
.iot-gateway-task-sets
  h1 闸机任务管理
  SspTable(
    :value="list",
    :columns="attributes",
    :loading="loading",
    withRefresh,
    withToolbar,
    @refresh="loadData",
    @handleRow="handleRow"
  )
    template(v-slot:toolbarLeft)
      el-button(type="primary", @click="showDialogCreate", size="mini") 创建
    template(v-slot:finishedAt="{ row }")
      small {{ formatDateTime(row.finishedAt) }}
    template(v-slot:createdAt="{ row }")
      small {{ formatDateTime(row.createdAt) }}
    template(v-slot:updatedAt="{ row }")
      small {{ formatDateTime(row.updatedAt) }}
    template(v-slot:projectId="{ row }")
      span {{ $get(row, ['xProject', 'title']) }}
    template(v-slot:adminId="{ row }")
      span {{ $get(row, ['xUser', 'display_name']) }}
  el-dialog(title="创建任务", :visible.sync="displayDialogCreate")
    .create-buttons
      .create-button(
        v-for="commandType in commandTypes",
        :key="commandType.key"
      )
        el-button(
          size="large",
          type="primary",
          plain,
          style="width: 100%",
          @click="handleCreate(commandType)"
        ) {{ commandType.title }}
</template>

<script>
const commandTypes = [
  {
    key: 'person/list',
    title: '获取闸机用户资料',
    commandType: 'person/list',
  },
  {
    key: 'person/sync',
    title: '同步闸机用户资料',
    commandType: 'person/sync',
  },
];

export default {
  name: 'IotGatewayTaskSets',
  props: {
    projectId: { type: [String, Number] },
  },
  data() {
    return {
      list: [],
      attributes: [],
      loading: false,
      displayDialogCreate: false,
      commandTypes,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const res = await this.api('/api/iot-gateway-task-sets/list', {
        projectId: this.projectId,
      })();
      const { attributes = [], list = [] } = res || {};
      this.list = list;
      this.attributes = attributes;
      this.loading = false;
    },
    handleRow(row) {
      this.$router.push(`/admin/iot-gateway-task-sets/tasks/${row.id}`);
    },
    showDialogCreate() {
      this.displayDialogCreate = true;
    },
    async handleCreate(commandType) {
      if (!commandType) return;

      this.displayDialogCreate = false;
      this.loading = true;
      const res = await this.api('/api/iot-gateway-task-sets/create', {
        projectId: this.projectId,
        ...commandType,
      })();
      const { id } = res || {};
      if (id) {
        this.$router.push(`/admin/iot-gateway-task-sets/tasks/${id}`);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.create-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
.create-button {
  flex: 0 0 calc(100% / 3);
  box-sizing: border-box;
  padding: 5px;
}
</style>
