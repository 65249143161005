<template lang="pug">
.header(@click="enterFullScreen")
  .header-text 营造云智慧工地
</template>

<script>
export default {
  name: 'Header',
  emits: ['action'],
  data() {
    return {};
  },
  methods: {
    enterFullScreen() {
      this.$emit('action', 'full-screen');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 425px;
  height: 241px;
  background: url('/images/screen-v3/screen-logo.png') no-repeat top left;
  background-size: 425px 241px;
  cursor: pointer;
}

.header-text {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  margin: 34px 0 20px 90px;
}
</style>
