import _ from 'lodash';
import { ref } from 'vue';
import { useFetch2 } from '../../composables';

export default (props) => {
  const images = ref([]);
  const children = ref([]);

  const { refresh } = useFetch2('/api/v3/progress-blueprint-image/children', {
    getBody: () => {
      return {
        withState: !!props.readonly,
        blueprintId: props.blueprintId,
      };
    },
    success: (data) => {
      images.value = _.get(data.value, ['list'], []);
      children.value = _.get(data.value, ['children'], []);
    },
  });

  return {
    images,
    refresh,
    children,
  };
};
