<template>
  <div class="map-view">
    <ui-alert type="error" class="error" v-if="error">
      {{ error }}
    </ui-alert>
    <div v-else-if="projectInfo && projectInfo.length">
      <ui-flex col a-start>
        <div
          style="
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.05);
            margin-bottom: 10px;
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
          "
        >
          <ui-flex column j-start a-start>
            <ui-flex row wrap j-start a-center style="flex: 1 0 auto" class="map-markers">
              <ui-popover
                v-for="(item, index) in mapMarkers"
                :key="index"
                placement="bottom"
                :title="item.title"
                :offset="100"
                width="200"
                trigger="hover"
              >
                <div style="cursor: pointer" slot="reference">
                  <img :src="item.icon" style="vertical-align: middle" class="map-marker" />
                  {{ item.title }}
                </div>
                <template>
                  <ui-flex row between a-center class="info-line">
                    <div>未安全交底</div>
                    <div>{{ $get(membersStats, [item.title, 'alertsCount']) || '-' }}</div>
                  </ui-flex>
                  <ui-flex row between a-center class="info-line">
                    <div>今日签到人数</div>
                    <div>{{ $get(membersStats, [item.title, 'todayCount']) || '-' }}</div>
                  </ui-flex>
                  <ui-flex row between a-center class="info-line">
                    <div>在册人数</div>
                    <div>{{ $get(membersStats, [item.title, 'membersCount']) || '-' }}</div>
                  </ui-flex>
                </template>
              </ui-popover>
              <ui-popover
                placement="bottom"
                title="危险源设备"
                :offset="100"
                width="200"
                trigger="hover"
              >
                <div slot="reference" style="cursor: pointer">
                  <img
                    :src="require(`../../../assets/images/mapMarkers/IOT_DEVICE.png`)"
                    style="vertical-align: middle"
                    class="map-marker"
                  />
                  &nbsp;危险源设备
                </div>
                <ui-flex row between a-center class="info-line">
                  <div>未授权启动设备</div>
                  <div>{{ $get(devicesStats, ['todayAlertsCount']) || '-' }}</div>
                </ui-flex>
                <ui-flex row between a-center class="info-line">
                  <div>今日定位信息</div>
                  <div>{{ $get(devicesStats, ['todayCount']) || '-' }}</div>
                </ui-flex>
                <ui-flex row between a-center class="info-line">
                  <div>在册设备数</div>
                  <div>{{ $get(devicesStats, ['devicesCount']) || '-' }}</div>
                </ui-flex>
                <ui-flex row between a-center class="info-line">
                  <div>在册危险源数</div>
                  <div>{{ $get(devicesStats, ['dangerZonesCount']) || '-' }}</div>
                </ui-flex>
              </ui-popover>
              <ui-popover
                placement="bottom"
                title="监控设备"
                :offset="100"
                width="200"
                trigger="hover"
              >
                <div slot="reference" style="cursor: pointer">
                  <img
                    :src="require(`../../../assets/images/mapMarkers/CAM.png`)"
                    style="vertical-align: middle"
                    class="map-marker"
                  />
                  &nbsp;监控设备
                </div>
                <ui-flex row between a-center class="info-line">
                  <div>在线设备数</div>
                  <div>{{ $get(camsStats, ['onlineCount']) || '-' }}</div>
                </ui-flex>
                <ui-flex row between a-center class="info-line">
                  <div>在册设备数</div>
                  <div>{{ $get(camsStats, ['camsCount']) || '-' }}</div>
                </ui-flex>
              </ui-popover>
            </ui-flex>
            <ui-flex
              row
              j-start
              a-center
              wrap
              style="flex: 0 0 auto; margin-left: 0; margin-top: 8px"
            >
              <div class="map-control" v-if="isSuperAdmin">
                <ui-button icon="el-icon-sort" size="small" @click="syncGpsoo" class="mr">
                  同步
                </ui-button>
              </div>
              <div>
                <ui-button icon="el-icon-refresh" size="small" @click="reloadMap" />
              </div>
              <div>
                <ui-button class="mr" size="small" @click="loadData"> 刷新</ui-button>
              </div>
              <div>
                <ui-button
                  icon="el-icon-refresh"
                  size="small"
                  :type="isAutoRefreshOn ? 'primary' : 'default'"
                  @click="toggleAutoRefreshOn"
                >
                  自动刷新
                </ui-button>
              </div>
            </ui-flex>
          </ui-flex>
        </div>
        <ui-flex style="width: 100%">
          <app-map-mouse-editor
            :value="projectInfo[0].fence || ''"
            screen-offset="190px"
            readonly
            @map-ready="handleMapRes"
            :key="mapKey"
          />
        </ui-flex>
        <template v-if="false">
          <ui-flex>
            <ui-section-title>人员</ui-section-title>
            <ui-admin-table
              module-url="/admin/users"
              :data="membersInfo"
              :attributes="membersColumns"
              :with-create="false"
              :with-delete="false"
              is-edit-view
              stripe
              :with-search="membersInfo.length > 5"
            >
              <template v-slot:column_added="{ row }">
                {{ row.addedDisplay }}
                <ui-badge class="mark" value="!" v-if="getAlerts('members', row.member_id) > 0" />
              </template>
            </ui-admin-table>
            <ui-section-title>危险源</ui-section-title>
            <ui-admin-table
              module-url="/admin/danger-zones"
              :data="dangerZonesInfo"
              :attributes="dangerZonesColumns"
              :with-create="false"
              :with-delete="false"
              is-edit-view
              stripe
              :with-search="dangerZonesInfo.length > 5"
            />
            <ui-section-title>危险源物联网设备</ui-section-title>
            <ui-admin-table
              module-url="/admin/iot-devices"
              :data="iotDeviceTrackingInfo"
              :attributes="iotDeviceTrackingColumns"
              :with-create="false"
              :with-delete="false"
              is-edit-view
              stripe
              :with-search="iotDeviceTrackingInfo.length > 5"
            >
              <template v-slot:column_added="{ row }">
                {{ row.added }}
                <ui-badge class="mark" value="!" v-if="getAlerts('iotDevices', row.id) > 0" />
              </template>
            </ui-admin-table>
            <ui-section-title>监控设备</ui-section-title>
            <ui-admin-table
              module-url="/admin/cams"
              :data="camsInfo"
              :attributes="camsColumns"
              :with-create="false"
              :with-delete="false"
              is-edit-view
              stripe
              :with-search="camsInfo.length > 5"
            >
              <template v-slot:column_state="{ row }">
                <el-tag v-if="row.state" type="success">在线</el-tag>
                <el-tag v-else type="info">离线</el-tag>
              </template>
            </ui-admin-table>
          </ui-flex>
        </template>
      </ui-flex>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { _, moment, G } from '@yishitec/web';
import AppMapMouseEditor from '../../../components/maps/AppMapMouseEditor.vue';
import permissions from '../../../mixins/permissions';

const { getIconSize, getIconPng } = G();

const setInc = (obj, route, incBy = 1) => {
  const oldValue = _.get(obj, route) || 0;
  const value = Number(oldValue) + Number(incBy);
  _.set(obj, route, value);
};

const projectColumns = [
  {
    name: 'id',
    title: 'ID',
  },
  {
    name: 'title',
    title: '项目名称',
  },
];

const handleColumns = (columnsDefinition = []) =>
  _.map(columnsDefinition, (column) => {
    const ret = _.cloneDeep(column);
    if (ret.name !== 'id') {
      if (!ret.minWidth || ret.minWidth < 150) {
        ret.minWidth = 150;
      }
    }
    if (ret.name === 'added') {
    }
    return ret;
  });

const getMapMarkerIcon = (companyType, adminType) => {
  const iconsMap = {
    施工单位: {
      管理人员: 'SG_ADMIN',
      安全员: 'SG_SECURITY',
      作业人员: 'SG_WORKER',
    },
    监理单位: {
      管理人员: 'JL',
      专项管理员: 'JL',
      作业人员: 'JL',
    },
  };
  return _.get(iconsMap, [companyType, adminType], 'MARKER');
};

export default {
  name: 'MapView',
  components: { AppMapMouseEditor },
  mixins: [permissions],
  data() {
    return {
      error: '',
      mapKey: Date.now(),
      withCustomFields: false,
      projectInfo: [],
      projectColumns: handleColumns(projectColumns),
      membersInfo: [],
      membersColumns: [],
      dangerZonesInfo: [],
      dangerZonesColumns: [],
      iotDeviceTrackingInfo: [],
      iotDeviceTrackingColumns: [],
      camsInfo: [],
      camsColumns: [],
      iotAlerts: { members: {}, iotDevices: {} },
      map: null,
      isAutoRefreshOn: false,
      tiAutoRefresh: null,
      markerGroupStates: {},
      mapMarkers: [
        {
          title: '施工单位管理人员',
          icon: require(`../../../assets/images/mapMarkers/SG_ADMIN.png`),
        },
        {
          title: '施工单位安全员',
          icon: require(`../../../assets/images/mapMarkers/SG_SECURITY.png`),
        },
        {
          title: '施工单位作业人员',
          icon: require(`../../../assets/images/mapMarkers/SG_WORKER.png`),
        },
        { title: '监理单位人员', icon: require(`../../../assets/images/mapMarkers/JL.png`) },
      ],
      opsZonesLogs: [],
      opsZonesReport: {},
      activeMarkerGroups: [],
      markersGroups: {},
      markers: [],
    };
  },
  computed: {
    membersStats() {
      const ret = {};
      _.forEach(this.membersInfo, (item) => {
        let icon = `${item.company_type}人员`;
        if (item.company_type === '施工单位') {
          icon = `${item.company_type}${item.admintype}`;
        }
        if (item.username !== 'admin') {
          setInc(ret, [icon, 'membersCount']);
          if (item.added) {
            setInc(ret, [icon, 'todayCount']);
          }
          if (this.getAlerts('members', item.member_id) > 0) {
            setInc(ret, [icon, 'alertsCount']);
          }
        }
      });
      return ret;
    },
    devicesStats() {
      const ret = {
        todayCount: 0,
        devicesCount: 0,
        dangerZonesCount: 0,
      };
      _.forEach(this.iotDeviceTrackingInfo, (item) => {
        setInc(ret, ['devicesCount']);
        if (item.added) {
          setInc(ret, ['todayCount']);
          if (this.getAlerts('iotDevices', item.id)) {
            setInc(ret, ['todayAlertsCount']);
          }
        }
      });
      setInc(ret, ['dangerZonesCount'], _.get(this.dangerZonesInfo, ['length']));
      return ret;
    },
    camsStats() {
      const ret = {
        onlineCount: 0,
        camsCount: 0,
      };
      _.forEach(this.camsInfo, (item) => {
        setInc(ret, ['camsCount']);
        if (item.state) {
          setInc(ret, ['onlineCount']);
        }
      });
      return ret;
    },
  },
  watch: {
    isAutoRefreshOn(value) {
      this.controlAutoRefresh(value);
    },
  },
  mounted() {
    this.loadData();
    setTimeout(() => {
      this.mapKey = Date.now();
    }, 500);
  },
  beforeDestroy() {
    this.controlAutoRefresh(false);
  },
  methods: {
    getAlerts(group, id) {
      return (this.iotAlerts[group][id] || []).length;
    },
    handleMapRes(map) {
      this.map = map;
      this.markMembersOnMap();
    },
    async reloadMap() {
      this.map = null;
      await this.loadData();
      this.mapKey = Date.now();
    },
    async loadData() {
      await this.loadProjectInfo();
      if (this.projectInfo && this.projectInfo.length) {
        try {
          this.loadOpsZonesLogsReport();
          await this.loadOpsZonesLogs();
          await this.loadProjectMembers();
          this.loadProjectDangerZones();
          this.loadIotDeviceTracking();
          this.loadCams();
        } catch (e) {
          this.error = '-';
        }
      }
    },
    async loadOpsZonesLogs() {
      const { vId } = this.$route.params;
      const res = await this.api('/api/ops-zones-logs/list', {
        projectId: vId,
        withFences: true,
      })();
      const { list = [] } = res || {};
      this.opsZonesLogs = list;
      this.markMembersOnMap();
    },
    async loadOpsZonesLogsReport() {
      const { vId } = this.$route.params;
      const res = await this.api('/api/ops-zones-logs/report-overview', {
        projectId: vId,
      })();
      this.opsZonesReport = res;
      this.markMembersOnMap();
    },
    async loadProjectInfo() {
      const { vId } = this.$route.params;
      const projectRes = await this.withNoLoading(this.api(`/api/projects/edit/${vId}`));
      if (_.get(projectRes, 'item')) {
        this.projectInfo = [projectRes.item];
      } else {
        this.$message({
          type: 'error',
          message: '找不到指定的项目',
        });
      }
    },
    async loadProjectMembers() {
      const projectId = this.projectInfo[0].id;
      const membersRes = await this.withNoLoading(
        this.api('/api/tracking-logs/list', {
          projectId,
        }),
      );
      if (!membersRes) {
        throw new Error();
      }
      this.membersInfo = _.map(
        _.uniqBy(membersRes.list, (item) => item.member_id),
        (item) => ({
          ...item,
          addedDisplay: item.added ? moment(item.added).format('HH:mm:ss') : '-',
        }),
      );
      this.membersColumns = handleColumns(membersRes.attributes);

      const alerts = {
        members: {},
        iotDevices: {},
      };
      _.forEach(membersRes.alerts, (alert) => {
        if (alert.member_id) {
          if (!alerts.members[alert.member_id]) {
            alerts.members[alert.member_id] = [];
          }
          alerts.members[alert.member_id].push(alert);
        }
        if (alert.iot_device_id) {
          if (!alerts.iotDevices[alert.iot_device_id]) {
            alerts.iotDevices[alert.iot_device_id] = [];
          }
          alerts.iotDevices[alert.iot_device_id].push(alert);
        }
      });
      this.iotAlerts = alerts;

      this.markMembersOnMap();
    },
    async loadProjectDangerZones() {
      const projectId = this.projectInfo[0].id;
      const dangerZonesRes = await this.withNoLoading(
        this.api('/api/danger-zones/list', { filter: projectId }),
      );
      this.dangerZonesInfo = dangerZonesRes.list;
      this.dangerZonesColumns = handleColumns(dangerZonesRes.attributes);
    },
    async loadIotDeviceTracking() {
      const projectId = this.projectInfo[0].id;
      const iotDeviceTrackingRes = await this.withNoLoading(
        this.api('/api/iot-device-tracking/latest', { project_id: projectId }),
      );
      this.iotDeviceTrackingInfo = iotDeviceTrackingRes.list;
      this.iotDeviceTrackingColumns = handleColumns(iotDeviceTrackingRes.attributes);
      this.markMembersOnMap();
    },
    async loadCams() {
      const projectId = this.projectInfo[0].id;
      if (
        this.amI(projectId, '施工单位', '安全员', true) ||
        this.amI(projectId, '施工单位', '作业人员', true)
      ) {
        return;
      }
      const camsRes = await this.withNoLoading(
        this.api('/api/cams/list-v2', { project_id: projectId }),
      );
      if (camsRes) {
        this.camsInfo = camsRes.list;
        this.camsColumns = handleColumns(camsRes.attributes);
      }
      this.markMembersOnMap();
    },
    markMembersOnMap() {
      if (!this.map) return;
      const { map, mapUtils } = this.map;

      const clearMarker = (marker) => {
        map.removeOverLay(marker);
      };
      _.forEach(this.markers, clearMarker);

      const { addPointOverlay, addPolygonOverlay, toLngLat, buildInfoWindow } = mapUtils;
      if (!this.markerGroupStates.opsZonesLogs) {
        _.forEach(this.opsZonesLogs, (log) => {
          const zone = _.get(log, ['xOpsZone']);
          if (_.get(zone, ['xDangerZone', 'op_type']) === '区域危险源') {
            let fences = _.get(zone, ['xDangerZone', 'location_amap']);
            try {
              fences = JSON.parse(fences);
              const containsWd = Boolean(
                _.find(_.get(zone, ['xOpsZonesDangerTypes']), (findItem) => {
                  return _.get(findItem, ['xOpsDangerType', 'degree']) === '危大';
                }),
              );
              const containsCwd = Boolean(
                _.find(_.get(zone, ['xOpsZonesDangerTypes']), (findItem) => {
                  return _.get(findItem, ['xOpsDangerType', 'degree']) === '超危大';
                }),
              );
              _.forEach(fences, (fence) => {
                let marker = null;
                let firstPoint = null;

                const fencePoints = _.map(fence, (point) => {
                  const ret = toLngLat(point);
                  if (!firstPoint) {
                    firstPoint = ret;
                  }
                  return ret;
                });
                const polygon = addPolygonOverlay(fencePoints, {
                  color: '#105699',
                  opacity: 0.8,
                  weight: 2,
                  fillColor: '#2370cb',
                  fillOpacity: 0.2,
                  infoWindow: {
                    html: `施工部位名称：${zone.title}
${containsWd ? '危大 √' : ''} ${containsCwd ? '超危大 √' : ''}
人数：${this.formatNumber(log.workersCount)}
开始实施时间：${this.formatDateTime(log.startAt)}
实施终止时间：${this.formatDateTime(log.endAt)}`
                      .split('\n')
                      .join('<br>'),
                  },
                });
                const groupKey = `施工部位_${log.id}`;
                this.addMarkerToGroup(polygon, groupKey);
                this.activeMarkerGroups.push(groupKey);
                this.markers.push(marker);
              });
            } catch (e) {
              console.log(e);
            }
          }
        });
        if (this.opsZonesLogs && this.opsZonesLogs.length) {
          this.markerGroupStates.opsZonesLogs = true;
        }
      }

      if (!this.markerGroupStates.members) {
        _.forEach(this.membersInfo, (memberInfo) => {
          const { location_amap, company_type, admintype } = memberInfo;
          if (!['施工单位', '监理单位'].includes(company_type)) {
            return true;
          }
          if (!location_amap) return true;

          const isEBadge = memberInfo.action === '18gps定位';

          const position = location_amap.split(',');
          let icon = this.getIconPng(getMapMarkerIcon(company_type, admintype));
          const marker = addPointOverlay(toLngLat(position), this.getIconPng(icon), {
            iconSize: this.getIconSize(icon),
            infoWindow: {
              html: [
                `姓名：${_.get(memberInfo, 'display_name')}`,
                `用户名：${_.get(memberInfo, 'username')}`,
                `工种：${_.get(memberInfo, 'worktype', '') || '-'}`,
                `最近签到时间：${moment(_.get(memberInfo, 'added')).format('YYYY-MM-DD HH:mm:ss')}`,
                ...(isEBadge ? [`定位信息来自电子工牌`] : []),
                ...alertMessages,
              ].join('<br>'),
            },
          });

          const alertMessages = [];
          if (this.iotAlerts.members[memberInfo.member_id]) {
            alertMessages.push(
              '最近报警：',
              ..._.map(this.iotAlerts.members[memberInfo.member_id], (item) => '- ' + item.message),
            );
          }

          const groupKey = [company_type, admintype].join('');
          this.activeMarkerGroups.push(groupKey);
          this.addMarkerToGroup(marker, groupKey);
          this.markers.push(marker);

          if (this.iotAlerts.members[memberInfo.member_id]) {
            addPointOverlay(
              toLngLat(position),
              this.getIconPng(getMapMarkerIcon('utils', 'alert')),
              {
                iconSize: getIconSize('alert'),
              },
            );
          }
        });
        if (this.membersInfo && this.membersInfo.length) {
          this.markerGroupStates.members = true;
        }
      }
      if (!this.markerGroupStates.iotDeviceTrackingInfo) {
        _.forEach(this.iotDeviceTrackingInfo, (trackingInfo) => {
          const { location_amap, company_type, admintype } = trackingInfo;
          if (!location_amap) return true;
          const alertMessages = [];
          if (this.iotAlerts.iotDevices[trackingInfo.id]) {
            alertMessages.push(
              '最近报警：',
              ..._.map(this.iotAlerts.iotDevices[trackingInfo.id], (item) => '- ' + item.message),
            );
          }
          const position = location_amap.split(',');
          const marker = addPointOverlay(
            toLngLat(position),
            this.getIconPng(getMapMarkerIcon('utils', 'IOT_DEVICE')),
            {
              iconSize: getIconSize('IOT_DEVICE'),
              infoWindow: {
                html: [
                  `物联网设备：${_.get(trackingInfo, 'title')}`,
                  `设备编号：${_.get(trackingInfo, 'device_sn')}`,
                  `最近定位时间：${_.get(trackingInfo, 'added', '-')}`,
                  ...alertMessages,
                ].join('<br>'),
              },
            },
          );

          if (this.iotAlerts.iotDevices[trackingInfo.id]) {
            addPointOverlay(
              toLngLat(position),
              this.getIconPng(getMapMarkerIcon('utils', 'alert')),
              {
                iconSize: getIconSize('alert'),
              },
            );
          }
          this.addMarkerToGroup(marker, `危险源设备_${trackingInfo.id}`);
        });
        if (this.iotDeviceTrackingInfo && this.iotDeviceTrackingInfo.length) {
          this.markerGroupStates.iotDeviceTrackingInfo = true;
        }
      }

      if (!this.markerGroupStates.camsInfo) {
        _.forEach(this.camsInfo, (camInfo) => {
          const { location_amap } = camInfo;
          if (!location_amap) return true;
          const position = location_amap.split(',');
          const marker = addPointOverlay(
            toLngLat(position),
            this.getIconPng(getMapMarkerIcon('utils', 'CAM')),
            {
              iconSize: getIconSize('CAM'),
              infoWindow: {
                html: [
                  `监控设备：${_.get(camInfo, 'title')}`,
                  `设备状态${_.get(camInfo, 'state') ? '在线' : '离线'}`,
                ].join('<br>'),
              },
            },
          );
          const groupKey = `监控设备_${camInfo.id}`;
          this.addMarkerToGroup(marker, groupKey);
          this.markers.push(marker);
        });
        if (this.camsInfo && this.camsInfo.length) {
          this.markerGroupStates.camsInfo = true;
        }
      }

      _.forEach(this.markersGroups, (group, groupName) => {
        _.forEach(group, (marker) => {
          if (this.activeMarkerGroups.includes(groupName)) {
            P.map.addOverLay(marker);
          } else {
            P.map.removeOverLay(marker);
          }
        });
      });
    },
    getIconPng(icon) {
      return getIconPng(icon);
    },
    getIconSize(icon) {
      return;
    },
    addMarkerToGroup(marker, groupName) {
      if (!this.markersGroups[groupName]) {
        this.markersGroups[groupName] = [];
      }
      this.markersGroups[groupName].push(marker);
    },

    toggleAutoRefreshOn() {
      this.isAutoRefreshOn = !this.isAutoRefreshOn;
    },
    controlAutoRefresh(targetState) {
      if (this.tiAutoRefresh) {
        clearInterval(this.tiAutoRefresh);
      }
      if (this.targetState) {
        this.tiAutoRefresh = setInterval(this.loadData, 60 * 1000);
      }
    },
    async syncGpsoo() {
      await this.withNoLoading(this.api('/api/sync-gpsoo'));
    },
    formatNumber(value) {
      return Number(value || 0) || '-';
    },
    formatDateTime(value) {
      const mValue = moment(value);
      if (!mValue.isValid()) {
        return '-';
      }
      return mValue.format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>

<style lang="less" scoped>
.info-line {
  padding: 10px;
  border-bottom: solid 1px #eee;
}

.map-markers {
  & > span {
    flex: 1 0 50%;
  }
}

.map-marker {
  height: 16px;
}

.map-view {
  /deep/ .el-popover__reference {
    margin: 2px 0;
  }

  .map-control {
    margin-bottom: 4px;
  }
}
</style>
