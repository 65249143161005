import { render, staticRenderFns } from "./SelectOpsDangerType.vue?vue&type=template&id=b257dd38&scoped=true&lang=pug"
import script from "./SelectOpsDangerType.vue?vue&type=script&lang=js"
export * from "./SelectOpsDangerType.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b257dd38",
  null
  
)

export default component.exports