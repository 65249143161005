<template lang='pug'>
  div
    Legends(
      title="人员考勤"
      :list="legends"
      sub-window='checkins'
      @sub-window='$emit("sub-window", $event)'
    ).mb
    BarChart(:legends="legends" :list="signins" round tail="共#人").mb
    RingsChart(
      :configs="rings"
      :list="projects"
    )
    LineChart(
      :list="history"
      :legends="legends"
      x="date"
      area
    )
</template>

<script>
import { _ } from '@yishitec/web';
import BarChart from '../Charts/BarChart.vue';
import RingsChart from '../Charts/RingsChart.vue';
import LineChart from '../Charts/LineChart.vue';
import Legends from '../Legends.vue';

export default {
  name: 'BoardMemberCheckins',
  props: {
    projects: Array,
    history: Array,
  },
  emits: ['sub-window'],
  components: {
    BarChart,
    LineChart,
    RingsChart,
    Legends,
  },
  data() {
    const legends = [
      {
        color: '#73F39A',
        title: '施工单位管理人员',
        name: 'sgIn',
      },
      {
        color: '#6690FF',
        title: '监理单位人员',
        name: 'jlIn',
      },
      {
        color: '#6C68EE',
        title: '施工单位作业人员',
        name: 'workerIn',
      },
    ];
    return {
      legends,
      rings: [
        {
          key: 'sg',
          numberBy: legends[0].name,
          totalBy: 'sgOn',
          color: legends[0].color,
          hint: '登录/在场',
          label: '#人',
        },
        {
          key: 'jl',
          numberBy: legends[1].name,
          totalBy: 'jlOn',
          color: legends[1].color,
          hint: '登录/在场',
          label: '#人',
        },
        {
          key: 'worker',
          numberBy: legends[2].name,
          totalBy: 'workerOn',
          color: legends[2].color,
          hint: '交底/在场',
          label: '#人',
        },
      ],
    };
  },
  computed: {
    signins() {
      const sum = (name) => {
        return _.sumBy(this.projects, (i) => i[name]);
      };
      return [
        {
          id: 1,
          title: '全部考勤',
          sgIn: sum('sgIn'),
          jlIn: sum('jlIn'),
          workerIn: sum('workerIn'),
        },
      ];
    },
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
.mb {
  margin-bottom: 15px;
}
</style>
