<template lang="pug">
el-upload(
  :action="uploadAction",
  :on-success="handleUploadSuccess",
  :on-error="handleUploadError",
  :show-file-list="listType !== 'none'",
  :list-type="listType",
  :file-list="fileList",
  :before-upload="beforeUpload",
  :on-preview="handlePreview",
  :on-remove="handleRemove",
  :multiple="multiple"
)
  slot
    el-button(type="primary", round)
      i.el-icon-plus.el-icon--left
      | {{ label || '上传文件' }}
</template>

<script>
import { _ } from '@yishitec/web';

export default {
  name: 'SspUpload',
  props: {
    action: { type: String, default: '' },
    value: { type: String, default: '' },
    listType: { type: String, default: 'picture' },
    label: { type: String, default: null },
    multiple: { type: Boolean, default: false },
  },
  emits: ['input', 'success', 'error', 'beforeUpload'],
  data() {
    return {};
  },
  computed: {
    uploadAction() {
      return this.action || '/api/upload';
    },
    fileList() {
      if (!this.value) return [];
      const file = String(this.value || '');
      const name = _.last(String(file || '').split('/'));
      return [
        {
          name,
          url: file,
        },
      ];
    },
  },
  methods: {
    handleUploadSuccess(ev) {
      if (_.get(ev, ['message'])) {
        const message = _.get(ev, 'message');
        this.$emit('error', message);
        return;
      }
      this.$emit('success', this.$get(ev, ['data']));
      this.$emit('input', `${this.$get(ev, ['data', 'url'])}`);
    },
    handleUploadError(ev) {
      // eslint-disable-next-line no-console
      console.log({ handleUploadError: ev });
      this.$emit('error', ev.message);
    },
    beforeUpload(ev) {
      this.$emit('beforeUpload', ev);
    },
    handlePreview(file) {
      const previewWindow = window.open('');
      previewWindow.location.href = file.url;
    },
    handleRemove() {
      this.$emit('input', '');
    },
  },
};
</script>

<style lang="scss" scoped></style>
