<template>
  <div>
    <ui-page-header title="用户行为日志" />
    <ui-stage>
      <ui-admin-table
        :attributes="attributes"
        :data="data"
        :with-create="false"
        :with-actions="false"
      >
        <template v-slot:column_behavior="{ row }">
          {{ row.behavior | behaviorCode }}
        </template>
        <template v-slot:column_model_name="{ row }">
          {{ row.model_name | modelCode }}
        </template>
        <template v-slot:column_model_id="{ row }">
          <template v-if="getViewUrl(row.model_name, row.model_id)">
            <router-link :to="getViewUrl(row.model_name, row.model_id)" target="_blank">
              查看{{ row.model_name | modelCode }} #{{ row.model_id }}
            </router-link>
          </template>
          <template v-else> #{{ row.model_id }} </template>
        </template>
      </ui-admin-table>
    </ui-stage>
  </div>
</template>

<script>
import { _ } from '@yishitec/web';

const behaviorCodes = {
  CAM__START: '开始查看监控',
  CAM__STOP: '停止查看监控',
};

const modelCodes = {
  Cam: '监控',
};

const apiCodes = {
  Cam: 'cams',
};

export default {
  name: 'UserLogs',
  data() {
    return {
      attributes: [],
      data: [],
    };
  },
  mounted() {
    this.loadData();
  },
  filters: {
    behaviorCode(code) {
      return _.get(behaviorCodes, code, code);
    },
    modelCode(code) {
      return _.get(modelCodes, code, code);
    },
  },
  methods: {
    async loadData() {
      const { attributes, list } = await this.withLoading(this.api('/api/user-logs/list'));
      this.attributes = attributes;
      this.data = list;
    },
    getViewUrl(modelName, modelId) {
      let api = _.get(apiCodes, modelName);
      if (!api) return null;
      if (_.isString(api)) {
        const apiCode = api;
        api = (id) => `/admin/${apiCode}/edit/${id}`;
      }
      return api(modelId);
    },
  },
};
</script>
