<template lang="pug">
div.ssp-sidebar
  div(v-if="withLeftSidebar").sidebar
    slot(name="sidebar")
  div(
    :class="{ 'with-sidebar': withLeftSidebar }"
  ).stage
    slot
</template>

<script>
export default {
  name: 'SspSidebar',
  props: {
    withLeftSidebar: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {},
  },
};
</script>

<style lang="scss" scoped>
.ssp-sidebar {
  @include ui-flex-row(flex-start, flex-start);
}
.sidebar {
  flex: 0 0 168px;
  margin-right: 30px;
}
.stage {
  flex: 0 0 auto;
  width: 100%;
  &.with-sidebar {
    width: calc(100% - 198px);
  }
}
</style>
