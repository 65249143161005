<template lang="pug">
div.bars
  ChartLabel(
    v-for="item in list"
    :key="item.id"
    :title="item.title"
  )
    .bar(:class="{ round }")
      .part(
        v-if="!isAvailable(item)"
      ) 暂无数据
      template(v-else)
        .part(
          v-for="legend in legends"
          :key="legend.title"
          :style="getPartStyles(item, legend)"
        ) {{ getPercentage(item, legend) }}
        .part(
          v-if="isAvailable(item) && !getTotal(item)"
          :style="getLastPartStyles(item, lastLegend)"
        ) {{ getPercentage(item, lastLegend) }}
    .tail(v-if="tail") {{ tail.replace('#', getTotal(item)) }}
</template>

<script>
import { _, numeral } from '@yishitec/web';
import ChartLabel from './ChartLabel.vue';

export default {
  name: 'BarChart',
  props: {
    legends: Array,
    list: Array,
    tail: String,
    round: Boolean,
    availableBy: String,
  },
  components: { ChartLabel },
  computed: {
    lastLegend() {
      return _.last(this.legends);
    },
  },
  methods: {
    getPartStyles(item, legend) {
      return {
        flex: `${item[legend.name] || 0} 0 1px`,
        background: legend.color,
      };
    },
    getLastPartStyles(item, legend) {
      return {
        flex: '1 0 1px',
        background: legend.color,
      };
    },
    getTotal(item) {
      return _.sumBy(this.legends, (i) => item[i.name]) || 0;
    },
    getPercentage(item, legend) {
      const number = item[legend.name] || 0;
      const total = this.getTotal(item) || 1;
      return numeral(number / total).format('0,0%');
    },
    isAvailable(item) {
      if (this.availableBy) {
        return item[this.availableBy];
      }
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
.bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 2px;
  overflow: hidden;
  flex: 1 0 1px;

  &.round {
    border-radius: 1000px;
  }
}

.part {
  flex: 1 0 1px;
  background: fadeout(#fff, 80);
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  color: #000;
  overflow: hidden;
}

.tail {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  flex: 0 0 80px;
}
</style>
