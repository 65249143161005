<template lang="pug">
el-dialog(
  v-if="parentId"
  v-loading="loading"
  title="应用模板"
  visible
  @close="emitClose"
)
  SspFormItem(
    label="选择模板"
    type="select"
    :options="templatesOptions"
    v-model="form.templateId"
  )
  div.mt
    el-button(
      type="primary"
      @click="handleSubmit"
    ) 应用
</template>

<script>
import { _ } from '@yishitec/web';

export default {
  name: 'ProgressApplyTemplateModal',
  props: {
    parentId: { type: [String, Number], default: null },
  },
  emits: ['close', 'refresh'],
  data() {
    return {
      form: {},
      loading: false,
      templatesOptions: [],
    };
  },
  computed: {},
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.form = {
        templateId: null,
      };
      const templates = await this.withLoading(
        this.api('/api/progress-templates/list-templates', {
          parentId: this.parentId,
        }),
      );
      this.templatesOptions = _.map(templates, (item) => {
        const { id: value, title } = item;
        return {
          value,
          title,
        };
      });
    },
    emitClose() {
      this.$emit('close');
    },
    emitRefresh() {
      this.$emit('refresh');
    },
    async handleSubmit() {
      this.loading = true;
      const res = await this.withLoading(
        this.api('/api/progress-templates/apply-template', {
          ...this.form,
          parentId: this.parentId,
        }),
      );
      if (res) {
        this.$message({
          type: 'success',
          message: '保存成功',
        });
        this.emitRefresh();
        this.emitClose();
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
