import { _, axios } from '@yishitec/web';

export default async (url, options) => {
  const { body: data, ...axiosOptions } = options;
  const res = await axios(url, {
    method: 'post',
    data,
    ...axiosOptions,
  });
  return _.get(res, 'data.data') || res.data;
};
