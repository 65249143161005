<template>
  <div v-if="text" :class="['ui-notice', { expanded }]" @click="toggleExpand">
    <div class="ui-notice__text">
      {{ text }}
    </div>
    <div :class="['ui-notice__expand', { expanded }]" />
  </div>
</template>

<script>
export default {
  name: 'UiNotice',
  props: {
    url: String,
  },
  data() {
    return {
      text: '',
      expanded: false,
    };
  },
  watch: {
    url() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const res = await this.api(this.url)();
      if (res && res.text) {
        this.text = res.text;
      } else {
        this.text = '';
      }
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-notice {
  margin-bottom: 15px;
  background: rgba(#ffc750, 0.1);
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
  box-sizing: border-box;
  max-height: 200px;
  overflow: hidden;
  &::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    background: linear-gradient(to top, rgba(#ffc750, 1), rgba(#ffc750, 0));
    transition: all 0.5s;
  }
  &.expanded {
    max-height: none;
    &::after {
      bottom: -30px;
      opacity: 0;
    }
  }
  &__text {
    flex: 1 0 1px;
    margin-right: 16px;
    font-size: 12px;
    line-height: 1.6;
    white-space: pre-line;
  }
  &__expand {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 5px;
    height: 9px;
    margin-left: 10px;
    background: url('../assets/images/arrow-right.png') no-repeat center center;
    background-size: contain;
    transform: rotate(90deg);
    transform-origin: center;
    transition: all 0.3s;
    &.expanded {
      transform: rotate(-90deg);
    }
  }
}
</style>
