<template>
  <div class="center cam-player-switch">
    <CamVideoJs
      v-if="vCam.serviceType === 'HLS'"
      :cam="vCam"
      :isHd="isHd"
      :isLive="isLive"
      :start="start"
      :end="end"
      ref="playerJs"
    />
    <CamPlayerEz
      v-else
      :cam="vCam"
      :isHd="isHd"
      :isLive="isLive"
      :start="start"
      :end="end"
      ref="playerEz"
    />
  </div>
</template>

<script>
import CamVideoJs from './CamVideoJs.vue';
import CamPlayerEz from './CamPlayerEz.vue';

export default {
  name: 'CamPlayer',
  props: {
    cam: { type: Object, default: null },
    isHd: { type: Boolean, default: false },
    isLive: { type: Boolean, default: true },
    start: { type: [Date, Number, Date], default: null },
    end: { type: [Date, Number, Date], default: null },
  },
  components: {
    CamVideoJs,
    CamPlayerEz,
  },
  computed: {
    vCam() {
      return {
        ...this.cam,
      };
    },
  },
  methods: {
    updateUrl() {
      const player = this.getPlayer();
      if (player && player.updateUrl) {
        player.updateUrl();
      }
    },
    getPlayer() {
      if (!this.vCam) return null;
      if (this.vCam.serviceType === 'HLS') {
        return this.$refs.playerJs;
      }
      return this.$refs.playerEz;
    },
  },
};
</script>

<style lang="less" scoped>
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
</style>
