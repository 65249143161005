<template lang="pug">
div.progress-images-manager
  div(v-if="activeItem")
    div.image-meta
      div.meta-index {{ activeItem.index + 1 }}
      div.meta-info
        span {{ activeItem.filename }}
        span 大小：{{ activeItem.size | formatFileSize }}
        span(v-if='false') 上传时间：{{ activeItem.createdAt | formatDateTime }}
      div(v-if="!isPreview").meta-edit
        ui-link(
          type="primary"
          @click="showEditModal"
        ).meta-edit-button.ml 编辑
        ProgressImageEditor(
          @refresh="handleImageEditorRefresh"
          visible
          v-if="editItem"
          :data="editItem"
          @close="closeEditModal"
        )
    resize-observer(@notify="updateVersion")
    ProgressImageViewer(
      :key="version"
      :item="activeItem"
      match
      :highlightColor="highlightColor"
      :highlightColors="highlightColors"
      :highlightColorsGreen="highlightColorsGreen"
      :addPathForItemId="selectedChildItemId"
      @bindPaths="handleBindPaths"
      :children="children"
    )
  div(v-else-if="isPreview").images-empty
  div.images-toolbar
    div(v-if="!isPreview").images-add
      SspUpload(
        v-loading="uploadLoading"
        label="上传新的图示"
        @beforeUpload="startUploadLoading"
        @error="handleUploadError"
        @success="loadImgInfo"
        listType="none"
      )
      canvas(ref="canvas").info-canvas
    div.images-thumbs
      div(
        v-for="item in value"
        :key="item.id"
        @click="handleClickItem(item)"
      ).images-thumb
        img(
          :src="item.url"
          :class="{ active: active === item.id }"
        ).thumb-img
</template>

<script>
/* eslint-disable object-curly-newline */
import { _, numeral, moment } from '@yishitec/web';
import * as analyzeImageColors from '../utils/analyzeImageColors';
import ProgressImageViewer from './ProgressImageViewer.vue';
import ProgressImageEditor from './ProgressImageEditor.vue';
import SspUpload from './ssp/SspUpload.vue';

export default {
  name: 'ProgressImagesManager',
  props: {
    value: { type: Array, default: () => [] },
    progressId: { type: Number, default: null },
    selectedChildItemId: { type: Number, default: null },
    highlightColor: { type: String, default: null },
    highlightColors: { type: Array, default: null },
    highlightColorsGreen: { type: Array, default: null },
    isPreview: { type: Boolean, default: false },
    children: { type: Array, default: () => [] },
  },
  emits: ['refresh', 'input', 'update:selectedChildItemId'],
  components: { ProgressImageViewer, SspUpload, ProgressImageEditor },
  data() {
    return {
      active: null,
      uploadLoading: false,
      editItem: null,
      version: 1,
    };
  },
  computed: {
    idsMap() {
      return _.zipObject(
        _.map(this.value, (item) => item.id),
        this.value,
        (item) => item.url,
      );
    },
    activeItem() {
      return this.idsMap[this.active];
    },
  },
  watch: {
    value() {
      this.loadData();
    },
    activeItem() {
      this.version += 1;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (!this.activeItem) {
        const firstItem = _.get(this.value, 0);
        if (firstItem) {
          this.handleClickItem(firstItem);
        }
      }
    },
    handleClickItem(item) {
      this.active = item.id;
    },
    startUploadLoading() {
      this.uploadLoading = true;
    },
    async loadImgInfo(info) {
      const messageLoading = this.$message({
        type: 'warning',
        message: '正在分析图片...',
      });
      try {
        const imgInfo = await analyzeImageColors.handleUploadRes(info, this.$refs.canvas);
        const { url, filename, size, width, height } = imgInfo;
        const res = await this.withLoading(
          this.api('/api/progress/add-image', {
            url,
            filename,
            size,
            width,
            height,
            progressId: this.progressId,
          }),
        );
        if (!res) {
          throw new Error('保存失败');
        }
        this.uploadLoading = false;
        this.$emit('input', [...this.value, { ...res, index: this.value.length }]);
        this.$nextTick(() => {
          this.handleClickItem(res);
          this.$nextTick(() => {
            this.showEditModal();
          });
        });
      } catch (e) {
        this.handleUploadError(e);
      }
      messageLoading.close();
    },
    handleUploadError(e) {
      this.$message({
        type: 'error',
        message: e.message || '无法处理上传的图片',
      });
      this.uploadLoading = false;
    },
    showEditModal() {
      this.editItem = this.idsMap[this.active];
    },
    closeEditModal() {
      this.editItem = null;
    },
    triggerRefresh() {
      this.$emit('refresh');
    },
    handleImageEditorRefresh() {
      this.triggerRefresh();
    },
    async handleBindPaths(ev) {
      const childrenIds = _.map(this.children, (item) => item.id);
      const res = await this.withLoading(
        this.api('/api/progress/bind-paths', {
          childrenIds,
          paths: ev,
        }),
      );
      if (res) {
        this.$message({
          type: 'success',
          message: '保存成功',
        });
        this.$emit('update:selectedChildItemId', null);
        this.$emit('refresh');
      }
    },
    updateVersion() {
      this.version += 1;
    },
  },
  filters: {
    formatFileSize(value) {
      if (value < 1000) {
        return `${value} Bytes`;
      }
      if (value < 1e6) {
        return `${numeral(value / 1000).format(0, 0.0)}KB`;
      }
      if (value < 1e9) {
        return `${numeral(value / 1e6).format(0, 0.0)}MB`;
      }
      return `${numeral(value / 1e9).format(0, 0.0)}GB`;
    },
    formatDateTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>

<style lang="scss" scoped>
.images-toolbar {
  margin: 30px 0;
  @include ui-flex-row(ui-flex-row, center);
}

.images-add {
  margin-right: 15px;
}

.button-icon {
  display: inline-block;
  margin-right: 8px;
  height: 14px;
}

.images-thumbs {
  flex: 1 0 1px;
  @include ui-flex-row(center);
  flex-wrap: wrap;
}

.images-thumb {
  flex: 0 0 auto;
  cursor: pointer;
  margin: 5px;
}

.thumb-img {
  display: block;
  height: 55px;
  border-radius: 3px;
  border: solid 1px #cbcbcb;
  box-sizing: border-box;

  &.active {
    border: solid 3px #2f3ced;
  }
}

.full-img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
}

.info-canvas {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 1px;
  height: 1px;
  display: none;
}

.image-meta {
  margin-bottom: 10px;
  background: #74899a;
  @include ui-flex-row(space-between, stretch);
  color: #fff;
}

.meta-info {
  flex: 1 0 1px;
  @include ui-flex-row(flex-start, center);
  flex-wrap: wrap;

  span {
    display: inline-block;
    flex: 0 0 auto;
    margin-right: 10px;
  }
}

.meta-index {
  flex: 0 0 80px;
  background: #22445f;
  margin-right: 10px;
  @include ui-flex-row;
  padding: 8px;
  font-weight: bolder;
}

.meta-edit {
  @include ui-flex-row;
  margin: 0 10px;
}

.meta-edit-button {
  color: #fff;
}

.images-empty {
  background: rgba(black, 0.05);
  min-height: calc(100vh - 480px);
}
</style>
