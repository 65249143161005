<template lang="pug">
div
  div.p-trace
    div.p-container
      el-breadcrumb
        el-breadcrumb-item(
          to="/progress/projects"
        ) 项目管理
        el-breadcrumb-item(
          to="/progress/projects"
        ) 项目列表
        el-breadcrumb-item 项目详情
  div(v-loading="loading").p-page
    div(v-if="!data" style="{ height: 300px }")
    div(v-else).p-container
      ui-flex(row between a-center)
        div
          div.p-title {{ data.title }}
            span.hint
              span 系统ID: {{ data.id }}
              el-tag(size="mini").ml 进行中
        span
          router-link(
            :to="`/progress/progress/view/${data.id}`"
            target="_blank"
          ).inline-block.mr-2
            el-button(round plain type="primary") -> 前往作业计划
          router-link(
            :to="`/admin/projects/edit/${data.id}`"
            target="_blank"
          )
            el-button(round plain type="primary") -> 前往项目管理
      div.p-card
        div(v-if="false").p-card-cover
          div.p-card-title 当前项目的相关文档
        div.p-card-title 项目详情
        SspFormItem(
          v-for="field in attributes"
          :key="field.key"
          v-model="data[field.key]"
          v-bind="field"
          type="custom"
        )
          div.field-view {{ data[field.key] || '/' }}
      div.p-card(v-if='vWithProgressModule')
        div.p-card-title 管理图示配置
        AdminList(
          module='progress-blueprint'
          prefix='/progress/projects/blueprints'
          apiPrefix='/api/v3/progress-blueprint'
          :scope='{ projectId: data.id }'
          :newQuery='`?projectId=${data.id}`'
        )
</template>

<script>
export default {
  name: 'ProgressProjectEdit',
  props: {
    id: { type: [String, Number], default: null },
  },
  data() {
    return {
      loading: false,
      data: null,
    };
  },
  computed: {
    vWithProgressModule() {
      return this.data.withProgressModule;
    },
    attributes() {
      return [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'title',
          label: '项目名称',
        },
        {
          key: 'location',
          label: '项目地址',
        },
      ];
    },
  },
  watch: {
    id() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      if (!this.id) return;
      this.loading = true;
      const res = await this.withLoading(this.api(`/api/projects/edit/${this.id}`));
      if (res) {
        this.data = res.item;
      } else {
        this.data = null;
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.field-view {
  min-height: 40px;
  @include ui-flex-row(flex-start, center);
}

.inline-block {
  display: inline-block;
}

.mr-2 {
  margin-right: 12px;
}
</style>
