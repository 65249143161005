<template lang="pug">
div.ssp-empty-card
  div
    div.title {{ title || '找不到指定的数据' }}
    div(v-if="hint").hint {{ hint }}
</template>

<script>
export default {
  name: 'SspEmptyCard',
  props: {
    title: { type: String, default: '' },
    hint: { type: String, default: '' },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {},
  },
};
</script>

<style lang="scss" scoped>
.ssp-empty-card {
  background: url('../../assets/images/progress/empty-card.png') no-repeat left center;
  background-color: #fff;
  padding: 32px;
  border-radius: 5px;
  min-height: 230px;
  box-sizing: border-box;
  padding-left: 180px;
  @include ui-flex-col(center, flex-start);
  @include xs {
    padding: 16px;
  }
}
.title {
  font-size: 20px;
  line-height: 42px;
  font-weight: bolder;
  color: #1c1d21;
}
.hint {
  margin-top: 10px;
  font-size: 14px;
  line-height: 21px;
  color: #8181a5;
}
</style>
