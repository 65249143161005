/* eslint-disable global-require */

const dict = {
  cams: require('./subWindows/cams'),
  projects: require('./subWindows/projects'),
  checkins: require('./subWindows/checkins'),
  members: require('./subWindows/members'),
  materials: require('./subWindows/materials'),
  supervisionLogs: require('./subWindows/supervisionLogs'),
  dangerZones: require('./subWindows/dangerZones'),
  opsZones: require('./subWindows/opsZones'),
};

export default (name, id) => {
  const item = dict[name] || null;
  if (item && id) {
    item.apiId = id;
  }
  return item;
};
