<template lang="pug">
div(:class="{ 'with-hover': withHover }" @click="handleClick").side-card
  .card-hover(v-if="withHover")
    i.el-icon-arrow-right.arrow
  .title {{ title }}
  .badge(v-if="badge")
    .badge-title {{ badgeTitle }}&nbsp;{{ badge }}
  .list(v-if="list && list.length")
    SideCardListItem(
      v-for="item in list"
      :key="item.title"
      v-bind="item"
    )
</template>

<script>
import SideCardListItem from './SideCardListItem.vue';

export default {
  name: 'SideCard',
  props: {
    title: String,
    badge: Number,
    badgeTitle: String,
    list: Array,
    n: Number,
    total: Number,
    withHover: Boolean,
    subWindow: String,
  },
  emits: ['sub-window'],
  components: {
    SideCardListItem,
  },
  methods: {
    handleClick() {
      if (this.withHover && this.subWindow) {
        this.$emit('sub-window', this.subWindow);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.side-card {
  background: fadeout(#fff, 95);
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
  padding: 12px 20px;
  overflow: hidden;

  &.with-hover {
    cursor: pointer;
  }

  .card-hover {
    position: absolute;
    top: 50%;
    border-radius: 1000px 0 0 1000px;
    right: -106px;
    width: 106px;
    height: 106px;
    margin-top: -53px;
    opacity: 0;
    transition: all 0.5s;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .arrow {
      margin-left: 16px;
      font-size: 24px;
      color: #fff;
      display: block;
    }
  }

  &:hover,
  &.hover {
    .card-hover {
      opacity: 1;
      background-image: linear-gradient(
        270deg,
        rgba(236, 213, 137, 0) 0%,
        fadeout(#d4ab51, 60) 100%
      );
      right: -30px;
    }
  }
}

.title {
  font-size: 15px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 12px;
}

.badge {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  box-sizing: border-box;
  border-radius: 0 5px 0 100px;
  background: fadeout(#000, 80);
  line-height: 30px;
  padding: 0 14px 0 25px;
  font-size: 12px;
  color: #9ba6ad;
}
</style>
