<template>
  <div class="app-reports" v-if="layout">
    <ReportComponent
      v-for="(item, itemIndex) in layout"
      :key="itemIndex"
      :item="item"
      :reportsData="data"
    />
  </div>
</template>

<script>
import ReportComponent from './ReportComponent.vue';

export default {
  name: 'AppReports',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    layout: {
      type: Array,
      default: null,
    },
  },
  components: {
    ReportComponent,
  },
};
</script>

<style lang="scss">
.app-reports {
  font-size: 16px;
}
</style>
