<template lang="pug">
.left-sidebar-section-title
  slot
</template>

<script>
export default {
  name: 'LeftSidebarSectionTitle',
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.left-sidebar-section-title {
  font-size: 14px;
  font-weight: bolder;
  text-align: center;
  background: rgba(#fff, 0.2);
  border-radius: 1000px;
  line-height: 2;
  margin-top: 20px;
}
</style>
