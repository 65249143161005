<template lang="pug">
.bottom-left-legend-item
  ScreenUserIcon(:color="color").bottom-left-legend-badge
  span {{ title }}
</template>

<script>
import ScreenUserIcon from '../../../components/ScreenUserIcon.vue';

export default {
  name: 'BottomLegendItem',
  props: {
    title: String,
    color: String,
  },
  components: {
    ScreenUserIcon,
  },
};
</script>

<style lang="scss" scoped>
.bottom-left-legend-item {
  margin: 7px 0;
}

.bottom-left-legend-badge {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  box-sizing: border-box;

  &.is-orange {
    color: #ffb946;
    border-color: #ffb946;
  }

  &.is-blue {
    color: #4d7bf3;
    border-color: #4d7bf3;
  }

  &.is-green {
    color: #45b42b;
    border-color: #45b42b;
  }
}
</style>
