<template>
  <div>
    <div class="app-nav__top" />
    <div class="app-nav">
      <ui-container>
        <ui-flex row between a-center>
          <ui-flex zero>
            <ui-logo class="app-nav__logo" />
          </ui-flex>
          <ui-flex row zero>
            <app-nav-item is-external link="/api/redirect/ios" target="_blank">
              iOS 应用下载
            </app-nav-item>
            <app-nav-item is-external link="/api/redirect/android-checkin" target="_blank">
              安卓应用下载
            </app-nav-item>
            <app-nav-item is-external link="/admin" target="_blank">进入控制台</app-nav-item>
            <app-nav-item is-external link="/progress" target="_blank"
              >进入进度管理系统</app-nav-item
            >
            <template v-if="false">
              <app-nav-item v-if="isLoggedIn" link="/web">
                欢迎，{{ userDisplayName }}
              </app-nav-item>
              <app-nav-item v-else is-external> 用户登录 </app-nav-item>
            </template>
          </ui-flex>
        </ui-flex>
      </ui-container>
    </div>
  </div>
</template>

<script>
import AppNavItem from './AppNavItem.vue';

export default {
  name: 'AppNav',
  components: { AppNavItem },
  computed: {
    isLoggedIn() {
      return false;
    },
    userDisplayName() {
      return '管理用户';
    },
  },
};
</script>

<style lang="less">
.app-nav {
  margin: 30px 0;
  &__top {
    height: 5px;
    background: linear-gradient(-90deg, #71a4e6 0%, #1a4f95 100%);
  }
  &__logo {
    height: 50px;
  }
}
</style>
