<template>
  <div>
    <ui-stage>
      <ui-admin-table
        v-if="data"
        :attributes="attributes"
        :data="data"
        :module-url="moduleUrl"
        pagination-method="front-end"
        :with-create="false"
        :with-delete="false"
      >
        <template v-slot:column_notice="{ row }">
          <template v-if="String(row.notice).length <= abstractLength">
            {{ row.notice }}
          </template>
          <template v-else>
            <el-popover placement="bottom" width="300" trigger="hover">
              <div class="notice-content">{{ row.notice }}</div>
              <template v-slot:reference>
                <div>
                  {{ textAbstract(row.notice) }}
                </div>
              </template>
            </el-popover>
          </template>
        </template>
      </ui-admin-table>
    </ui-stage>
  </div>
</template>

<script>
import { adminList } from '@yishitec/web/mixins';

export default {
  name: 'List',
  mixins: [adminList],
  data() {
    return {
      abstractLength: 20,
    };
  },
  methods: {
    textAbstract(value) {
      const abstract = String(value || '').substr(0, this.abstractLength);
      return `${abstract}...`;
    },
  },
};
</script>

<style lang="less" scoped>
.notice-content {
  white-space: pre-line;
  height: 300px;
  overflow: auto;
  max-height: calc(100vh - 100px);
  box-sizing: border-box;
  padding-right: 20px;
}
</style>
