import { _ } from '@yishitec/web';
import permissions from './permissions';

export default {
  mixins: [permissions],
  data() {
    return {
      filter: '',
      filterName: '全部',
      projects: [],
    };
  },
  watch: {
    filter() {
      this.apiBodyData.filter = this.filter;
      this.fetchData();
      this.filterName = _.get(
        _.find(this.projects, (item) => item.id === this.filter),
        'title',
        '',
      );
    },
  },
  mounted() {
    this.apiBodyData.currentProjects = this.currentProjects;
    this.apiBodyData.filter = this.filter;
    this.fetchProjects();
  },
  methods: {
    async fetchProjects() {
      const { currentProjects } = this;
      const list = await this.withLoading(
        this.api('/api/get-projects', {
          currentProjects,
          flatten: 1,
        }),
      );
      const projects = [{ id: '', title: '全部' }];
      this.addCustomFilters(projects);
      projects.push(...list);
      this.projects = projects;
    },
    addCustomFilters() {},
  },
};
