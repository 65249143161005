<template>
  <div class="home">
    <app-nav />
    <app-slides />
    <app-features class="mt" />
    <app-footer style="margin-top: 30px" />
  </div>
</template>

<script>
import AppNav from '../components/AppNav.vue';
import AppSlides from '../components/AppSlides.vue';
import AppFeatures from '../components/AppFeatures.vue';
import AppFooter from '../components/AppFooter.vue';

export default {
  name: 'Home',
  components: {
    AppFooter,
    AppFeatures,
    AppSlides,
    AppNav,
  },
};
</script>
