<template lang="pug">
.bottom
  .bottom-left
    .bottom-left-upper
      ui-flex.bottom-left-title(row, a-center)
        .bottom-left-title-text 近14日登录人数
        .bottom-left-title-deco
      .bottom-left-title-caption 全部智慧工地项目
    .bottom-left-legends
      BottomLegendItem(
        title="监理单位管理人员"
      )
      BottomLegendItem(
        color="#4d7bf3"
        title="施工单位管理人员与安全员"
      )
      BottomLegendItem(
        color="#45b42b"
        title="施工单位作业人员"
      )
  BottomBarChart(
    v-if="history && history.list"
    :list="history.list"
    :max="history.valueMax"
    style="flex: 1 0 1px;"
  )
</template>

<script>
import BottomLegendItem from './BottomLegendItem.vue';
import BottomBarChart from './BottomBarChart.vue';

export default {
  name: 'Bottom',
  props: {
    history: Object,
  },
  components: { BottomLegendItem, BottomBarChart },
};
</script>

<style lang="less" scoped>
.bottom {
  position: absolute;
  bottom: 0;
  left: 222px;
  right: 270px;
  height: 166px;
  box-sizing: border-box;
  color: #fff;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.bottom-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex: 0 0 auto;
  margin-right: 4px;
}

.bottom-left-title {
  font-weight: bolder;
  margin-bottom: 1px;
}

.bottom-left-title-text {
  flex: 0 0 auto;
  margin-right: 1em;
}

.bottom-left-title-deco {
  flex: 1 0 1px;
  height: 1px;
  background: fadeout(#fff, 80);
}

.bottom-left-title-caption {
  color: fadeout(#fff, 40);
}

.bottom-left-legends {
  margin-top: 10px;
  font-size: 12px;
  padding-bottom: 10px;
}
</style>
