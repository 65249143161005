import { $fetch } from '../../composables';

export default (input) => {
  const {
    addPathForItemId = { value: null },
    currentImage = { value: null },
    refreshChildren = () => {
    },
  } = input || {};
  const handleBindPaths = async ev => {
    await $fetch('/api/v3/progress-blueprint-image/bind-paths', {
      body: {
        id: addPathForItemId.value,
        bindPaths: {
          paths: ev,
        },
      },
    });
    refreshChildren();
  };
  return {
    handleBindPaths,
  };
};
