<template lang="pug">
.right-pie
  AppPie(:value="signins", :width="130", :height="130", horizontal)
</template>

<script>
import AppPie from '../../../components/AppPie.vue';

export default {
  name: 'RightPie',
  props: {
    signins: Array,
  },
  components: { AppPie },
};
</script>

<style lang="less" scoped>
.right-pie {
  position: absolute;
  width: 233px;
  height: 114px;
  top: 22px;
  right: 16px;
}
</style>
