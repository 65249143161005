<template>
  <div :class="['report-card', dark]">
    <div class="card-title">{{ title }}</div>
    <div class="card-number" :style="{ color }">{{ number }}</div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ReportCard',
  props: {
    title: String,
    data: Array,
    reportsData: Object,
    dark: Boolean,
    color: String,
  },
  computed: {
    number() {
      return _.get(this.reportsData, this.data);
    },
  },
};
</script>

<style lang="scss">
.report-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: #eceef5;
  padding: 15px;
  font-size: 1em;
  font-weight: bolder;
  border-radius: 5px;
  &.dark {
    color: #fff;
  }
  .card-title {
    color: #1e3258;
  }
  .card-number {
    font-size: 1.4em;
  }
}
</style>
