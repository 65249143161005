<template>
  <div>
    <ui-stage v-if="data">
      <ui-tabs v-model="tab" type="border-card">
        <ui-tab-pane name="main" label="编辑">
          <ui-admin-form
            :compact="compact"
            :readonly="readonly"
            :fields="attributes"
            :model="data"
            :module-url="moduleUrl"
            with-delete
            @submit="handleSubmit"
          />
        </ui-tab-pane>
        <ui-tab-pane
          name="task-sets"
          label="闸机任务管理"
          v-if="data.type === '闸机' && withIotTasks"
        >
          <IotGatewayTaskSets :projectId="data.project_id" />
        </ui-tab-pane>
      </ui-tabs>
    </ui-stage>
  </div>
</template>

<script>
import { adminEdit } from '@yishitec/web/mixins';
import IotGatewayTaskSets from '../../../components/IotGatewayTasks/IotGatewayTaskSets.vue';

export default {
  name: 'Edit',
  components: {
    IotGatewayTaskSets,
  },
  mixins: [adminEdit],
  data() {
    return {
      withCustomFields: false,
      withIotTasks: true,
    };
  },
};
</script>
