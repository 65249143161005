<template>
  <div>
    <ui-stage>
      <div class="mb">
        <ui-alert type="success" :closable="false" class="mb">
          <h3>请选择日期 <small style="color: red">*</small></h3>
          <ui-datetime-picker v-model="date" type="date" class="mb" />
        </ui-alert>

        <div>
          <h3>今日是否施工</h3>
          <div>
            <ui-data-select :options="optionsShouldWork" v-model="shouldWork" />
          </div>
        </div>

        <div>
          <h3>交底内容：<small>每日施工部位及注意事项</small></h3>
          <ui-input
            type="textarea"
            :autosize="{ minRows: 10, maxRows: 20 }"
            v-model="notice"
            :readonly="!canEdit"
          ></ui-input>
        </div>

        <div v-if="isChildProject">
          <el-row :gutter="10">
            <el-col :span="8">
              <h3>现场照片</h3>
              <ui-uploads v-model="form.photos" :readonly="!canEdit"></ui-uploads>
            </el-col>
            <el-col :span="8">
              <h3>人员照片</h3>
              <ui-uploads v-model="form.workersPhoto" :readonly="!canEdit"></ui-uploads>
            </el-col>
            <el-col :span="8">
              <h3>人员数</h3>
              <ui-data-select v-model="form.workersCount" :options="workersCountPicker" />
            </el-col>
          </el-row>
          <div class="mt" v-if="pickers.selectedOpsZonesIds">
            <UiTransferList
              v-model="form.selectedOpsZonesIds"
              title="施工部位"
              :options="pickers.selectedOpsZonesIds.options"
              :readonly="!canEdit"
            />
          </div>
        </div>

        <div class="mt" v-if="pickers.selected_danger_zone_ids">
          <UiTransferList
            v-model="form.selected_danger_zone_ids"
            :options="pickers.selected_danger_zone_ids.options"
            title="危险源"
            :readonly="!canEdit"
          />
        </div>

        <ui-flex row center style="margin-top: 20px" v-if="canEdit">
          <ui-button type="primary" @click="handleSubmit" round style="width: 120px"
            >保存
          </ui-button>
        </ui-flex>
      </div>
    </ui-stage>
  </div>
</template>

<script>
import { formatDateTime, _, UiAdminForm } from '@yishitec/web';
import { adminList } from '@yishitec/web/mixins';
import UiTransferList from '../../../components/UiTransferList.vue';
import permissions from '../../../mixins/permissions';

export default {
  name: 'EditDailyDangerZones',
  components: { UiTransferList },
  mixins: [adminList, permissions],
  data() {
    const date = formatDateTime(undefined, 'YYYY-MM-DD');
    return {
      projectId: _.get(this.$route, 'params.vId'),
      date,
      collapseDangerZones: ['selected'],
      selectedIds: [],
      dangerZones: [],
      notice: '',
      canEdit: false,
      shouldWork: '是',
      optionsShouldWork: ['是', '否'],
      attributes: [],
      pickers: {},
      form: {},
      isChildProject: false,
      workersCountPicker: [],
    };
  },
  watch: {
    date(next, prev) {
      this.fetchDangerZones();
      this.fetchData();
    },
  },
  mounted() {
    this.fetchDangerZones();
  },
  filters: {
    filterDate(value) {
      return formatDateTime(value, 'YYYY-MM-DD');
    },
  },
  methods: {
    async fetchData() {
      const res = await this.withLoading(
        this.api('/api/daily-danger-zones/date', {
          date: this.date,
          projectId: this.projectId,
        }),
      );
      const { attributes, pickers, canEdit, isChildProject, ...form } = res || {};
      this.attributes = attributes;
      this.pickers = pickers || [];
      this.canEdit = canEdit;
      this.form = form;
      this.isChildProject = isChildProject;
      this.workersCountPicker = _.map(_.get(this.pickers.workersCount, 'options'), (i) => ({
        title: i.title,
        value: i.key,
      }));

      const notice = res;
      this.notice = notice.notice;
      this.selectedIds = JSON.parse(notice.selected_danger_zone_ids || '[]');
      this.shouldWork = notice.shouldWork || '是';
      this.canEdit = notice.canEdit;
    },
    async fetchDangerZones() {
      const dangerZones = await this.withLoading(
        this.api('/api/danger-zones/list', {
          currentProjects: _.intersection(this.currentProjects, [Number(this.projectId)]),
          filter: '',
        }),
      );

      this.dangerZones = _.map(
        _.sortBy(dangerZones.list, [(item) => item.level !== '危大危险源']),
        (item) => ({
          id: item.id,
          title: [
            item.level === '危大危险源' ? '【危大】' : '',
            item.title,
            item.sn,
            item.location,
            item.op_status,
          ].join(' '),
        }),
      );
    },
    async handleSubmit() {
      const resSaveNotice = await this.withLoading(
        this.api('/api/daily-danger-zones/save-date', {
          projectId: this.projectId,
          ...this.form,
          notice: this.notice,
          date: this.date,
          dangerZoneIds: this.form.selected_danger_zone_ids,
        }),
      );

      if (!resSaveNotice) return;

      this.$message({
        type: 'success',
        message: '已保存',
      });
    },
  },
};
</script>
