import { ref } from 'vue';
import { $fetch } from '../../composables';

export default (input) => {
  const {
    blueprintId = null,
    refresh = () => {
    },
  } = input || {};
  const uploadLoading = ref(false);

  const startLoading = () => {
    uploadLoading.value = true;
  };

  const stopLoading = () => {
    uploadLoading.value = false;
  };

  const handleUploaded = async (ev) => {
    const { url, originalname } = ev || {};
    await $fetch('/api/v3/progress-blueprint-image/upload', {
      body: {
        title: originalname,
        url,
        blueprintId,
      },
    });
    await refresh();
    uploadLoading.value = false;
  };

  return {
    uploadLoading,
    startLoading,
    stopLoading,
    handleUploaded,
  };
};
