<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import AppMapEditor from '@/components/AppMapEditor.vue';

const props = defineProps({
  attribute: Object,
  modelValue: {},
  form: Object,
  readonly: Boolean,
});
const emit = defineEmits(['update:modelValue', 'change', 'widgetAction']);

const vReadonly = computed(() => {
  return props.readonly || props.attribute.readonly;
});
</script>

<template lang="pug">
  div.w-full
    AdminModelPicker(
      v-if="attribute.widget === 'model'"
      :modelValue="modelValue"
      @update:modelValue="($event) => { $emit('update:modelValue', $event); $emit('change', $event) }"
      :readonly="vReadonly"
      v-bind="attribute"
    )
    div(v-else-if="attribute.widget === 'info'")
      div(v-html="modelValue")
    AdminUpload(
      v-else-if="attribute.widget === 'upload'"
      :modelValue="modelValue"
      @update:modelValue="($event) => { $emit('update:modelValue', $event); $emit('change', $event) }"
      v-bind="attribute"
      :readonly="vReadonly"
    )
    AdminFilesList(
      v-else-if='attribute.widget === "filesList"'
      :list='modelValue'
      readonly
      displayEmpty
    )
    el-switch(
      v-else-if="attribute.widget === 'switchRound'"
      type="round"
      :modelValue="modelValue"
      @update:modelValue="($event) => { $emit('update:modelValue', $event); $emit('change', $event) }"
      :disabled="vReadonly"
    )
      template(#checked) 是
      template(#unchecked) 否
    el-input(
      v-else-if="attribute.widget === 'text'"
      type="textarea"
      :value="modelValue"
      @input="$emit('update:modelValue', $event)"
      @change="$emit('change', $event)"
      :autosize="{ minRows: 3 }"
      :readonly="vReadonly"
    )
    el-select(
      v-else-if="attribute.widget === 'select'"
      :value="modelValue"
      @input="($event) => { $emit('update:modelValue', $event); $emit('change', $event) }"
      allow-search
      :disabled="vReadonly"
      clearable
      filterable
      :allow-create="attribute.allowCreate"
    ).w-full
      template(v-if="attribute.dict")
        el-option(
          v-for="(option, key) in attribute.dict"
          :key="key"
          :value="key"
          :label='option'
        )
      template(v-else-if="attribute.options")
        el-option(
          v-for="(option, optionIndex) in attribute.options"
          :key="optionIndex"
          :value="option"
          :label='option'
        )
    AppMapEditor(
      v-else-if='attribute.widget === "location"'
      :value='modelValue'
      @input="($event) => { $emit('update:modelValue', $event); $emit('change', $event) }"
      type='point'
    )
    AppMapEditor(
      v-else-if='attribute.widget === "locationArea"'
      :value='modelValue'
      @input="($event) => { $emit('update:modelValue', $event); $emit('change', $event) }"
      type='polygon'
    )
    el-input(
      v-else-if="attribute.widget === 'input'"
      :value="modelValue"
      @input="$emit('update:modelValue', $event)"
      @change="$emit('change', $event)"
      clearable
      :readonly="vReadonly"
      v-bind="{ ...attribute, type: 'input' }"
    )
    el-input(
      v-else
      :value="modelValue"
      @input="$emit('update:modelValue', $event)"
      @change="$emit('change', $event)"
      allow-clear
      :readonly="vReadonly"
      v-bind="attribute"
    )

    el-button-group(
      v-if="attribute.widgetActions && attribute.widgetActions.length"
    ).mt-2
      el-button(
        v-for="item in attribute.widgetActions"
        :key="item.title"
        @click="emit('widgetAction', { action: item.action })"
      ) {{ item.title }}

    el-alert(v-if="attribute.hint" type="warning").my-2
      template(#icon)
        i.el-icon-info
      div(v-html="attribute.hint").whitespace-pre-line
</template>

<style scoped>
.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mt-2 {
  margin-top: 8px;
}
</style>
