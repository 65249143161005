<script setup>
/* eslint-disable no-unused-vars,no-shadow */
import { defineProps, defineEmits, computed, ref, onMounted } from 'vue';
import { _, axios } from '@yishitec/web';
import { useRouter, useFetch2, useNotification } from '@/composables';
import AdminCardTitle from './AdminCardTitle.vue';

const props = defineProps({
  module: String,
  prefix: String,
  apiPrefix: String,
  listApi: String,
  actions: Array,
  displayTitle: Boolean,
  newQuery: String,
  scope: Object,
});
const emit = defineEmits(['navigate', 'init']);

const router = useRouter();
const { Notification } = useNotification();

const vPrefix = computed(() => {
  return props.prefix || `/admin/${props.module}`;
});

const vApiPrefix = computed(() => {
  return props.apiPrefix || `/api/admin/${props.module}`;
});

const paging = ref({
  total: 0,
  page: 1,
  pageSize: 10,
});

const $table = ref(null);
const title = ref('');
const attributes = ref([]);
const filterAttributes = ref([]);
const list = ref([]);
const filters = ref({});
const batchActions = ref([]);
const selectedIds = ref([]);
const withActions = ref(true);
const withCreate = ref(true);
const isFirstLoad = ref(true);
const expanded = ref(false);
const errorMessage = ref(null);
const itemActions = ref(null);
const summary = ref([]);
const isExpandedReadonly = ref(true);

const { data, pending, refresh } = useFetch2(props.listApi || `${vApiPrefix.value}/list`, {
  getBody: () => {
    return {
      ...paging.value,
      filters: {
        ...(props.scope || {}),
        ...(filters.value || {}),
      },
    };
  },
  // eslint-disable-next-line no-shadow
  success: (data) => {
    errorMessage.value = null;
    if (data.value.message) {
      errorMessage.value = data.value.message;
      return;
    }
    if (data.value.redirect) {
      router.replace(data.value.redirect);
      return;
    }
    title.value = data.value.title;
    attributes.value = data.value.attributes;
    filterAttributes.value =
      data.value.filterAttributes ||
      (data.value.attributes || []).filter((i) => !['upload'].includes(i.widget));
    list.value = data.value.list;
    paging.value.total = data.value.total || data.value.list.length;
    paging.value.page = data.value.page || 1;
    batchActions.value = data.value.batchActions;
    withActions.value = data.value.withActions !== false;
    withCreate.value = data.value.withCreate !== false;
    if (isFirstLoad.value) {
      filters.value = data.value.defaultFilters || {};
    }
    expanded.value = data.value.expanded || false;
    isFirstLoad.value = false;
    itemActions.value = data.value.itemActions || null;
    summary.value = data.value.summary || [];
    isExpandedReadonly.value = _.isUndefined(data.value.isExpandedReadonly)
      ? true
      : data.value.isExpandedReadonly;
  },
});

emit('init', { refresh });

const vItemActions = computed(() => {
  return itemActions.value || props.actions;
});

const attributesExpanded = computed(() => {
  return [
    {
      widget: 'card',
      title: title.value,
      children: _.map(attributes.value, (i) => i),
    },
  ];
});

const submitFilters = () => {
  paging.value.page = 1;
  selectedIds.value = [];
  refresh();
};

const handlePageChange = (value) => {
  paging.value.page = value;
  refresh();
};

const pendingBatchAction = ref(false);

const handlePageSizeChange = (value) => {
  paging.value.pageSize = value;
  refresh();
};

const handleBatchAction = async (action) => {
  pendingBatchAction.value = true;
  if (action.to) {
    router.push(action.to);
    pendingBatchAction.value = false;
  } else if (action.open) {
    window.open(action.open);
    pendingBatchAction.value = false;
  } else if (action.api) {
    const data = await axios(action.api, {
      method: 'post',
      data: {
        ids: selectedIds.value,
        filters: filters.value,
      },
    });
    pendingBatchAction.value = false;
    if (data.message) {
      Notification.warning({
        title: data.message,
      });
    }
    if (data.code === 0) {
      Notification.success({
        title: data.message || '操作成功',
      });
      refresh();
    }
  } else if (action.navigate) {
    emit('navigate', action.navigate);
  }
};

onMounted(() => {
  if (errorMessage.value) {
    Notification.warning({
      title: errorMessage.value,
    });
  }
});
</script>

<template lang="pug">
  div.admin-list
    div(v-if="attributes && attributes.length")
      AdminCardTitle(v-if='displayTitle') {{ displayTitle ? title : '' }}
      div
        el-collapse(
          v-if="filterAttributes && filterAttributes.length"
          style='margin-bottom: 2em;'
        )
          el-collapse-item(
            title='筛选'
            name='筛选'
          )
            template(#title)
              i.el-icon-search.el-icon--left
              | 筛选
            el-row(:gutter='10').row
              el-col(:span="22")
                el-form(
                  label-position="top"
                  :model="filters"
                )
                  AdminForm(
                    :attributes="filterAttributes"
                    :form="filters"
                    :defaultSpan='8'
                    labelPosition='top'
                  )
              el-col(:span="2" :style='{ paddingBottom: "20px" }')
                el-button(
                  @click="submitFilters"
                  type="secondary"
                  :class="['mb-[20px]']"
                )
                  i.el-icon-search.mr-2
                  | 筛选
        div.a-space(v-if="(withActions && withCreate) || (batchActions && batchActions.length) || true").flex-wrap
          div
            router-link(:to="`${vPrefix}/edit/new${newQuery || ''}`" v-if="withActions && withCreate").inline-block
              el-button(type="primary").mb-2
                i.el-icon-plus.mr-2
                | 创建
          el-button(
            v-for="(action, actionIndex) in batchActions"
            :key="actionIndex"
            type="secondary"
            :disabled="pendingBatchAction || (action.selection && !selectedIds.length)"
            @click="handleBatchAction(action)"
          ).mb-2.whitespace-normal
            i.el-icon-more.mr-2
            | {{ action.title || action }}
          i.el-icon-refresh.cursor-pointer.p-2.mb-2(@click="refresh" :style="{ marginLeft: '6px', fontSize: '16px' }")
          slot(name="toolbar")
        el-alert(
          type="warning"
          closable
          @close="$table.clearSelection()"
          v-if="selectedIds.length"
        ) 已选择 {{ selectedIds.length }} 项
        template(
          v-if="expanded && list && list.length"
        )
          AdminForm(
            :form="list[0]"
            :attributes="attributesExpanded"
            :readonly="isExpandedReadonly"
          )
        template(v-else)
          el-table(
            :data="list"
            row-key="id"
            @selection-change="$event => { selectedIds = $event }"
            v-loading="pending"
            resizable
            ref='$table'
          )
            el-table-column(
              type='selection'
              width='55'
            )
            el-table-column(
              v-for="col in attributes"
              :key="col.name"
              :label="col.title || col.name"
              :prop="col.name"
              show-overflow-tooltip
              v-bind="col"
            )
              template(#default="{ row:record }")
                AdminCell(:record="record" :attribute="col")
                  template(v-slot="{ value, row }")
                    slot(
                      :name="`slot_${col.name}`"
                      :value="value"
                      :row="row"
                      :list="list"
                    )
            el-table-column(
              v-if="withActions"
              key="_actions"
              label="操作"
              prop="id"
              fixed="right"
              :cell-style="{ whiteSpace: 'nowrap', width: '96px' }"
            )
              template(#default="{ row: record }")
                AdminActions(
                  v-if="record && record.id"
                  :record="record"
                  :attributes="attributes"
                  :prefix="vPrefix"
                  :api-prefix="vApiPrefix"
                  :actions="vItemActions"
                  @refresh="refresh"
                )
          el-pagination(
            v-if="list.length !== paging.total"
            :total="paging.total"
            :currentPage="paging.page"
            @current-change="$event => { paging.page = $event; handlePageChange($event) }"
            :page-size="paging.pageSize"
            @size-change="$event => { paging.pageSize = $event; handlePageSizeChange($event) }"
            layout="total, sizes, prev, pager, next, jumper"
          ).flex-wrap
</template>

<style lang="scss" scoped>
.mr-2 {
  margin-right: 8px;
}

.mb-2 {
  margin-bottom: 8px;
}

.p-2 {
  padding: 8px;
}

.a-space {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -4px;
  margin-right: -4px;

  >* {
    margin-left: 4px;
    margin-right: 4px;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.inline-block {
  display: inline-block;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.admin-list {
  :deep(.el-button.whitespace-normal) {
    white-space: normal !important;
  }
}
</style>
