<template lang="pug">
div.ssp-form-item
  div.label {{ label }}：
  div.input-container
    el-input-number(
      v-if="type === 'number'"
      :value="value"
      @input="handleInput"
      controls-position="right"
      :min="0"
      :max="100"
      :step="10"
    )
    ui-data-select(
      v-else-if="type === 'select'"
      :value="value"
      @input="handleInput"
      :options="options"
      filterable
      clearable
      allow-create
    )
    SspMultiselect(
      v-else-if='type === "multiselect"'
      :value='value'
      @input='handleInput'
      :options='options'
      filterable
      clearable
      :placeholder='placeholder'
    )
    SspCheckboxButtons(
      v-else-if='type === "checkbox-buttons"'
      :value='value'
      @input='handleInput'
      :options='options'
      v-bind='$attrs'
    )
    el-date-picker(
      v-else-if="type === 'date'"
      :value="value"
      value-format="yyyy-MM-dd"
      @input="handleInput"
    )
    el-date-picker(
      v-else-if="type === 'datetime'"
      type="datetime"
      :value="value"
      value-format="yyyy-MM-dd HH:mm:ss"
      format="yyyy-MM-dd HH:mm:ss"
      @input="handleInput"
    )
    SspUpload(
      v-else-if="type === 'file'"
      :value="value"
      @input="handleInput"
      :listType="listType"
    )
    slot(v-else-if="type === 'custom'" :value="value" :onInput="handleInput")
    el-input(
      v-else
      :value="value"
      @input="handleInput"
      :placeholder="placeholder"
      :autosize="{ minRows: 3, maxRows: 40}"
      :type="type"
    )
    span(v-if="inputAppend").ml {{ inputAppend }}
</template>

<script>
import SspUpload from './SspUpload.vue';
import SspMultiselect from './SspMultiselect.vue';
import SspCheckboxButtons from './SspCheckboxButtons.vue';

export default {
  name: 'SspFormItem',
  props: {
    label: { type: String, default: '' },
    // v-model
    value: { type: [String, Number, Array], default: '' },
    placeholder: { type: String, default: '' },
    type: { type: String, default: '' }, // input, password
    inputAppend: { type: String, default: null },
    options: { type: Array, default: () => [] },
    listType: { type: String, default: 'picture' },
  },
  emits: ['input'],
  components: {
    SspUpload,
    SspMultiselect,
    SspCheckboxButtons,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleInput(ev) {
      this.$emit('input', ev);
    },
  },
};
</script>

<style lang="scss" scoped>
.ssp-form-item {
  @include ui-flex-row(flex-start, flex-start);

  & + & {
    margin-top: 10px;
  }
}

.label {
  min-height: 40px;
  @include ui-flex-row(flex-start, center);
  flex: 0 0 120px;
  margin-right: 10px;
}

.input-container {
  flex: 1 0 1px;
}
</style>
