<template lang="pug">
  div(style='height: 100%')
    SubWindowNormal(
      v-if='loaded'
      v-bind='$attrs'
      :projects='projects'
      :moduleData='moduleData'
      @sub-window='handleSubWindow'
    )
    div(v-else v-loading='true' element-loading-background="rgba(0, 0, 0, 0.8)").loading
    SubWindow(v-if='subWindow' @close='handleSubWindow(null)' style='z-index: 3;')
      SubWindowLoader(
        :projects='projects'
        v-bind='subWindow'
      )
</template>

<script>
import SubWindowNormal from './SubWindowNormal.vue';
import SubWindow from './SubWindow.vue';
import getSubWindowNormal from './getSubWindowNormal';

export default {
  name: 'SubWindowLoader',
  props: {
    projects: Array,
    apiUrl: String,
    apiId: [Number, String],
  },
  components: { SubWindow, SubWindowNormal },
  data() {
    return {
      loaded: false,
      moduleData: null,
      subWindow: null,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loaded = false;
      if (this.apiUrl) {
        this.moduleData = await this.api(this.apiUrl, {
          id: this.apiId,
        })();
      }
      this.loaded = true;
    },
    handleSubWindow(subWindowConfig) {
      const { subWindow, id } = subWindowConfig || {};
      this.subWindow = getSubWindowNormal(subWindow, id);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  height: 100%;
}
</style>
