<template>
  <div class="report-progress-blocks">
    <div class="progress-title">
      {{ title }}
    </div>
    <div class="progress-blocks">
      <div v-for="(block, blockIndex) in blocks" :key="blockIndex" class="block" :style="block" />
    </div>
    <div class="progress-number">
      {{ number }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ReportProgressBlocks',
  props: {
    title: String,
    reportsData: Object,
    total: Array,
    active: Array,
    color: {
      type: String,
      default: '#000',
    },
  },
  computed: {
    number() {
      return _.get(this.reportsData, this.total) || 0;
    },
    numberActive() {
      return Number(_.get(this.reportsData, this.active) || 0);
    },
    blocks() {
      return _.map(_.range(this.number), (i) => {
        if (i < this.numberActive) {
          return {
            borderColor: this.color,
            backgroundColor: this.color,
          };
        }
        return {
          backgroundColor: '#fff',
        };
      });
    },
  },
};
</script>

<style lang="scss">
.report-progress-blocks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .progress-title {
    flex: 0 0 5em;
    margin-right: 10px;
    text-align: left;
    color: #1e3258;
    font-weight: bolder;
    font-size: 1em;
    text-align: center;
  }
  .progress-blocks {
    flex: 1 0 1px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px 0;
    max-width: 80%;
    .block {
      width: 10px;
      height: 10px;
      border-radius: 2px;
      box-sizing: border-box;
      margin: 1px;
      border: solid 1px #b5c2d1;
    }
  }
  .progress-number {
    margin-left: 10px;
    flex: 0 0 4em;
    text-align: right;
    font-size: 1.6em;
    color: #4c932b;
    font-weight: bolder;
  }
}
</style>
