<template lang='pug'>
  div.sub-window-frame
    .header
      BoardTitle(
        :title="title"
        :icon="icon"
      )
    .indicators(v-if="indicators && indicators.length")
      SubWindowStats(
        v-for="(item, index) in vIndicators"
        :key="index"
        v-bind="item"
      ).item
    ScrollBox.table-section
      div.table-area
        .table-hint 数据截止时间：{{ time }}
        table.table
          thead
            tr
              th(
                v-for="(col, colIndex) in table"
                :key="colIndex"
                :style="{ width: col.width || 'auto' }"
              ) {{ col.title || col.name }}
          tbody
            tr(
              v-for="project in dataList"
              :key="project.id"
            )
              td(
                v-for="(col, colIndex) in table"
                :key="colIndex"
              )
                router-link(
                  v-if="col.type === 'adminOps' && col.to"
                  :to="col.to.replace(':projectId', project.projectId)"
                  target="_blank"
                ).link
                  i.el-icon-s-cooperation.el-icon--left
                  | 查看详情
                a.link(
                  v-else-if='col.type === "adminOps" && col.subWindow'
                  @click='$emit("sub-window", { subWindow: col.subWindow, id: project.id })'
                )
                  i.el-icon-s-cooperation.el-icon--left
                  | 查看详情
                span(v-else-if="col.type === 'stats' && col.cols && col.cols.length")
                  span(
                    v-for="(iCol, indexICol) in col.cols"
                    :key="indexICol"
                    :class="{ primary: indexICol === 0 }"
                  )
                    span(v-if="indexICol > 0").divider /
                    | {{ project[iCol]}}
                span(v-else-if="col.name") {{ project[col.name] }}
      .table-toolbar
</template>

<script>
import { _, moment } from '@yishitec/web';
import BoardTitle from './BoardTitle.vue';
import SubWindowStats from './SubWindowStats.vue';

import { getSum, getMaxMoment } from './genCards';

export default {
  name: 'SubWindowNormal',
  props: {
    projects: Array,
    moduleData: Object,
    icon: String,
    title: String,
    indicators: Array,
    table: Array,
  },
  emits: ['sub-window'],
  components: {
    BoardTitle,
    SubWindowStats,
  },
  data() {
    return {};
  },
  computed: {
    dataList() {
      return (this.moduleData && this.moduleData.list) || this.projects;
    },
    vIndicators() {
      return _.map(this.indicators, (config) => {
        const {
          title, before, total, n, after,
        } = config;
        const item = {
          title,
          before,
          n: getSum(this.dataList, n),
          after,
        };
        if (total) {
          item.after = `/ ${getSum(this.dataList, total)}${after || ''}`;
        }
        return item;
      });
    },
    time() {
      return moment(getMaxMoment(this.projects, 'updatedAt')).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
.sub-window-frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
}

.header,
.indicators {
  flwx: 0 0 auto;
  padding: 2px 104px 2px 4px;
}

.indicators {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;

  .item {
    margin-left: 8px;
    margin-right: 12px;
    margin-bottom: 20px;
    width: 230px;
    height: 52px;
  }
}

.table-section {
  flex: 1 0 1px;
  box-sizing: border-box;
  border-radius: 12px;
}

.table-area {
  border-radius: 12px;
  box-sizing: border-box;
  padding: 20px;
  color: #000;
  font-size: 14px;
  background: #fff;
}

.table-hint {
  margin-bottom: 10px;
  color: #9ba6ad;
  font-size: 12px;
}

.table {
  border-collapse: collapse;
  min-width: 100%;
  color: #2c4553;

  th,
  td {
    text-align: left;
    padding: 8px 20px;
    border: solid 1px #e3e3e3;
  }

  th {
    font-weight: bold;
    background: #f9f8f0;
  }

  .link {
    color: #2c4553;

    &:hover {
      text-decoration: underline;
    }
  }

  .primary {
    color: #d09d2d;
  }

  .divider {
    display: inline-block;
    margin: 0 0.25em;
  }
}

.link {
  cursor: pointer;
}
</style>
