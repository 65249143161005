<template lang="pug">
div.row
  .title {{ title }}
  .numbers
    .number {{ n }}
    .total(v-if="total") {{ total }}
</template>

<script>
export default {
  name: 'SideCardListItem',
  props: {
    title: String,
    n: Number,
    total: Number,
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 3px 0;
}

.title {
  font-size: 12px;
  margin-right: 10px;
  color: #9ba6ad;
}

.number {
  display: inline-block;
  font-size: 16px;
  color: #f6be43;
}

.total {
  display: inline-block;
  font-size: 14px;
  color: #ffffff;
  margin-left: 0.25em;

  &::before {
    content: ' / ';
    color: #9ba6ad;
    font-size: 14px;
  }
}
</style>
