<template>
  <div class="admin-home">
    <ui-page-header title="选择项目" />
    <ui-stage>
      <ui-alert class="mb" type="success" v-if="isSuperAdmin">系统管理员</ui-alert>
      <div v-for="(group, groupIndex) in projects" :key="group.groupTitle">
        <ui-checkbox
          v-model="checkAll[groupIndex]"
          class="admin-home__group-title"
          @change="(value) => handleGroupChange(value, group, groupIndex)"
        >
          {{ group.groupTitle }}
        </ui-checkbox>
        <ui-checkbox-group v-model="selectedProjects[groupIndex]">
          <ui-flex row j-start wrap>
            <div
              v-for="project in group.children"
              :key="project.id"
              style="flex: 0 0 33.33333%"
              class="admin-home__item"
            >
              <ui-checkbox
                :label="project.id"
                border
                class="admin-home__item__checkbox"
                @change="(value) => handleItem(value, groupIndex)"
              >
                {{ project.title }}
                <small
                  class="admin-home__item__hint"
                  v-if="$get(project, ['xProjectsUsersBindings', 0, 'id'])"
                >
                  {{ $get(project, ['xProjectsUsersBindings', 0, 'company_type']) }}
                  {{ $get(project, ['xProjectsUsersBindings', 0, 'admintype']) }}
                </small>
              </ui-checkbox>
            </div>
          </ui-flex>
        </ui-checkbox-group>
      </div>
      <ui-flex row center class="mt">
        <ui-button type="primary" @click="handleSubmit"> 提交 </ui-button>
      </ui-flex>
    </ui-stage>
  </div>
</template>

<script>
import { _ } from '@yishitec/web';
// eslint-disable-next-line no-unused-vars
import { mapState, mapMutations } from 'vuex';
import permissions from '../../mixins/permissions';

export default {
  name: 'AdminHome',
  mixins: [permissions],
  data() {
    const projects = []; // []: { groupTitle, children: project[] }
    const selectedProjects = []; // []: groupIndex: id[]
    const checkAll = []; // []: groupIndex: bool
    this.documentTitle('选择人员');
    return {
      projects,
      selectedProjects,
      checkAll,
    };
  },
  computed: {
    ...mapState(['currentProjects']),
  },
  watch: {
    projects() {
      if (this.projects.length) {
        _.forEach(this.projects, (group, groupIndex) => {
          this.selectedProjects[groupIndex] = _.intersection(
            _.map(group.children, (project) => project.id),
            this.currentProjects,
          );
          this.checkAll[groupIndex] = false;
          if (group.children.length === this.selectedProjects[groupIndex].length) {
            this.checkAll[groupIndex] = true;
          }
        });
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapMutations(['setCurrentProjects']),
    async fetchData() {
      this.projects = await this.withLoading(
        this.api(
          '/api/get-projects',
          {
            withPermissions: true,
          },
          {
            defaultValue: [],
          },
        ),
      );
    },
    handleGroupChange(value, group, groupIndex) {
      if (!value) {
        this.selectedProjects[groupIndex] = [];
      } else {
        this.selectedProjects[groupIndex] = _.map(group.children, (item) => item.id);
      }
      this.selectedProjects = [...this.selectedProjects];
      this.checkAll[groupIndex] = value;
    },
    handleItem(value, groupIndex) {
      if (this.selectedProjects[groupIndex].length === this.projects[groupIndex].children.length) {
        this.checkAll[groupIndex] = true;
      } else {
        this.checkAll[groupIndex] = false;
      }
    },
    async handleSubmit() {
      const projects = _.uniq(_.flatten(this.selectedProjects));
      if (!projects.length) {
        this.$message({
          type: 'error',
          message: '请至少选择一个项目',
        });
        return;
      }
      const currentProjects = await this.withLoading(
        this.api('/api/select-projects', {
          projects,
        }),
      );
      this.setCurrentProjects([...currentProjects]);
      this.$message({
        type: 'success',
        message: '提交成功',
      });
      this.$router.push('/admin/projects');
    },
  },
};
</script>

<style scoped lang="less">
.admin-home {
  &__group-title {
    padding: 8px;
  }
  &__item {
    padding: 8px;
    box-sizing: border-box;
    &__checkbox {
      width: 100%;
    }
    &__hint {
      color: #aaa;
      font-size: 12px;
      display: inline-block;
      margin-left: 8px;
    }
  }
}
</style>
