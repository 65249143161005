import { _ } from '@yishitec/web';
import { mapState } from 'vuex';

const levels = ['作业人员', '安全员', '平台管理员', '管理员', '平台管理员'];

const getLevel = (levelName) => {
  let vLevelName = levelName;
  if (vLevelName === '作业人员') {
    vLevelName = '作业人员';
  }
  return _.indexOf(levels, vLevelName);
};

export default {
  computed: {
    ...mapState(['permissions', 'isSuperAdmin', 'currentProjects']),
  },
  methods: {
    amI(projectId, companyType = '', level = '作业人员', exact = false) {
      if (!exact && this.isSuperAdmin) return true;
      const ret = Boolean(
        _.find(this.permissions, (item) => {
          if (projectId && Number(item.project_id) !== Number(projectId)) return false;
          if (companyType && item.company_type !== companyType) return false;
          if (level && exact && item.admintype !== level) return false;
          if (level && !exact && getLevel(item.admintype) < getLevel(level)) return false;
          return true;
        }),
      );
      return ret;
    },
    amIInCompany(companyName) {
      return Boolean(
        _.find(this.permissions, (findPermission) => findPermission.company === companyName),
      );
    },
  },
};
