<template lang="pug">
div.divider
</template>

<script>
export default {
  name: 'Divider',
};
</script>

<style lang="scss" scoped>
.divider {
  height: 1px;
  background: #2d373d;
  margin: 15px 0;
}
</style>
