<template>
  <div>
    <ui-page-header title="危险源报警报表" />
    <ui-stage>
      <div>
        <span>起止时间：</span>
        <ui-date-picker
          v-model="dates"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="dateRangePickerOptions"
        />
        <ui-button @click="loadData">查询</ui-button>
      </div>
      <ui-admin-table
        :attributes="attributes"
        :data="data"
        :with-create="false"
        :with-actions="false"
      />
    </ui-stage>
  </div>
</template>

<script>
import { moment } from '@yishitec/web';

export default {
  name: 'DangerZonesReport',
  data() {
    return {
      attributes: [],
      data: [],
      dates: [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      dateRangePickerOptions: {
        shortcuts: [
          {
            text: '本月',
            onClick(picker) {
              const start = moment().startOf('month').format('YYYY-MM-DD');
              const end = moment().format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '上月',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
              const end = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const [startDate, endDate] = this.dates;
      const { attributes, data } = await this.withLoading(
        this.api('/api/reports/danger-zones', { startDate, endDate }),
      );
      this.attributes = attributes;
      this.data = data;
    },
  },
};
</script>

<style></style>
