<template>
  <div class="app-footer">
    <ui-container>
      <ui-flex row between class="column--mobile">
        <ui-flex zero>
          <div>版权所有&copy;{{ year }} {{ owner }}</div>
          <div>联系地址：{{ address }}</div>
        </ui-flex>
        <ui-flex zero>
          <div>
            {{ ownerEn }}
          </div>
          <div>
            {{ addressEn }}
          </div>
        </ui-flex>
      </ui-flex>
      <div class="mt app-footer__center">
        <div>
          <a href="/api/redirect/ios" target="_blank"> iOS 应用下载 </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="/api/redirect/android-checkin" target="_blank"> 安卓应用下载 </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <router-link to="/privacy-policy"> 隐私政策 </router-link>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <router-link to="/ssl-root">
            <el-link type="primary"> 解决SSL根证书问题 </el-link>
          </router-link>
        </div>
        <div>
          备案号：
          <a href="https://beian.miit.gov.cn/" target="_blank">{{ beian }}</a>
        </div>
        <div>本网站建议使用Google Chrome、Firefox或IE9以上浏览器、1366*768以上分辨率浏览。</div>
      </div>
    </ui-container>
  </div>
</template>

<script>
import { moment } from '@yishitec/web';

export default {
  name: 'AppFooter',
  data() {
    return {
      year: moment().year(),
      owner: '上海同川信息技术有限公司',
      address: '上海市浦东新区金桥路1088号A楼1920',
      ownerEn: 'Shanghai Tongchuan Information Technology Co., Ltd.',
      addressEn: 'Room 226, 29, No. 6999, Chuansha Road, Pudong New Area',
      beian: '沪ICP备18025391号-1',
    };
  },
};
</script>

<style scoped lang="less">
.app-footer {
  color: #aaa;
  a {
    color: #aaa;
  }
  &__center {
    text-align: center;
  }
}
.column--mobile {
  flex-direction: column;
  align-items: strtch;
  text-align: center;
}
</style>
