<template>
  <div>
    <ui-stage>
      <ui-admin-table
        v-if="data"
        :attributes="attributes"
        :data="data"
        :module-url="moduleUrl"
        pagination-method="front-end"
        :with-create="false"
        :with-delete="false"
      >
        <template slot="toolbar">
          <div class="mb" v-if="canTriggerSync">
            <ui-button type="primary" @click="handleSync" size="small" style="margin-right: 10px"
              >从萤石云平台同步</ui-button
            >
            <router-link to="/admin/cams/add">
              <ui-button type="primary" plain size="small"> 添加第三方监控(HLS) </ui-button>
            </router-link>
          </div>
        </template>
        <template slot="column_state" slot-scope="{ row }">
          <ui-tag :type="row.state == '1' ? 'success' : 'info'">{{
            row.state == '1' ? '在线' : '离线'
          }}</ui-tag>
        </template>
        <template slot="column_is_critical" slot-scope="{ row }">
          <ui-tag v-if="row.is_critical" type="danger">重点源</ui-tag>
        </template>
      </ui-admin-table>
    </ui-stage>
  </div>
</template>

<script>
import { adminList } from '@yishitec/web/mixins';
import permissions from '../../../mixins/permissions';

export default {
  name: 'List',
  mixins: [adminList, permissions],
  props: {
    projectId: { type: String },
  },
  data() {
    return {
      apiBodyData: {
        project_id: this.projectId,
      },
      vvApi: '/api/cams/list-v2',
    };
  },
  computed: {
    canTriggerSync() {
      return this.amI(null, null, '平台管理员') || this.amI(null, '建设单位', '管理人员', true);
    },
  },
  methods: {
    async handleSync() {
      await this.withLoading(this.api('/api/cams/sync'));
      await this.fetchData();
    },
  },
};
</script>

<style lang="less" scoped></style>
