<template>
  <ui-carousel :height="height">
    <ui-carousel-item>
      <div
        class="slide-item"
        :style="{
          height,
          backgroundImage: `url('${require('../assets/images/slides/slide.jpg')}')`,
        }"
      />
    </ui-carousel-item>
  </ui-carousel>
</template>

<script>
export default {
  name: 'AppSlides',
  data() {
    return {
      height: '41.6666667vw',
    };
  },
};
</script>

<style scoped lang="less">
.slide-item {
  width: 100%;
  background: no-repeat center center;
  background-size: cover;
}
</style>
