<template lang="pug">
el-dialog(
  v-if="sourceId"
  v-loading="loading"
  title="保存模板"
  visible
  @close="emitClose"
)
  SspFormItem(
    label="模板名称"
    v-model="form.title"
  )
  div.mt
    el-button(
      type="primary"
      @click="handleSubmit"
    ) 保存
</template>

<script>
export default {
  name: 'ProgressSaveTemplateModal',
  props: {
    sourceId: { type: [String, Number], default: null },
    sourceItem: { type: Object, default: null },
  },
  emits: ['close'],
  data() {
    return {
      form: {},
      loading: false,
    };
  },
  computed: {},
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.form = {
        title: '',
      };
      if (this.sourceItem) {
        if (this.sourceItem.title) {
          this.form.title = this.sourceItem.title;
        }
      }
    },
    emitClose() {
      this.$emit('close');
    },
    async handleSubmit() {
      this.loading = true;
      const res = await this.withLoading(
        this.api('/api/progress-templates/save-template', {
          ...this.form,
          sourceId: this.sourceId,
        }),
      );
      if (res) {
        this.$message({
          type: 'success',
          message: '保存成功',
        });
        this.emitClose();
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
