<script setup>
import { defineProps, ref } from 'vue';
import { useRoute, useRouter } from '@/composables';
import module from './module';

const route = useRoute();
const router = useRouter();

const { modulePath } = module;

defineProps({
  vId: [String, Number],
});

const { tab, handleChangeTab } = useTabs();

function useTabs() {
  const tab = ref(route.query.tab || '基本信息');

  function handleChangeTab(ev) {
    router.replace(`?tab=${ev.name}`);
  }

  return {
    tab,
    handleChangeTab,
  };
}
</script>

<template lang="pug">
  ui-stage
    el-tabs(
      type="border-card"
      v-model='tab'
      @tab-click='handleChangeTab'
    )
      el-tab-pane(label="基本信息" name='基本信息' lazy)
        AdminFormPage(
          :module="modulePath"
          :id='vId'
        )
      template(v-if="vId && vId !== 'new'")
</template>
