<template lang="pug">
.tree-aggregation-item
  .item-row.cursor-pointer(@click="toggleExpand")
    .item-expand-toggle
      img.toggle-arrow(
        :src="require('../assets/images/arrow-right.png')",
        :class="{ expanded: isExpanded }",
        v-if="!empty"
      )
    .item-title(:class="{ strong: !empty }") {{ title }}
    .item-stats(v-if="stats") {{ stats }}
  .item-children(v-if="!empty && isExpanded")
    slot
</template>

<script>
export default {
  name: 'TreeAggregationItem',
  props: {
    title: String,
    stats: String,
    empty: { type: Boolean, default: false },
  },
  data() {
    return {
      isExpanded: true,
    };
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.tree-aggregation-item {
  color: #fff;
  font-size: 12px;
  margin: 5px 0;
}

.item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-expand-toggle {
  flex: 0 0 9px;
  margin-right: 8px;
  text-align: center;
}

.toggle-arrow {
  width: 6px;
  height: 9px;
  transition: all 0.3s;
  display: inline-block;

  &.expanded {
    transform: rotate(90deg);
    transform-origin: center center;
  }
}

.item-title {
  flex: 1 0 1px;

  &.strong {
    font-weight: bold;
  }
}

.item-stats {
  flex: 0 0 auto;
  margin-left: 10px;
  font-size: 12px;
  color: #6da3ce;
}

.item-children {
  box-sizing: border-box;
  margin-left: 0;
  padding: 1px 0;
  background: rgba(#000, 0.25);
}
</style>
