<template>
  <div :class="['cam-view', { compact }]">
    <ui-flex :row="layout === 'row'" :col="layout === 'col'" justify a-center class="mobile--col">
      <cam-player
        :class="['cam-view__video', { 'cam-view__video--in-col': layout === 'col', hideControls }]"
        :cam="cam"
        :is-hd="isHd"
        :key="playerVersion"
        ref="player"
        :is-live="playerIsLive"
        :start="start"
        :end="end"
      />

      <div class="cam-view__buttons" v-if="!hideControls">
        <ui-section-title>
          {{ cam.title }}
        </ui-section-title>
        <div class="controls-container">
          <ui-tabs v-model="activeTab" accordion>
            <ui-tab-pane label="直播" name="live">
              <div class="error-message" v-if="!withPtz">仅查看</div>
              <ui-flex v-else column center>
                <template v-if="!compact">
                  <div class="cam-view__buttons__row" />
                  <div class="cam-view__buttons__row" />
                  <div class="cam-view__buttons__row" />
                </template>
                <div class="cam-view__buttons__row" v-if="withResize">
                  <ui-button round icon="el-icon-crop" @click="adjustSize"
                    >重置视频窗口大小
                  </ui-button>
                </div>
                <template v-if="!compact">
                  <div class="cam-view__buttons__row" />
                  <div class="cam-view__buttons__row" />
                </template>
                <div class="cam-view__buttons__row">
                  <ui-button
                    type="primary"
                    icon="el-icon-caret-top"
                    circle
                    @mousedown.native="ptzAction('up')"
                    @mouseup.native="ptzActionStop('up')"
                    @touchstart.native="ptzAction('up')"
                    @touchend.native="ptzActionStop('up')"
                  />
                </div>
                <div class="cam-view__buttons__row overflow">
                  <ui-button
                    type="primary"
                    icon="el-icon-caret-left"
                    circle
                    style="margin-right: 50px"
                    @mousedown.native="ptzAction('left')"
                    @mouseup.native="ptzActionStop('left')"
                    @touchstart.native="ptzAction('left')"
                    @touchend.native="ptzActionStop('left')"
                  />
                  <ui-button
                    type="primary"
                    icon="el-icon-caret-right"
                    circle
                    @mousedown.native="ptzAction('right')"
                    @mouseup.native="ptzActionStop('right')"
                    @touchstart.native="ptzAction('right')"
                    @touchend.native="ptzActionStop('right')"
                  />
                </div>
                <div class="cam-view__buttons__row">
                  <ui-button
                    type="primary"
                    icon="el-icon-caret-bottom"
                    circle
                    @mousedown.native="ptzAction('down')"
                    @mouseup.native="ptzActionStop('down')"
                    @touchstart.native="ptzAction('down')"
                    @touchend.native="ptzActionStop('down')"
                  />
                </div>
                <template v-if="!compact">
                  <div class="cam-view__buttons__row" />
                  <div class="cam-view__buttons__row" />
                </template>
                <div class="cam-view__buttons__row">
                  <ui-button
                    type="primary"
                    icon="el-icon-zoom-in"
                    round
                    @mousedown.native="ptzAction('in')"
                    @mouseup.native="ptzActionStop('in')"
                    >放大
                  </ui-button>
                  <ui-button
                    type="primary"
                    icon="el-icon-zoom-out"
                    round
                    @mousedown.native="ptzAction('out')"
                    @mouseup.native="ptzActionStop('out')"
                    >缩小
                  </ui-button>
                </div>
                <div class="cam-view__buttons__row" />
                <div class="cam-view__buttons__row" />
                <ui-checkbox label="高清" v-model="isHd" border v-if="withHd" />
              </ui-flex>
            </ui-tab-pane>
            <ui-tab-pane label="回放" name="replay" v-if="withRec">
              <div class="mt">
                <div>开始时间：</div>
                <ui-date-picker v-model="start" type="datetime" class="action-button" />
              </div>
              <div class="mt">
                <div>结束时间：</div>
                <ui-date-picker v-model="end" type="datetime" class="action-button" />
              </div>
              <div class="mt">
                <ui-button type="primary" class="action-button" @click="handleSubmitReplay">
                  回放
                </ui-button>
              </div>
              <div class="mt">
                <ui-input :value="streamUrl" readonly />
              </div>
            </ui-tab-pane>
          </ui-tabs>
        </div>
      </div>
    </ui-flex>
  </div>
</template>

<script>
import { moment, _ } from '@yishitec/web';
import CamPlayer from './CamPlayer.vue';

export default {
  name: 'CamView',
  components: { CamPlayer },
  props: {
    cam: { type: Object, default: null },
    withResize: { type: Boolean, default: true },
    withHd: { type: Boolean, default: true },
    layout: { type: String, default: 'row' }, // row, col
    compact: { type: Boolean, default: false },
    hideControls: { type: Boolean, default: false },
  },
  metaInfo: {
    script: [
      {
        src: '/EZUIKit-JavaScript/ezuikit.js',
      },
    ],
  },
  data() {
    // TODO 回放
    return {
      isHd: false,
      playerVersion: 0,
      activeSections: ['live'],
      activeTab: 'live',
      start: moment().subtract(1, 'hour').valueOf(),
      end: moment().valueOf(),
      startOptions: {
        shortcuts: [
          {
            text: '1小时前',
            onClick(picker) {
              picker.$emit('pick', moment().subtract(1, 'hour').valueOf());
            },
          },
        ],
      },
      endOptions: {
        shortcuts: [
          {
            text: '1小时前',
            onClick(picker) {
              picker.$emit('pick', moment().subtract(1, 'hour').valueOf());
            },
          },
          {
            text: '现在',
            onClick(picker) {
              picker.$emit('pick', moment().valueOf());
            },
          },
        ],
      },
      streamUrl: '',
      updateUrlDebounced: _.debounce(this.updateUrl, 1000),
    };
  },
  computed: {
    playerIsLive() {
      return this.activeTab === 'live';
      // return !this.activeSections.includes('replay');
    },
    withRec() {
      if (this.cam.serviceType === 'HLS') {
        return false;
      }
      return true;
    },
    withPtz() {
      if (this.cam.serviceType === 'HLS') {
        return false;
      }
      return true;
    },
  },
  watch: {
    isHd() {
      this.playerVersion += 1;
    },
    /**
     *         :is-live="playerIsLive"
     :start="start"
     :end="end"
     */
    playerIsLive() {
      this.getStreamUrl();
    },
    start() {
      this.getStreamUrl();
    },
    end() {
      this.getStreamUrl();
    },
  },
  methods: {
    adjustSize() {
      this.$refs.player.adjustSize();
    },
    async ptzAction(action) {
      try {
        await this.api(
          `/api/cams/cam-op/${action}/${this.cam.device_serial}/${this.cam.camera_id}`,
          {
            speed: 1,
          },
        )();
      } catch (e) {
        // console.log(e);
      }
    },
    async ptzActionStop(action) {
      try {
        await this.api(
          `/api/cams/cam-op-stop/${action}/${this.cam.device_serial}/${this.cam.camera_id}`,
        )();
      } catch (e) {
        // console.log(e);
      }
    },
    stopPlayer() {
      this.$refs.player.stopPlayer();
    },
    handleSubmitReplay() {
      if (this.$refs.player) {
        this.$refs.player.updateUrl();
      }
    },
    async getStreamUrl() {
      this.streamUrl = '';
      try {
        const res = await this.api('/api/cams/stream-url', {
          serial: this.cam.device_serial,
          channel: this.cam.camera_id,
          ext: {
            expireTime: 24 * 3600,
            protocol: 2,
            quality: 1,
            startTime: moment(this.start).format('YYYY-MM-DD HH:mm:ss'),
            stopTime: moment(this.end).format('YYYY-MM-DD HH:mm:ss'),
            type: 3,
            supportH265: 1,
          },
        })();
        if (res) {
          this.streamUrl = res.url;
        }
      } catch (e) {
        (() => {})();
      }
      this.updateUrlDebounced();
    },
    updateUrl() {
      this.$refs.player.updateUrl();
    },
  },
};
</script>

<style lang="less">
.cam-view {
  &__video {
    background: #000;
    height: calc(100vh - 350px);
    margin-right: 20px;
    flex: 1 0 1px;
    width: 100%;

    video {
      width: 100%;
      height: 100%;
    }

    &--in-col {
      height: 66.66666667vw;
      min-height: 66.66666667vw;
      margin-right: 0;
    }

    &.hideControls {
      height: auto;
      margin-right: 0;
    }
  }

  &__buttons {
    flex: 0 0 350px;
    @media screen and (max-width: 768px) {
      flex: 0 0 auto;
      width: 100%;
    }

    &__row {
      margin: 4px 0;
    }
  }
}

.ui-flex.ui-flex--row.mobile--col {
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.error-message {
  padding: 45px 0;
  text-align: center;
}
</style>

<style lang="less" scoped>
.controls-container {
  padding: 15px;
}

.action-button {
  width: 100%;
}

.cam-view.compact {
  .ui-section-title {
    margin-bottom: 0;
  }

  .controls-container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .cam-view__buttons__row.overflow {
    margin-top: -20px;
    margin-bottom: -20px;
  }
}
</style>
