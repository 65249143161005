<!--<coffee>
import { numeral } from '@yishitec/web'
import { computed } from 'vue'

`const props = defineProps({
  data: Object
})`

percents = computed =>
  return numeral props.data.delayedPercents
    .format '0,0.00%'
</coffee>-->

<script setup>
var percents;

import {
  numeral
} from '@yishitec/web';

import {
  computed
} from 'vue';

const props = defineProps({
  data: Object
});

percents = computed(() => {
  return numeral(props.data.delayedPercents).format('0,0.00%');
});

</script>

<template lang="pug">
.state-alert(v-if="data.delayedPercents > 0")
  div
    span 约
    span.number {{ percents }}
  div 施工任务滞后
</template>

<style lang="scss" scoped>
.state-alert {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 10px;
  border-radius: 4px;
  background: #ffeaea;
}

.number {
  font-size: 1.8em;
  display: inline-block;
  margin-left: 0.25em;
}
</style>