<template>
  <Component v-if="item" :is="componentName" v-bind="item" :reportsData="reportsData" :dark="dark">
    <ReportComponent
      v-for="(childItem, itemIndex) in children"
      :key="itemIndex"
      :item="childItem"
      :reportsData="reportsData"
      :dark="dark"
    />
  </Component>
</template>

<script>
import _ from 'lodash';
import ReportSection from './ReportSection.vue';
import ReportGridsM5 from './ReportGridsM5.vue';
import ReportGrid from './ReportGrid.vue';
import ReportProgressBlocks from './ReportProgressBlocks.vue';
import ReportGridsRow from './ReportGridsRow.vue';
import ReportCardsRow from './ReportCardsRow.vue';
import ReportCard from './ReportCard.vue';
import ReportGridsM3 from './ReportGridsM3.vue';
import ReportGridBadge from './ReportGridBadge.vue';

export default {
  name: 'ReportComponent',
  props: {
    item: {
      type: Object,
      default: null,
    },
    reportsData: Object,
    dark: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ReportSection,
    ReportGridsM5,
    ReportGrid,
    ReportProgressBlocks,
    ReportGridsRow,
    ReportCardsRow,
    ReportCard,
    ReportGridsM3,
    ReportGridBadge,
  },
  computed: {
    componentName() {
      return `Report${_.upperFirst(_.camelCase(this.item.type || 'div'))}`;
    },
    children() {
      return this.item.children || [];
    },
  },
};
</script>

<style lang="scss"></style>
