<template lang="pug">
div(v-if="sections")
  div(
    v-for="section in sections"
    :key="section.id || section.title"
  )
    .title {{ section.title }}
    StatsScreenTree(:value="section.tree")
</template>

<script>
import LeftSidebarSectionTitle from './LeftSidebarSectionTitle.vue';

export default {
  name: 'LeftStats',
  props: {
    sections: Array,
  },
  components: {
    LeftSidebarSectionTitle,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.title {
  font-size: 12px;
  line-height: 22px;
  color: #6da3ce;
  text-align: center;
  border-bottom: solid 1px #194877;
  padding: 2px;
  box-sizing: border-box;
  margin-bottom: 9px;
}
</style>
