<template lang="pug">
  div(
    :style='{ marginTop: "32px" }'
  )
    ui-form(label-width='160px' v-if='attributes && attributes.length')
      ui-form-item(
        v-for="attribute in attributes"
        :key="attribute.name"
        :label="`${attribute.title || attribute.name}：`"
      )
        .hint-container
          .hint(v-if='attribute.hint')
            ui-icon(name='info').mr
            | {{ attribute.hint }}
          .tools(v-if='attribute.shortcuts && attribute.shortcuts.length && !readonly')
            .tools-body
              span(:style='{ lineHeight: "32px" }')
                ui-icon(name='document-add')
              span(
                v-for='tag in attribute.shortcuts'
                :key='tag'
              ).tools-item
                ui-button(
                  size='small'
                  @click='insertText(attribute, tag)'
                ) {{ tag }}
            .tools-extra.tools-item(v-if='false')
              ui-button(size='small')
                ui-icon(name='chat-dot-square')
        ui-input(
          type='textarea'
          v-model='json[attribute.name]'
          :autosize="{ minRows: 3, maxRows: 20 }"
          :readonly='readonly'
        )
      ui-form-item.text-center(
        v-if='!readonly'
      )
        ui-button(
          type='primary'
          @click='handleSubmit'
        )
          ui-icon(name='check').mr
          | 保存
        ui-button(
          type='primary'
          plain
          @click='handleSubmitPng'
        )
          ui-icon(name='check').mr
          | 保存并下载PNG
        ui-button(
          type='primary'
          plain
          @click='handleSubmitPdf'
        )
          ui-icon(name='check').mr
          | 保存并下载PDF
      ui-form-item.text-center(
        v-else-if='id'
      )
        ui-button(
          type='primary'
          plain
          @click='handleGetPdf()'
        )
          ui-icon(name='check').mr
          | 下载PDF
        ui-button(
          type='primary'
          plain
          @click='handleGetPdf(void 0, { png: "1" })'
        )
          ui-icon(name='check').mr
          | 下载PNG
</template>

<script>
import { _, moment } from '@yishitec/web';

export default {
  name: 'SupervisionELogEditor',
  props: {
    date: { type: [String, Number], default: null },
    projectId: { type: [String, Number], default: null },
  },
  data() {
    return {
      attributes: [],
      json: {},
      vDate: null,
      id: null,
      readonly: false,
    };
  },
  watch: {
    date() {
      this.loadData();
    },
    projectId() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      if (!this.date || !this.projectId) {
        return;
      }
      this.vDate = this.formatDate(this.date);
      const res = await this.withLoading(
        this.api(`/api/supervision-e-logs/edit/${this.projectId}/${this.vDate}`),
      );
      this.id = null;
      _.extend(this, res);
    },
    insertText(attribute, text) {
      this.$set(
        this.json,
        attribute.name,
        [this.json[attribute.name] || '', text].filter(Boolean).join('\n'),
      );
    },
    formatDate(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    async handleSubmit() {
      const res = await this.withLoading(
        this.api('/api/supervision-e-logs/save', {
          date: this.vDate,
          projectId: this.projectId,
          json: this.json,
        }),
      );
      if (res && res.id) {
        this.$message({
          type: 'success',
          message: '保存成功',
        });
      }
      return res;
    },
    async handleGetPdf(id, options = {}) {
      if (!(id || this.id)) {
        return;
      }
      const { png = '0' } = options || {};
      const pdfRes = await this.withLoading(
        this.api('/api/reports/supervision-e-logs/pdf', {
          id: id || this.id,
          ...(options || {}),
        }),
      );
      const url = _.get(pdfRes, ['url']);
      if (!url) {
        this.$message({
          type: 'error',
          message: _.get(pdfRes, 'message') || '生成PDF失败',
        });
        return;
      }
      this.$alert(`您的${png === '1' ? 'PNG' : 'PDF'}已生成，点击下方按钮下载`, '电子监理日志', {
        confirmButtonText: '下载',
        callback: (action) => {
          if (action === 'confirm') {
            window.open(url);
          }
        },
      });
    },
    async handleSubmitPng() {
      const res = await this.handleSubmit();
      const id = _.get(res, ['id']);
      if (!id) return;
      this.id = id;
      await this.handleGetPdf(undefined, { png: '1' });
    },
    async handleSubmitPdf() {
      const res = await this.handleSubmit();
      const id = _.get(res, ['id']);
      if (!id) return;
      this.id = id;
      await this.handleGetPdf();
    },
  },
};
</script>

<style lang="scss" scoped>
.hint-container {
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 8px 8px 0 0;
}

.hint {
  background: rgba(#000, 0.03);
  font-size: 12px;
  line-height: 1.6;
  box-sizing: border-box;
  padding: 8px;
  max-width: 80%;
  border-radius: 8px 8px 0 0;
  white-space: pre-line;
}

.tools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 8px;
  background: rgba(aliceblue, 0.5);
  border-radius: 8px 8px 0 0;
}

.tools-extra {
  margin-left: 12px;
}

.tools-body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  flex-wrap: wrap;
  box-sizing: border-box;
  opacity: 0.6;
}

.tools-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.tools {
  &:hover {
    .tools-body {
      opacity: 1;
    }
  }
}
</style>
