<template lang="pug">
div.stats
  .title {{ title }}
  .numbers
    span(v-if="before") {{ before }}
    span.primary {{n || '0'}}
    span(v-if="after") {{ after }}
</template>

<script>
export default {
  name: 'BoardStats',
  props: {
    title: String,
    before: String,
    n: [String, Number],
    after: String,
  },
};
</script>

<style lang="scss" scoped>
.stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 14px;
  color: #fff;
}

.numbers {
  font-size: 14px;
  color: #fff;
}

.primary {
  font-size: 20px;
  color: #f6be43;
  display: inline-block;
  margin: 0 0.1em;
}
</style>
