import { _, moment } from '@yishitec/web';

export const getSum = (projects, name) => {
  return _.sumBy(projects, (i) => i[name] || 0) || 0;
};

export const getCount = (projects, name) => {
  return _.sumBy(projects, (i) => (i[name] ? 1 : 0)) || 0;
};

export const getMax = (projects, name) => {
  return _.maxBy(projects, name) || 0;
};

export const getMaxMoment = (projects, name) => {
  return _.get(
    _.maxBy(projects, (i) => moment(i[name])),
    [name],
  );
};

export default (projects = []) => {
  const sum = (name) => {
    return getSum(projects, name);
  };
  const count = (name) => {
    return getCount(projects, name);
  };
  return [
    {
      key: 'projects',
      title: '项目管理',
      badge: 0,
      badgeTitle: '待处理',
      list: [
        {
          title: '作业项目数量',
          n: count('id'),
        },
      ],
      withHover: true,
      subWindow: 'projects',
    },
    {
      key: 'members',
      title: '人员管理',
      badge: sum('membersAlerts') + sum('membersPending'),
      badgeTitle: '待处理',
      list: [
        {
          title: '施工单位管理人员',
          n: sum('sgIn'),
          total: sum('sgOn'),
        },
        {
          title: '监理单位人员',
          n: sum('jlIn'),
          total: sum('jlOn'),
        },
        {
          title: '施工单位作业人员',
          n: sum('workerIn'),
          total: sum('workerOn'),
        },
      ],
      withHover: true,
      subWindow: 'checkins',
    },
    {
      key: 'cams',
      title: '监控管理',
      badge: sum('camsAlerts'),
      badgeTitle: '待处理',
      list: [
        {
          title: '监控数量',
          n: sum('camsOnline'),
          total: sum('cams'),
        },
      ],
      withHover: true,
      subWindow: 'cams',
    },
    {
      key: 'materials',
      title: '材料管理',
      badge: sum('materialsPending'),
      badgeTitle: '待处理',
      list: [
        {
          title: '材料记录',
          n: sum('materials'),
        },
      ],
      withHover: true,
      subWindow: 'materials',
    },
    {
      key: 'supervision-logs',
      title: '监理日志',
      list: [
        {
          title: '近30天上传数量',
          n: sum('supervisionLogsLate30'),
        },
      ],
      withHover: true,
      subWindow: 'supervisionLogs',
    },
    {
      key: 'danger-zones',
      title: '危险源管理',
      badge: sum('dangerZonesAlerts'),
      badgeTitle: '待处理',
      list: [
        {
          title: '今日作业危险源',
          n: sum('dangerZonesToday'),
          total: sum('dangerZonesTotal'),
        },
        {
          title: '设备',
          n: sum('devicesToday'),
          total: sum('devicesTotal'),
        },
        {
          title: '静态危险源',
          n: sum('staticDangerZonesToday'),
          total: sum('staticDangerZonesTotal'),
        },
      ],
      withHover: true,
      subWindow: 'dangerZones',
    },
    {
      key: 'ops-zones',
      title: '施工部位管理',
      badge: sum('opsZonesAlerts'),
      badgeTitle: '待处理',
      list: [
        {
          title: '今日作业施工部位',
          n: sum('opsZonesToday'),
          total: sum('opsZonesTotal'),
        },
        {
          title: '今日作业危险源区域',
          n: sum('areaDangerZonesToday'),
          total: sum('areaDangerZonesTotal'),
        },
      ],
      withHover: true,
      subWindow: 'opsZones',
    },
  ];
};
