<template lang='pug'>
  div
    Legends(
      title="人员信息"
      :list="legends"
      sub-window='members'
      @sub-window='$emit("sub-window", $event)'
    ).mb
    BarChart(
      :legends="legends"
      :list="membersOn"
      tail="共#人"
      round
    ).mb
</template>

<script>
import { _ } from '@yishitec/web';
import Legends from '../Legends.vue';
import BarChart from '../Charts/BarChart.vue';

export default {
  name: 'BoardMemberProfiles',
  props: {
    projects: Array,
  },
  emits: ['sub-window'],
  components: {
    Legends,
    BarChart,
  },
  data() {
    return {
      legends: [
        {
          color: '#73F39A',
          title: '在场',
          name: 'membersOn',
        },
        {
          color: '#7396F3',
          title: '暂离',
          name: 'membersOut',
        },
        {
          color: '#4D5E69',
          title: '离场的',
          name: 'membersOff',
        },
      ],
    };
  },
  computed: {
    membersOn() {
      const sum = (name) => _.sumBy(this.projects, (i) => i[name]);
      return [
        {
          id: 1,
          title: '在场情况',
          membersOn: sum('membersOn'),
          membersOut: sum('membersOut'),
          membersOff: sum('membersOff'),
        },
      ];
    },
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
.mb {
  margin-bottom: 15px;
}
</style>
