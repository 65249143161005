<template lang="pug">
div
  span(:class="[`is-${color}`]").right-badge
  span {{ title }}
</template>

<script>
export default {
  name: 'RightBadge',
  props: {
    color: String,
    title: String,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.right-badge {
  display: inline-block;
  margin: 3px 6px 3px 0;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  vertical-align: middle;

  &.is-orange {
    background: rgba(255, 199, 96, 0.2);
    border: 1px solid #ffc760;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  }

  &.is-blue {
    background: rgba(77, 123, 243, 0.2);
    border: 1px solid #4d7bf3;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  }

  &.is-green {
    background: rgba(182, 239, 127, 0.2);
    border: 1px solid #b6ef7f;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  }

  &.is-pink {
    background: rgba(255, 134, 214, 0.2);
    border: 1px solid #ff86d6;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  }

  &.is-red {
    background: rgba(#ff6969, 0.2);
    border: 1px solid #ff6969;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  }
}
</style>
