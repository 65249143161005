<script setup>
import module from './module';

const { modulePath } = module;
</script>

<template lang="pug">
  div
    ui-stage
      AdminList(
        :apiPrefix='`/api/v3/${modulePath}`'
        :module='modulePath'
      )
</template>
