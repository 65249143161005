<template lang="pug">
  Board(
    title="施工部位管理"
    :hint="`待处理 ${pending}`"
    icon="/images/data-center-screen/boards/icon-ops-zones.svg"
    sub-window='opsZones'
    @sub-window="$emit('sub-window', $event)"
  )
    .row.mb
      BulletNumbers(
        :n="opsZonesToday"
        :total="opsZonesTotal"
        title="今日作业施工部位"
      )
      BulletNumbers(
        :n="areaDangerZonesToday"
        :total="areaDangerZonesTotal"
        title="今日作业危险区域"
      )
    Legends(
      :list="legends"
    )
    LineChart(
      :list="history"
      :legends="legends"
      x="date"
      area
    )
</template>

<script>
import Board from '../Board.vue';
import BulletNumbers from '../Charts/BulletNumbers.vue';
import LineChart from '../Charts/LineChart.vue';
import Legends from '../Legends.vue';
import { getSum } from '../genCards';

export default {
  name: 'BoardOpsZones',
  props: {
    projects: Array,
    history: Array,
  },
  components: {
    BulletNumbers,
    Board,
    LineChart,
    Legends,
  },
  data() {
    return {
      legends: [
        {
          color: '#7396F3',
          title: '今日作业施工部位',
          name: 'opsZonesToday',
        },
        {
          color: '#C75C39',
          title: '待审核的作业日志',
          name: 'opsZonesAlertsToday',
        },
      ],
    };
  },
  computed: {
    pending() {
      return getSum(this.projects, 'opsZonesAlerts');
    },
    opsZonesToday() {
      return this.sum('opsZonesToday');
    },
    opsZonesTotal() {
      return this.sum('opsZonesTotal');
    },
    areaDangerZonesToday() {
      return this.sum('areaDangerZonesToday');
    },
    areaDangerZonesTotal() {
      return this.sum('areaDangerZonesTotal');
    },
  },
  methods: {
    sum(name) {
      return getSum(this.projects, name);
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}

.mb {
  margin-bottom: 15px;
}
</style>
