<template lang="pug">
div
  el-checkbox(
    border
    v-model="selectedOnly"
  ).m-card 仅已选中的项
  SspTable(
    :value="vDangerZones"
    :columns="attributes"
    @handleRow="toggleRow"
  )
    template(v-slot:check="{ row }")
      el-checkbox(
        :value="value.includes(row.id)"
        @change="toggleRow(row)"
      )
</template>

<script>
import { _ } from '@yishitec/web';

export default {
  name: 'SelectDangerZones',
  props: {
    projectId: { type: [String, Number], default: null },
    value: { type: Array, default: () => [] },
  },
  emits: ['input'],
  data() {
    return {
      dangerZones: [],
      attributes: [],
      selectedOnly: false,
    };
  },
  computed: {
    vDangerZones() {
      if (this.selectedOnly) {
        return _.filter(this.dangerZones, (item) => this.value.includes(item.id));
      }
      return this.dangerZones;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loadDangerZones();
    },
    async loadDangerZones() {
      const res = await this.withLoading(
        this.api('/api/danger-zones/list', {
          filter: this.projectId,
        }),
      );
      const { list = [], attributes } = res || {};
      this.dangerZones = list;
      this.attributes = [
        {
          name: 'check',
          title: '选择',
        },
        ...attributes,
      ];
    },
    toggleRow(row) {
      if (this.value.includes(row.id)) {
        this.$emit(
          'input',
          _.filter(this.value, (item) => item !== row.id),
        );
        return;
      }
      this.$emit('input', _.uniq([...this.value, row.id]));
    },
  },
};
</script>

<style lang="scss" scoped></style>
