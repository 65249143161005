<template lang="pug">
.bar-row
  .bar-label {{ title }}
  .bar
    .bar-active(
      :class="[`is-${color}`]"
      :style="{ width: `${(n / (total || 1)) * 100}%` }"
    )
  .bar-number {{ n | formatNumber }}
  .bar-percentage {{ (n / (total || 1)) | formatPercentage }}
</template>

<script>
import { numeral } from '@yishitec/web';

export default {
  name: 'RightBar',
  props: {
    title: String,
    color: String,
    n: Number,
    total: Number,
  },
  methods: {},
  filters: {
    formatNumber(value) {
      return numeral(value).format('0,0');
    },
    formatPercentage(value) {
      return numeral(value).format('0,0%');
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .bar {
    flex: 0 0 82px;
    height: 14px;
    margin: 1px 0;

    .bar-active {
      height: 14px;
      box-sizing: border-box;
      border-right: solid 2px #ffc760;
      width: 100%;

      &.is-orange {
        border-color: #ffc760;
        background: rgba(255, 229, 153, 0.3);
      }

      &.is-blue {
        background: rgba(132, 178, 250, 0.3);
        border-color: #4d7bf3;
      }

      &.is-red {
        background: rgba(#ff6969, 0.3);
        border-color: #ff6969;
      }

      &.is-green {
        background: rgba(220, 248, 182, 0.3);
        border-color: #b6ef7f;
      }

      &.is-pink {
        background: rgba(255, 134, 214, 0.3);
        border-color: #ff86d6;
      }
    }
  }

  .bar-label {
    margin-right: 8px;
    width: 5em;
    flex: 0 0 auto;
  }

  .bar-number {
    margin-left: 8px;
    width: 20px;
  }

  .bar-percentage {
    margin-left: 8px;
    width: 30px;
    opacity: 0.4;
  }
}
</style>
