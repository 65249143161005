import { ref } from 'vue';
import { _, axios } from '@yishitec/web';
import useRouter from './useRouter';

export default (url, iOptions) => {
  const {
    shouldRedirect = true,
    getBody: iGetBody = null,
    success = null,
    body = null,
    headers = {},
    ...options
  } = iOptions || {};

  const router = useRouter();

  const pending = ref(true);
  const data = ref(null);

  const getBody = () => {
    if (_.isFunction(iGetBody)) {
      return iGetBody();
    }
    return body;
  };

  const handleRes = async (res) => {
    const { data: iData, error } = res;
    if (error && error.value) {
      // eslint-disable-next-line no-console
      console.log({ fetchError: error.value });
    }
    data.value = iData.value.data;
    if (shouldRedirect && iData.value.message === '请先登录') {
      router.push('/auth/login');
    }
    if (_.isFunction(success)) {
      await success(data);
    }
  };

  const refresh = async () => {
    pending.value = true;
    let res;
    let error;
    try {
      res = await axios(url, {
        method: 'post',
        data: getBody(),
        headers,
        ...(options || {}),
      });
    } catch (e) {
      error = e;
    }
    pending.value = false;
    handleRes({
      data: {
        value: _.get(res, 'data', {}),
      },
      error,
    });
  };

  refresh();

  return {
    data,
    pending,
    refresh,
  };
};
