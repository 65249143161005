<template lang="pug">
.right
  .right-section
    .section-title 今日各项目登录率
    RightBadges
  .right-scroll(ref="rightScroll")
    RightProjectInfo(
      v-for="(project, i) in projects",
      :key="project.id",
      :ref="`rightScrollItem_${i}`",
      :isActive="rightScrollIndex === i"
      :project="project"
    )
    div(ref="rightScrollFill")
</template>

<script>
import RightSectionTitle from './RightSectionTitle.vue';
import RightProjectInfo from './RightProjectInfo.vue';
import RightBadges from './RightBadges.vue';

export default {
  name: 'RightSidebar',
  props: {
    projects: Array,
  },
  components: {
    RightSectionTitle,
    RightProjectInfo,
    RightBadges,
  },
  data() {
    return {
      rightScrollIndex: -1,
    };
  },
  mounted() {
    this.barsScrollNext();
  },
  methods: {
    barsScrollNext() {
      window.$refs = this.$refs;
      const noop = () => {};
      if (this.$refs.rightScroll) {
        if (this.$refs.rightScrollFill) {
          this.$refs.rightScrollFill.style.height = `${this.$refs.rightScroll.offsetHeight}px`;
        }
        try {
          this.rightScrollIndex += 1;
          let $el = this.$refs[`rightScrollItem_${this.rightScrollIndex}`];
          if (!$el) {
            this.rightScrollIndex = 0;
            $el = this.$refs[`rightScrollItem_${this.rightScrollIndex}`];
          }
          $el = ($el && $el[0]) || $el;
          if ($el) {
            if (this.$refs.rightScroll.scrollTo) {
              this.$refs.rightScroll.scrollTo(0, $el.offsetTop);
            } else {
              this.$refs.rightScroll.scrollTop = $el.offsetTop;
            }
          }
        } catch (e) {
          noop();
        }
      }
      setTimeout(this.barsScrollNext, 5000);
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../assets/styles/app-mixins.less';

.right {
  position: absolute;
  top: 146px;
  right: 16px;
  width: 234px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  box-sizing: border-box;
  padding: 44px 20px 0;
  background: url('/images/screen-v3/right.png') no-repeat top left;
  background-size: 234px 92px;
}

.right-section {
  margin-bottom: 30px;
  font-size: 12px;
  flex: 0 0 auto;
}

.section-title {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  line-height: 20px;
}

.right-scroll {
  flex: 1 0 1px;
  position: relative;
  box-sizing: border-box;
  overflow-y: auto;
  scroll-behavior: smooth;
  .thin-scroll();
  margin-right: -10px;
  padding-right: 10px;
}
</style>
