<template lang="pug">
ProjectView(
  :projectId="projectId"
  :id="id"
  isPreview
)
</template>

<script>
export default {
  name: 'ProgressProressProjectItemView',
  props: {
    projectId: { type: [String, Number], default: null },
    id: { type: [String, Number], default: null },
  },
  components: {
    // eslint-disable-next-line global-require
    ProjectView: require('./Project.vue').default,
  },
};
</script>
