<template>
  <video
    id="my-player"
    ref="video"
    class="video-js"
    controls
    preload="auto"
    data-setup="{}"
    :style="{ width: '100%', height: '100%' }"
  >
    <source :src="cam.hlsUrl" type="application/x-mpegURL" />
    <p class="vjs-no-js">
      当前浏览器不支持播放HLS视频。
      <a href="https://videojs.com/html5-video-support/" target="_blank"> 了解更多 </a>
    </p>
  </video>
</template>

<script>
export default {
  name: 'CamVideoJs',
  props: {
    cam: { type: Object, default: null },
    isHd: { type: Boolean, default: false },
    isLive: { type: Boolean, default: true },
    start: { type: [Date, Number, Date], default: null },
    end: { type: [Date, Number, Date], default: null },
  },
  metaInfo: {
    script: [
      {
        src: '/videojs/video.min.js',
      },
    ],
    link: [{ rel: 'stylesheet', href: '/videojs/video-js.min.css' }],
  },
  mounted() {
    const player = window.videojs(this.$refs.video);
    player.play();
  },
};
</script>

<style></style>
