<script>
import ProgressImageViewer from '../ProgressImageViewer.vue';

export default {
  name: 'ImageViewer',
  components: { ProgressImageViewer },
  props: {
    currentImage: { type: Object, default: null },
    children: { type: Array, default: () => [] },
    addPathForItemId: { type: [Number, String], default: null },
    readonly: { type: Boolean, default: false },
  },
  emits: ['canvasInit', 'bindPaths'],
};
</script>

<template lang="pug">
div
  ProgressImageViewer(
    :key="currentImage.id"
    v-if="currentImage && currentImage.url"
    :item="currentImage"
    :children="children"
    :addPathForItemId="addPathForItemId"
    @canvasInit="$emit('canvasInit', $event)"
    @bindPaths="$emit('bindPaths', $event)"
  )
</template>

<style scoped lang="scss">

</style>
