<template lang="pug">
a(:href="to" @click.prevent.stop="handleClick").nav-item
  img(:src="icon" :alt="title").icon
  .title {{ title }}
</template>

<script>
export default {
  name: 'HeaderNavItem',
  props: {
    title: String,
    icon: String,
    to: String,
    action: String,
  },
  emits: ['action'],
  methods: {
    handleClick() {
      if (this.action) {
        this.$emit('action', this.action);
        return;
      }
      if (this.to) {
        this.$router.push(this.to);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-item {
  box-sizing: border-box;
  padding: 4px 16px;
  cursor: pointer;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
}

.icon {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}
</style>
