<template lang="pug">
el-dialog(
  :title="'附属项目'",
  visible
  :before-close="() => $emit('close')",
  append-to-body
)
  .sub-projects-container.info-window
    div(
      v-for="project in subProjects",
      :key="project.id",
      v-html="getHtmlInfoWindow(project)"
    )
</template>

<script>
import genHtml from './getHtmlInfoWindow';

export default {
  name: 'ModalSubProjects',
  props: {
    subProjects: Array,
    tasks: Object,
  },
  emits: ['close'],
  methods: {
    getHtmlInfoWindow(project) {
      return genHtml(project, {
        tasks: this.tasks,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-projects-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  & > div {
    flex: 0 0 150px;
    margin: 0 16px 16px 0;
  }
}

.info-window {
  color: #000;
  font-size: 10px;
}
</style>
