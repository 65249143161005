<template>
  <div>
    <ui-stage v-if="data">
      <ui-tabs v-model="tab" type="border-card">
        <ui-tab-pane v-if="data.id" name="cam" label="查看监控">
          <template v-if="tab === 'cam'">
            <template v-if="isLoading">
              <ui-alert type="warning" :closable="false">加载中...</ui-alert>
            </template>
            <template v-else-if="!isOnline">
              <ui-alert type="warning" :closable="false">
                设备当前离线，无法预览。
                <!-- 您可在手机APP上查看监控回放。-->
              </ui-alert>
            </template>
            <template v-else>
              <cam-view :cam="data" />
            </template>
          </template>
        </ui-tab-pane>
        <ui-tab-pane name="main" label="编辑">
          <template v-if="tab === 'main'">
            <ui-alert
              v-if="data.id && !data.serviceType"
              class="mb"
              :closable="false"
              type="warning"
            >
              部分字段需在萤石云平台编辑，系统将定期进行同步。
            </ui-alert>
            <ui-admin-form
              :compact="compact"
              :readonly="readonly"
              :fields="attributes"
              :model="data"
              :module-url="moduleUrl"
              with-delete
              @submit="handleSubmit"
            >
              <template slot="field__state" slot-scope="{ value }">
                <ui-tag :type="value == '1' ? 'success' : 'info'">{{
                  value == '1' ? '在线' : '离线'
                }}</ui-tag>
              </template>
              <template slot="field__is_critical" slot-scope="{ value, onInput }">
                <ui-select :value="String(value)" @input="onInput">
                  <ui-option value="true" label="是"></ui-option>
                  <ui-option value="false" label="否"></ui-option>
                </ui-select>
              </template>
              <template v-slot:field__location_amap="{ value, onInput, readonly }">
                <app-map-editor :value="value" @input="onInput" :readonly="readonly" type="point" />
              </template>
            </ui-admin-form>
          </template>
        </ui-tab-pane>
      </ui-tabs>
    </ui-stage>
  </div>
</template>

<script>
import { _ } from '@yishitec/web';
import { adminEdit } from '@yishitec/web/mixins';
import CamView from '../../../components/admin/CamView/CamView.vue';
import AppMapEditor from '../../../components/AppMapEditor.vue';

export default {
  name: 'Edit',
  mixins: [adminEdit],
  components: { CamView, AppMapEditor },
  data() {
    return {
      tab: 'cam',
      withCustomFields: false,
    };
  },
  computed: {
    isLoading() {
      return !this.data;
    },
    isOnline() {
      return this.data && this.data.state;
    },
  },
  methods: {
    handleFetchDataRes(res) {
      if (!_.get(res, ['item', 'id'])) {
        this.tab = 'main';
      } else {
        this.tab = 'cam';
      }
    },
  },
};
</script>
