<template lang="pug">
div(:class="{ bordered }")
  slot(v-if="display")
</template>

<script>
export default {
  name: 'FeContainer',
  props: {
    display: { type: Boolean, default: false },
    bordered: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.bordered {
  border: solid 1px red;
}
</style>
