<template>
  <div>
    <ui-stage v-loading="loading">
      <ui-button class="mb" @click="handleSubmit" type="primary">添加选中项</ui-button>
      <ui-table :data="list" @selection-change="handleSelectionChange">
        <ui-table-column type="selection" width="55" />
        <ui-table-column label="公司" property="company" />
        <ui-table-column label="用户名" property="username" />
        <ui-table-column label="姓名" property="display_name" />
      </ui-table>
    </ui-stage>
  </div>
</template>

<script>
import { _ } from '@yishitec/web';

export default {
  name: 'AddJs',
  data() {
    return {
      loading: false,
      list: [],
      selected: [],
      projectId: null,
    };
  },
  watch: {
    $route() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      this.projectId = this.$route.query.project_id;
      this.list = await this.withLoading(this.api('/api/users/add-js'));
      this.loading = false;
    },
    handleSelectionChange(ev) {
      this.selected = ev;
    },
    async handleSubmit() {
      const res = await this.withLoading(
        this.api('/api/users/save-add-js', {
          pmIds: _.map(this.selected, (item) => item.id),
          projectId: this.projectId,
        }),
      );
      if (res && res.length) {
        this.$message({
          type: 'success',
          message: '已添加',
        });
        this.$router.push(`/admin/users?project_id=${encodeURIComponent(this.projectId)}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
