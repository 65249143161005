<template>
  <div>
    <ui-stage v-if="data">
      <ui-tabs v-model="tab" type="border-card">
        <ui-tab-pane name="main" label="编辑">
          <el-alert v-if="alert" type="warning" class="mb">
            {{ alert.message }}
            <el-button :type="alert.type" v-if="alert.action" class="ml" @click="handleAlertAction">
              {{ alert.action }}
            </el-button>
          </el-alert>
          <ui-admin-form
            :compact="compact"
            :readonly="readonly"
            :fields="attributes"
            :model="data"
            :module-url="moduleUrl"
            with-delete
            @submit="handleSubmit"
          >
            <template v-slot:field__opsZoneId="{ type, config, readonly }">
              <ui-type-edit
                :type="type"
                v-model="data['opsZoneId']"
                :config="config"
                :readonly="readonly"
              />
              <UiNotice v-if="data.opsZoneId" :url="`/api/ops-zones/notice/${data.opsZoneId}`" />
            </template>
          </ui-admin-form>
        </ui-tab-pane>
      </ui-tabs>
    </ui-stage>
  </div>
</template>

<script>
import { _ } from '@yishitec/web';
import { adminEdit } from '@yishitec/web/mixins';
import UiTypeEdit from '@yishitec/web/components/UiTypeEdit.vue';
import permissionsMixin from '../../../mixins/permissions';

export default {
  name: 'Edit',
  mixins: [adminEdit, permissionsMixin],
  components: { UiTypeEdit },
  data() {
    return {
      withCustomFields: false,
      alert: {},
    };
  },
  methods: {
    handleFetchDataRes(res) {
      this.alert = _.get(res, 'alert') || null;
    },
    async handleAlertAction() {
      if (!this.alert) return;
      if (this.alert.actionMessage) {
        this.$message({
          type: this.alert.type || '',
          message: this.alert.actionMessage,
        });
      }
      if (this.alert.api) {
        const res = await this.withLoading(
          this.api(this.alert.api, {
            ...this.data,
          }),
        );
        if (res) {
          this.$message({
            type: 'success',
            message: '审核成功',
          });
          this.fetchData();
        }
      }
    },
  },
};
</script>
