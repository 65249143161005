<script>
export default {
  name: 'BlueprintImageDetail',
  props: {
    currentImage: { type: Object, default: null },
    readonly: { type: Boolean, default: false },
  },
  setup(props) {
    return {};
  },
};
</script>

<template lang="pug">
div(v-if="currentImage").detail-toolbar
  div
    span(v-if='false') {{ currentImage.id }}
    span {{ currentImage.title }}
  div
    el-button(
      v-if="!readonly"
      size="mini"
    ) 编辑
</template>

<style scoped lang="scss">
.detail-toolbar {
  background: #042d83;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 8px 16px 8px;
  color: #fff;
  border-radius: 8px 8px 0 0;
  margin-bottom: -8px;
}
</style>
