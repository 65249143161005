<template lang="pug">
div
  ui-stage
    ProjectsFilterList(
      url="/api/ops-zones-logs/list"
      pathPrefix="/admin/ops-zones-logs/edit"
    )
      template(v-slot="{ projectId }")
        template(v-if="projectId")
          router-link(
            to="/admin/ops-zones"
          ).mr
            el-button(
              type="primary"
            ) 选择施工部位添加今日作业记录
          router-link(
            :to="`/admin/ops-zones-logs-report?projectId=${projectId}`"
          ).mr
            el-button(
              type="default"
            ) 查看统计表
      template(v-slot:projectId="{ row }") {{ $get(row, ['xProject', 'title']) }}
      template(v-slot:opsZoneId="{ row }") {{ $get(row, ['xOpsZone', 'title']) }}
      template(v-slot:userId="{ row }") {{ $get(row, ['xUser', 'display_name']) }}
      template(v-slot:startApprovedBy="{ row }") {{ $get(row, ['xOpsZonesLogStartApprovedBy', 'display_name']) }}
      template(v-slot:endApprovedBy="{ row }") {{ $get(row, ['xOpsZonesLogEndApprovedBy', 'display_name']) }}
      template(v-slot:images="{ row }")
        small(v-if="!row.images") -
        i(v-else).el-icon-picture
      template(v-slot:startAt="{ row }")
        small {{ formatDateTime(row.startAt) }}
      template(v-slot:endAt="{ row }")
        small {{ formatDateTime(row.endAt) }}
      template(v-slot:createdAt="{ row }")
        small {{ formatDateTime(row.createdAt) }}
      template(v-slot:updatedAt="{ row }")
        small {{ formatDateTime(row.updatedAt) }}
      template(v-slot:startApprovalState="{ row }")
        ApprovalStateTag(:value="row.startApprovalState")
      template(v-slot:endApprovalState="{ row }")
        ApprovalStateTag(:value="row.endApprovalState")
</template>

<script>
export default {
  name: 'List',
};
</script>

<style lang="scss" scoped></style>
