<template lang="pug">
.cam-snapshot-container
  .cam-snapshot.cursor-pointer(v-if='item', @click='displayAi = true')
    ui-flex.offline-masked(v-if='item.camState === "离线"', col, j-center, a-center) 监控离线
    el-image.cover-image(v-else-if='item.image', :src='item.image', fit='cover')
    ui-flex.offline-masked(v-else, col, j-center, a-center) 没有截图
    .title {{ formatTime(item.createdAt) }}
  CamSnapshotAi(v-if='displayAi', @close='displayAi = false', :id='item.id')
</template>

<script>
import { moment, _ } from '@yishitec/web';
import CamSnapshotAi from './CamSnapshotAi.vue';

export default {
  name: 'CamSnapshot',
  props: {
    item: { type: Object, default: null },
    list: { type: Array, default: () => [] },
  },
  components: {
    CamSnapshotAi,
  },
  data() {
    return {
      displayAi: false,
    };
  },
  computed: {
    vPreviewList() {
      return _.map(this.list, (item) => {
        return item.image;
      });
    },
  },
  methods: {
    formatTime(value) {
      const m = moment(value);
      if (!m.isValid()) return '/';
      return m.format('HH:mm:ss');
    },
  },
};
</script>

<style lang="scss" scoped>
.cam-snapshot {
  position: relative;
  background: rgba(#000, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.cover-image {
  display: block;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 4 / 3;
}

.title {
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(#000, 1), rgba(#000, 0));
  color: #fff;
}

.offline-masked {
  aspect-ratio: 4 / 3;
  background: rgba(#000, 0.6);
  color: #fff;
}
</style>
