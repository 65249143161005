<template>
  <div>
    <ui-stage>
      <ui-flex row>
        <ui-flex style="flex: 0 0 110px" class="mr br pr">
          <app-links-list v-model="filter" :data="projects" with-search />
        </ui-flex>
        <ui-flex>
          <ui-admin-table
            v-if="data"
            :attributes="attributes"
            :data="data"
            :module-url="moduleUrl"
            pagination-method="front-end"
            :with-create="false"
            always-display-pagination
            ref="table"
          >
            <template slot="toolbar">
              <div class="mb" v-if="canManageSuperAdmin">
                <router-link
                  class="mr"
                  :to="`/admin/users/add?admintype=${encodeURIComponent('平台管理员')}`"
                >
                  <ui-button type="primary" icon="el-icon-edit-outline">创建平台管理员</ui-button>
                </router-link>
              </div>
              <div class="mb" v-if="canManageJsAdmin">
                <router-link
                  class="mr"
                  :to="`/admin/users/add?project_id=${encodeURIComponent(
                    filter,
                  )}&company_type=${encodeURIComponent('建设单位')}&admintype=${encodeURIComponent(
                    '管理人员',
                  )}`"
                >
                  <ui-button type="primary" icon="el-icon-edit-outline"
                    >创建建设单位管理人员
                  </ui-button>
                </router-link>
                <router-link
                  class="mr"
                  :to="`/admin/users/add-js?project_id=${encodeURIComponent(filter)}`"
                >
                  <ui-button type="primary" icon="el-icon-edit-outline">
                    添加建设单位管理人员
                  </ui-button>
                </router-link>
              </div>
              <template v-if="canManageJsAdminSameCompany">
                <div class="mb" v-for="(company, index) in companyNamesJs" :key="`js1-${index}`">
                  <router-link
                    class="mr"
                    :to="`/admin/users/add?project_id=${encodeURIComponent(
                      filter,
                    )}&company=${encodeURIComponent(company)}&company_type=${encodeURIComponent(
                      '建设单位',
                    )}&admintype=${encodeURIComponent('管理人员')}`"
                  >
                    <ui-button type="primary" icon="el-icon-edit-outline"
                      >创建建设单位管理人员：{{ company }}
                    </ui-button>
                  </router-link>
                </div>
              </template>
              <div class="mb" v-if="canManageJsAdminNoProject">
                <router-link
                  class="mr"
                  :to="`/admin/users/add?company_type=${encodeURIComponent(
                    '建设单位',
                  )}&admintype=${encodeURIComponent('管理人员')}`"
                >
                  <ui-button type="primary" icon="el-icon-edit-outline"
                    >创建建设单位管理人员
                  </ui-button>
                </router-link>
              </div>
              <template v-if="canManageJsAdminNoProjectSameCompany">
                <div class="mb" v-for="(company, index) in companyNamesJs" :key="`js2-${index}`">
                  <router-link
                    class="mr"
                    :to="`/admin/users/add?company=${encodeURIComponent(
                      company,
                    )}&company_type=${encodeURIComponent(
                      '建设单位',
                    )}&admintype=${encodeURIComponent('管理人员')}`"
                  >
                    <ui-button type="primary" icon="el-icon-edit-outline"
                      >创建建设单位管理人员：{{ company }}
                    </ui-button>
                  </router-link>
                </div>
              </template>
              <template v-if="canManageJsProjectOps">
                <div class="mb" v-for="(company, index) in companyNamesJs" :key="`js2-${index}`">
                  <router-link
                    class="mr"
                    :to="`/admin/users/add?project_id=${encodeURIComponent(
                      filter,
                    )}&company=${encodeURIComponent(company)}&company_type=${encodeURIComponent(
                      '建设单位',
                    )}&admintype=${encodeURIComponent('专项管理员')}`"
                  >
                    <ui-button type="primary" icon="el-icon-edit-outline"
                      >创建建设单位专项管理员：{{ company }}
                    </ui-button>
                  </router-link>
                </div>
              </template>
              <div class="mb" v-if="canManageJlAdmin">
                <router-link
                  class="mr"
                  :to="`/admin/users/add?project_id=${encodeURIComponent(
                    filter,
                  )}&company_type=${encodeURIComponent('监理单位')}&admintype=${encodeURIComponent(
                    '管理人员',
                  )}`"
                >
                  <ui-button type="primary" icon="el-icon-edit-outline"
                    >创建监理单位管理人员
                  </ui-button>
                </router-link>
              </div>
              <template v-if="canManageJlAdminSameCompany">
                <div class="mb" v-for="(company, index) in companyNamesJl" :key="`jl1-${index}`">
                  <router-link
                    class="mr"
                    :to="`/admin/users/add?project_id=${encodeURIComponent(
                      filter,
                    )}&company=${company}&company_type=${encodeURIComponent(
                      '监理单位',
                    )}&admintype=${encodeURIComponent('管理人员')}`"
                  >
                    <ui-button type="primary" icon="el-icon-edit-outline"
                      >创建监理单位管理人员：{{ company }}
                    </ui-button>
                  </router-link>
                </div>
              </template>
              <template v-if="canManageJlOperator">
                <div class="mb" v-for="(company, index) in companyNamesJl" :key="`js2-${index}`">
                  <router-link
                    class="mr"
                    :to="`/admin/users/add?project_id=${encodeURIComponent(
                      filter,
                    )}&company=${encodeURIComponent(company)}&company_type=${encodeURIComponent(
                      '监理单位',
                    )}&admintype=${encodeURIComponent('作业人员')}`"
                  >
                    <ui-button type="primary" icon="el-icon-edit-outline"
                      >创建监理单位作业人员：{{ company }}
                    </ui-button>
                  </router-link>
                </div>
              </template>
              <div class="mb" v-if="canManageSgAdmin">
                <router-link
                  class="mr"
                  :to="`/admin/users/add?project_id=${encodeURIComponent(
                    filter,
                  )}&company_type=${encodeURIComponent('施工单位')}&admintype=${encodeURIComponent(
                    '管理人员',
                  )}`"
                >
                  <ui-button type="primary" icon="el-icon-edit-outline"
                    >创建施工单位管理人员
                  </ui-button>
                </router-link>
              </div>
              <template v-if="canManageSgAdminSameCompany">
                <div class="mb" v-for="(company, index) in companyNamesSg" :key="`sg1-${index}`">
                  <router-link
                    class="mr"
                    :to="`/admin/users/add?project_id=${encodeURIComponent(
                      filter,
                    )}&company=${encodeURIComponent(company)}&company_type=${encodeURIComponent(
                      '施工单位',
                    )}&admintype=${encodeURIComponent('管理人员')}`"
                  >
                    <ui-button type="primary" icon="el-icon-edit-outline"
                      >创建施工单位管理人员：{{ company }}
                    </ui-button>
                  </router-link>
                </div>
              </template>
              <template v-if="canManageSgAdminSameCompany">
                <div class="mb" v-for="(company, index) in companyNamesSg" :key="`jl2-${index}`">
                  <router-link
                    class="mr"
                    :to="`/admin/users/add?project_id=${encodeURIComponent(
                      filter,
                    )}&company=${company}&company_type=${encodeURIComponent(
                      '施工单位',
                    )}&admintype=${encodeURIComponent('安全员')}`"
                  >
                    <ui-button type="primary" icon="el-icon-edit-outline"
                      >创建施工单位安全员：{{ company }}
                    </ui-button>
                  </router-link>
                </div>
              </template>
              <template v-if="canManageSgOperatorSameCompany">
                <div class="mb" v-for="(company, index) in companyNamesSg" :key="`sg2-${index}`">
                  <router-link
                    class="mr"
                    :to="`/admin/users/add?project_id=${encodeURIComponent(
                      filter,
                    )}&company=${company}&company_type=${encodeURIComponent(
                      '施工单位',
                    )}&admintype=${encodeURIComponent('作业人员')}`"
                  >
                    <ui-button type="primary" icon="el-icon-edit-outline"
                      >创建施工单位作业人员：{{ company }}
                    </ui-button>
                  </router-link>
                </div>
              </template>
            </template>
            <div slot="column__adminActions" slot-scope="scope">
              <ui-button-group>
                <router-link
                  :to="`${moduleUrl}/edit/${scope.row.id}?pageInList=${$refs.table.currentPage}&listPageSize=${$refs.table.pageSize}`"
                >
                  <ui-button size="mini" type="primary" icon="el-icon-edit">编辑</ui-button>
                </router-link>
                <router-link
                  :to="`${moduleUrl}/delete/${scope.row.id}?backUrl=${encodeURIComponent(
                    $route.fullPath,
                  )}`"
                  v-if="canEditRow(scope.row)"
                >
                  <ui-button size="mini" type="info" icon="el-icon-delete">删除</ui-button>
                </router-link>
              </ui-button-group>
            </div>
          </ui-admin-table>
        </ui-flex>
      </ui-flex>
    </ui-stage>
  </div>
</template>

<script>
import { _ } from '@yishitec/web';
import { adminList } from '@yishitec/web/mixins';
import AppLinksList from '../../../components/AppLinksList.vue';
import withProjectsFilters from '../../../mixins/withProjectsFilters';
import permissions from '../../../mixins/permissions';

export default {
  name: 'List',
  components: { AppLinksList },
  mixins: [adminList, withProjectsFilters, permissions],
  data() {
    return {
      filter: Number(this.$route.query.project_id),
      defaultFetchData: false,
      apiBodyData: {
        currentProjects: this.$store.state.currentProjects,
      },
    };
  },
  computed: {
    companyNamesJs() {
      return this.getCompanyNames('建设单位');
    },
    companyNamesJl() {
      return this.getCompanyNames('监理单位');
    },
    companyNamesSg() {
      return this.getCompanyNames('施工单位');
    },
    isFilterProjectId() {
      return this.filter && this.filter !== 'NONE' && !_.startsWith(this.filter, '_');
    },
    canManageSuperAdmin() {
      return this.filter === '_平台管理员' && this.isSuperAdmin;
    },
    canManageJsAdmin() {
      return this.isFilterProjectId && this.isSuperAdmin;
    },
    canManageJsAdminSameCompany() {
      return this.isFilterProjectId && this.amI(this.filter, '建设单位', '管理人员', true);
    },
    canManageJsAdminNoProject() {
      return (!this.filter || this.filter === 'NONE') && this.isSuperAdmin;
    },
    canManageJsAdminNoProjectSameCompany() {
      return (
        (!this.filter || this.filter === 'NONE') && this.amI(null, '建设单位', '管理人员', true)
      );
    },
    canManageJsProjectOps() {
      return this.isFilterProjectId && this.amI(this.filter, '建设单位', '管理人员', true);
    },
    canManageJlAdmin() {
      return this.isFilterProjectId && this.amI(this.filter, '建设单位', '管理人员', true);
    },
    canManageJlAdminSameCompany() {
      return this.isFilterProjectId && this.amI(this.filter, '监理单位', '管理人员', true);
    },
    canManageJlOperator() {
      return this.isFilterProjectId && this.amI(this.filter, '监理单位', '管理人员', true);
    },
    canManageSgAdmin() {
      return this.isFilterProjectId && this.amI(this.filter, '建设单位', '管理人员', true);
    },
    canManageSgAdminSameCompany() {
      return this.isFilterProjectId && this.amI(this.filter, '施工单位', '管理人员', true);
    },
    canManageSgOperatorSameCompany() {
      return (
        this.isFilterProjectId &&
        (this.amI(this.filter, '施工单位', '管理人员', true) ||
          this.amI(this.filter, '施工单位', '安全员', true))
      );
    },
  },
  watch: {
    data(next, prev) {
      if (!prev && next) {
        this.$nextTick(() => {
          this.$refs.table.pageSize = Number(this.$route.query.pageSize || 10);
          this.$nextTick(() => {
            this.$refs.table.currentPage = Number(this.$route.query.page || 1);
          });
        });
      }
    },
  },
  mounted() {
    this.apiBodyData.filter = this.filter;
    this.fetchData();
    this.filterName = _.get(
      _.find(this.projects, (item) => item.id === this.filter),
      'title',
      '',
    );
  },
  methods: {
    getCompanyNames(companyType) {
      return _.uniq(
        _.map(
          _.filter(
            this.$store.state.permissions,
            (item) =>
              item.company_type === companyType &&
              (item.admintype === '管理人员' || item.admintype === '安全员'),
          ),
          (item) => item.company,
        ),
      );
    },
    addCustomFilters(projects) {
      if (this.amI(null, null, '平台管理员')) {
        projects.push({
          id: '_平台管理员',
          title: '平台管理员',
        });
        projects.push({
          id: 'NONE',
          title: '没有绑定到项目的',
        });
      }
      if (this.amI(null, '监理单位', '管理人员', true)) {
        projects.push({
          id: '_待审核',
          title: '待审核',
        });
      }
      if (
        this.amI(null, '建设单位', '管理人员', true) ||
        this.amI(null, '建设单位', '专项管理员', true)
      ) {
        projects.push({
          id: '_待建设单位审核',
          title: '待建设单位审核',
        });
      }
    },
    canEditRow(row) {
      if (this.amIInCompany(row.company) && row.username === 'admin') {
        // 不能自行删除本公司管理员
        return false;
      }
      if (row.admintype === '平台管理员') {
        if (this.amI(null, null, '平台管理员')) return true;
      }
      if (row.company_type === '建设单位' && row.admintype === '管理人员') {
        if (this.amI(null, null, '平台管理员')) return true;
        if (row.company && this.amI(null, '建设单位', '管理人员', true)) {
          if (_.indexOf(this.companyNamesJs, row.company) !== -1) return true;
        }
      }
      if (row.company_type === '建设单位' && row.admintype === '专项管理员') {
        if (this.amI(row.project_id, '建设单位', '管理人员', true)) return true;
      }
      if (row.company_type === '监理单位' && row.admintype === '管理人员') {
        if (this.amI(row.project_id, '建设单位', '管理人员', true)) return true;
        if (this.amI(row.project_id, '监理单位', '管理人员', true)) return true;
      }
      if (row.company_type === '监理单位' && row.admintype === '作业人员') {
        if (this.amI(row.project_id, '监理单位', '管理人员', true)) return true;
      }
      if (row.company_type === '监理单位' && row.admintype === '专项管理员') {
        if (this.amI(row.project_id, '监理单位', '管理人员', true)) return true;
      }
      if (row.company_type === '施工单位' && row.admintype === '管理人员') {
        if (this.amI(row.project_id, '建设单位', '管理人员', true)) return true;
        if (this.amI(row.project_id, '施工单位', '管理人员', true)) return true;
      }
      if (row.company_type === '施工单位' && row.admintype === '安全员') {
        if (this.amI(row.project_id, '施工单位', '管理人员', true)) return true;
      }
      if (row.company_type === '施工单位' && row.admintype === '作业人员') {
        if (
          this.amI(row.project_id, '施工单位', '管理人员', true) ||
          this.amI(row.project_id, '施工单位', '安全员', true)
        ) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
