<template>
  <div :class="['report-grid', { dark, pointer: toWeb }]" @click="handleClick">
    <div :class="['grid-title', { dark }]" :style="{ color: titleColor }">
      {{ title }}
    </div>
    <div class="grid-number" :style="{ color }">{{ number }}</div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ReportGrid',
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
    reportsData: Object,
    dark: Boolean,
    titleColor: String,
    color: String,
    toWeb: String,
  },
  computed: {
    number() {
      return _.get(this.reportsData, this.data) || 0;
    },
  },
  methods: {
    handleClick() {
      if (!this.toWeb) {
        return;
      }
      this.$router.push(this.toWeb);
    },
  },
};
</script>

<style lang="scss">
.report-grid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  font-size: 1em;
  font-weight: bolder;
  padding: 10px;
  box-sizing: border-box;
  &.pointer {
    cursor: pointer;
  }
  .grid-title {
    color: #1e3258;
    margin-bottom: 10px;
    &.dark {
      color: #fff;
    }
  }
  .grid-number {
    font-size: 1.8em;
  }
}
</style>
