<template lang="pug">
div
  div.p-trace
    div.p-container
      el-breadcrumb
        el-breadcrumb-item 作业计划
        el-breadcrumb-item 项目列表
  div.p-page
    div.p-container
      div.map-container
        app-map-mouse-editor(
          :screen-offset="'400px'"
          minHeight="400px"
          readonly
          @map-ready="handleMapRes"
          :mapStyle="`amap://styles/97ae8bc5c7a3075efd63065ce082271c`"
        ).map-container
      SspTabs(
        v-model="tab"
        :tabs="tabs"
      )
        template(v-slot:toolbar)
          div.toolbar-label 排序：
          ui-data-select(
            v-model="sortBy"
            :options="sortByOptions"
          )
          el-input(
            v-model="keyword"
            placeholder="系统ID或关键词"
          ).ml
            template(v-slot:append)
              el-button(icon="el-icon-search")
      div(v-loading="loading")
        SspEmptyCard(
          v-if="!vData || !vData.length"
          :hint="'您可以通过“创建新的项目”来开始工作。'"
        )
        SspTable(
          v-else
          :value="vData"
          :columns="attributes"
          @handleRow="handleRow"
          :searchKeyword="keyword"
        )
          template(v-slot:addtime="{ row }") {{ row.addtime | formatDateTime }}
          template(v-slot:progressState="{ row }")
            ProgressStateTag(:value="$get(row, 'xProjectProgresss.0.state') || '未开始'")
          template(v-slot:aggProgress="{ row }")
            SubProjectNavItem(
              :id="row.id"
              :title="''"
              :progress="$get(row, ['xProjectProgresss', 0, 'aggProgress'])"
              :active="false"
              :isTool="false"
              :row="row"
            )
</template>

<script>
import { _, moment } from '@yishitec/web';
import AppMapMouseEditor from '../../../components/maps/AppMapMouseEditor.vue';
import ProgressStateTag from '../../../components/ProgressStateTag.vue';
import SubProjectNavItem from '../../../components/SubProjectNavItem.vue';

export default {
  name: 'ProressProjectsList',
  props: {},
  components: {
    AppMapMouseEditor,
    ProgressStateTag,
    SubProjectNavItem,
  },
  data() {
    return {
      tab: 'All',
      data: [],
      loading: false,
      sortBy: 'updatedAt',
      keyword: '',
    };
  },
  computed: {
    tabs() {
      return [
        // {
        //   key: 'All',
        //   badge: false,
        //   title: '全部',
        // },
        // {
        //   key: 'Wip',
        //   number: 0,
        //   title: '进行中',
        //   color: '#89C45A',
        // },
        // {
        //   key: 'Todo',
        //   number: 0,
        //   title: '未开始',
        //   color: '#E48080',
        // },
        // {
        //   key: 'Done',
        //   number: 0,
        //   title: '已结束',
        // },
      ];
    },
    vData() {
      return _.reverse(
        _.sortBy(this.data, [
          (item) => {
            return moment(_.get(item, 'xProjectProgresss.0.updatedAt') || item.updatedAt).valueOf();
          },
        ]),
      );
    },
    sortByOptions() {
      return [
        {
          value: 'updatedAt',
          title: '更新时间',
        },
      ];
    },
    attributes() {
      return [
        {
          name: 'id',
          title: 'ID',
        },
        { name: 'title', title: '项目名称' },
        { name: 'location', title: '项目地址' },
        { name: 'type', title: '项目类型' },
        { name: 'addtime', title: '创建时间' },
        { name: 'progressState', title: '状态' },
        { name: 'aggProgress', title: '进度' },
      ];
    },
  },
  watch: {
    tab() {
      this.loadData();
    },
    data() {
      this.handleMapRes(this.map);
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const res = await this.withLoading(this.api('/api/progress/projects'));
      if (res) {
        const { list } = res;
        this.data = list;
      }
      this.loading = false;
    },
    handleRow(row) {
      this.$router.push(`/progress/progress/view/${row.id}`);
    },
    handleMapRes(mapRes) {
      if (!mapRes) return;
      if (mapRes) {
        this.map = mapRes;
      }
      if (!this.map) return;
      const { map, AMap } = this.map;
      const markers = [];
      _.forEach(this.data, (project) => {
        const fence = JSON.parse(project.fence || '[]');

        _.forEach(fence, (fencePartial) => {
          const position = fencePartial[0];
          if (position) {
            const polygon = new AMap.Polygon({
              path: _.map(fencePartial, (point) => new AMap.LngLat(point.lng, point.lat)),
              strokeColor: '#35F48F',
              strokeOpacity: 0.2,
              fillColor: '#35F48F',
              fillOpacity: 0.1,
              strokeWeight: 1,
            });
            polygon.on('mouseover', () => {
              polygon.setOptions({
                storkeOpacity: 0.8,
                fillOpacity: 0.5,
              });
            });
            polygon.on('mouseout', () => {
              polygon.setOptions({
                storkeOpacity: 0.2,
                fillOpacity: 0.1,
              });
            });
            const size = new AMap.Size(163, 180);
            const infoWindow = new AMap.InfoWindow({
              isCustom: false,
              autoMove: true,
              closeWhenClickMap: true,
              content: `${project.title}`,
              size,
              anchor: 'bottom-center',
              offset: new AMap.Pixel(0, -20),
              showShadlw: true,
              retainWhenClose: false,
            });
            polygon.on('click', () => {
              infoWindow.open(map, new AMap.LngLat(position.lng, position.lat));
            });
            markers.push(polygon);
            map.add(polygon);

            const marker = new AMap.Marker({
              position: new AMap.LngLat(position.lng, position.lat),
              anchor: 'bottom-center',
              offset: new AMap.Pixel(0, 0),
              topWhenClick: true,
              // eslint-disable-next-line global-require
              icon: require('../../../assets/images/screen/project-mark.png'),
            });
            marker.on('click', () => {
              infoWindow.open(map, new AMap.LngLat(position.lng, position.lat));
            });
            markers.push(marker);
            map.add(marker);

            const markerText = new AMap.Text({
              position: new AMap.LngLat(position.lng, position.lat),
              anchor: 'bottom-center',
              offset: new AMap.Pixel(0, -20),
              text: project.title,
            });
            markers.push(markerText);
            map.add(markerText);
          }
        });
      });
      map.setFitView(markers);
    },
  },
  filters: {
    formatDateTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  margin-bottom: 32px;
  ::v-deep {
    .amap-overlay-text-container {
      background: none;
      color: #35f48f;
      border: none;
      font-weight: bolder;
      text-shadow: 1px 1px 2px #000;
    }
  }
}
</style>
