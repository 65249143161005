<script setup>
import _ from 'lodash';
import {
  defineProps, defineEmits, ref, computed,
} from 'vue';
import { useNotification, $fetch } from '../../composables';

const props = defineProps({
  modelValue: String,
  mini: Boolean,
  action: { type: String, default: '/api/upload' },
  triggered: Boolean,
  readonly: Boolean,
  limit: Number,
  apiData: Object,
});
const $emit = defineEmits(['update:modelValue']);

const { Notification } = useNotification();

const valueToList = value => {
  return String(value || '')
    .trim()
    .split('\n')
    .filter(Boolean)
    .map(url => ({ name: url, url }));
};

const listToValue = list => {
  return list.map(i => i.url).filter(Boolean).join('\n');
};

const filesList = ref(valueToList(props.modelValue));

const uploadId = ref(0);

const handleFileAdded2 = async (file) => {
  if (props.limit === 1) {
    filesList.value = [];
  } else if (props.limit > 1 && filesList.value.length >= props.limit) {
    Notification.warning({ title: `文件上传限${props.limit}个文件，请先删除现有的文件再上传` });
    return;
  }
  // eslint-disable-next-line no-plusplus
  const id = uploadId.value++;
  filesList.value.push({ id, file });
  const formData = new FormData();
  _.forEach(props.apiData || {}, (value, key) => {
    formData.append(key, value);
  });
  formData.append('file', file);
  const res = await $fetch(props.action, {
    method: 'post',
    body: formData,
  });
  const newItem = { name: res.url, url: res.url };
  const next = listToValue([
    ...valueToList(props.modelValue),
    newItem,
  ]);
  $emit('update:modelValue', next);
  filesList.value = filesList.value.map(i => {
    if (i.id === id) {
      return newItem;
    }
    return i;
  });
};

const handleFileAdded = async (file) => {
  await handleFileAdded2(file);
  throw new Error('PreventDefault');
};

const handleFileRemoved = event => {
  const next = valueToList(props.modelValue)
    .filter(i => i.url !== event.url);
  $emit('update:modelValue', listToValue(next));
  filesList.value = filesList.value.filter(i => i.url !== event.url);
};

const filesCount = computed(() => {
  return valueToList(props.modelValue).length;
});

const buttonText = computed(() => {
  if (props.readonly) return `${filesCount.value}`;
  if (!props.mini) return '上传';
  if (!filesCount.value) return '上传';
  return `${filesCount.value}`;
});

const pending = ref(false);

const $upload = ref(null);

// onMounted(() => {
//   if (props.triggered && $upload.value && !props.modelValue) {
//     pending.value = true
//     setTimeout(() => {
//       pending.value = false
//     }, 1000)
//   }
// })
//
const displayModal = ref(false);
</script>

<template lang='pug' scoped>
div
  el-upload(
    :file-list="filesList"
    :show-file-list="false"
    :action="action"
    :before-upload="handleFileAdded"
    :before-remove="handleFileRemoved"
    ref="$upload"
    :disabled="pending || readonly"
    multiple
    v-if="!readonly"
  )
    el-button(type="secondary") {{ pending ? '正在准备...' : '上传' }}
  div(v-else-if="!filesList || !filesList.length") - 无 -
  AdminFilesList(:list="filesList" @delete="handleFileRemoved($event)" :readonly="readonly")
</template>
