<template lang="pug">
.cam-snapshot-ai-mask(@click='close')
  .cam-snapshot-ai(@click.stop)
    ui-flex.cam-snapshot-ai-title(row, j-between)
      .cam-snapshot-ai-title-text
        | 营造云AI分析
      .cam-snapshot-ai-title-close(@click='close')
        | 关闭
    ui-flex.cam-snapshot-ai-content(row, v-if='!loaded')
      | 加载中...
    ui-flex.cam-snapshot-ai-content(row, v-else)
      .photo(v-if='snapshotAi')
        el-image.photo-img(
          :src='snapshotAi.image',
          fit='contain',
          :preview-src-list='[snapshotAi.image]'
        )
        .info-card(v-if='snapshotAi.xCam')
          .info-title {{ snapshotAi.xCam.title }}
          .info-hint {{ formatDateTime(snapshotAi.createdAt) }}
      .body.body-empty(v-if='snapshotAi.aiInitState !== "已完成"')
        .ai-title 营造云AI分析
        div
          el-button(@click='initAi', type='primary') 启动AI分析
      .body(v-else)
        .ai-title 营造云AI分析
        .stats
          .stats-item
            .stats-item-title 人员数
            .stats-item-value {{ aboveZero(snapshotAi.workersCount) }}
          .stats-item
            .stats-item-title 未戴安全帽数
            .stats-item-value {{ aboveZero(snapshotAi.workersCount - snapshotAi.helmetsCount) }}
          .stats-item
            .stats-item-title 未穿反光服数
            .stats-item-value {{ aboveZero(snapshotAi.workersCount - snapshotAi.vetsCount) }}
          .stats-item
            .stats-item-title 设备数
            .stats-item-value {{ aboveZero(snapshotAi.devicesCount) }}
        .info-card
          .info-title 监控画面分析风险提示
          .info-hint {{ snapshotAi.alerts }}
        .gap
        ui-flex(row, j-end)
          .ai-power(@click='toggleAiChat') Powered by 营造云AI
          span &nbsp;|&nbsp;
          .refresh(@click='aiReset') 重试
        div(v-if='displayAiChat && snapshotAi && snapshotAi.id')
          .gap
          AiChatMessages(:cam-snapshot-id='snapshotAi.id')
</template>

<script>
import { moment, _ } from '@yishitec/web';
import AiChatMessages from './AiChatMessages.vue';

export default {
  name: 'CamSnapshotAi',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ['close'],
  components: {
    AiChatMessages,
  },
  data() {
    return {
      loaded: false,
      snapshotAi: null,
      displayAiChat: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      // init
      this.loaded = false;
      this.snapshotAi = null;
      // fetch
      const res = await this.withLoading(
        this.api('/api/cam-snapshots/ai-detail', {
          id: this.id,
        }),
      );
      const detail = _.get(res, ['detail'], {});
      this.snapshotAi = detail;
      this.loaded = true;
    },
    async initAi() {
      this.$message.info('AI分析中...');
      const res = await this.withLoading(
        this.api('/api/cam-snapshots/ai-init', {
          id: this.id,
        }),
      );
      const detail = _.get(res, ['detail'], {});
      this.snapshotAi = detail;
      this.$message.success('AI分析完成');
    },
    async aiReset() {
      const res = await this.withLoading(
        this.api('/api/cam-snapshots/ai-reset', {
          id: this.id,
        }),
      );
      this.$message.success('AI分析已重置');

      await this.loadData();
    },
    close() {
      this.$emit('close');
    },
    formatDateTime(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    aboveZero(value) {
      return Number(value > 0 ? value : 0);
    },
    toggleAiChat() {
      this.displayAiChat = !this.displayAiChat;
    },
  },
};
</script>

<style scoped>
.cam-snapshot-ai-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.cam-snapshot-ai {
  background-color: #fff;
  border-radius: 10px;
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}
.cam-snapshot-ai-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: solid 1px #ccc;
}
.cam-snapshot-ai-title-text {
  flex: 1 0 1px;
}
.cam-snapshot-ai-title-close {
  flex: 0 0 auto;
  cursor: pointer;
}
.cam-snapshot-ai-content {
  font-size: 14px;
  color: #666;
  min-width: 450px;
}
.photo {
  flex: 0 0 auto;
  margin-right: 20px;
  width: 240px;
}
.photo-img {
  width: 100%;
  display: block;
  border: solid 1px #ccc;
  margin-bottom: 20px;
}
.body {
  flex: 1 0 1px;
}
.info-card {
  padding: 10px;
  border: solid 1px #ccc;
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(to bottom, #f5f5f5, #fff);
  }
}
.info-title {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 12px;
}
.info-hint {
  position: relative;
  white-space: pre-line;
}
.body-empty {
  text-align: center;
  padding: 60px 20px;
}
.ai-title {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}
.stats {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
}
.stats-item {
  flex: 1 0 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px #ccc;
  padding: 10px;
}
.stats-item-title {
  font-size: 14px;
  color: #666;
}
.stats-item-value {
  font-size: 24px;
  color: #333;
}
.gap {
  height: 20px;
}
.ai-power {
  font-size: 14px;
  color: #aaa;
  text-align: right;
  cursor: pointer;
  font-style: italic;
}
.refresh {
  font-size: 14px;
  color: #aaa;
  text-align: right;
  cursor: pointer;
}
</style>
