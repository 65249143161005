<script setup>
import { defineProps, ref, defineEmits } from 'vue';
import { useFetch2, useSubmit } from '../../../../../composables';

const props = defineProps({
  blueprintId: [String, Number],
});
defineEmits(['startToBind', 'updateChildren']);

const titles = ref('');
const adminListRefresh = ref(null);

const handleAdminListInit = (ev) => {
  const { refresh } = ev || {};
  adminListRefresh.value = refresh || null;
};

const { refresh } = useFetch2('/api/v3/progress-blueprint-part/titles', {
  getBody: () => {
    return { blueprintId: props.blueprintId };
  },
  success: (data) => {
    titles.value = data.value.titles;
  },
});

const { handleSubmit } = useSubmit('/api/v3/progress-blueprint-part/save-titles', null, {
  afterSubmit: () => {
    refresh();
    if (adminListRefresh.value) {
      adminListRefresh.value();
    }
  },
});

const submitTitles = () => {
  handleSubmit({
    blueprintId: props.blueprintId,
    titles: titles.value,
  });
};
</script>

<template lang="pug">
div
  div.p-card-title 管理分段
  el-tabs(tab-position='left')
    el-tab-pane(label='详情列表')
      AdminList(
        module='progress-blueprint-part'
        :prefix='`/progress/projects/parts`'
        apiPrefix='/api/v3/progress-blueprint-part'
        :scope='{ blueprintId }'
        :newQuery='`?blueprintId=${blueprintId}`'
        @init='handleAdminListInit'
      )
        template(v-slot:slot_bindPaths="{ row, list }")
          el-button(
            size="mini"
            type="secondary"
            @click="$emit('startToBind', { id: row.id })"
          )
            i.el-icon-edit
    el-tab-pane(label='文本批量编辑')
      div.mb-2
        el-link(@click='refresh')
          i.el-icon-refresh
      el-alert.mb-2 请在下方文本框填写全部分段名称（全部桩号/井号等），保存后将按此顺序创建全部分段信息。不存在于本列表的全部分段将被删除。
      el-input(
        type='textarea'
        :rows='20'
        v-model='titles'
      ).text-mono.mb-2
      el-button(type='primary' @click='submitTitles') 提交
</template>

<style lang="scss" scoped>
.text-mono {
  font-family: monospace;
}

.mb-2 {
  margin-bottom: 12px;
}
</style>
