<template lang="pug">
div
  div.image-editor-container
    .image-editor-stage
      BlueprintImageDetail(
        :currentImage="currentImage"
        :readonly="readonly"
      )
      ImageViewer(
        v-if="currentImage"
        :currentImage="currentImage"
        :addPathForItemId="addPathForItemId"
        :readonly="readonly"
        :children="children"
        @canvasInit="handleCanvasInit"
        @bindPaths="handleBindPaths"
      ).relative
      div.empty(v-else) 暂无图示
    .image-editor-children(v-if="displayImagesSidebar")
      .image-editor-children-title
        span 关联图示
        el-button(
          size="mini"
          type="secondary"
          @click="refresh"
        ).ml
          i.el-icon-refresh
      .image-editor-children-body
        .children-item(
          v-for="item in children"
          :key="item.id"
        )
          el-button(
            size="mini"
            :type="item.id === addPathForItemId ? 'primary' : 'secondary'"
            @click="$emit('startToBind', { id: item.id })"
          ).mr
            i.el-icon-edit
          span {{ item.title }}
      div(style="flex: 0 0 auto; height: 20px")
  div.toolbar
    div
      SspUpload(
        label="上传新的图示"
        v-if="!readonly"
        v-loading="uploadLoading"
        listType="none"
        @beforeUpload="startLoading"
        @success="handleUploaded"
        @error="stopLoading"
      )
    div.thumbs-container
      BlueprintImageThumbs(
        :images="images"
        :currentImage="currentImage"
        @select="selectImage"
      )
    div
      el-checkbox(
        v-if="!readonly"
        v-model="displayImagesSidebar"
        label="快捷关联图示"
        @input="handleToggleSidebar"
      )
</template>

<script>
import { toRefs, ref, nextTick } from 'vue';
import SspUpload from '../ssp/SspUpload.vue';
import BlueprintImageThumbs from './BlueprintImageThumbs.vue';
import BlueprintImageDetail from './BlueprintImageDetail.vue';
import ImageViewer from './ImageViewer.vue';

import useLoadImages from './useLoadImages';
import useUploadImage from './useUploadImage';
import useSwitchImages from './useSwitchImages';
import useBindPaths from './useBindPaths';

export default {
  name: 'ProgressBlueprintImages',
  props: {
    blueprintId: { type: [Number, String] },
    readonly: { type: Boolean, default: false },
    addPathForItemId: { type: [String, Number], default: null },
  },
  emits: ['startToBind', 'refreshChildren'],
  components: {
    SspUpload,
    BlueprintImageThumbs,
    BlueprintImageDetail,
    ImageViewer,
  },
  setup(props, { emit }) {
    const { addPathForItemId } = toRefs(props);
    const { images, refresh, children } = useLoadImages(props);
    const { uploadLoading, startLoading, stopLoading, handleUploaded } = useUploadImage({
      blueprintId: props.blueprintId,
      refresh,
    });
    const { currentImage, selectImage } = useSwitchImages({ images });
    const { handleBindPaths } = useBindPaths({
      currentImage,
      addPathForItemId,
      children,
      refreshChildren: () => {
        refresh();
        emit('refreshChildren');
      },
    });
    const handleResize = ref(() => {});
    const displayImagesSidebar = ref(!props.readonly);
    const handleCanvasInit = (ev) => {
      const { handleResize: iHandleResize } = ev || {};
      handleResize.value = iHandleResize;
    };
    const handleToggleSidebar = async () => {
      await nextTick();
      handleResize.value();
    };
    return {
      images,
      refresh,
      children,
      currentImage,
      selectImage,
      handleCanvasInit,
      handleBindPaths,
      uploadLoading,
      startLoading,
      stopLoading,
      handleUploaded,
      displayImagesSidebar,
      handleResize,
      handleToggleSidebar,
    };
  },
};
</script>

<style lang="scss" scoped>
.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.thumbs-container {
  flex: 1 0 1px;
}

.relative {
  position: relative;
}

.empty {
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  background: #f5f5f5;
}

.image-editor-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.image-editor-stage {
  flex: 1 0 1px;
}

.image-editor-children {
  flex: 0 0 auto;
  width: 300px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.image-editor-children-title {
  padding: 8px;
  font-weight: bold;
  font-size: 16px;
  flex: 0 0 auto;
}

.image-editor-children-body {
  flex: 1 0 1px;
  overflow: auto;
}

.children-item {
  padding: 4px;
  border-bottom: solid 1px #f5f5f5;
}
</style>
