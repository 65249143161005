<template lang='pug'>
div(v-if='projectId')
  h2(@click='addItem') 投资管理功能
  div
    router-link(
      v-for="(item, index) in list"
      :key="index"
      :to="item.to"
    ).item
      el-button(type="secondary") {{ item.title }}
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'SupervisionSignOffNav',
  props: {
    projectId: [String, Number],
  },
  setup(props) {
    const list = ref([
      {
        to: `/admin/supervision-design-version/${props.projectId}`,
        title: '上传设计变更文件',
      },
      {
        to: `/admin/supervision-sign-off/${props.projectId}`,
        title: '上传签证凭证',
      },
    ]);
    const addItem = () => {
      list.value.push({
        to: '/admin',
        title: '管理员首页',
      });
    };
    return {
      list,
      addItem,
    };
  },
};
</script>

<style lang='scss' scoped>
.item {
  display: inline-block;
  margin-right: 10px;
}
</style>
