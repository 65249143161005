<template>
  <div>
    <ui-stage v-if='data'>
      <ui-tabs v-model='tab' type='border-card'>
        <ui-tab-pane name='main' label='编辑'>
          <ui-alert
            type='warning'
            :closable='false'
            class='mb'
            v-if="data.approve_state === '待审核'"
          >待审核
          </ui-alert
          >
          <ui-alert
            type='warning'
            :closable='false'
            class='mb'
            v-if="data.approve_state === '待审核' && data.permissions.canApprove"
          >
            <ui-button type='warning' size='large' @click='handleApprove'>审核</ui-button>
          </ui-alert>
          <ui-section-title> 材料管理记录</ui-section-title>

          <ui-admin-form
            :compact='compact'
            :readonly='vReadonly'
            :fields='attributes'
            :model='data'
            :module-url='moduleUrl'
            @submit='handleSubmit'
            :cols='2'
            :with-delete='false'
            :with-save='false'
          >
            <template v-slot:field__docs='{ type, config, readonly }'>
              <ui-type-edit
                type='file'
                v-model="data['docs']"
                :config='config'
                :readonly='readonly'
              />
            </template>
            <template v-slot:field__onSiteCheckReport='{ type, config, readonly }'>
              <ui-type-edit
                type='file'
                v-model="data['onSiteCheckReport']"
                :config='config'
                :readonly='readonly'
              />
            </template>
          </ui-admin-form>
          <ui-section-title> 材料明细</ui-section-title>
          <ui-flex row v-for='(entry, index) in entries' :key='index' class='mb'>
            <div style='width: 100%' class='detail-forms'>
              <ui-admin-form
                :readonly='vReadonly'
                :fields='entriesAttributes'
                :model='entry'
                :cols='4'
                :with-delete='false'
                :with-save='false'
                labelPosition='top'
              />
            </div>
            <div style='flex: 0 0 auto; margin-left: 10px; padding-top: 26px' v-if='!vReadonly'>
              <ui-button @click='removeEntry(index)' round> 删除</ui-button>
              <ui-button @click='addEntry(index)' round> 添加</ui-button>
            </div>
          </ui-flex>
          <div class='mb' v-if='!entries.length && !vReadonly'>
            <ui-button @click='addEntry(index)' round style='width: 100px'> 添加</ui-button>
          </div>
          <div class='mb' v-else-if='!entries.length && vReadonly'>
            <ui-alert :closable='false'>无记录</ui-alert>
          </div>

          <div class='mt text-center' v-if='!vReadonly'>
            <ui-button type='primary' icon='el-icon-success' @click='handleSubmit'>
              提交
            </ui-button>
            <router-link
              v-if='!vReadonly && data.id'
              :to='`${moduleUrl}/delete/${data.id}`'
              class='ml'
            >
              <ui-button type='info' icon='el-icon-delete'> 删除</ui-button>
            </router-link>
          </div>
        </ui-tab-pane>
      </ui-tabs>
    </ui-stage>
  </div>
</template>

<script>
import { _ } from '@yishitec/web';
import { adminEdit } from '@yishitec/web/mixins';
import UiTypeEdit from '@yishitec/web/components/UiTypeEdit.vue';

export default {
  name: 'Edit',
  mixins: [adminEdit],
  components: {
    UiTypeEdit,
  },
  data() {
    return {
      withCustomFields: false,
      entriesAttributes: [],
      entries: [],
      newEntry: {},
    };
  },
  methods: {
    async handleFetchDataRes(res) {
      const {
        entriesAttributes, canEdit, item, newEntry,
      } = res;
      _.forEach(entriesAttributes, (attribute) => {
        if (attribute.name === 'title') {
          // eslint-disable-next-line no-param-reassign
          attribute.type = 'text';
        }
      });
      this.entriesAttributes = entriesAttributes;
      this.dataReadonly = !canEdit;
      this.entries = _.get(item, 'xMaterialsMaterialsEntries', []);
      this.newEntry = newEntry;
      this.upperLevelUrl = `${this.moduleUrl}/list/${item.project_id}`;
    },
    addEntry(index) {
      this.entries.splice(index + 1, 0, _.cloneDeep(this.newEntry));
    },
    removeEntry(index) {
      this.entries.splice(index, 1);
    },
    beforeHandleSubmit(data) {
      return {
        data,
        entries: this.entries,
      };
    },
    async handleApprove() {
      const res = await this.withLoading(
        this.api('/api/materials/approve', {
          id: this.data.id,
        }),
      );
      if (res) {
        this.fetchData();
        this.$message({
          type: 'success',
          message: '审核完成',
        });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.detail-forms {
  ::v-deep {
    .el-form-item__label {
      line-height: 24px;
      padding-bottom: 4px;
    }
  }
}
</style>
