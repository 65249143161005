<template lang="pug">
div.widget
  ScrollingBox.scroll
    BarChart(:list="list" :legends="legends" :availableBy="availableBy")
  .top
  .bottom
</template>

<script>
import BarChart from './BarChart.vue';

export default {
  name: 'ScrollingBarChart',
  props: {
    list: Array,
    legends: Array,
    availableBy: String,
  },
  components: { BarChart },
};
</script>

<style lang="scss" scoped>
.widget {
  position: relative;
  flex: 1 0 1px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.top,
.bottom {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  background-image: linear-gradient(
    180deg,
    #20292e 0%,
    rgba(32, 41, 46, 0.5) 52%,
    rgba(32, 41, 46, 0) 100%
  );
}

.scroll {
  padding-top: 10px;
  padding-bottom: 10px;
}

.bottom {
  bottom: 0;
  top: auto;
  background-image: linear-gradient(
    0deg,
    #20292e 0%,
    rgba(32, 41, 46, 0.5) 52%,
    rgba(32, 41, 46, 0) 100%
  );
}
</style>
