<template lang="pug">
div
  ui-page-header(title="重大办进场数据核验工具")
  ui-stage
    el-card.mb
      el-button(type="primary", size="mini", @click="goNext") 下一条
      el-divider(direction="vertical")
      el-button(type="primary", plain, size="mini", @click="loadData") 刷新
      el-divider(direction="vertical")
      span ID:&nbsp;
      el-input(v-model="id", label="ID", size="mini", style="width: 6em")
      el-divider(direction="vertical")
      el-input-number(v-model="offset", label="Offset", size="mini")
      el-divider(direction="vertical")
      el-switch(v-model="skipEmpty", active-text="忽略未上传照片的项")
      el-divider(direction="vertical")
      el-switch(v-model="isToday", active-text="今日")
      el-divider(direction="vertical")
      span 集中验证，手动处理问题数据
    template(v-if="grant.id")
      el-card.mb(:header="`安全交底信息 ${grant.id}`")
        el-row(:gutter="20")
          el-col(:span="12")
            el-form(label-width="200px")
              el-form-item(label="交底照片")
                el-image.image-thumb(
                  fit="contain",
                  :src="grant.faceRecognition",
                  :preview-src-list="[grant.faceRecognition]"
                )
              el-form-item(label="姓名") {{ grant.xDailyDangerZoneGrantUser.display_name }}
              el-form-item(label="身份证号") {{ grant.xDailyDangerZoneGrantUser.id_card }}
              el-form-item(label="进场时间") {{ formatMoment(grant.createdAt) }}
              el-form-item(label="核验状态")
                el-tag(
                  effect="dark",
                  :type="grant.healthQrState === '绿色' ? 'success' : 'warning'"
                ) {{ grant.healthQrState }}
                el-button.ml(
                  type="primary",
                  size="mini",
                  @click="defineFooter('DailyDangerZoneGrant', grant.id, 'healthQrState', grant.healthQrState || '')"
                ) 编辑
          el-col(:span="12")
            el-form(label-width="200px")
              el-form-item(label="生效的照片")
                el-image.image-thumb(
                  v-for="file in getLines(grant.healthQrCheckImages)",
                  :key="file",
                  fit="contain",
                  :src="file",
                  :preview-src-list="getLines(grant.healthQrCheckImages)"
                )
              el-form-item(label="上报成功")
                el-switch(:value="grant.healthQrStateUploaded", readonly)
                el-button.ml(
                  v-if="!grant.healthQrStateUploaded",
                  type="danger",
                  size="mini",
                  @click="reDo"
                ) 重新上报
              el-form-item(label="插件配置")
                el-switch(:value="plugin.enabled", readonly)
              el-form-item(label="错误信息") {{ grant.alertMessage || '' }}
                el-button.ml(
                  type="primary",
                  size="mini",
                  @click="defineFooter('DailyDangerZoneGrant', grant.id, 'alertMessage', grant.alertMessage || '')"
                ) 编辑
      el-card.mb(header="用户信息")
        el-table(:data="users", style="width: 100%")
          el-table-column(prop="id", label="ID", width="120")
          el-table-column(prop="project_id", label="项目ID", width="120")
          el-table-column(
            prop="xUserProjectProject.title",
            label="项目名称",
            width="200"
          )
          el-table-column(prop="company_type", label="公司类型", width="180")
          el-table-column(prop="admintype", label="管理员角色", width="180")
          el-table-column(prop="pluginsData", label="插件数据", width="auto")
            template(v-slot="{ row }")
              code(v-html="getJson(row.pluginsData)" v-if="row.pluginsData")
              el-button(type="default" size="mini" plain @click="handleRegisterUser(row.project_id, row.id)" style="margin-left: 10px") 注册人员
      el-card.mb(header="健康码评估结果")
        el-row(:gutter="20")
          el-col(:span="12")
            el-form(label-width="200px")
              el-form-item(label="健康码颜色") {{ healthQrState.qrState }}
          el-col(:span="12")
            el-form(label-width="200px")
              el-form-item(label="核酸状态") {{ healthQrState.hsState }}
              el-form-item(label="最近核酸检测时间") {{ formatMoment(healthQrState.lastHsAt) }}
      el-card.mb(
        v-for="(item, index) in xHealthQrChecks",
        :key="item.id",
        :header="`健康码验证明细 #${index + 1} ID: ${item.id}`"
      )
        el-row(:gutter="20")
          el-col(:span="12")
            el-form(label-width="200px")
              el-form-item(label="照片")
                el-image.image-thumb(
                  fit="contain",
                  :src="`${item.healthQrUpload}?v=${imageVersions[item.healthQrUpload] || 0}`",
                  :preview-src-list="[`${item.healthQrUpload}?v=${imageVersions[item.healthQrUpload] || 0}`]"
                )
                div
                  el-button(
                    type="primary"
                    size="mini"
                    @click="rotateImage(item.healthQrUpload, 90)"
                  ) 旋转90度
                  el-button(
                    type="primary"
                    size="mini"
                    @click="rotateImage(item.healthQrUpload, 180)"
                  ) 旋转180度
                  el-button(
                    type="primary"
                    size="mini"
                    @click="rotateImage(item.healthQrUpload, 270)"
                  ) 旋转270度
          el-col(:span="12")
            el-form(label-width="200px")
              el-form-item(label="数据来源") {{ item.source }}
                el-button.ml(
                  type="primary",
                  size="mini",
                  @click="defineFooter('HealthQrCheck', item.id, 'source', item.source || '')"
                ) 编辑
              el-form-item(label="是否通过OCR")
                el-switch(:value="item.isOcr", readonly)
              el-form-item(label="健康码颜色") {{ item.qrState }}
                el-button.ml(
                  type="primary",
                  size="mini",
                  @click="defineFooter('HealthQrCheck', item.id, 'qrState', item.qrState || '')"
                ) 编辑
                el-button.ml(
                  type="primary",
                  size="mini",
                  @click="defineFooter('HealthQrCheck', item.id, 'qrState', '绿色', 'input', true)"
                ) 绿色
              el-form-item(label="核酸状态") {{ item.hsState }}
                el-button.ml(
                  type="primary",
                  size="mini",
                  @click="defineFooter('HealthQrCheck', item.id, 'hsState', item.hsState || '')"
                ) 编辑
                el-button.ml(
                  type="primary",
                  size="mini",
                  @click="defineFooter('HealthQrCheck', item.id, 'hsState', '阴性', 'input', true)"
                ) 阴性
              el-form-item(label="最近核酸检测时间") {{ formatMoment(item.lastHsAt) }}
                el-button.ml(
                  type="primary",
                  size="mini",
                  @click="defineFooter('HealthQrCheck', item.id, 'lastHsAt', item.lastHsAt || '', 'datetime')"
                ) 编辑
                el-button.ml(
                  type="primary",
                  size="mini",
                  @click="defineFooter('HealthQrCheck', item.id, 'lastHsAt', formatMoment(grant.createdAt, -24), 'datetime', true)"
                ) 24小时
                el-button.ml(
                  type="primary",
                  size="mini",
                  @click="defineFooter('HealthQrCheck', item.id, 'lastHsAt', formatMoment(grant.createdAt, -48), 'datetime', true)"
                ) 48小时
              el-form-item(label="原始验证结果")
                el-collapse(v-if="item.raw")
                  el-collapse-item(title="JSON")
                    code.code {{ getJson(item.raw) }}
                el-button.ml(
                  type="primary",
                  size="mini",
                  @click="reScan(item.id)"
                ) 重新扫描
    .fixed-footer
      el-input.mr(v-model="edit.model")
      el-input.mr(v-model="edit.id")
      el-input.mr(v-model="edit.field")
      el-input.mr(v-model="edit.value")
      el-button.ml(@click="handleEdit", type="primary") 保存
  el-backtop(:bottom="120")
  div(style="height: 100px")
</template>

<script>
import { _, moment } from '@yishitec/web';

export default {
  name: 'OpsHealthCheckKit',
  data() {
    return {
      id: null,
      offset: 0,
      skipEmpty: true,
      isToday: false,
      grant: {},
      xHealthQrChecks: [],
      healthQrState: {},
      users: [],
      loadDebounced: _.debounce(this.loadData, 500),
      edit: {
        model: '',
        id: '',
        field: '',
        value: '',
      },
      imageVersions: {},
    };
  },
  watch: {
    id() {
      this.loadDebounced();
    },
    offset() {
      this.id = null;
      this.loadDebounced();
    },
    skipEmpty() {
      this.loadDebounced();
    },
    isToday() {
      this.loadDebounced();
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    if (this.$route.query.isToday === '1') {
      this.isToday = true;
    }
    this.loadData();
  },
  methods: {
    async loadData() {
      _.extend(this, {
        grant: {},
        xHealthQrChecks: [],
        healthQrState: {},
      });
      this.$nextTick(async () => {
        const res = await this.withLoading(
          this.api('/api/ops/pdycsp-grants/one', {
            id: this.id || null,
            offset: this.offset || 0,
            skipEmpty: this.skipEmpty,
            isToday: this.isToday,
          }),
        );
        _.extend(this, res);
        this.id = _.get(res, ['grant', 'id']) || null;
      });
    },
    async handleEdit() {
      await this.withLoading(
        this.api(
          `/api/ops/pdycsp-grants/edit/${this.edit.model}/${this.edit.id}/${this.edit.field}`,
          {
            value: this.edit.value,
          },
        ),
      );
      this.id = this.grant.id;
      await this.loadData();
    },
    async reScan(id) {
      await this.withLoading(this.api(`/api/ops/pdycsp-grants/re-scan/${id}`));
      this.id = this.grant.id;
      await this.loadData();
    },
    async reDo() {
      await this.withLoading(this.api(`/api/ops/pdycsp-grants/re-do/${this.grant.id}`));
      this.id = this.grant.id;
      await this.loadData();
    },
    goNext() {
      this.id = null;
      this.loadDebounced();
    },
    getLines(value) {
      return String(value || '').split('\n');
    },
    formatMoment(value, addHours = 0) {
      if (!value) return '-';
      const momentValue = moment(value);
      if (!momentValue.isValid()) {
        return '-';
      }
      return momentValue.add(addHours, 'hours').format('YYYY-MM-DD HH:mm:ss');
    },
    getJson(value) {
      return JSON.stringify(JSON.parse(value || '{}'), null, 2);
    },
    async handleRegisterUser(projectId, userId) {
      await this.withLoading(
        this.api(`/api/projects/plugin-task/pdycsp/1/addUser/${projectId}/${userId}`),
      );
      this.id = this.grant.id;
      await this.loadData();
    },
    async defineFooter(model, id, field, value = '', type = 'input', doSubmit = false) {
      _.extend(this.edit, {
        model,
        id,
        field,
        value,
      });

      try {
        if (!doSubmit) {
          const res = await this.$prompt(this.edit.model, '编辑', {
            confirmButtonText: '提交',
            cancelButtonText: '取消',
            inputValue: value,
          });
          const { value: inputValue = '' } = res;
          this.edit.value = inputValue;
        }
        if (type === 'datetime') {
          const momentValue = moment(this.edit.value, 'YYYYMMDDHHmmss');
          if (momentValue.isValid()) {
            this.edit.value = momentValue.format('YYYY-MM-DD HH:mm:ss');
          }
        }
        await this.handleEdit();
      } catch (e) {
        this.$message({
          type: 'warning',
          message: '已取消',
        });
      }
    },
    async rotateImage(image, degrees = 90) {
      await this.withLoading(
        this.api('/api/ops/pdycsp-grants/rotate-image', {
          image,
          degrees,
        }),
      );
      if (!this.imageVersions[image]) {
        this.imageVersions[image] = 1;
      } else {
        this.imageVersions[image] += 1;
      }
      this.imageVersions = {
        ...this.imageVersions,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.image-thumb {
  width: 200px;
  height: 200px;
  background: rgba(#000, 0.05);
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(#000, 0.5);
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.code {
  white-space: pre;
  line-height: 1.2;
  display: block;
  padding: 10px;
  background: rgba(#000, 0.05);
}
</style>
