<template lang='pug'>
  div.legends-row
    .title(:class='{ link: subWindow }' @click='handleClickTitle') {{ title }}
      i(v-if="subWindow").el-icon-arrow-right.el-icon--right
    .legends
      span(v-for="item in list" :key="item.title")
        span.color(:style="{ background: item.color }")
        span.label {{ item.title }}
    .tail(v-if="center")
</template>

<script>
export default {
  name: 'Legends',
  props: {
    title: String,
    list: Array,
    center: Boolean,
    subWindow: String,
  },
  emits: ['sub-window'],
  data() {
    return {};
  },
  methods: {
    handleClickTitle() {
      if (this.subWindow) {
        this.$emit('sub-window', this.subWindow);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.legends-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 14px;
  color: #fff;
}

.legends {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.color {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 0.75em;
  border-radius: 2px;
  position: relative;
  top: 1px;
  margin-right: 0.25em;
}

.label {
  font-size: 12px;
  color: #9ba6ad;
}

.link {
  cursor: pointer;
}
</style>
