<template lang="pug">
ui-flex(row j-center a-center :class="[theme]").navs
  HeaderNavItem(
    v-for="item in navs"
    :key="item.key"
    :to="item.to"
    :title="item.title"
    :icon="item.icon"
    :action="item.action"
    @action="handleAction"
  ).item
</template>

<script>
import HeaderNavItem from './HeaderNavItem.vue';

export default {
  name: 'HeaderNavs',
  props: {
    theme: { type: String, default: 'dark' },
  },
  emits: ['action'],
  components: {
    HeaderNavItem,
  },
  data() {
    return {
      navs: [
        {
          key: 'console',
          title: '工地控制台',
          to: '/admin/users',
          icon: '/images/screen-v3/icon-console.svg',
        },
        {
          key: 'full-screen',
          title: '进入全屏模式',
          to: '#',
          action: 'full-screen',
          icon: '/images/screen-v3/icon-fullscreen.svg',
        },
        // {
        //   key: 'notifications',
        //   title: '通知中心',
        //   to: '/admin/notifications',
        //   icon: '/images/screen-v3/icon-alert.svg',
        // },
      ],
    };
  },
  methods: {
    handleAction(action) {
      this.$emit('action', action);
    },
  },
};
</script>

<style lang="scss" scoped>
.navs {
  position: absolute;
  top: 31px;
  left: 220px;
  box-sizing: border-box;
  border-radius: 5px;
  background: #0f1f39;
  color: #fff;

  &.darker {
    background: #000000;
  }
}

.item {
  flex: 0 0 auto;
  position: relative;

  &::after {
    content: ' ';
    position: absolute;
    top: 1px;
    bottom: 1px;
    width: 1px;
    right: 0;
    background: #194877;
  }

  &:last-child {
    &::after {
      content: '';
      display: none;
    }
  }
}
</style>
