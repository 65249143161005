<template lang="pug">
div.bar-row
  .title {{ title }}
  slot
</template>

<script>
export default {
  name: 'ChartLabel',
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.bar-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0;
}

.title {
  flex: 0 0 auto;
  width: 80px;
  font-size: 12px;
  color: #9ba6ad;
  margin-right: 10px;
  text-align: right;
}
</style>
