<template>
  <div>
    <ui-page-header title="人脸识别签到" />
    <ui-stage>
      <ui-flex col center>
        <div v-loading="!qr">
          <vue-qrious :value="qr" :size="200" />
        </div>
        <div class="mt">使用”智慧工地“APP扫描上方二维码进入人脸识别签到</div>
      </ui-flex>
    </ui-stage>
  </div>
</template>

<script>
import { _ } from '@yishitec/web';

export default {
  name: 'FaceSigninQr',
  data() {
    return {
      qr: '',
      ti: null,
    };
  },
  mounted() {
    this.loadData();
    this.ti = setInterval(this.loadData, 60000);
  },
  beforeDestroy() {
    if (this.ti) {
      clearInterval(this.ti);
    }
  },
  methods: {
    async loadData() {
      this.qr = _.get(await this.withLoading(this.api('/api/face-signin/get-qr')), ['qr']);
    },
  },
};
</script>
