<template lang="pug">
div.ssp-tabs
  div.tabs-items
    div(
      v-for="item in vTabs"
      :key="item.key"
      @click="handleClickItem(item)"
      :class="{ active: value === item.key }"
    ).tabs-item
      span {{ item.title }}
      el-tag(
        v-if="item.badge"
        size="mini"
        effect="dark"
        :color="item.color || '#8D8D8D'"
      ).tab-item-tag {{ item.number }}
  div.tabs-toolbar
    slot(name="toolbar")
</template>

<script>
import { _ } from '@yishitec/web';

export default {
  name: 'SspTabs',
  props: {
    // []: key, title, badge[bool, t], number, color
    tabs: { type: Array, default: () => [] },
    value: { type: String },
  },
  emits: ['input'],
  computed: {
    vTabs() {
      return _.map(this.tabs, (item) => {
        if (_.isUndefined(item.badge)) {
          // eslint-disable-next-line no-param-reassign
          item.badge = true;
        }
        return item;
      });
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      if (!this.value && this.tabs && this.tabs.length) {
        this.handleClickItem(this.tabs[0]);
      }
    },
    handleClickItem(tabItem) {
      this.$emit('input', tabItem.key);
    },
  },
};
</script>

<style lang="scss" scoped>
.ssp-tabs {
  @include ui-flex-row(space-between, center);
  margin-bottom: 20px;
}
.tabs-items {
  @include ui-flex-row(flex-start, center);
}
.tabs-item {
  box-sizing: border-box;
  padding: 8px;
  position: relative;
  cursor: pointer;
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    border-radius: 10px;
    background: rgba(#525ce5, 0);
    transition: all 0.3s;
  }
  & + & {
    margin-left: 10px;
  }
  &:hover,
  &.active {
    &::after {
      background-color: #525ce5;
    }
  }
}
.tab-item-tag {
  border: none;
  margin-left: 5px;
}
.tabs-toolbar {
  @include ui-flex-row(flex-end, center);
  ::v-deep .toolbar-label {
    white-space: nowrap;
  }
}
</style>
