import { render, staticRenderFns } from "./AiChatMessages.vue?vue&type=template&id=529032c8&scoped=true&lang=pug"
import script from "./AiChatMessages.vue?vue&type=script&lang=js"
export * from "./AiChatMessages.vue?vue&type=script&lang=js"
import style0 from "./AiChatMessages.vue?vue&type=style&index=0&id=529032c8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "529032c8",
  null
  
)

export default component.exports