<template>
  <div>
    <ui-stage>
      <ui-card>
        <ui-flex column center style="min-height: 200px">
          <div style="margin-bottom: 20px">
            {{ message }}
          </div>
          <div>
            <ui-button @click="() => $router.back()" icon="el-icon-arrow-left">返回</ui-button>
            <ui-button type="danger" @click="handleDelete" icon="el-icon-delete">确认</ui-button>
          </div>
        </ui-flex>
      </ui-card>
    </ui-stage>
  </div>
</template>

<script>
import { adminDelete } from '@yishitec/web/mixins';

export default {
  name: 'Delete',
  mixins: [adminDelete],
  data() {
    return {
      message: '确认删除这条今日危险源授权？',
    };
  },
};
</script>

<style lang="less" scoped></style>
