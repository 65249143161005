<script setup>
import _ from 'lodash';
import { defineProps, defineEmits, ref } from 'vue';
import { useFetch2 } from '../../composables';

const props = defineProps({
  searchApi: String,
  modelValue: {},
  readonly: Boolean,
});
defineEmits(['update:modelValue']);

const fieldNames = ref({ value: 'id', label: 'title' });
const titleField = ref('id');
const searchKeyword = ref('');
const options = ref([]);

const { data, pending, refresh } = useFetch2(`${props.searchApi}`, {
  lazy: true,
  getBody: () => {
    return {
      page: 1,
      pageSize: 100,
      ...searchKeyword.value ? {
        filters: {
          [`${titleField.value}:contain`]: searchKeyword.value,
        },
      } : {},
    };
  },
  success: (data) => {
    const findTitleAttribute = _.find(data.value.attributes, i => i.name === 'title' || i.isTitle);
    if (findTitleAttribute) {
      titleField.value = findTitleAttribute.name;
    }
    options.value = _.map(data.value.list, i => {
      return {
        id: i.id,
        title: String(i[titleField.value] || i.id),
      };
    });
  },
});
const handleSearch = (value) => {
  searchKeyword.value = value;
  refresh();
};
</script>

<template lang='pug'>
el-select(
  :value="modelValue ? Number(modelValue): null"
  @input="$emit('update:modelValue', $event)"
  filterable
  :disabled="readonly"
  clearable
  remote
  :remoteMethod="handleSearch"
  :loading="pending"
  style='width: 100%'
)
  el-option(
    v-for='item in options'
    :key='item.id'
    :label='item.title'
    :value='item.id'
  )
</template>
