<template>
  <ui-flex col stretch class="admin-container">
    <ui-flex between row a-center class="admin-container__header">
      <ui-flex zero>
        <ui-logo class="admin-container__header__logo" link-home="/admin" />
      </ui-flex>
      <ui-flex zero>
        <router-link to="/">
          <ui-link type="primary">返回首页</ui-link>
        </router-link>
        <ui-divider direction="vertical" />
        <router-link to="/screen" target="_blank">
          <ui-link type="primary">大屏</ui-link>
        </router-link>
        <ui-divider direction="vertical" />
        <ui-dropdown v-if="user" trigger="hover" @command="handleUserCommands">
          <ui-link type="primary">
            欢迎，{{ user.display_name }}
            <i class="el-icon-more" />
          </ui-link>
          <ui-dropdown-menu slot="dropdown">
            <ui-dropdown-item command="changePassword">修改密码</ui-dropdown-item>
            <ui-dropdown-item divided command="logout">注销</ui-dropdown-item>
          </ui-dropdown-menu>
        </ui-dropdown>
      </ui-flex>
    </ui-flex>
    <ui-flex row a-start style="flex: 1 0 1px" v-if="user" class="overflow-auto">
      <ui-flex class="admin-container__nav" style="flex: 0 0 200px">
        <div v-for="(item, itemIndex) in menu" :key="itemIndex">
          <p v-if="item.sectionTitle">
            <span>
              {{ item.sectionTitle }}
            </span>
          </p>
          <app-nav-item v-else :link="item.link" :exact="item.exact || false">
            {{ item.title }}
            <template #right>
              <template v-if="item.right">
                <el-link type="primary" class="admin-container__nav__right">
                  {{ item.right }}
                </el-link>
              </template>
              <template v-else-if="item.rightBadge">
                <el-badge :value="item.tasksCount" />
              </template>
            </template>
          </app-nav-item>
        </div>
      </ui-flex>
      <ui-flex class="admin-container__stage">
        <div>
          <router-view />
        </div>
      </ui-flex>
    </ui-flex>
  </ui-flex>
</template>

<script>
import { _ } from '@yishitec/web';
import logout from '@yishitec/web/core/logout';
import { mapState, mapGetters, mapMutations } from 'vuex';
import checkLoggedIn from '../../mixins/checkLoggedIn';
import permissions from '../../mixins/permissions';
import AppNavItem from '../../components/admin/AppNavItem.vue';

export default {
  name: 'AdminContainer',
  components: { AppNavItem },
  mixins: [checkLoggedIn, logout, permissions],
  data() {
    return {
      tasksCount: 0,
      taskSections: {},
      tasks: {},
      tiTasks: null,
    };
  },
  computed: {
    ...mapState(['currentProjects', 'isSuperAdmin']),
    ...mapGetters(['currentProjectsLength']),
    canViewCams() {
      return !this.amI(null, '施工单位', '作业人员', true);
    },
    canViewProgressModule() {
      const inProjectsFilter = !_.find(this.permissions, (item) => {
        return _.get(item, ['xUserProjectProject', 'withProgressModule']);
      });
      if (inProjectsFilter) {
        return false;
      }
      return !this.amI(null, '施工单位', '作业人员', true);
    },
    isAnyAdmin() {
      return (
        this.amI(null, null, '管理人员', true) ||
        this.amI(null, null, '安全员', true) ||
        this.amI(null, null, '平台管理员', true)
      );
    },
    isJs() {
      return this.amI(null, '建设单位', '管理人员', true);
    },
    isJlAdmin() {
      return this.amI(null, '监理单位', '管理人员', true);
    },
    isSgAdmin() {
      return (
        this.amI(null, '施工单位', '安全员', true) || this.amI(null, '施工单位', '管理人员', true)
      );
    },
    menu() {
      const whenever = (condition, item) => {
        const ensureArray = (value) => {
          return _.isArray(value) ? value : [value];
        };
        return condition ? ensureArray(item) : [];
      };

      /**
       * @typedef MenuItem
       * @property link {string}
       * @property title {string}
       * @property exact {boolean}
       * @property right {string|number}
       * @property rightBadge {string|number}
       */

      /**
       *
       * @param obj {MenuItem}
       * @return {*}
       */
      const item = (obj) => {
        return _.merge(
          {
            link: '',
            title: '',
            exact: false,
            right: null,
            rightBadge: null,
          },
          obj,
        );
      };

      /**
       * @typedef SectionTitleItem
       * @property sectionTitle {string}
       */

      /**
       *
       * @param obj {SectionTitleItem}
       * @return {*}
       */
      const sectionTitle = (obj) => {
        return _.merge(
          {
            sectionTitle: '',
          },
          obj,
        );
      };

      return [
        item({
          link: '/admin/select-projects',
          exact: true,
          title: '选择项目',
          right: `已选择 ${this.currentProjectsLength}`,
        }),
        ...whenever(
          this.isAnyAdmin,
          item({
            link: '/admin/reports/dashboard',
            exact: true,
            title: '数据中心',
          }),
        ),
        ...whenever(
          this.isAnyAdmin || this.isSgAdmin || this.isSuperAdmin,
          item({
            link: '/admin/tasks',
            title: '任务中心 (待审核)',
            rightBadge: this.tasksCount,
          }),
        ),
        sectionTitle({ sectionTitle: '项目和人员' }),
        item({
          link: '/admin/users',
          title: '管理人员',
          rightBadge: this.taskSections.users,
        }),
        item({
          link: '/admin/company-internal-notices',
          title: '人员安全教育管理',
        }),
        item({
          link: '/admin/projects',
          title: '管理项目',
        }),
        ...whenever(this.canViewProgressModule, [
          sectionTitle({
            sectionTitle: '进度管理',
          }),
          item({
            link: '/progress',
            title: '进度管理系统',
          }),
        ]),
        ...whenever(this.canViewCams, [
          sectionTitle({
            sectionTitle: '监控',
          }),
          item({
            link: '/admin/cams',
            title: '管理监控',
          }),
          item({
            link: '/admin/cam-snapshots',
            title: '监控画面快照',
          }),
        ]),
        sectionTitle({
          sectionTitle: '质量管理',
        }),
        item({
          link: '/admin/materials',
          title: '管理材料',
          rightBadge: this.taskSections.materials,
        }),
        item({
          link: '/admin/danger-zone-events',
          title: '管理设备进出场',
          rightBadge: this.taskSections.dangerZoneEventDevices + this.taskSections.dangerZoneEvents,
        }),
        ...whenever(
          this.isJlAdmin,
          item({
            link: '/admin/key-component-acceptance',
            title: '管理关键部位验收',
          }),
        ),
        sectionTitle({
          sectionTitle: '安全文明施工管理',
        }),
        item({
          link: '/admin/plan-notice',
          title: '方案交底',
          rightBadge: this.taskSections.planNotice,
        }),
        item({
          link: '/admin/danger-zones',
          title: '安全管理',
        }),
        item({
          link: '/admin/ops-zones',
          title: '施工部位',
        }),
        item({
          link: '/admin/ops-zones-logs',
          title: '今日作业施工部位',
        }),
        item({
          link: '/admin/danger-zone-grants',
          title: '管理危险源作业记录',
        }),
        item({
          link: '/admin/iot-alerts',
          title: '管理危险源报警记录',
          rightBadge: this.taskSections.iotAlerts,
        }),
        item({
          link: '/admin/iot-devices',
          title: '管理物联网设备',
        }),
        sectionTitle({
          sectionTitle: '安全交底',
        }),
        item({
          link: '/admin/daily-danger-zones',
          title: '管理每日安全交底',
          rightBadge: this.tasks.dailyNotice,
        }),
        item({
          link: '/admin/daily-notice-grants',
          title: '历史安全交底记录',
        }),
        item({
          link: '/admin/simple-project-management',
          title: '管线项目管理',
        }),
        sectionTitle({
          sectionTitle: '监理模块',
        }),
        item({
          link: '/admin/supervision-logs',
          title: '监理日志',
          rightBadge: this.tasks.supervisionLogs,
        }),
        item({
          link: '/admin/supervision-e-logs',
          title: '电子监理日志',
        }),
        ...whenever(this.isJlAdmin, [
          item({
            link: '/admin/supervision-design-version',
            title: '设计变更',
          }),
          item({
            link: '/admin/supervision-sign-off-approval',
            title: '上传签证凭证',
          }),
        ]),
        ...whenever(this.isJs, [
          sectionTitle({
            sectionTitle: '投资管理',
          }),
          item({
            link: '/admin/supervision-design-version',
            title: '设计变更',
          }),
          item({
            link: '/admin/supervision-sign-off-approval',
            title: '上传签证凭证',
          }),
        ]),
        sectionTitle({
          sectionTitle: '签到 (场景应用)',
        }),
        item({
          link: '/admin/trackings',
          title: '管理签到',
        }),
        ...whenever(
          this.isSgAdmin,
          item({
            link: '/admin/face-signin-qr',
            title: '人脸识别签到',
          }),
        ),
        sectionTitle({
          sectionTitle: '报表统计',
        }),
        item({
          link: '/admin/reports/signin',
          title: '人员考勤',
        }),
        item({
          link: '/admin/reports/danger-zones',
          title: '危险源报警',
        }),
        item({
          link: '/admin/reports/users-count',
          title: '各单位用户数统计',
        }),
        item({
          link: '/admin/user-logs',
          title: '管理员日志',
        }),
        // item({
        //   link: '/admin/work-report-fields',
        //   title: '今日施工日记内容',
        // }),
        item({
          link: '/admin/reports/daily-reports-2',
          title: '施工日记',
        }),
        ...whenever(this.isAnyAdmin, [
          item({
            link: '/admin/reports/daily-reports-3',
            title: '抽查表',
          }),
          item({
            link: '/admin/daily-reports-uploads',
            title: '抽查表回执',
          }),
        ]),
        ...whenever(this.isSuperAdmin, [
          sectionTitle({
            sectionTitle: '勘察物探',
          }),
          item({
            link: '/admin/prospecting-project',
            title: '项目名称',
          }),
          item({
            link: '/admin/prospecting-project-user',
            title: '项目人员',
          }),
          item({
            link: '/admin/prospecting-project-file',
            title: '技术要求和计划',
          }),
          item({
            link: '/admin/prospecting-project-grant',
            title: '安全交底',
          }),
          // item({
          //   link: '/admin/prospecting-project-grant-stat',
          //   title: '安全交底统计',
          // }),
          item({
            link: '/admin/prospecting-project-task',
            title: '勘察物探任务',
          }),
          item({
            link: '/admin/prospecting-project-task-node',
            title: '测点反馈',
          }),
        ]),
      ];
    },
  },
  watch: {
    isJlAdmin(value) {
      this.unmountQueryTasks();
      if (value) this.initQueryTasks();
    },
  },
  mounted() {
    this.initQueryTasks();
  },
  beforeDestroy() {
    this.unmountQueryTasks();
  },
  methods: {
    ...mapMutations(['setCurrentProjects']),
    handleUserCommands(command) {
      const [action, param] = command.split('|');
      if (_.isFunction(this[action])) {
        this[action](param);
      }
    },
    changePassword() {
      this.$router.push('/admin/change-password');
    },
    beforeLogout() {
      this.setCurrentProjects([]);
    },
    async loadTasks() {
      const tasks = await this.api('/api/tasks/count')();
      this.tasksCount = tasks.count;
      // this.taskSections = tasks.sections;
      this.tasks = tasks.tasks;
    },
    initQueryTasks() {
      if (!this.isJlAdmin) {
        return;
      }
      this.loadTasks();
      this.tiTasks = setInterval(this.loadTasks, 60e3);
    },
    unmountQueryTasks() {
      if (this.tiTasks) clearInterval(this.tiTasks);
      this.tiTasks = null;
    },
  },
};
</script>

<style scoped lang="less">
@import '../../assets/styles/app-mixins';

.admin-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  &__header {
    flex: 0 0 auto;
    padding: 10px 20px;
    box-sizing: border-box;
    color: #fff;
    background: darken(@theme, 20);

    &__logo {
      height: 30px;
    }
  }

  &__nav {
    box-shadow: 0 0 10px 0 fadeout(#000, 80);
    box-sizing: border-box;
    position: relative;
    height: 100%;
    overflow: auto;

    p {
      margin: 0;
      font-size: 16px;
      font-weight: bolder;
      color: #000;
      padding: 10px 20px;

      span {
        display: inline-block;
        background: #eee;
        padding: 4px 0 4px 8px;
        position: relative;
        left: -8px;
        border-radius: 5px;

        &::after {
          content: '：';
        }
      }
    }

    &__item {
      padding: 4px 0;
    }

    &__right {
      font-size: 12px;
    }
  }

  &__stage {
    flex: 1 0 auto;
    overflow: auto;
    width: calc(100% - 200px);
    height: 100%;
  }
}

.overflow-auto {
  overflow: auto;
}
</style>
