<template lang="pug">
div
  div.skyline
  ui-container
    h1.page-title 智慧工地访客登记系统
    div.title-hint {{ project.title }}
    el-form(label-width="80px")
      el-form-item(label="车牌号")
        el-input(v-model="form.carPlate")
</template>

<script>
/**
 * @typedef ProjectInfo
 * @property {string} title 项目名称
 *
 * @typedef GuestSignupForm
 * @property {string} carPlate 车牌号
 */

export default {
  name: 'GuestSignup',
  data() {
    return {
      projectShortCode: null,
      /**
       * @type {ProjectInfo}
       */
      project: null,
      /**
       * @type {GuestSignupForm}
       */
      form: null,
    };
  },
  mounted() {
    this.projectShortCode = this.$route.params.projectShortCode;
    this.loadData();
  },
  methods: {
    async loadData() {
      if (!this.projectShortCode) {
        this.$message({
          type: 'error',
          message: '请确认链接或二维码正确',
        });
        return;
      }

      const res = await this.$api(`/api/guest-signups/prepare/${this.projectShortCode}`);
      if (!res) {
        this.$message({
          type: 'error',
          message: '找不到指定的项目，请确认链接或二维码正确',
        });
        return;
      }
      const { project, form } = res;
      this.project = project;
      this.form = form;
    },
  },
};
</script>

<style scoped></style>
