<template>
  <component
    :is="vComponentName"
    :to="vTo"
    :href="vHref"
    :class="['app-nav-item', { active: vActive }]"
  >
    <ui-flex row between>
      <ui-flex>
        <slot></slot>
      </ui-flex>
      <ui-flex zero>
        <slot name="right"></slot>
      </ui-flex>
    </ui-flex>
  </component>
</template>

<script>
import { _ } from '@yishitec/web';

export default {
  name: 'AppNavItem',
  props: {
    link: { type: String, default: '#' },
    isExternal: { type: Boolean, default: false },
    exact: { type: Boolean, default: false },
  },
  computed: {
    vComponentName() {
      return this.isExternal ? 'a' : 'router-link';
    },
    vTo() {
      return this.isExternal ? undefined : this.link;
    },
    vHref() {
      return this.link;
    },
    vActive() {
      if (_.endsWith(_.last(this.$route.matched).path, '/*')) return false;
      if (this.exact) return this.$route.fullPath === this.link;
      return Boolean(_.find(this.$route.matched, (item) => item.regex.test(this.link)));
    },
  },
};
</script>

<style scoped lang="less">
@import '../../assets/styles/app-mixins.less';

.app-nav-item {
  display: block;
  padding: 10px 20px;
  color: @theme;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;

  &.active,
  &:hover {
    background: @theme;
    color: #fff;
  }
}
</style>
