<template>
  <div class="tracking-view">
    <ui-alert type="error" class="error" v-if="error">
      {{ error }}
    </ui-alert>
    <ui-stage v-else-if="projectInfo && projectInfo.length">
      <div class="tracking-project-title">{{ projectInfo[0].title }}</div>
      <el-row :gutter="10" style="margin-bottom: 15px">
        <el-col :span="6">
          <el-card header="人员" :body-style="{ overflow: 'auto', height: '10em' }">
            <ui-popover
              v-for="(item, index) in mapMarkers"
              :key="index"
              placement="bottom"
              :title="item.title"
              :offset="100"
              width="200"
              trigger="hover"
            >
              <div
                :style="{
                  cursor: 'pointer',
                  opacity: isActive(item) ? 1 : 0.5,
                }"
                @click="toggleActive(item)"
                class="map-marker-toggle"
                slot="reference"
              >
                <img :src="item.icon" class="legend-icon" style="vertical-align: middle" />
                {{ item.title }}
              </div>
              <template>
                <ui-flex row between a-center class="info-line">
                  <div>未安全交底</div>
                  <div>
                    {{ $get(membersStats, [item.title, 'alertsCount']) || '-' }}
                  </div>
                </ui-flex>
                <ui-flex row between a-center class="info-line">
                  <div>今日登录人数</div>
                  <div>
                    {{ $get(membersStats, [item.title, 'todayCount']) || '-' }}
                  </div>
                </ui-flex>
                <ui-flex row between a-center class="info-line">
                  <div>今日围栏内人数</div>
                  <div>
                    {{ $get(membersStats, [item.title, 'todaySigninCount']) || '-' }}
                  </div>
                </ui-flex>
                <ui-flex row between a-center class="info-line">
                  <div>在册人数</div>
                  <div>
                    {{ $get(membersStats, [item.title, 'membersCount']) || '-' }}
                  </div>
                </ui-flex>
              </template>
            </ui-popover>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card header="危险源" :body-style="{ overflow: 'auto', height: '10em' }">
            <template v-slot:header>
              <div style="float: right; margin-left: 10px">
                <ui-popover
                  placement="bottom"
                  title="危险源设备"
                  :offset="100"
                  width="200"
                  trigger="hover"
                >
                  <div slot="reference" style="cursor: pointer">
                    <el-link type="primary">危险源统计</el-link>
                  </div>
                  <ui-flex row between a-center class="info-line">
                    <div>未授权启动设备</div>
                    <div>
                      {{ $get(devicesStats, ['todayAlertsCount']) || '-' }}
                    </div>
                  </ui-flex>
                  <ui-flex row between a-center class="info-line">
                    <div>今日定位信息</div>
                    <div>{{ $get(devicesStats, ['todayCount']) || '-' }}</div>
                  </ui-flex>
                  <ui-flex row between a-center class="info-line">
                    <div>在册设备数</div>
                    <div>{{ $get(devicesStats, ['devicesCount']) || '-' }}</div>
                  </ui-flex>
                  <ui-flex row between a-center class="info-line">
                    <div>在册危险源数</div>
                    <div>
                      {{ $get(devicesStats, ['dangerZonesCount']) || '-' }}
                    </div>
                  </ui-flex>
                  <ui-flex row between a-center class="info-line">
                    <div>施工中（受控）危险源数</div>
                    <div>
                      {{ $get(activeDevicesStats, ['dangerZonesCount']) || '-' }}
                    </div>
                  </ui-flex>
                </ui-popover>
              </div>
              <div style="float: right; margin-left: 10px">
                <ui-popover
                  placement="bottom"
                  title="监控设备"
                  :offset="100"
                  width="200"
                  trigger="hover"
                  style="float: right"
                >
                  <div slot="reference" style="cursor: pointer">
                    <el-link type="primary">监控统计</el-link>
                  </div>
                  <ui-flex row between a-center class="info-line">
                    <div>在线设备数</div>
                    <div>{{ $get(camsStats, ['onlineCount']) || '-' }}</div>
                  </ui-flex>
                  <ui-flex row between a-center class="info-line">
                    <div>在册设备数</div>
                    <div>{{ $get(camsStats, ['camsCount']) || '-' }}</div>
                  </ui-flex>
                </ui-popover>
              </div>
              设备
            </template>
            <div
              v-for="item in iotDeviceTrackingInfo"
              :key="item.id"
              :class="['map-marker-toggle', { active: isActive({ key: `危险源设备_${item.id}` }) }]"
              @click="toggleActive({ key: `危险源设备_${item.id}` })"
            >
              <img
                :src="require(`../../../assets/images/mapMarkers/IOT_DEVICE.png`)"
                style="vertical-align: middle"
              />
              &nbsp;{{ item.title }}
            </div>
            <div
              v-for="item in camsInfo"
              :key="item.id"
              :class="['map-marker-toggle', { active: isActive({ key: `监控设备_${item.id}` }) }]"
              @click="toggleActive({ key: `监控设备_${item.id}` })"
            >
              <img
                :src="require(`../../../assets/images/mapMarkers/CAM.png`)"
                style="vertical-align: middle"
              />
              &nbsp;{{ item.title }}
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card header="围栏" :body-style="{ overflow: 'auto', height: '10em' }">
            <div
              v-for="item in opsZonesLogs"
              :key="item.id"
              :class="['map-marker-toggle', { active: isActive({ key: `施工部位_${item.id}` }) }]"
              @click="toggleActive({ key: `施工部位_${item.id}` })"
            >
              <div class="example-area"></div>
              &nbsp;施工部位：{{ item.title }}
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card header="操作" :body-style="{ overflow: 'auto', height: '10em' }">
            <ui-button icon="el-icon-sort" size="small" @click="syncGpsoo" v-if="isSuperAdmin">
              同步 GPSOO 平台数据
            </ui-button>
            <ui-button class="ml" icon="el-icon-refresh-right" size="small" @click="loadData">
              刷新
            </ui-button>
            <ui-button
              class="ml"
              icon="el-icon-refresh"
              size="small"
              :type="isAutoRefreshOn ? 'primary' : 'default'"
              @click="toggleAutoRefreshOn"
              >每分钟自动刷新
            </ui-button>
          </el-card>
        </el-col>
      </el-row>
      <ui-flex col j-start a-stretch>
        <ui-flex style="width: 100%; margin-bottom: 30px">
          <app-map-mouse-editor
            :value="projectInfo[0].fence || ''"
            screen-offset="250px"
            readonly
            @map-ready="handleMapRes"
          />
        </ui-flex>
        <ui-flex>
          <ui-section-title>人员</ui-section-title>
          <ui-admin-table
            module-url="/admin/users"
            :data="membersInfo"
            :attributes="membersColumns"
            :with-create="false"
            :with-delete="false"
            is-edit-view
            stripe
            :with-search="membersInfo.length > 5"
          >
            <template v-slot:column_added="{ row }">
              {{ row.addedDisplay }}
              <ui-badge class="mark" value="!" v-if="getAlerts('members', row.member_id) > 0" />
            </template>
          </ui-admin-table>
          <ui-section-title>危险源</ui-section-title>
          <ui-admin-table
            module-url="/admin/danger-zones"
            :data="dangerZonesInfo"
            :attributes="dangerZonesColumns"
            :with-create="false"
            :with-delete="false"
            is-edit-view
            stripe
            :with-search="dangerZonesInfo.length > 5"
          />
          <ui-section-title>危险源物联网设备</ui-section-title>
          <ui-admin-table
            module-url="/admin/iot-devices"
            :data="iotDeviceTrackingInfo"
            :attributes="iotDeviceTrackingColumns"
            :with-create="false"
            :with-delete="false"
            is-edit-view
            stripe
            :with-search="iotDeviceTrackingInfo.length > 5"
          >
            <template v-slot:column_added="{ row }">
              {{ row.added }}
              <ui-badge class="mark" value="!" v-if="getAlerts('iotDevices', row.id) > 0" />
            </template>
          </ui-admin-table>
          <ui-section-title>监控设备</ui-section-title>
          <ui-admin-table
            module-url="/admin/cams"
            :data="camsInfo"
            :attributes="camsColumns"
            :with-create="false"
            :with-delete="false"
            is-edit-view
            stripe
            :with-search="camsInfo.length > 5"
          >
            <template v-slot:column_state="{ row }">
              <el-tag v-if="row.state" type="success">在线</el-tag>
              <el-tag v-else type="info">离线</el-tag>
            </template>
          </ui-admin-table>
        </ui-flex>
      </ui-flex>
    </ui-stage>
  </div>
</template>

<script>
/* eslint-disable */
import { _, moment, G } from '@yishitec/web';
import AppMapMouseEditor from '../../../components/maps/AppMapMouseEditor.vue';
import permissions from '../../../mixins/permissions';

const setInc = (obj, route, incBy = 1) => {
  const oldValue = _.get(obj, route) || 0;
  const value = Number(oldValue) + Number(incBy);
  _.set(obj, route, value);
};

const projectColumns = [
  {
    name: 'id',
    title: 'ID',
  },
  {
    name: 'title',
    title: '项目名称',
  },
];

const handleColumns = (columnsDefinition = []) =>
  _.map(columnsDefinition, (column) => {
    const ret = _.cloneDeep(column);
    if (ret.name !== 'id') {
      if (!ret.minWidth || ret.minWidth < 150) {
        ret.minWidth = 150;
      }
    }
    if (ret.name === 'added') {
    }
    return ret;
  });

const { getMapMarkerIcon, getIconSize, getIconPng } = G();

export default {
  name: 'Edit',
  components: { AppMapMouseEditor },
  mixins: [permissions],
  data() {
    return {
      error: '',
      withCustomFields: false,
      projectInfo: [],
      projectColumns: handleColumns(projectColumns),
      membersInfo: [],
      membersColumns: [],
      dangerZonesInfo: [],
      dangerZonesColumns: [],
      iotDeviceTrackingInfo: [],
      iotDeviceTrackingColumns: [],
      camsInfo: [],
      camsColumns: [],
      iotAlerts: { members: {}, iotDevices: {} },
      map: null,
      markers: [],
      isAutoRefreshOn: false,
      tiAutoRefresh: null,
      markerGroupStates: {},
      mapMarkers: [
        {
          title: '监理单位人员',
          key: '监理单位管理人员',
          icon: require(`../../../assets/images/mapMarkers/JL.png`),
        },
        {
          title: '施工单位管理人员',
          key: '施工单位管理人员',
          icon: require(`../../../assets/images/mapMarkers/SG_ADMIN.png`),
        },
        {
          title: '施工单位安全员',
          key: '施工单位安全员',
          icon: require(`../../../assets/images/mapMarkers/SG_SECURITY.png`),
        },
        {
          title: '施工单位作业人员',
          key: '施工单位作业人员',
          icon: require(`../../../assets/images/mapMarkers/SG_WORKER.png`),
        },
      ],
      activeMarkerGroups: [],
      markersGroups: {},
      opsZonesLogs: [],
      opsZonesReport: {},
    };
  },
  computed: {
    membersStats() {
      const ret = {};
      _.forEach(this.membersInfo, (item) => {
        let icon = `${item.company_type}人员`;
        if (item.company_type === '施工单位') {
          icon = `${item.company_type}${item.admintype}`;
        }
        if (item.username !== 'admin') {
          setInc(ret, [icon, 'membersCount']);
          if (item.added) {
            setInc(ret, [icon, 'todayCount']);
            if (item.is_in_fence) {
              setInc(ret, [icon, 'todaySigninCount']);
            }
          }
          if (this.getAlerts('members', item.member_id) > 0) {
            setInc(ret, [icon, 'alertsCount']);
          }
        }
      });
      return ret;
    },
    devicesStats() {
      const ret = {
        todayCount: 0,
        devicesCount: 0,
        dangerZonesCount: 0,
      };
      _.forEach(this.iotDeviceTrackingInfo, (item) => {
        setInc(ret, ['devicesCount']);
        if (item.added) {
          setInc(ret, ['todayCount']);
          if (this.getAlerts('iotDevices', item.id)) {
            setInc(ret, ['todayAlertsCount']);
          }
        }
      });
      setInc(ret, ['dangerZonesCount'], _.get(this.dangerZonesInfo, ['length']));
      return ret;
    },
    activeDevicesStats() {
      const ret = {
        dangerZonesCount: 0,
      };
      setInc(
        ret,
        ['dangerZonesCount'],
        _.filter(this.dangerZonesInfo, (dangerZone) => dangerZone.op_status === '施工中（受控）')
          .length,
      );
      return ret;
    },
    camsStats() {
      const ret = {
        onlineCount: 0,
        camsCount: 0,
      };
      _.forEach(this.camsInfo, (item) => {
        setInc(ret, ['camsCount']);
        if (item.state) {
          setInc(ret, ['onlineCount']);
        }
      });
      return ret;
    },
  },
  watch: {
    isAutoRefreshOn(value) {
      this.controlAutoRefresh(value);
    },
  },
  mounted() {
    this.loadData();
  },
  beforeDestroy() {
    this.controlAutoRefresh(false);
  },
  methods: {
    getAlerts(group, id) {
      return (this.iotAlerts[group][id] || []).length;
    },
    handleMapRes(map) {
      this.map = map;
      this.markMembersOnMap();
    },
    async loadData() {
      await this.loadProjectInfo();
      if (this.projectInfo && this.projectInfo.length) {
        try {
          this.loadOpsZonesLogsReport();
          await this.loadOpsZonesLogs();
          await this.loadProjectMembers();
          this.loadProjectDangerZones();
          this.loadIotDeviceTracking();
          this.loadCams();
        } catch (e) {
          this.error = '-';
        }
      }
    },
    async loadOpsZonesLogs() {
      const { vId } = this.$route.params;
      const res = await this.api('/api/ops-zones-logs/list', {
        projectId: vId,
        withFences: true,
      })();
      const { list = [] } = res || {};
      this.opsZonesLogs = list;
      this.markMembersOnMap();
    },
    async loadOpsZonesLogsReport() {
      const { vId } = this.$route.params;
      const res = await this.api('/api/ops-zones-logs/report-overview', {
        projectId: vId,
      })();
      this.opsZonesReport = res;
      this.markMembersOnMap();
    },
    async loadProjectInfo() {
      const { vId } = this.$route.params;
      const projectRes = await this.withLoading(this.api(`/api/projects/edit/${vId}`));
      if (_.get(projectRes, 'item')) {
        this.projectInfo = [projectRes.item];
      } else {
        this.$message({
          type: 'error',
          message: '找不到指定的项目',
        });
      }
    },
    async loadProjectMembers() {
      const projectId = this.projectInfo[0].id;
      const membersRes = await this.withLoading(
        this.api('/api/tracking-logs/list', {
          projectId,
        }),
      );
      if (!membersRes) throw new Error();
      this.membersInfo = _.map(
        _.uniqBy(membersRes.list, (item) => item.member_id),
        (item) => ({
          ...item,
          addedDisplay: item.added ? moment(item.added).format('HH:mm:ss') : '-',
        }),
      );
      this.membersColumns = handleColumns(membersRes.attributes);

      const alerts = {
        members: {},
        iotDevices: {},
      };
      _.forEach(membersRes.alerts, (alert) => {
        if (alert.member_id) {
          if (!alerts.members[alert.member_id]) {
            alerts.members[alert.member_id] = [];
          }
          alerts.members[alert.member_id].push(alert);
        }
        if (alert.iot_device_id) {
          if (!alerts.iotDevices[alert.iot_device_id]) {
            alerts.iotDevices[alert.iot_device_id] = [];
          }
          alerts.iotDevices[alert.iot_device_id].push(alert);
        }
      });
      this.iotAlerts = alerts;

      this.markMembersOnMap();
    },
    async loadProjectDangerZones() {
      const projectId = this.projectInfo[0].id;
      const dangerZonesRes = await this.withLoading(
        this.api('/api/danger-zones/list', { filter: projectId }),
      );
      this.dangerZonesInfo = dangerZonesRes.list;
      this.dangerZonesColumns = handleColumns(dangerZonesRes.attributes);
    },
    async loadIotDeviceTracking() {
      const projectId = this.projectInfo[0].id;
      const iotDeviceTrackingRes = await this.withLoading(
        this.api('/api/iot-device-tracking/latest', { project_id: projectId }),
      );
      this.iotDeviceTrackingInfo = iotDeviceTrackingRes.list;
      this.iotDeviceTrackingColumns = handleColumns(iotDeviceTrackingRes.attributes);
      this.markMembersOnMap();
    },
    async loadCams() {
      const projectId = this.projectInfo[0].id;
      if (
        this.amI(projectId, '施工单位', '安全员', true) ||
        this.amI(projectId, '施工单位', '作业人员', true)
      ) {
        return;
      }
      const camsRes = await this.withLoading(
        this.api('/api/cams/list-v2', { project_id: projectId }),
      );
      if (camsRes) {
        this.camsInfo = camsRes.list;
        this.camsColumns = handleColumns(camsRes.attributes);
      }
      this.markMembersOnMap();
    },
    markMembersOnMap() {
      if (!this.map) return;
      const { map, mapUtils } = this.map;
      const { addPointOverlay, addPolygonOverlay, toLngLat, buildInfoWindow } = mapUtils;
      if (!this.markerGroupStates.opsZonesLogs) {
        _.forEach(this.opsZonesLogs, (log) => {
          const zone = _.get(log, ['xOpsZone']);
          if (_.get(zone, ['xDangerZone', 'op_type']) === '区域危险源') {
            let fences = _.get(zone, ['xDangerZone', 'location_amap']);
            try {
              fences = JSON.parse(fences);
              const containsWd = Boolean(
                _.find(_.get(zone, ['xOpsZonesDangerTypes']), (findItem) => {
                  return _.get(findItem, ['xOpsDangerType', 'degree']) === '危大';
                }),
              );
              const containsCwd = Boolean(
                _.find(_.get(zone, ['xOpsZonesDangerTypes']), (findItem) => {
                  return _.get(findItem, ['xOpsDangerType', 'degree']) === '超危大';
                }),
              );
              _.forEach(fences, (fence) => {
                let marker = null;
                let firstPoint = null;

                const fencePoints = _.map(fence, (point) => {
                  const ret = toLngLat(point);
                  if (!firstPoint) {
                    firstPoint = ret;
                  }
                  return ret;
                });
                const polygon = addPolygonOverlay(fencePoints, {
                  color: '#105699',
                  opacity: 0.8,
                  weight: 2,
                  fillColor: '#2370cb',
                  fillOpacity: 0.2,
                  infoWindow: {
                    html: `施工部位名称：${zone.title}
${containsWd ? '危大 √' : ''} ${containsCwd ? '超危大 √' : ''}
人数：${this.formatNumber(log.workersCount)}
开始实施时间：${this.formatDateTime(log.startAt)}
实施终止时间：${this.formatDateTime(log.endAt)}`
                      .split('\n')
                      .join('<br>'),
                  },
                });
                const groupKey = `施工部位_${log.id}`;
                this.addMarkerToGroup(polygon, groupKey);
                this.activeMarkerGroups.push(groupKey);
                this.markers.push(marker);
              });
            } catch (e) {
              console.log(e);
            }
          }
        });
        if (this.opsZonesLogs && this.opsZonesLogs.length) {
          this.markerGroupStates.opsZonesLogs = true;
        }
      }

      if (!this.markerGroupStates.members) {
        _.forEach(this.membersInfo, (memberInfo) => {
          const { location_amap, company_type, admintype } = memberInfo;
          if (!['施工单位', '监理单位'].includes(company_type)) {
            return true;
          }
          if (!location_amap) return true;

          const isEBadge = memberInfo.action === '18gps定位';

          const position = location_amap.split(',');
          let icon = this.getIconPng(getMapMarkerIcon(company_type, admintype));
          const marker = addPointOverlay(toLngLat(position), this.getIconPng(icon), {
            iconSize: this.getIconSize(icon),
            infoWindow: {
              html: [
                `姓名：${_.get(memberInfo, 'display_name')}`,
                `用户名：${_.get(memberInfo, 'username')}`,
                `工种：${_.get(memberInfo, 'worktype', '') || '-'}`,
                `最近签到时间：${moment(_.get(memberInfo, 'added')).format('YYYY-MM-DD HH:mm:ss')}`,
                ...(isEBadge ? [`定位信息来自电子工牌`] : []),
                ...alertMessages,
              ].join('<br>'),
            },
          });

          const alertMessages = [];
          if (this.iotAlerts.members[memberInfo.member_id]) {
            alertMessages.push(
              '最近报警：',
              ..._.map(this.iotAlerts.members[memberInfo.member_id], (item) => '- ' + item.message),
            );
          }

          const groupKey = [company_type, admintype].join('');
          this.activeMarkerGroups.push(groupKey);
          this.addMarkerToGroup(marker, groupKey);
          this.markers.push(marker);

          if (this.iotAlerts.members[memberInfo.member_id]) {
            addPointOverlay(
              toLngLat(position),
              this.getIconPng(getMapMarkerIcon('utils', 'alert')),
              {
                iconSize: getIconSize('alert'),
              },
            );
          }
        });
        if (this.membersInfo && this.membersInfo.length) {
          this.markerGroupStates.members = true;
        }
      }
      if (!this.markerGroupStates.iotDeviceTrackingInfo) {
        _.forEach(this.iotDeviceTrackingInfo, (trackingInfo) => {
          const { location_amap, company_type, admintype } = trackingInfo;
          if (!location_amap) return true;
          const alertMessages = [];
          if (this.iotAlerts.iotDevices[trackingInfo.id]) {
            alertMessages.push(
              '最近报警：',
              ..._.map(this.iotAlerts.iotDevices[trackingInfo.id], (item) => '- ' + item.message),
            );
          }
          const position = location_amap.split(',');
          const marker = addPointOverlay(
            toLngLat(position),
            this.getIconPng(getMapMarkerIcon('utils', 'IOT_DEVICE')),
            {
              iconSize: getIconSize('IOT_DEVICE'),
              infoWindow: {
                html: [
                  `物联网设备：${_.get(trackingInfo, 'title')}`,
                  `设备编号：${_.get(trackingInfo, 'device_sn')}`,
                  `最近定位时间：${_.get(trackingInfo, 'added', '-')}`,
                  ...alertMessages,
                ].join('<br>'),
              },
            },
          );

          if (this.iotAlerts.iotDevices[trackingInfo.id]) {
            addPointOverlay(
              toLngLat(position),
              this.getIconPng(getMapMarkerIcon('utils', 'alert')),
              {
                iconSize: getIconSize('alert'),
              },
            );
          }
          this.addMarkerToGroup(marker, `危险源设备_${trackingInfo.id}`);
        });
        if (this.iotDeviceTrackingInfo && this.iotDeviceTrackingInfo.length) {
          this.markerGroupStates.iotDeviceTrackingInfo = true;
        }
      }

      if (!this.markerGroupStates.camsInfo) {
        _.forEach(this.camsInfo, (camInfo) => {
          const { location_amap } = camInfo;
          if (!location_amap) return true;
          const position = location_amap.split(',');
          const marker = addPointOverlay(
            toLngLat(position),
            this.getIconPng(getMapMarkerIcon('utils', 'CAM')),
            {
              iconSize: getIconSize('CAM'),
              infoWindow: {
                html: [
                  `监控设备：${_.get(camInfo, 'title')}`,
                  `设备状态${_.get(camInfo, 'state') ? '在线' : '离线'}`,
                ].join('<br>'),
              },
            },
          );
          const groupKey = `监控设备_${camInfo.id}`;
          this.addMarkerToGroup(marker, groupKey);
          this.markers.push(marker);
        });
        if (this.camsInfo && this.camsInfo.length) {
          this.markerGroupStates.camsInfo = true;
        }
      }

      _.forEach(this.markersGroups, (group, groupName) => {
        _.forEach(group, (marker) => {
          if (this.activeMarkerGroups.includes(groupName)) {
            P.map.addOverLay(marker);
          } else {
            P.map.removeOverLay(marker);
          }
        });
      });
    },
    getIconPng(icon) {
      return getIconPng(icon);
    },
    getIconSize(icon) {
      return;
    },
    addMarkerToGroup(marker, groupName) {
      if (!this.markersGroups[groupName]) {
        this.markersGroups[groupName] = [];
      }
      this.markersGroups[groupName].push(marker);
    },
    toggleAutoRefreshOn() {
      this.isAutoRefreshOn = !this.isAutoRefreshOn;
    },
    controlAutoRefresh(targetState) {
      if (this.tiAutoRefresh) {
        clearInterval(this.tiAutoRefresh);
      }
      if (this.targetState) {
        this.tiAutoRefresh = setInterval(this.loadData, 60 * 1000);
      }
    },
    async syncGpsoo() {
      await this.withLoading(this.api('/api/sync-gpsoo'));
    },
    formatNumber(value) {
      return Number(value || 0) || '-';
    },
    formatDateTime(value) {
      const mValue = moment(value);
      if (!mValue.isValid()) {
        return '-';
      }
      return mValue.format('YYYY-MM-DD HH:mm:ss');
    },
    isActive(item) {
      return this.activeMarkerGroups.includes(item.key);
    },
    toggleActive(item) {
      const isActive = this.isActive(item);
      if (isActive) {
        this.activeMarkerGroups = _.filter(
          this.activeMarkerGroups,
          (findItem) => String(findItem) !== String(item.key),
        );
      } else {
        this.activeMarkerGroups.push(item.key);
      }
      this.markMembersOnMap();
    },
  },
  filters: {
    formatNumber(value) {
      return Number(value || 0) || '-';
    },
  },
};
</script>

<style lang="less" scoped>
.tracking-view {
  position: relative;
}

.tracking-project-title {
  position: absolute;
  top: -76px;
  left: 11em;
}

.info-line {
  padding: 10px;
  border-bottom: solid 1px #eee;
}

.alert-content {
  color: #000;
  font-size: 14px;

  span {
    color: #f00;
    font-weight: bold;
  }
}

.legend-icon {
  height: 28px;
}

.map-marker-toggle {
  transition: all 0.3s;
  cursor: pointer;
  opacity: 0.5;
  margin: 4px 0;

  &.active {
    opacity: 1;
  }
}

.example-area {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: rgba(#2370cb, 0.2);
  border: solid 2px #105699;
  vertical-align: middle;
  border-radius: 1000px;
  box-sizing: border-box;
}
</style>
