<template>
  <div>
    <ui-stage>
      <ui-alert type="success" :closable="false">
        <ui-flex row style="padding-bottom: 15px">
          <div>
            <h3>日期</h3>
            <ui-datetime-picker v-model="date" type="date" />
          </div>
          <div class="ml">
            <h3>项目</h3>
            <ui-admin-model-picker v-model="projectId" :config="projectPickerConfig" />
          </div>
        </ui-flex>
      </ui-alert>
      <SupervisionELogEditor :date="date" :projectId="projectId"></SupervisionELogEditor>
    </ui-stage>
  </div>
</template>

<script>
import { _, moment } from '@yishitec/web';
import UiAdminModelPicker from '@yishitec/web/components/UiAdminModelPicker.vue';
import SupervisionELogEditor from '@/views/admin/SupervisionELogs/sections/SupervisionELogEditor.vue';
import permissions from '../../../mixins/permissions';

const projectPickerConfig = {
  title: '项目',
  name: 'projectId',
  model: {
    name: 'Project',
    titleFields: ['title'],
    type: 'string',
    appData: {
      apiName: 'projects',
    },
  },
  type: 'model',
};

export default {
  name: 'List',
  components: {
    SupervisionELogEditor,
    UiAdminModelPicker,
  },
  mixins: [permissions],
  data() {
    return {
      date: moment().format('YYYY-MM-DD'),
      projectId: '',
      data: null,
      entryFields: [],
      projectPickerConfig,
      saveData: {},
      cols: 1,
      companyName: '',
    };
  },
  watch: {
    currentProjects() {
      this.projectId = _.get(this, 'currentProjects.0');
    },
  },
  mounted() {
    this.projectId = _.get(this, 'currentProjects.0');
  },
};
</script>

<style lang="less" scoped></style>
