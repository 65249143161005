<template lang="pug">
  .progress-admin-container(:class='{ fullscreen: isFullscreen }')
    template(v-if='!isFullscreen')
      ProgressAdminHeader
      .deco
    .stage(v-if="userInfo && userInfo.id")
      router-view(v-if="hasProjects")
      .error(v-else)
        div 没有权限
</template>

<script>
import { _ } from '@yishitec/web';
import ProgressAdminHeader from '../../components/ProgressAdminHeader.vue';

export default {
  name: 'ProgressAdminContainer',
  props: {},
  components: {
    ProgressAdminHeader,
  },
  data() {
    return {
      hasProjects: true,
      isFullscreen: this.$route.query.fullscreen === '1',
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo || {};
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const res = await this.api('/api/whoami', null, {
          withMessage: false,
        })();
        if (!res) {
          this.$router.push('/progress/login');
          return;
        }
        this.$store.commit('login', res);

        const projectsRes = await this.api('/api/projects/list', null, {
          withMessage: false,
        })();
        if (
          _.get(
            _.filter(_.get(projectsRes, 'list') || [], (item) => item.withProgressModule),
            'length',
          )
        ) {
          this.hasProjects = true;
        } else {
          this.hasProjects = false;
        }
      } catch (e) {
        this.$message({
          type: 'error',
          message: '请先登录',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-admin-container {
  position: relative;
  min-height: 100vh;

  ::v-deep {
    .p-page {
      padding: 16px 16px;
    }

    .p-trace {
      padding: 20px 16px;
      background: #e2e6f2;
    }

    .p-container {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      // max-width: 1400px;
    }

    .p-trace + .p-page {
      padding: 16px 16px;
    }

    .p-title {
      color: #0f1b41;
      font-size: 20px;
      font-weight: bolder;

      .hint {
        font-size: 12px;
        display: inline-block;
        margin-left: 10px;
        color: rgba(#000, 0.75);
        --webkit-tap-highlight-color: rgba(#000, 0.75);
      }
    }

    .p-hint {
      font-size: 13px;
      line-height: 18px;
      color: #1c1d21;
      margin-top: 5px;
    }

    .p-card {
      border-radius: 10px;
      background: #fff;
      padding: 24px 40px;
      margin-top: 20px;
      overflow: hidden;
      @include xs {
        padding: 12px;
      }
    }

    .p-card-cover {
      padding: 24px 40px;
      margin: -24px -40px 0;
      background: #c9cfe1;
      margin-bottom: 24px;
      @include xs {
        padding: 12px;
        margin: -12px -12px 0 -12px;
      }
    }

    .p-card-title {
      font-size: 20px;
      line-height: 42px;
      font-weight: bolder;
      color: #000000;
      margin-bottom: 30px;

      small {
        display: inline-block;
        font-size: 14px;
        line-height: 21px;
        color: #495886;
        font-weight: normal;
        margin-left: 10px;
      }
    }
  }

  &.fullscreen {
    :deep(.p-page) {
      padding: 0;
    }

    :deep(.p-card) {
      margin: 0;
      padding: 0;
    }
  }
}

.deco {
  position: absolute;
  right: 60px;
  bottom: 60px;
  width: 320px;
  height: 220px;
  background-size: contain;
  background: url('../../assets/images/progress/progress-deco.png') no-repeat center center;
}

.stage {
  position: relative;
}

.error {
  @include ui-flex-row;
  padding: 60px 0;
}
</style>
