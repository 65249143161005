module.exports = {
  icon: 'images/data-center-screen/boards/icon-cams.svg',
  title: '监控管理',
  indicators: [
    {
      title: '监控数量',
      n: 'camsOnline',
      total: 'cams',
    },
  ],
  table: [
    {
      title: '项目名称',
      name: 'title',
      width: '220px',
    },
    {
      title: '监控数量',
      width: '120px',
      type: 'stats',
      cols: ['camsOnline', 'cams'],
    },
    {
      title: '相关操作',
      width: '120px',
      type: 'adminOps',
      to: '/admin/cams/list/:projectId',
    },
    {},
  ],
};
