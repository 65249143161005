import { render, staticRenderFns } from "./FieldUploads.vue?vue&type=template&id=6fb8574a&scoped=true&lang=pug"
import script from "./FieldUploads.vue?vue&type=script&lang=js"
export * from "./FieldUploads.vue?vue&type=script&lang=js"
import style0 from "./FieldUploads.vue?vue&type=style&index=0&id=6fb8574a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fb8574a",
  null
  
)

export default component.exports