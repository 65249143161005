<template lang="pug">
.map-cover-card(:class="[`is-${color}`, { 'with-ellipsis': withEllipsis }]")
  .map-cover-card-title {{ title }}
  .map-cover-card-number {{ number }}
    small(v-if="total") /{{ total }}
</template>

<script>
export default {
  name: 'MapCoverCard',
  props: {
    title: String,
    number: [String, Number],
    total: [String, Number],
    color: String,
    withEllipsis: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.map-cover-card {
  background: rgba(#194877, 0.8);
  box-shadow: 0 3px 8px 0 rgba(#000000, 0.2);
  box-sizing: border-box;
  width: 100%;
  height: 63px;
  margin-top: 8px;
  padding: 8px;
  position: relative;

  //&.is-blue {
  //  background: rgba(50, 162, 255, 0.7);
  //  border: 1px solid rgba(23, 209, 247, 0.5);
  //  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  //}
  //
  //&.is-red {
  //  background: rgba(235, 105, 168, 0.7);
  //  border: 1px solid rgba(255, 67, 158, 0.5);
  //  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.5);
  //}
  //
  //&.is-yellow {
  //  background: rgba(250, 216, 96, 0.7);
  //  border: 1px solid rgba(250, 213, 95, 0.5);
  //  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  //}

  &.with-ellipsis {
    cursor: pointer;

    &::after {
      content: '...';
      position: absolute;
      bottom: 5px;
      right: 5px;
      display: block;
      color: #fff;
      font-size: 12px;
    }
  }
}

.map-cover-card-number {
  font-size: 30px;
  font-weight: bolder;
  line-height: 30px;

  small {
    color: rgba(#fff, 0.5);
    font-size: 14px;
    display: inline-block;
    margin-left: 2px;
  }
}
</style>
