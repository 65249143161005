import { render, staticRenderFns } from "./BottomLegendItem.vue?vue&type=template&id=5ea30394&scoped=true&lang=pug"
import script from "./BottomLegendItem.vue?vue&type=script&lang=js"
export * from "./BottomLegendItem.vue?vue&type=script&lang=js"
import style0 from "./BottomLegendItem.vue?vue&type=style&index=0&id=5ea30394&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ea30394",
  null
  
)

export default component.exports