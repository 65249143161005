<template lang="pug">
div
  div.search-input-container
    el-input(
      :value="value"
      @input="handleInput"
      placeholder="搜索..."
    )
  slot
</template>

<script>
export default {
  name: 'Searchable',
  props: {
    value: { type: String, default: '' },
  },
  emits: ['input', 'search'],
  data() {
    return {};
  },
  computed: {},
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {},
    handleInput(ev) {
      this.$emit('input', ev);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-input-container {
  margin-bottom: 10px;
}
</style>
