<template lang="pug">
div
  app-map-mouse-editor.map-container(
    :screen-offset="'277px'",
    readonly,
    @map-ready="handleMapRes",
    :mapStyle="`amap://styles/97ae8bc5c7a3075efd63065ce082271c`",
    :withSearch="false"
  )
  .map-reset-button-container
    .map-reset-button(@click="resetMapView") 重置
</template>

<script>
import { _, G } from '@yishitec/web';
import AppMapMouseEditor from '../../../components/maps/AppMapMouseEditor.vue';
import projectLevels from './projectLevels';
import genHtml from './getHtmlInfoWindow';

export default {
  name: 'MapCanvas',
  props: {
    selectedProjectIds: Array,
    projects: Array,
    tasks: Object,
    cams: Array,
  },
  emits: ['show-sub-project', 'update:selectedProjectIds'],
  components: {
    AppMapMouseEditor,
  },
  data() {
    return {
      map: null,
      markers: [],
      detailMarkers: [],
      hasMapLoaded: false,
    };
  },
  watch: {
    projects() {
      this.handleMapRes();
    },
    tasks() {
      this.handleMapRes();
    },
    cams() {
      this.handleMapRes();
    },
  },
  methods: {
    handleMapRes(mapRes) {
      if (window.showModalSubProjects) {
        window.showModalSubProjects = null;
      }
      window.showModalSubProjects = (projectId) => {
        this.$emit('show-sub-project', projectId);
      };
      if (mapRes) {
        this.map = mapRes;
      }
      if (!this.map) return;

      const { map, mapUtils } = this.map;
      const { toLngLat, addPolygonOverlay, addPointOverlay, addLabelOverlay, setFitView } =
        mapUtils;
      const infoWindowSize = {
        width: 175,
        height: 400,
      };

      // Clear markers on map to avoid overlap.
      const clearMarker = (marker) => {
        map.removeOverLay(marker);
      };
      _.forEach(this.markers, clearMarker);
      _.forEach(this.detailMarkers, clearMarker);
      this.markers = [];
      this.detailMarkers = [];
      const { markers, detailMarkers } = this;

      _.forEach(this.projects, (project) => {
        if (project.parentId) return true;
        let projectLevelIndex = _.keys(projectLevels).length - 1;
        const { projectOnlineRatio, workPendingState } = project;
        if (workPendingState === '暂停施工') {
          projectLevelIndex = '暂停施工';
        } else if (projectOnlineRatio >= 0.5) {
          projectLevelIndex = 'L1';
        } else if (projectOnlineRatio >= 0.3) {
          projectLevelIndex = 'L2';
        } else if (projectOnlineRatio >= 0.1) {
          projectLevelIndex = 'L3';
        } else if (projectOnlineRatio >= 0) {
          projectLevelIndex = 'L4';
        }
        const projectLevel = _.get(projectLevels, projectLevelIndex) || projectLevels.DEFAULT;
        const fence = JSON.parse(project.fence || '[]');

        _.forEach(fence, (fencePartial, fencePartialIndex) => {
          if (fencePartialIndex > 0) return false;
          const position = fencePartial[0];
          if (position) {
            let htmlSubProjects = '';
            const subProjects = _.filter(
              this.projects,
              (findProject) => findProject.parentId === project.id,
            );
            if (subProjects.length) {
              htmlSubProjects = _.map(subProjects, (subProject) => {
                return `
                  <div class='right-section-title is-secondary'>
                    ${subProject.title}
                  </div>
                `;
              }).join('');
              htmlSubProjects = `
                <div style='cursor: pointer' onclick='showModalSubProjects(${project.id})'>
                  <hr></hr>
                  <div class='right-section-title'>附属项目</div>
                  ${htmlSubProjects}
                  <div>点击查看详情</div>
                  <hr></hr>
                </div>
              `;
            }
            const html = `
              <div class='info-window'>
                ${genHtml(project, { tasks: this.tasks })}
                ${htmlSubProjects}
                <div style='margin-top: 10px;'><a target='_blank' href='${`/admin/trackings/edit/${project.id}`}' class='el-button el-button--primary is-round el-button--small color-white'>管理项目</a></div>
              </div>
            `;
            const polygonPoints = _.map(fencePartial, (point) => toLngLat([point.lng, point.lat]));
            const polygon = addPolygonOverlay(polygonPoints, {
              color: projectLevel.color,
              opacity: 0.2,
              fillColor: projectLevel.color,
              fillOpacity: 0.1,
              weight: 1,

              highlightByOpacity: 5,

              infoWindow: {
                html,
                ...infoWindowSize,
              },

              onClick: () => {
                this.$emit('update:selectedProjectIds', [Number(project.id)]);
              },
            });

            markers.push(polygon);
            detailMarkers.push(polygon);

            const marker = addPointOverlay(
              toLngLat([position.lng, position.lat]),
              projectLevel.markerIcon,
              {
                iconSize: { width: 20, height: 23 },
                infoWindow: {
                  html,
                  ...infoWindowSize,
                },
                onClick: () => {
                  const projectId = project.id;
                  const updateFitView = () => {
                    setFitView([polygon]);
                    this.$emit('update:selectedProjectIds', [Number(projectId)]);
                  };
                  if (projectId) {
                    updateFitView();
                    setTimeout(updateFitView, 200);
                  }
                },
              },
            );

            if (fencePartialIndex === 0) {
              markers.push(marker);
            } else {
              detailMarkers.push(marker);
            }

            const markerText = addLabelOverlay(
              toLngLat([position.lng, position.lat]),
              project.title,
              {
                x: 1,
                y: -5,
                color: projectLevel.color,
              },
            );
            markers.push(markerText);
          }
          return true;
        });
        return true;
      });
      _.forEach(this.cams, (cam) => {
        if (!cam.location_amap) {
          return true;
        }
        const [lng, lat] = String(cam.location_amap).split(',');

        const camUrl = `/admin/cams/edit/${cam.id}`;
        let camButton = `
          <a target='_blank' href='${camUrl}' class='el-button color-white el-button--info is-round el-button--small'>监控状态：离线</a>
        `;
        if (cam.state) {
          camButton = `
          <a target='_blank' href='${camUrl}' class='el-button color-white el-button--primary is-round el-button--small'>监控状态：在线</a>
        `;
        }
        const html = `
          <div class='info-window'>
            <div class='right-section-title'>${cam.title}</div>
            ${camButton}
          </div>
        `;
        const marker = addPointOverlay(
          toLngLat([Number(lng), Number(lat)]),
          G().getIconPng(G().getMapMarkerIcon('utils', 'CAM')),
          {
            iconSize: G().getIconSize(G().getMapMarkerIcon('utils', 'CAM')),
            infoWindow: {
              ...infoWindowSize,
              html,
            },
          },
        );

        markers.push(marker);
        detailMarkers.push(marker);
        return true;
      });
      if (!this.hasMapLoaded) {
        if (markers.length) {
          setFitView(markers);
          this.hasMapLoaded = true;
        }
      }

      const handleMapZoom = () => {
        const zoomLevel = map.getZoom();
        if (zoomLevel > 12) {
          _.forEach(detailMarkers, (item) => {
            map.addOverLay(item);
          });
        } else {
          _.forEach(detailMarkers, (item) => {
            map.removeOverLay(item);
          });
        }
      };

      map.addEventListener('zoomstart', handleMapZoom);
      map.addEventListener('zoomend', handleMapZoom);
      handleMapZoom();
    },
    resetMapView() {
      const { markers } = this;
      const { mapUtils } = this.map;
      const { setFitView } = mapUtils;
      if (markers.length) {
        setFitView(markers);
      }
      this.$emit('update:selectedProjectIds', []);
    },
  },
};
</script>

<style lang="less" scoped>
.map-container {
  @offsetSize: 0px;
  position: absolute;
  top: @offsetSize;
  bottom: @offsetSize;
  left: @offsetSize;
  right: @offsetSize;

  > div {
    background: none !important;
  }
}

.map-reset-button-container {
  right: 10px;
  bottom: 80px;
  z-index: 1000;
  position: absolute;
}

.map-reset-button {
  width: 30px;
  height: 30px;
  background-color: white;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  border: 1px solid #ccc;
  color: #000;
  font-size: 10px;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
}
</style>
