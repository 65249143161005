<template lang="pug">
el-popover.map-cover-card-container(
  placement="right-start",
  width="200",
  trigger="hover"
)
  .map-cover-card-popover
    slot
  template(v-slot:reference)
    MapCoverCard(
      :color="color"
      :title="title"
      :number="number"
      :total="total"
      withEllipsis
    )
</template>

<script>
import MapCoverCard from './MapCoverCard.vue';

export default {
  name: 'MapCoverCardWithPopover',
  props: {
    color: String,
    title: String,
    number: [String, Number],
    total: [String, Number],
  },
  components: {
    MapCoverCard,
  },
};
</script>

<style lang="scss" scoped>
.map-cover-card-container {
  display: block;
}

.map-cover-card-popover {
  font-size: 12px;
  line-height: 1.6;

  h3 {
    font-size: 14px;
    margin: 0 0 4px 0;
  }
}
</style>
