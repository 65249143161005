<template lang="pug">
  .title-row
    .title(@click="handleClick" :class="{ 'cursor-pointer': Boolean(subWindow) }")
      img(v-if="icon" :alt="title" :src="icon").icon
      span {{ title }}
      i(v-if="subWindow").el-icon-arrow-right.el-icon--right
    .hint(v-if="hint") {{ hint }}
</template>

<script>
export default {
  name: 'BoardTitle',
  props: {
    icon: String,
    title: String,
    hint: String,
    subWindow: String,
  },
  emits: ['sub-window'],
  methods: {
    handleClick() {
      if (this.subWindow) {
        this.$emit('sub-window', this.subWindow);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title-row {
  margin: -1px -1px 0;
  padding: 8px 12px 8px 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: #e9b029;
}

.icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 0.5em;
  position: relative;
  top: 1px;
}

.hint {
  font-size: 12px;
  color: #9ba6ad;
}
</style>
