<template lang="pug">
.info-window-cards
  slot
</template>

<script>
export default {
  name: 'InfoWindowCards',
};
</script>

<style lang="scss" scoped>
.info-window-cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  & > div {
    flex: 1 0 1px;
  }
}
</style>
