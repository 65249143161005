/* eslint-disable global-require */

export default {
  L1: {
    theme: 'GREEN',
    color: '#35F48F',
    markerIcon: require('../../../assets/images/screen/project-mark-l1.min.png'),
  },
  L2: {
    theme: 'YELLOW',
    color: '#F4BF35',
    markerIcon: require('../../../assets/images/screen/project-mark-l2.min.png'),
  },
  L3: {
    theme: 'PURPLE',
    color: '#B735F4',
    markerIcon: require('../../../assets/images/screen/project-mark-l3.min.png'),
  },
  暂停施工: {
    theme: 'GRAY',
    color: '#ccc',
    markerIcon: require('../../../assets/images/screen/project-mark-pending.min.png'),
  },
  DEFAULT: {
    theme: 'RED',
    color: '#F43535',
    markerIcon: require('../../../assets/images/screen/project-mark-l4.min.png'),
  },
};
