<template>
  <div class="user-company-internal-notice">
    <ui-alert v-if="value.isOutdated"> 人员安全教育内容有更新！ </ui-alert>
    <ui-admin-form readonly :model="notices" :fields="noticesAttributes">
      <template v-slot:field__uploads="{ value }">
        <field-uploads :value="value" readonly />
      </template>
    </ui-admin-form>
    <ui-admin-form
      :model="value"
      :fields="attributes"
      :with-delete="false"
      :with-save="false"
      :readonly="readonly"
    />
  </div>
</template>

<script>
export default {
  name: 'UserCompanyInternalNotice',
  props: {
    id: { type: String },
    value: { type: Object },
    readonly: { type: Boolean, deafult: true },
  },
  data() {
    return {
      attributes: [],
      notices: {},
      noticesAttributes: [],
    };
  },
  watch: {
    id() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const res = await this.withLoading(
        this.api(`/api/users/user-company-internal-notice/${this.id || ''}`),
      );
      // eslint-disable-next-line object-curly-newline
      const { noticesAttributes, attributes, notices, item } = res;
      this.noticesAttributes = noticesAttributes;
      this.attributes = attributes;
      this.notices = notices;
      this.$emit('input', item);
    },
  },
};
</script>
