<template>
  <div>
    <ui-stage>
      <ui-admin-table
        v-if="data"
        :attributes="vAttributes"
        :data="data"
        :module-url="moduleUrl"
        pagination-method="front-end"
      >
      </ui-admin-table>
    </ui-stage>
  </div>
</template>

<script>
import { _ } from '@yishitec/web';
import { adminList } from '@yishitec/web/mixins';

export default {
  name: 'List',
  mixins: [adminList],
  computed: {
    vAttributes() {
      return window.G.developer
        ? this.attributes
        : _.filter(this.attributes, (item) => !['ip', 'ua'].includes(item.name));
    },
  },
};
</script>

<style lang="less" scoped></style>
