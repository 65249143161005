<template lang="pug">
  div(v-loading='loading')
    slot
</template>

<script>
export default {
  name: 'UiLoading',
  props: {
    loading: { type: Boolean, default: false },
  },
};
</script>
