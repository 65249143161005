<template lang="pug">
div.board.item
  BoardTitle(
    :icon="icon"
    :title="title"
    :hint="hint"
    :subWindow="subWindow"
    style="background: #182127"
    @sub-window="$emit('sub-window', $event)"
  )
  .content(:class="{ 'flex-container': flex }")
    slot
</template>

<script>
import BoardTitle from './BoardTitle.vue';

export default {
  name: 'Board',
  props: {
    title: String,
    icon: String,
    hint: String,
    subWindow: String,
    flex: { type: Boolean, default: true },
  },
  emits: ['sub-window'],
  components: { BoardTitle },
};
</script>

<style lang="less" scoped>
.board.item {
  background: #20292e;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #2d373d;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.content {
  padding: 20px;
  box-sizing: border-box;
  flex: 1 0 1px;
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
</style>
