<template lang="pug">
div(:class="{'is-primary': primary, 'is-secondary': secondary}").right-section-title
  slot
</template>

<script>
export default {
  name: 'RightSectionTitle',
  props: {
    primary: Boolean,
    secondary: Boolean,
  },
};
</script>

<style lang="less" scoped>
.right-section-title {
  font-size: 12px;
  font-weight: bolder;
  margin: 3px 0;

  &.is-primary {
    font-size: 14px;
  }

  &.is-secondary {
    opacity: 0.4;
    font-size: 10px;
  }
}
</style>
