<template>
  <div class="report-grids-m5">
    <div class="grid-1" v-if="children[0]">
      <ReportComponent :item="children[0]" :reportsData="reportsData" />
    </div>
    <div class="grids-g4">
      <div class="grid-2" v-if="children[1]">
        <ReportComponent :item="children[1]" :reportsData="reportsData" dark />
      </div>
      <div class="grid-3" v-if="children[2]">
        <ReportComponent :item="children[2]" :reportsData="reportsData" dark />
      </div>
      <div class="grid-4" v-if="children[3]">
        <ReportComponent :item="children[3]" :reportsData="reportsData" dark />
      </div>
      <div class="grid-5" v-if="children[4]">
        <ReportComponent :item="children[4]" :reportsData="reportsData" dark />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportGridsM5',
  props: {
    children: {
      type: Array,
      default: () => [],
    },
    reportsData: Object,
  },
};
</script>

<style lang="scss">
.report-grids-m5 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  .grid-1 {
    flex: 1 0 1px;
    box-sizing: border-box;
    margin-right: 10px;
    border-radius: 5px;
    border: solid 1px rgba(#b5c2d1, 1);
    box-sizing: border-box;
    padding: 6px 0;
  }
  .grids-g4 {
    flex: 2 0 1px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-bottom: -10px;
    & > div {
      border-radius: 5px;
      margin-right: 10px;
      border: solid 1px rgba(#b5c2d1, 1);
      box-sizing: border-box;
      padding: 6px 0;
      flex: 1 0 auto;
      width: calc(50% - 10px);
      margin-bottom: 10px;
    }
  }
  .grid-2 {
    border-color: #96a8be;
    background-color: #96a8be;
  }
  .grid-3 {
    border-color: #75879d;
    background-color: #75879d;
  }
  .grid-4 {
    border-color: #83a3cb;
    background-color: #83a3cb;
  }
  .grid-5 {
    border-color: #5887c1;
    background-color: #5887c1;
  }
}
</style>
