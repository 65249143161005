<template>
  <div>
    <ui-stage>
      <ui-admin-table
        v-if="data"
        :attributes="attributes"
        :data="data"
        :module-url="moduleUrl"
        pagination-method="front-end"
        :link-create="linkCreate"
        :with-create="canCreate"
      >
        <template v-slot:column_approve_state="{ row }">
          <el-tag type="primary" v-if="row.approve_state === '待审核'">待审核</el-tag>
          <el-tag type="success" v-else>已审核</el-tag>
        </template>
        <template v-slot:column__adminActions="scope">
          <div>
            <ui-button-group>
              <router-link :to="`${moduleUrl}/edit/${scope.row.id}`">
                <ui-button
                  size="mini"
                  type="primary"
                  icon="el-icon-edit"
                  v-if="scope.row.permissions.canEdit"
                  >编辑</ui-button
                >
                <ui-button
                  size="mini"
                  type="primary"
                  icon="el-icon-view"
                  v-else-if="scope.row.permissions.canView"
                  >查看</ui-button
                >
              </router-link>
              <router-link
                :to="`${moduleUrl}/delete/${scope.row.id}?backUrl=${encodeURIComponent(
                  $route.fullPath,
                )}`"
                v-if="scope.row.permissions.canDelete"
              >
                <ui-button size="mini" type="info" icon="el-icon-delete">删除</ui-button>
              </router-link>
            </ui-button-group>
          </div>
        </template>
      </ui-admin-table>
    </ui-stage>
  </div>
</template>

<script>
import { adminList } from '@yishitec/web/mixins';
import permissions from '../../../mixins/permissions';

export default {
  name: 'List',
  mixins: [adminList, permissions],
  props: {
    projectId: { type: String },
  },
  data() {
    return {
      apiBodyData: {
        project_id: this.projectId,
      },
      linkCreate: `${this.moduleUrl}/add?project_id=${this.projectId}`,
    };
  },
  computed: {
    canCreate() {
      return Boolean(
        this.projectId &&
          String(this.projectId) !== 'undefined' &&
          this.amI(this.projectId, '施工单位', '管理人员', true),
      );
    },
  },
};
</script>

<style lang="less" scoped></style>
