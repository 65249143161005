module.exports = {
  icon: 'images/data-center-screen/boards/icon-supervision-logs.svg',
  title: '监理日志管理',
  apiUrl: '/api/reports/data-center-screen/sub-windows/supervision-logs',
  indicators: [],
  table: [
    {
      name: '项目名称',
      width: '220px',
    },
    {
      name: '过去30天上传次数',
      width: '180px',
      type: 'stats',
      cols: ['过去30天上传次数', 'days30'],
    },
    {
      name: '过去30天不作业天数',
      width: '180px',
      type: 'stats',
      cols: ['过去30天不作业天数', 'days30'],
    },
    {
      name: '查看详情',
      type: 'adminOps',
      to: '/admin/supervision-logs',
    },
    {},
  ],
};
