<template lang="pug">
div.left-container
  .content-container
    .logo(@click="$emit('action', 'full-screen')")
      div.logo-text 营造云智慧工地
    .scroll
      slot
</template>

<script>
export default {
  name: 'LeftContainer',
  emits: ['action'],
};
</script>

<style lang="less" scoped>
@import '../../../assets/styles/app-mixins.less';

.left-container {
  flex: 0 0 auto;
  width: 0;
  background-image: linear-gradient(180deg, #445762 0%, #262b2f 39%, #000000 100%);
  position: relative;
  transition: all 1s;
}

.content-container {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 114px 30px 0;
  box-sizing: border-box;
}

.logo {
  background: url('/images/data-center-screen/logo.png') no-repeat top left;
  background-size: 425px 241px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 241px;
  cursor: pointer;
}

.logo-text {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin: 36px 0 20px 110px;
}

.scroll {
  position: relative;
  flex: 1 0 1px;
  overflow: auto;
  margin-right: -10px;
  padding-right: 10px;
  box-sizing: border-box;
  .thin-scroll();
}
</style>
