<script>
import { _ } from '@yishitec/web';
import { Chart } from '@antv/g2';

export default {
  name: 'LineChart',
  props: {
    list: Array,
    legends: Array,
    area: Boolean,
    x: String,
  },
  data() {
    return {
      chart: null,
      points: [],
    };
  },
  watch: {
    list() {
      this.updateData();
    },
  },
  mounted() {
    this.loadChart();
  },
  methods: {
    loadChart() {
      const { list } = this;
      if (!list || !list.length) return;
      const chart = new Chart({
        container: this.$refs.chart,
        autoFit: false,
      });

      const { data, colors } = this.transformData(list);
      chart.data(data);

      chart.tooltip({
        showCrosshairs: true,
        shared: true,
      });

      chart.axis(this.x, {
        grid: {
          line: {
            style: {
              strokeOpacity: 0.2,
            },
          },
        },
      });

      chart.scale('y', {
        nice: true,
        min: 0,
      });
      chart.axis('y', {
        position: 'left',
        grid: {
          line: {
            style: {
              strokeOpacity: 0.1,
            },
          },
        },
      });

      if (this.area) {
        chart
          .area()
          .position('x*y')
          .style({
            fields: ['series'],
            callback: (series) => {
              const color = colors[series];
              return {
                fill: `l(90) 0:${color}33 1:${color}00`,
                fillOpacity: 1,
              };
            },
          })
          .color('series', (series) => {
            return colors[series];
          });
      }
      chart
        .line()
        .position('x*y')
        .color('series', (series) => {
          return colors[series];
        })
        .style({
          strokeOpacity: 1,
        });
      chart
        .point()
        .position('x*y')
        .color('series', (series) => {
          return colors[series];
        })
        .style({
          strokeOpacity: 1,
        });

      chart.render();
      this.chart = chart;
      this.resize();
    },
    transformData(list) {
      const colors = {};

      const data = {};
      _.forEach(_.orderBy(list, [this.x], ['asc']), (i) => {
        _.forEach(this.legends, (legend) => {
          const oldValue = _.get(data, [i[this.x], legend.name], 0);
          _.set(data, [i[this.x], legend.name], oldValue + Number(i[legend.name] || 0));
        });
      });

      const ret = [];
      _.forEach(data, (day, x) => {
        _.forEach(this.legends, (legend) => {
          colors[legend.title] = legend.color;
          ret.push({
            x,
            series: legend.title,
            y: day[legend.name],
          });
        });
      });

      this.points = ret;

      return {
        colors,
        data: ret,
      };
    },
    updateData() {
      if (!this.chart) {
        this.loadChart();
        return;
      }
      this.chart.changeData(this.transformData(this.list).data);
    },
    resize() {
      if (!this.$refs || !this.$refs.chart || !this.$refs.container) return;
      this.$refs.chart.style.display = 'none';
      this.chart.changeSize(this.$refs.container.offsetWidth, this.$refs.container.offsetHeight);
      this.$refs.chart.style.display = 'block';
      setTimeout(this.resize, 1000);
    },
  },
};
</script>

<template lang="pug">
div(ref="container").line-chart-container
  div(ref="chart").line-chart
</template>

<style lang="scss" scoped>
.line-chart-container {
  flex: 1 0 1px;
  min-height: 120px;
}

.line-chart {
  width: 100%;
  height: 100%;
}
</style>