/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
/* eslint-disable import/no-dynamic-require,global-require */
// eslint-disable-next-line object-curly-newline
import { main, _, moment, G, Vue } from '@yishitec/web';
import { ContainerView, ErrorView, PageHeaderView } from '@yishitec/web/views';
import UiAdminModelPicker from '@yishitec/web/components/UiAdminModelPicker';

import VueAmap from 'vue-amap';
import VueResize from 'vue-resize';
import VueMeta from 'vue-meta';
import VueQrious from 'vue-qrious';
import 'vue-resize/dist/vue-resize.css';

import store from './store';
import './assets/styles/app-mixins.less';
import './assets/styles/app.less';

import Home from './views/Home.vue';
import PrivacyPolicy from './views/PrivacyPolicy.vue';
import AdminLogin from './views/admin/AdminLogin.vue';
import AdminContainer from './views/admin/AdminContainer.vue';
import AdminHome from './views/admin/AdminHome.vue';
import AdminChangePassword from './views/admin/AdminChangePassword.vue';

import CamsProjects from './views/admin/Cams/Projects.vue';
import CamSnapshotsProjects from './views/admin/CamSnapshots/Projects.vue';
import CamsList from './views/admin/Cams/List.vue';
import MaterialsProjects from './views/admin/Materials/Projects.vue';
import MaterialsList from './views/admin/Materials/List.vue';
import KeyComponentAcceptanceProjects from './views/admin/KeyComponentAcceptance/Projects.vue';
import KeyComponentAcceptanceList from './views/admin/KeyComponentAcceptance/List.vue';
import PlanNoticeProjects from './views/admin/PlanNotice/Projects.vue';
import PlanNoticeList from './views/admin/PlanNotice/List.vue';
import Tasks from './views/admin/Tasks/List.vue';

import SigninsReport from './views/admin/reports/SigninsReport.vue';
import DangerZonesReport from './views/admin/reports/DangerZonesReport.vue';
import UserLogs from './views/admin/UserLogs.vue';

G().appName = '智慧工地';

G().amapKey = '3a68d390140625ca1d26542a3ffa9060';

G().colorDiffTolerance = 50;

G().getMapMarkerIcon = (companyType, adminType) => {
  const iconsMap = {
    施工单位: {
      管理人员: 'SG_ADMIN',
      安全员: 'SG_SECURITY',
      作业人员: 'SG_WORKER',
    },
    监理单位: {
      管理人员: 'JL',
      专项管理员: 'JL',
      作业人员: 'JL',
    },
    utils: {
      alert: 'alert',
      IOT_DEVICE: 'IOT_DEVICE',
      IOT_DEVICE_PILE: 'IOT_DEVICE_PILE',
      CAM: 'CAM',
      MARKER: 'MARKER',
    },
  };
  return _.get(iconsMap, [companyType, adminType], 'MARKER');
};

G().getIconSize = (iconName) => {
  const iconsMap = {
    DEFAULT: { width: 32, height: 32 },
    SG_ADMIN: { width: 31, height: 48 },
    SG_SECURITY: { width: 31, height: 48 },
    SG_WORKER: { width: 31, height: 48 },
    JL: { width: 31, height: 48 },
    alert: { width: 19, height: 19 },
    CAM: { width: 32, height: 32 },
    IOT_DEVICE: { width: 32, height: 32 },
    IOT_DEVICE_PILE: { width: 32, height: 32 },
    MARKER: { width: 31, height: 48 },
  };
  return _.get(iconsMap, [iconName], iconsMap.DEFAULT);
};

G().getIconPng = (icon) => {
  return require(`./assets/images/mapMarkers/${icon}.png`);
};

G().options工种 = () => {
  return '普工 木工 钢筋工 瓦工 电工 架子工 桩工 焊工 起重司机 挖机驾驶员 技术负责人 安全员 质检员 施工员 资料员 材料员 机械员 劳务员 标准员'.split(
    ' ',
  );
};

const noop = () => {};

Vue.use(VueResize);
Vue.use(VueAmap);
Vue.mixin({
  methods: {
    async withNoLoading(fn) {
      try {
        const res = await fn();
        return res;
      } catch (e) {
        return null;
      }
    },
    containsText(item, keyword = '') {
      if (!keyword) return true;
      if (!_.isString(item)) {
        try {
          item = JSON.stringify(item);
        } catch (e) {
          noop();
        }
      }
      if (!_.isString(item)) {
        try {
          item = item.toString();
        } catch (e) {
          noop();
        }
      }
      if (!_.isString(item)) {
        try {
          item = String(item);
        } catch (e) {
          noop();
        }
      }
      item = item.toLowerCase();
      const [...keywords] = String(keyword || '')
        .toLowerCase()
        .split(' ');
      let flag = true;
      _.forEach(keywords, (keywordFraction) => {
        if (!keywordFraction) return true;
        if (!String(item).includes(keywordFraction)) {
          flag = false;
          return false;
        }
        return true;
      });
      return flag;
    },
    formatDateTime(value) {
      const mValue = moment(value);
      if (!mValue.isValid()) {
        return '-';
      }
      return mValue.format('YYYY-MM-DD HH:mm:ss');
    },
  },
});
VueAmap.initAMapApiLoader({
  key: G().amapKey,
  plugin: ['AMap.MouseTool', 'AMap.PolyEditor', 'AMap.ToolBar', 'AMap.Autocomplete'],
  v: '1.4.15',
});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.component('ui-loading', require('./components/UiLoading.vue').default);

Vue.component('UiAdminModelPicker', UiAdminModelPicker);
Vue.component('vue-qrious', VueQrious);
Vue.component('FieldUploads', require('./components/FieldUploads.vue').default);
Vue.component('SspFormItem', require('./components/ssp/SspFormItem.vue').default);
Vue.component('SspTabs', require('./components/ssp/SspTabs.vue').default);
Vue.component('SspEmptyCard', require('./components/ssp/SspEmptyCard.vue').default);
Vue.component('SspTable', require('./components/ssp/SspTable.vue').default);
Vue.component('SspSidebar', require('./components/ssp/SspSidebar.vue').default);
Vue.component('SspSidebarLink', require('./components/ssp/SspSidebarLink.vue').default);
Vue.component(
  'ProgressSaveTemplateModal',
  require('./components/ProgressSaveTemplateModal.vue').default,
);
Vue.component(
  'ProgressApplyTemplateModal',
  require('./components/ProgressApplyTemplateModal.vue').default,
);
Vue.component('ProjectsFilterList', require('./components/admin/ProjectsFilterList').default);
Vue.component('Searchable', require('./components/admin/Searchable').default);
Vue.component('AppButton', require('./components/AppButton').default);
Vue.component('FormDateTime', require('./components/admin/FormDateTime').default);
Vue.component('MemberName', require('./components/admin/MemberName').default);
Vue.component('ProjectName', require('./components/admin/ProjectName').default);
Vue.component('DangerZoneMap', require('./components/admin/DangerZoneMap').default);
Vue.component('AppMapMouseEditor', require('./components/maps/AppMapMouseEditor').default);
Vue.component('SelectDangerZones', require('./components/admin/SelectDangerZones').default);
Vue.component('SelectOpsDangerType', require('./components/admin/SelectOpsDangerType').default);
Vue.component('ApprovalStateTag', require('./components/ApprovalStateTag.vue').default);
Vue.component('ProgressStateTag', require('./components/ProgressStateTag.vue').default);
Vue.component('UiNotice', require('./components/UiNotice.vue').default);
Vue.component('AppReports', require('./components/AppReports/index.vue').default);
Vue.component('ReportComponent', require('./components/AppReports/ReportComponent.vue').default);
Vue.component('MapInfoWindow', require('./components/MapInfoWindow/MapInfoWindow.vue').default);
Vue.component('InfoWindowTitle', require('./components/MapInfoWindow/InfoWindowTitle.vue').default);
Vue.component('InfoWindowCard', require('./components/MapInfoWindow/InfoWindowCard.vue').default);
Vue.component('InfoWindowCards', require('./components/MapInfoWindow/InfoWindowCards.vue').default);
Vue.component('StatsScreenTree', require('./components/StatsScreenTree').default);
Vue.component('FeContainer', require('./components/FeContainer.vue').default);
Vue.component('ScrollBox', require('./components/ScrollBox.vue').default);
Vue.component('ScrollingBox', require('./components/ScrollingBox.vue').default);

// nuxi-admin
Vue.component('AdminList', require('./components/nuxi-admin/AdminList').default);
Vue.component('AdminCardTitle', require('./components/nuxi-admin/AdminCardTitle.vue').default);
Vue.component('AdminActions', require('./components/nuxi-admin/AdminActions.vue').default);
Vue.component('AdminForm', require('./components/nuxi-admin/AdminForm.vue').default);
Vue.component('AdminFormPage', require('./components/nuxi-admin/AdminFormPage.vue').default);
Vue.component('AdminInput', require('./components/nuxi-admin/AdminInput.vue').default);
Vue.component('AdminCell', require('./components/nuxi-admin/AdminCell.vue').default);
Vue.component('AdminModelPicker', require('./components/nuxi-admin/AdminModelPicker.vue').default);
Vue.component('AdminFilesList', require('./components/nuxi-admin/AdminFilesList.vue').default);
Vue.component('AdminUpload', require('./components/nuxi-admin/AdminUpload.vue').default);

const buildCrudRoutes = (path, options = {}) => {
  const {
    dir = _.upperFirst(_.camelCase(path)),
    prefix = _.upperFirst(_.camelCase(path)),
    model = path,
    moduleName = _.startCase(path),
    children = [], // eslint-disable-next-line no-unused-vars
    getChildren = (meta) => [],
  } = options;
  const meta = {
    moduleUrl: `/admin/${path}`,
    model,
    moduleName,
  };
  const vChildren = [...children, ...getChildren(meta)];
  const paths = _.map(vChildren, (child) => child.path);
  const avoidPaths = (child) => {
    if (paths.includes(child.path)) return [];
    return [child];
  };
  return {
    path,
    component: ContainerView,
    meta,
    children: [
      ...avoidPaths({
        path: '',
        component: PageHeaderView,
        props: {
          ...meta,
          breadcrumbs: [{ title: G().appName, link: '/admin' }, { title: `管理${moduleName}` }],
          pageTitle: `管理${moduleName}`,
        },
        children: [
          {
            path: '',
            name: `${prefix}List`,
            component: require(`./views/admin/${dir}/List.vue`).default,
            props: (router) => ({
              ...meta,
              ...router.params,
            }),
          },
        ],
      }),
      {
        path: 'add',
        component: PageHeaderView,
        props: {
          ...meta,
          pageTitle: `创建${moduleName}信息`,
        },
        children: [
          {
            path: '',
            name: `${prefix}Add`,
            component: require(`./views/admin/${dir}/Edit.vue`).default,
            props: (route) => ({
              ...meta,
              ...route.params,
            }),
          },
        ],
      },
      {
        path: 'edit',
        component: PageHeaderView,
        props: {
          ...meta,
          pageTitle: `编辑${moduleName}信息`,
        },
        children: [
          {
            path: ':vId',
            name: `${prefix}Edit`,
            component: require(`./views/admin/${dir}/Edit.vue`).default,
            props: (route) => ({
              ...meta,
              ...route.params,
            }),
          },
          {
            path: ':idA/:idB',
            name: `${prefix}Edit`,
            component: require(`./views/admin/${dir}/Edit.vue`).default,
            props: (route) => ({
              ...meta,
              ...route.params,
            }),
          },
        ],
      },
      {
        path: 'delete',
        component: PageHeaderView,
        props: {
          ...meta,
          breadcrumbs: [
            { title: G().appName, link: '/admin' },
            {
              title: `${moduleName}管理`,
              link: meta.moduleUrl,
            },
            { title: `删除${moduleName}` },
          ],
          pageTitle: `删除${moduleName}`,
        },
        children: [
          {
            path: ':id',
            name: `${prefix}Delete`,
            component: require(`./views/admin/${dir}/Delete.vue`).default,
            props: (route) => ({
              ...meta,
              ...route.params,
            }),
          },
        ],
      },
      ...vChildren,
    ],
  };
};

const buildProjectCrudRoutes = (path, options) => {
  const {
    dir = _.upperFirst(_.camelCase(path)),
    prefix = _.upperFirst(_.camelCase(path)),
    moduleName = _.startCase(path),
  } = options;
  return buildCrudRoutes(path, {
    ...options,
    moduleName,
    getChildren: (meta) => [
      {
        path: '',
        component: PageHeaderView,
        props: {
          ...meta,
          pageTitle: `管理${moduleName} - 选择项目`,
        },
        children: [
          {
            path: '',
            name: `${prefix}Projects`,
            component: require(`./views/admin/${dir}/Projects.vue`).default,
            props: (route) => ({
              ...meta,
              ...route.params,
            }),
          },
        ],
      },
      {
        path: 'list',
        component: PageHeaderView,
        props: {
          ...meta,
          pageTitle: `管理${moduleName}`,
        },
        children: [
          {
            path: ':projectId',
            name: `${prefix}List`,
            component: require(`./views/admin/${dir}/List.vue`).default,
            props: (route) => ({
              ...meta,
              ...route.params,
            }),
          },
        ],
      },
    ],
  });
};

main({
  routes: [
    {
      component: Home,
      name: 'home',
      path: '/',
      children: [],
    },
    {
      component: PrivacyPolicy,
      name: 'PrivacyPolicy',
      path: '/privacy-policy',
    },
    {
      component: ContainerView,
      props: {
        keyed: false,
      },
      name: 'admin',
      path: '/admin',
      children: [
        {
          name: 'adminLogin',
          component: AdminLogin,
          path: 'login',
        },
        {
          path: '',
          nae: 'adminContainer',
          component: AdminContainer,
          children: [
            {
              path: '',
              name: 'AdminDefaultScreen',
              component: require('./views/admin/AdminDefaultScreen.vue').default,
            },
            {
              path: 'select-projects',
              name: 'adminHome',
              component: AdminHome,
            },
            {
              path: 'change-password',
              name: 'adminChangePassword',
              component: AdminChangePassword,
            },
            {
              path: 'tasks',
              name: 'Tasks',
              component: PageHeaderView,
              props: {
                moduleName: '任务',
                pageTitle: '全部任务',
              },
              children: [
                {
                  path: '',
                  name: 'TasksList',
                  component: Tasks,
                },
              ],
            },
            buildCrudRoutes('users', {
              moduleName: '人员',
              getChildren: (meta) => [
                {
                  path: 'add-js',
                  component: PageHeaderView,
                  props: {
                    ...meta,
                    pageTitle: '添加建设单位管理人员',
                    breadcrumbs: [
                      { title: G().appName, link: '/admin' },
                      {
                        title: '管理人员',
                        link: '/admin/users',
                      },
                      { title: '添加建设单位管理人员' },
                    ],
                  },
                  children: [
                    {
                      path: '',
                      name: 'AddJsView',
                      component: require('./views/admin/Users/AddJs').default,
                      props: {
                        ...meta,
                      },
                    },
                  ],
                },
              ],
            }),
            buildCrudRoutes('company-internal-notices', {
              moduleName: ' 人员安全教育',
            }),
            buildCrudRoutes('projects', { moduleName: '项目' }),
            buildCrudRoutes('cams', {
              moduleName: '监控',
              getChildren: (meta) => [
                {
                  path: '',
                  component: PageHeaderView,
                  props: {
                    ...meta,
                    pageTitle: '管理监控 - 选择项目',
                  },
                  children: [
                    {
                      path: '',
                      name: 'CamsProjects',
                      component: CamsProjects,
                      props: (route) => ({
                        ...meta,
                        ...route.params,
                      }),
                    },
                  ],
                },
                {
                  path: 'list',
                  component: PageHeaderView,
                  props: {
                    ...meta,
                    pageTitle: '管理监控',
                  },
                  children: [
                    {
                      path: ':projectId',
                      name: 'CamsList',
                      component: CamsList,
                      props: (route) => ({
                        ...meta,
                        ...route.params,
                      }),
                    },
                  ],
                },
              ],
            }),
            buildCrudRoutes('cam-snapshots', {
              moduleName: '监控画面快照',
              getChildren: (meta) => {
                return [
                  {
                    path: '',
                    component: PageHeaderView,
                    props: {
                      ...meta,
                      pageTitle: '监控画面快照 - 选择项目',
                    },
                    children: [
                      {
                        path: '',
                        name: 'CamSnapshotsProjects',
                        component: CamSnapshotsProjects,
                        props: (route) => {
                          return {
                            ...meta,
                            ...route.params,
                          };
                        },
                      },
                    ],
                  },
                ];
              },
            }),
            buildCrudRoutes('user-projects', { moduleName: '人员-项目绑定' }),
            buildCrudRoutes('user-id-table', { moduleName: '实名登记表' }),
            buildCrudRoutes('danger-zones', { moduleName: '安全管理' }),
            buildCrudRoutes('ops-zones', { moduleName: '施工部位' }),
            buildCrudRoutes('daily-reports-uploads', {
              moduleName: '抽查表回执',
            }),
            buildCrudRoutes('ops-zones-logs', {
              moduleName: '今日作业施工部位',
            }),
            {
              path: 'ops-zones-logs-report',
              component: PageHeaderView,
              props: {
                breadcrumbs: [
                  {
                    title: G().appName,
                    link: '/admin',
                  },
                  {
                    title: '今日作业施工部位',
                    link: '/admin/ops-zones-logs',
                  },
                  {
                    title: '今日作业施工部位统计表',
                  },
                ],
                pageTitle: '今日作业施工部位统计表',
              },
              children: [
                {
                  path: '',
                  name: 'OpsZonesLogsReport',
                  props: {
                    moduleUrl: '/admin/ops-zones-logs',
                    model: 'ops-zones-logs',
                    moduleName: '今日作业施工部位',
                    pageTitle: '今日作业施工部位统计表表',
                  },
                  component: require('./views/admin/OpsZonesLogs/Report.vue').default,
                },
              ],
            },
            buildCrudRoutes('daily-danger-zones', { moduleName: '安全交底' }),
            buildCrudRoutes('daily-notice-grants', {
              moduleName: '每日安全交底记录',
            }),
            buildCrudRoutes('danger-zone-grants', {
              moduleName: '危险源作业记录',
            }),
            buildCrudRoutes('iot-devices', { moduleName: '危险源物联网设备' }),
            {
              path: 'iot-gateway-task-sets',
              component: ContainerView,
              children: [
                {
                  path: 'tasks',
                  component: ContainerView,
                  children: [
                    {
                      path: ':id',
                      name: 'IotGatewayTasks',
                      component: require('./views/admin/IotGatewayTaskSets/Tasks.vue').default,
                    },
                  ],
                },
              ],
            },
            buildCrudRoutes('iot-alerts', { moduleName: '危险源报警记录' }),
            buildCrudRoutes('supervision-logs', {
              moduleName: '监理日志',
              getChildren: (meta) => [
                {
                  path: 'config',
                  component: PageHeaderView,
                  props: {
                    ...meta,
                    pageTitle: '配置监理日志字段',
                  },
                  children: [
                    {
                      path: ':companyName',
                      name: 'SupervisionLogConfigEdit',
                      component: require('./views/admin/SupervisionLogs/Config.vue').default,
                      props: (route) => ({
                        ...meta,
                        ...route.params,
                      }),
                    },
                  ],
                },
              ],
            }),
            buildCrudRoutes('supervision-e-logs', {
              moduleName: '电子监理日志',
            }),
            buildProjectCrudRoutes('supervision-design-version', { moduleName: '设计变更' }),
            buildProjectCrudRoutes('supervision-sign-off-approval', { moduleName: '签证凭证' }),
            buildProjectCrudRoutes('danger-zone-events', {
              moduleName: '设备进出场',
            }),
            buildCrudRoutes('work-report-fields', {
              moduleName: '今日施工日记内容',
            }),
            buildProjectCrudRoutes('danger-zone-event-devices', {
              moduleName: '设备',
            }),
            buildCrudRoutes('key-component-acceptance', {
              moduleName: '关键部位验收',
              getChildren: (meta) => [
                {
                  path: '',
                  component: PageHeaderView,
                  props: {
                    ...meta,
                    pageTitle: '管理关键部位验收 - 选择项目',
                  },
                  children: [
                    {
                      path: '',
                      name: 'KeyComponentAcceptanceProjects',
                      component: KeyComponentAcceptanceProjects,
                      props: (route) => ({
                        ...meta,
                        ...route.params,
                      }),
                    },
                  ],
                },
                {
                  path: 'list',
                  component: PageHeaderView,
                  props: {
                    ...meta,
                    pageTitle: '管理关键部位验收',
                  },
                  children: [
                    {
                      path: ':projectId',
                      name: 'KeyComponentAcceptanceList',
                      component: KeyComponentAcceptanceList,
                      props: (route) => ({
                        ...meta,
                        ...route.params,
                      }),
                    },
                  ],
                },
              ],
            }),
            buildCrudRoutes('plan-notice', {
              moduleName: '施工方案交底',
              getChildren: (meta) => [
                {
                  path: '',
                  component: PageHeaderView,
                  props: {
                    ...meta,
                    pageTitle: '施工方案交底 - 选择项目',
                  },
                  children: [
                    {
                      path: '',
                      name: 'PlanNoticeProjects',
                      component: PlanNoticeProjects,
                      props: (route) => ({
                        ...meta,
                        ...route.params,
                      }),
                    },
                  ],
                },
                {
                  path: 'list',
                  component: PageHeaderView,
                  props: {
                    ...meta,
                    pageTitle: '管理施工方案交底',
                  },
                  children: [
                    {
                      path: ':projectId',
                      name: 'PlanNoticeList',
                      component: PlanNoticeList,
                      props: (route) => ({
                        ...meta,
                        ...route.params,
                      }),
                    },
                  ],
                },
              ],
            }),
            buildCrudRoutes('materials', {
              moduleName: '材料',
              getChildren: (meta) => [
                {
                  path: '',
                  component: PageHeaderView,
                  props: {
                    ...meta,
                    pageTitle: '管理材料 - 选择项目',
                  },
                  children: [
                    {
                      path: '',
                      name: 'MaterialsProjects',
                      component: MaterialsProjects,
                      props: (route) => ({
                        ...meta,
                        ...route.params,
                      }),
                    },
                  ],
                },
                {
                  path: 'list',
                  component: PageHeaderView,
                  props: {
                    ...meta,
                    pageTitle: '管理材料',
                  },
                  children: [
                    {
                      path: ':projectId',
                      name: 'MaterialsList',
                      component: MaterialsList,
                      props: (route) => ({
                        ...meta,
                        ...route.params,
                      }),
                    },
                  ],
                },
              ],
            }),

            // 物探
            buildCrudRoutes('prospecting-project', { moduleName: '勘察物探项目名称' }),
            buildCrudRoutes('prospecting-project-user', { moduleName: '勘察物探项目人员' }),
            buildCrudRoutes('prospecting-project-file', { moduleName: '技术要求和计划' }),
            buildCrudRoutes('prospecting-project-grant', { moduleName: '勘察物探安全交底' }),
            buildCrudRoutes('prospecting-project-grant-stat', {
              moduleName: '勘察物探安全交底统计',
            }),
            buildCrudRoutes('prospecting-project-task', { moduleName: '勘察物探任务' }),
            buildCrudRoutes('prospecting-project-task-node', { moduleName: '测点反馈' }),

            buildCrudRoutes('trackings', { moduleName: '签到' }),
            {
              path: 'user-logs',
              name: 'UserLogs',
              component: UserLogs,
            },
            {
              path: 'face-signin-qr',
              name: '人脸识别签到',
              component: require('./views/admin/FaceSigninQr.vue').default,
            },
            {
              path: 'reports',
              name: 'Reports',
              component: ContainerView,
              children: [
                {
                  path: 'signin',
                  name: 'ReportsSignins',
                  component: SigninsReport,
                },
                {
                  path: 'danger-zones',
                  name: 'ReportsDangerZones',
                  component: DangerZonesReport,
                },
                {
                  path: 'users-count',
                  name: 'ReportsUsersCount',
                  component: ContainerView,
                  children: [
                    {
                      path: 'preview',
                      name: 'ReportsUsersCountPreview',
                      component: require('./views/admin/reports/UsersCountReport/Preview').default,
                    },
                    {
                      path: '',
                      name: 'ReportsUsersCountView',
                      component: require('./views/admin/reports/UsersCountReport').default,
                    },
                  ],
                },
                {
                  path: 'dashboard',
                  name: 'ReportsDashboard',
                  component: require('./views/admin/reports/Dashboard.vue').default,
                },
                {
                  path: 'daily-reports',
                  name: 'DailyReports',
                  component: require('./views/admin/reports/DailyReports.vue').default,
                },
                {
                  path: 'daily-reports-2',
                  name: 'DailyReports2',
                  component: require('./views/admin/reports/DailyReports2.vue').default,
                },
                {
                  path: 'daily-reports-3',
                  name: 'DailyReports3',
                  component: require('./views/admin/reports/DailyReports3.vue').default,
                },
              ],
            },
            {
              path: 'ops',
              name: 'Ops',
              component: ContainerView,
              children: [
                {
                  path: 'pdycsp-health-check-kit',
                  name: 'PdycspHealthCheckKit',
                  component: require('./views/admin/Ops/HealthCheckKit.vue').default,
                },
              ],
            },
            {
              path: 'simple-project-management',
              name: 'SimpleProjectManagementIndex',
              component: () => import('./views/admin/SimpleProjectManagement/index.vue'),
            },
            {
              path: '*',
              name: 'AdminMissing',
              component: ErrorView,
            },
          ],
        },
      ],
    },
    {
      component: require('./views/progress/ProgressContainer.vue').default,
      props: {
        keyed: false,
      },
      name: 'progress',
      path: '/progress',
      children: [
        {
          name: 'progressAdminLogin',
          component: require('./views/progress/AdminLogin.vue').default,
          path: 'login',
        },
        {
          path: '',
          nae: 'adminContainer',
          component: require('./views/progress/ProgressAdminContainer').default,
          children: [
            {
              path: '',
              name: 'adminHome',
              component: require('./views/progress/Home.vue').default,
            },
            {
              // /progress/projects
              path: 'projects',
              component: ContainerView,
              children: [
                {
                  path: '',
                  name: 'progressProjectsList',
                  component: require('./views/progress/projects/List.vue').default,
                },
                {
                  path: 'edit',
                  component: ContainerView,
                  children: [
                    {
                      // /progress/projects/edit/:id
                      path: ':id',
                      name: 'progressProjectEdit',
                      component: require('./views/progress/projects/Edit.vue').default,
                      props: true,
                    },
                  ],
                },
                {
                  path: 'blueprints',
                  component: ContainerView,
                  children: [
                    {
                      path: 'edit',
                      component: ContainerView,
                      children: [
                        {
                          // /progress/projects/blueprints/edit/:id
                          path: ':id',
                          name: 'projectBlueprintsEdit',
                          component: require('./views/progress/projects/blueprints/Edit.vue')
                            .default,
                          props: true,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'parts',
                  component: ContainerView,
                  children: [
                    {
                      path: 'edit',
                      component: ContainerView,
                      children: [
                        {
                          path: ':id',
                          name: 'projectBlueprintPartEdit',
                          component: require('./views/progress/projects/parts/Edit.vue').default,
                          props: true,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              // progress/progress
              path: 'progress',
              component: ContainerView,
              children: [
                {
                  path: 'view',
                  component: ContainerView,
                  children: [
                    {
                      // progress/progress/view/:projectId
                      path: ':projectId',
                      componen: ContainerView,
                      name: 'progressProgressProjectView',
                      component: require('./views/progress/progress/ProjectPreview.vue').default,
                      props: true,
                    },
                    {
                      path: ':projectId/:id',
                      componen: ContainerView,
                      name: 'progressProgressItemView',
                      component: require('./views/progress/progress/ProjectPreview.vue').default,
                      props: true,
                    },
                  ],
                },
                {
                  path: ':projectId',
                  componen: ContainerView,
                  name: 'progressProgressProject',
                  component: require('./views/progress/progress/Project.vue').default,
                  props: true,
                },
                {
                  path: ':projectId/:id',
                  componen: ContainerView,
                  name: 'progressProgressItem',
                  component: require('./views/progress/progress/Project.vue').default,
                  props: true,
                },
                {
                  path: '',
                  name: 'progressProgressProjectsList',
                  component: require('./views/progress/progress/Projects.vue').default,
                },
              ],
            },
            {
              path: '*',
              name: 'AdminMissing',
              component: ErrorView,
            },
          ],
        },
      ],
    },
    {
      path: '/cam/:vId',
      name: 'CamView',
      component: require('./views/admin/Cams/View.vue').default,
      props: (route) => ({
        moduleUrl: '/admin/cams',
        model: 'cams',
        moduleName: 'Cams',
        hideControls: Boolean(Number(route.query.hideControls)),
        ...route.params,
      }),
    },
    {
      path: '/map/:vId',
      name: 'MapView',
      component: require('./views/admin/Trackings/View.vue').default,
      props: () => ({}),
    },
    {
      path: '/screen',
      name: 'DashboardScreen',
      component: require('./views/admin/ScreenViewV3/ScreensContainer.vue').default,
      props: () => ({
        screen: 'screenView',
      }),
    },
    {
      path: '/data-center',
      name: 'DataCenterScreen',
      component: require('./views/admin/ScreenViewV3/ScreensContainer.vue').default,
      props: () => ({
        screen: 'dataCenter',
      }),
    },
    {
      path: '/l',
      name: 'QuickLinks',
      component: ContainerView,
      children: [
        {
          path: 'guest',
          component: ContainerView,
          children: [
            {
              path: ':projectShortCode',
              component: require('./views/QuickLinks/GuestSignup.vue').default,
            },
          ],
        },
      ],
    },
    {
      path: '/ssl-root',
      name: 'SslRootCertFix',
      component: require('./views/ssl-root.vue').default,
      props: () => ({}),
    },
  ],
  componentsConfig: {
    common: {
      appName: '智慧工地', // eslint-disable-next-line global-require
      appLogo: require('./assets/images/logo/logo-horizontal.png'),
      owner: '同川信息',
      powerBy: '技术支持：上海熠世信息技术有限公司',
      moduleUrlBase: '/admin',
    },
    container: {
      maxWidth: '1100px',
    },
    adminModelPicker: {
      getApi: (
        apiName, // eslint-disable-next-line implicit-arrow-linebreak
      ) => `/api/${apiName.split('/').map(_.kebabCase).join('/')}/list`,
      getModuleUrl: (apiName) => `/admin/${apiName}`,
      getViewUrlById: (apiName, id) => `/admin/${apiName}/edit/${id}`,
    },
  },
  store,
});

Vue.component('ui-uploads', require('./components/UiUploads.vue').default);
