<script>
export default {
  name: 'BlueprintImageThumbs',
  props: {
    images: { type: Array, default: () => [] },
    currentImage: { type: Object, default: null },
  },
  emits: ['select'],
};
</script>

<template lang="pug">
div.thumbs
  div(
    v-for="item in images"
    :key="item.id"
    @click="$emit('select', item)"
    :class="[{ active: currentImage && item.id === currentImage.id }]"
  ).thumb
    img(
      :src="item.url"
      :title="item.title"
      :alt="item.title"
    ).thumb-img
</template>

<style scoped lang="scss">
.thumbs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.thumb {
  margin: 4px;
  box-sizing: border-box;
  cursor: pointer;
  border: solid 2px #ddd;

  &.active {
    border: solid 2px blue;
  }
}

.thumb-img {
  display: block;
  height: 45px;
  width: auto;
}
</style>
