<template lang="pug">
div.calendar-chart
  .row-select-month
    i.el-icon-arrow-left.el-icon--left.cursor-pointer(@click="addMonth(-1)")
    span {{ month }}
    i.el-icon-arrow-right.el-icon--right.cursor-pointer(@click="addMonth(1)")
  table.calendar-box
    thead
      tr
        th 日
        th 一
        th 二
        th 三
        th 四
        th 五
        th 六
    tbody
      tr(
        v-for="(week, weekIndex) in weeks"
        :key="weekIndex"
      )
        td(
          v-for="(day, dayIndex) in week"
          :key="dayIndex"
        )
          div(
            :class="[{ active: day.isActive, today: day.isToday, future: day.isFuture }, ...day.classNames || []]"
            :style="{ backgroundColor: day.color }"
          ).cell {{ day.text }}
  Legends(:list="legends" center).mt
</template>

<script>
import { _, moment } from '@yishitec/web';
import Legends from '../Legends.vue';

export default {
  name: 'CalendarChart',
  props: {
    list: Array,
    field: String,
  },
  emits: ['select-month'],
  components: { Legends },
  data() {
    return {
      month: moment().format('YYYY-MM'),
      legends: [
        {
          color: '#906D22',
          title: '部分未上传',
          name: '未上传',
        },
        {
          color: '#15582A',
          title: '已上传',
          name: '已上传',
        },
      ],
    };
  },
  computed: {
    weeks() {
      const weeks = [];
      const today = moment().startOf('day');
      const startOfMonth = moment(`${this.month}-01`).startOf('month');
      const endOfMonth = moment(`${this.month}-01`).endOf('month');
      const start = moment(startOfMonth).startOf('week');
      const end = moment(endOfMonth).endOf('week');
      const day = moment(start);
      let dayOfWeek = 0;
      let weekIndex = 0;
      while (day <= end) {
        if (!weeks[weekIndex]) {
          weeks.push([]);
        }
        weeks[weekIndex].push({
          date: day.format('YYYY-MM-DD'),
          text: day.format('DD'),
          isActive: day >= startOfMonth && day <= endOfMonth,
          isToday: day.valueOf() === today.valueOf(),
          isFuture: day > today,
          color: this.getDateColor(day.format('YYYY-MM-DD')),
        });
        day.add(1, 'days');
        dayOfWeek += 1;
        if (dayOfWeek >= 7) {
          dayOfWeek = 0;
          weekIndex += 1;
        }
      }
      return weeks;
    },
  },
  mounted() {
    this.addMonth(0);
  },
  methods: {
    addMonth(delta) {
      this.month = moment(`${this.month}-01`).add(delta, 'months').format('YYYY-MM');
      this.$nextTick(() => {
        this.$emit('select-month', {
          month: this.month,
          from: _.first(_.first(this.weeks)).date,
          to: _.last(_.last(this.weeks)).date,
        });
      });
    },
    getDateColor(date) {
      const number = this.getDateNumber(date);
      if (number > 0) {
        return _.get(this.legends, [0, 'color'], '');
      }
      if (number === 0) {
        return _.get(this.legends, [1, 'color'], '');
      }
      return _.get(this.legends, [2, 'color'], '');
    },
    getDateNumber(date) {
      const list = _.filter(this.list, (i) => i.date === date);
      if (!list.length) return null;
      return _.sumBy(list, (i) => i[this.field]);
    },
  },
};
</script>

<style lang="less" scoped>
.calendar-box {
  width: 100%;
  border-collapse: collapse;
  background: none;
  border: solid 1px #2d373d;
  text-align: center;
  font-size: 12px;
  color: #fff;

  thead {
    tr {
      background: #2d373d;
    }
  }

  th {
    color: #9ba6ad;
    font-weight: normal;
  }

  th,
  td {
    padding: 2px;
  }

  .cell {
    opacity: 0.5;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 1000px;
    color: fadeout(#fff, 10);

    &.active {
      opacity: 1;
    }

    &.today {
      color: #fff;
      font-weight: bolder;
    }
  }
}

.row-select-month {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 10px 0;
}
</style>
