<template lang="pug">
div
  div.p-trace
    div.p-container
      el-breadcrumb
        el-breadcrumb-item 项目管理
        el-breadcrumb-item 项目列表
  div.p-page
    div.p-container
      SspTabs(
        v-model="tab"
        :tabs="tabs"
      )
        template(v-slot:toolbar)
          div.toolbar-label 排序：
          ui-data-select(
            v-model="sortBy"
            :options="sortByOptions"
          )
          el-input(
            v-model="keyword"
            placeholder="系统ID或关键词"
          ).ml
            template(v-slot:append)
              el-button(icon="el-icon-search")
      div(v-loading="loading")
        SspEmptyCard(
          v-if="!vData || !vData.length"
          :hint="'您可以通过“创建新的项目”来开始工作。'"
        )
        SspTable(
          v-else
          :value="vData"
          :columns="attributes"
          @handleRow="handleRow"
          :searchKeyword="keyword"
        )
          template(v-slot:addtime="{ row }") {{ row.addtime | formatDateTime }}
</template>

<script>
import { _, moment } from '@yishitec/web';

export default {
  name: 'ProressProjectsList',
  props: {},
  data() {
    return {
      tab: 'All',
      data: [],
      loading: false,
      sortBy: 'updatedAt',
      keyword: '',
    };
  },
  computed: {
    tabs() {
      return [
        // {
        //   key: 'All',
        //   badge: false,
        //   title: '全部',
        // },
        // {
        //   key: 'Wip',
        //   number: 0,
        //   title: '进行中',
        //   color: '#89C45A',
        // },
        // {
        //   key: 'Todo',
        //   number: 0,
        //   title: '未开始',
        //   color: '#E48080',
        // },
        // {
        //   key: 'Done',
        //   number: 0,
        //   title: '已结束',
        // },
      ];
    },
    vData() {
      return _.reverse(_.sortBy(this.data, [(item) => moment(item.updatedAt)]));
    },
    sortByOptions() {
      return [
        {
          value: 'updatedAt',
          title: '更新时间',
        },
      ];
    },
    attributes() {
      return [
        {
          name: 'id',
          title: 'ID',
        },
        { name: 'title', title: '项目名称' },
        { name: 'location', title: '项目地址' },
        { name: 'type', title: '项目类型' },
        { name: 'addtime', title: '创建时间' },
      ];
    },
  },
  watch: {
    tab() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const res = await this.withLoading(this.api('/api/projects/list'));
      if (res) {
        const { list } = res;
        this.data = list;
      }
      this.loading = false;
    },
    handleRow(row) {
      this.$router.push(`/progress/projects/edit/${row.id}`);
    },
  },
  filters: {
    formatDateTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>

<style lang="scss" scoped></style>
