<template lang="pug">
div
  div.p-trace
    div.p-container
      el-breadcrumb
        el-breadcrumb-item(
          to="/progress/projects"
        ) 项目管理
        el-breadcrumb-item(
          to="/progress/projects"
        ) 项目列表
        el-breadcrumb-item(
          v-if='projectId'
          :to='`/progress/projects/edit/${projectId}`'
        ) 项目详情
        el-breadcrumb-item 图示配置
  div.p-page
    div.p-card
      div.admin-form-page-wrapper
        AdminFormPage(
          :id='id'
          module='progress-blueprint'
          prefix='/progress/projects/blueprints'
          apiPrefix='/api/v3/progress-blueprint'
          ref='form'
          @load='handleFormLoaded'
        )
    div.p-card(v-if="id !== 'new'")
      div.p-card-title 管理图示
      ProgressBlueprintImages(
        :blueprintId="id"
        :addPathForItemId="addPathForItemId"
        @refreshChildren="refreshChildren"
        @startToBind="startToBind"
      )
    div.p-card(v-if='id !== "new"')
      ProgressBlueprintParts(
        :blueprintId='id'
        @startToBind="startToBind"
      )
</template>

<script>
import _ from 'lodash';
import ProgressBlueprintParts from './sections/ProgressBlueprintParts.vue';
import ProgressBlueprintImages from '../../../../components/ProgressBlueprintImages/index.vue';

export default {
  name: 'ProgressProjectBlueprintEdit',
  props: {
    id: { type: [String, Number], default: null },
  },
  components: {
    ProgressBlueprintParts,
    ProgressBlueprintImages,
  },
  data() {
    return {
      projectId: null,
      addPathForItemId: null,
      children: [],
    };
  },
  methods: {
    handleFormLoaded(res) {
      this.projectId = _.get(res, ['form', 'projectId']);
    },
    startToBind(ev) {
      const {
        id = null,
      } = ev || {};
      this.addPathForItemId = id;
    },
    refreshChildren() {
      this.addPathForItemId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-form-page-wrapper {
  margin: -12px;
}
</style>
