<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';
import { useRoute, useRouter, useNotification, $fetch, useFetch2 } from '../../composables';

const props = defineProps({
  id: [String, Number],
  module: String,
  prefix: String,
  apiPrefix: String,
  actions: Array,
  readonly: Boolean,
});

const emit = defineEmits(['update-field', 'load']);

const form = ref({});
const attributes = ref([]);

const vPrefix = computed(() => {
  return props.prefix || `/admin/${props.module}`;
});

const vApiPrefix = computed(() => {
  return props.apiPrefix || `/api/v3/${props.module}`;
});

const route = useRoute();

const { Notification } = useNotification();

const handleUpdateField = async ($event) => {
  emit('update-field', $event);
  if ($event.attribute && $event.attribute.fieldHook) {
    const res = await $fetch(`${$event.apiPrefix || vApiPrefix.value}/field`, {
      method: 'post',
      body: {
        field: $event.attribute.name,
        form: {
          ...$event.form,
          widgetAction: $event.widgetAction,
        },
      },
    });
    if (res.message) {
      Notification.warning({ title: res.message });
      return;
    }
    const { form, attributes } = res;
    const { updateForm, updateAttributes } = $event;
    form && updateForm(form);
    attributes && updateAttributes(attributes);
  }
};

const apiActions = ref(null);

const vActions = computed(() => {
  if (!props.actions && !apiActions.value) {
    return null;
  }
  return [...(props.actions || []), ...(apiActions.value || [])];
});

const { refresh } = useFetch2(`${vApiPrefix.value}/detail`, {
  body: {
    id: props.id || route.params.id,
    defaultValues: route.query,
  },
  success(data) {
    form.value = data.value.form;
    attributes.value = [...data.value.attributes];
    apiActions.value = data.value.actions || null;
    emit('load', data.value);
  },
});
</script>

<template lang="pug">
div
  AdminForm(
    :form.sync="form"
    :attributes.sync="attributes"
    :actions="vActions"
    :prefix="vPrefix"
    :apiPrefix="vApiPrefix"
    @update-field="handleUpdateField($event)"
    @refresh="refresh"
    :readonly="readonly"
  )
</template>
