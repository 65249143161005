<template lang="pug">
div(
  v-loading="isLoading"
  element-loading-text="拼命加载中"
  element-loading-spinner="el-icon-loading"
  element-loading-background="rgba(0, 0, 0, 0.8)"
  ref="screenContainer"
).data-center-screen
  LeftContainer(:style="{ width: sidebarWidth }" @action="handleAction")
    el-select(
      v-if="!projectId"
      v-model="projectId"
      placeholder="全部项目"
      filterable
      clearable
      style='width: 100%'
      @change='loadData'
    )
      el-option(
        v-for="project in projects"
        :key="project.projectId"
        :label="project.title"
        :value="project.projectId"
      )
    el-button.picker-button(
      v-else
      @click='() => {projectId = null; loadData()}'
      icon='el-icon-arrow-left'
    ) 返回全部项目
    SideCards(:cards="cards" @sub-window="showSubWindow")
  .stage
    HeaderNavs(:style="{ top: '31px', left: '40px' }" darker @action="handleAction")
    HeaderDataCenterNav(
      active="data-center"
      @leave="closeLeftSidebar"
      @switch="$emit('switch', $event)"
    )
    HeaderDate(
      :style="{ top: '31px', right: '40px' }"
      theme="darker"
      :weather="weather"
    )
    Dashboard(
      :projects="projects"
      :history="history"
      @sub-window="showSubWindow"
    ).dashboard
    SubWindow(
      v-if="subWindow"
      @close="showSubWindow(null)"
    )
      SubWindowLoader(
        :projects='projects'
        v-bind='subWindow'
      )
</template>

<script>
import LeftContainer from './LeftContainer.vue';
import SideCards from './SideCards.vue';
import HeaderDate from '../ScreenViewV3/HeaderDate.vue';
import HeaderNavs from '../ScreenViewV3/HeaderNavs.vue';
import HeaderDataCenterNav from '../ScreenViewV3/HeaderDataCenterNav.vue';
import Dashboard from './Dashboard.vue';
import SubWindow from './SubWindow.vue';
import SubWindowLoader from './SubWindowLoader.vue';

import genCards from './genCards';
import enterFullScreen from '../ScreenViewV3/enterFullScreen';
import getSubWindowNormal from './getSubWindowNormal';

export default {
  name: 'DataCenterScreen',
  emits: ['switch'],
  components: {
    LeftContainer,
    SideCards,
    HeaderDate,
    HeaderNavs,
    HeaderDataCenterNav,
    Dashboard,
    SubWindow,
    SubWindowLoader,
  },
  data() {
    return {
      projects: [],
      history: [],
      sidebarWidth: '0',
      sidebarWidthFinal: '300px',
      isLoading: true,
      subWindow: null,
      projectId: null,
      weather: null,
    };
  },
  computed: {
    cards() {
      return genCards(this.projects);
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.loadData();
    this.isLoading = false;
    this.afterMount();
  },
  methods: {
    afterMount() {
      this.expandLeftSidebar();
    },
    expandLeftSidebar() {
      this.sidebarWidth = this.sidebarWidthFinal;
    },
    closeLeftSidebar() {
      this.sidebarWidth = '0';
    },
    async loadData() {
      const res = await this.api('/api/reports/data-center-screen', {
        ...(this.projectId
          ? {
              projectIds: [this.projectId],
            }
          : {}),
      })();
      const { projects = [], history = [] } = res || {};
      this.projects = projects;
      this.history = history;

      this.loadWeather();

      setTimeout(this.loadData, 60 * 1000);
    },
    async loadWeather() {
      this.weather = await this.api('/api/v3/weather-history/now')();
    },
    handleAction(action) {
      if (action === 'full-screen') {
        enterFullScreen(this.$refs.screenContainer);
      }
    },
    showSubWindow(subWindowName) {
      this.subWindow = getSubWindowNormal(subWindowName);
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../assets/styles/app-mixins.less';

.data-center-screen {
  background: #050d1b;
  color: #fff;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.stage {
  position: relative;
  flex: 1 0 1px;
  box-sizing: border-box;
  padding: 91px 40px 0 40px;
  background-image: linear-gradient(180deg, #242e34 0%, #000000 100%);
  width: calc(100% - 300px);
}

.dashboard {
  width: 100%;
  height: 100%;
  padding-bottom: 34px;
  box-sizing: border-box;
  margin-right: -10px;
  padding-right: 10px;
  .thin-scroll();
  overflow-y: auto;
  position: relative;
}

.picker-button {
  text-align: center;
  color: #000;
  cursor: pointer;
  width: 100%;
}
</style>
