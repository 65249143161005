module.exports = {
  icon: 'images/data-center-screen/boards/icon-danger-zones.svg',
  title: '危险源管理',
  // apiUrl: '/api/reports/data-center-screen/sub-windows/danger-zones',
  indicators: [
    {
      title: '今日作业设备',
      n: 'devicesToday',
      total: 'devicesTotal',
    },
    {
      title: '今日静态危险源',
      n: 'staticDangerZonesToday',
      total: 'staticDangerZonesTotal',
    },
  ],
  table: [
    {
      title: '项目名称',
      name: 'title',
      width: '220px',
    },
    {
      title: '设备数量',
      name: '设备数量',
      width: '120px',
      type: 'stats',
      cols: ['devicesToday', 'devicesTotal'],
    },
    {
      title: '静态危险源数量',
      name: '静态危险源数量',
      width: '120px',
      type: 'stats',
      cols: ['staticDangerZonesToday', 'staticDangerZonesTotal'],
    },
    // {
    //   name: '查看详情',
    //   type: 'adminOps',
    //   subWindow: 'projectDangerZones',
    // },
    {},
  ],
};
