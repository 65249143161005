<template>
  <div>
    <cam-view
      v-if="data"
      :cam="data"
      style="width: 100%"
      :with-resize="false"
      :with-hd="false"
      layout="col"
      compact
      ref="camView"
      :hide-controls="hideControls"
    />
    <div class="back-app__offset" />
  </div>
</template>

<script>
import { adminEdit } from '@yishitec/web/mixins';
import CamView from '../../../components/admin/CamView/CamView.vue';

export default {
  name: 'Edit',
  mixins: [adminEdit],
  props: {
    hideControls: Boolean,
  },
  components: { CamView },
  metaInfo: {
    script: [
      {
        src: 'https://js.cdn.aliyun.dcloud.net.cn/dev/uni-app/uni.webview.1.5.2.js',
      },
    ],
  },
  data() {
    return {
      withCustomFields: false,
    };
  },
  computed: {
    isLoading() {
      return !this.data;
    },
    isOnline() {
      return this.data && this.data.state;
    },
  },
  methods: {
    goBack() {
      this.$refs.camView.stopPlayer();
      if (window.uni) {
        window.uni.navigateBack();
      }
    },
  },
};
</script>

<style lang="less">
.back-app {
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;

  &__button {
    width: 100%;
  }

  &__offset {
    height: 100px;
  }
}
</style>
